import React, { useImperativeHandle, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Counter } from './Counter'
import { StyledTextarea, Container, StyledLabel, TopContent } from './Textarea.style'
import { UIMessage } from '../UIMessage'
import { TextareaProps, TextareaSubComponents } from './Textarea.types'
import { StyledBody, StyledBrandBody } from '../Input/Input.style'
import { BodySize } from '../../design-tokens/typography/Body/Body'
import { addNamespace } from '../../utils/helpers'
import withBrand from '../../utils/withBrand'

const TextareaWithoutNamespace = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      isInvalid,
      message,
      helpText,
      className,
      lightBackground,
      messageProps,
      counterProps,
      value,
      'aria-describedby': ariaDescribedBy,
      label,
      hasCounter,
      charLimitMessage,
      isBrand,
      ...textareaProps
    },
    forwardRef
  ) => {
    const localRef = useRef<HTMLTextAreaElement>()
    useImperativeHandle(forwardRef, () => localRef.current)
    const { disabled, readOnly, ...restTextareaProps } = textareaProps

    const [content, setContent] = useState(value)
    const [characterCount, setCharacterCount] = useState(content.length)

    const messageId = textareaProps.id ? `${textareaProps.id}-message` : undefined
    const counterId = textareaProps.id ? `${textareaProps.id}-counter` : undefined
    const describedByElements = [ariaDescribedBy, counterProps?.id, messageId].filter(Boolean)
    const definedMaxLength = textareaProps.maxLength ?? 400
    const isOverLimit = hasCounter && content.length === definedMaxLength

    const onChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      let updatedText = event.target.value
      if (hasCounter) {
        updatedText = updatedText.slice(0, definedMaxLength)
      }

      setContent(updatedText)
      setCharacterCount(updatedText.length)
      textareaProps.onChange?.(event)
    }

    const currentContent = hasCounter ? content.slice(0, definedMaxLength) : content

    const onClick = (event: React.MouseEvent<HTMLTextAreaElement>) => {
      // prevent click event to propagate to the container, causing double focus.
      event.stopPropagation()
      textareaProps.onClick?.(event)
    }

    const focus = () => {
      localRef.current?.select()
    }

    /**
     * Prevent default when clicking on anything else except input-tag
     * Input element would otherwise lose focus
     * @param event
     */
    const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
      const targetElem = event.target as HTMLElement
      if (targetElem.tagName && targetElem.tagName !== 'TEXTAREA') {
        event.preventDefault()
      }
    }

    return (
      <div onMouseDown={onMouseDown} className={className} role="none">
        <Container
          onClick={focus}
          isInvalid={isInvalid}
          hasMessage={Boolean(message)}
          readOnly={readOnly || disabled}
          aria-disabled={disabled}
          lightBackground={lightBackground}
          hasValue={Boolean(content)}
          isOverLimit={isOverLimit}
          isBrand={isBrand}
        >
          <TopContent>
            <StyledLabel htmlFor={textareaProps.id} aria-disabled={disabled} isBrand={isBrand}>
              {label}
            </StyledLabel>
            {hasCounter && (
              <Counter
                id={counterId}
                length={characterCount}
                maxLength={definedMaxLength}
                role="region"
                aria-live="polite"
                {...counterProps}
              />
            )}
          </TopContent>
          <StyledTextarea
            ref={localRef}
            aria-disabled={disabled}
            aria-readonly={readOnly}
            aria-invalid={isInvalid}
            aria-describedby={describedByElements.length ? describedByElements.join(' ') : undefined}
            {...restTextareaProps}
            value={currentContent}
            onChange={onChange}
            onClick={onClick}
            isBrand={isBrand}
            readOnly={readOnly || disabled}
            aria-atomic={true}
            aria-live="assertive"
          />
        </Container>
        {!!helpText &&
          (isBrand ? (
            <StyledBrandBody size={BodySize.Five}>{helpText}</StyledBrandBody>
          ) : (
            <StyledBody size={BodySize.Five}>{helpText}</StyledBody>
          ))}
        <div id={messageId} aria-live="assertive">
          {!!charLimitMessage ? (
            !!message ? (
              <UIMessage
                success={!isOverLimit ? !isInvalid : false}
                message={!isOverLimit ? message : charLimitMessage}
                isBrand={isBrand}
                {...messageProps}
              />
            ) : (
              isOverLimit && (
                <UIMessage success={false} message={charLimitMessage} isBrand={isBrand} {...messageProps} />
              )
            )
          ) : (
            !!message && (
              <UIMessage
                success={!isOverLimit ? !isInvalid : false}
                message={message}
                isBrand={isBrand}
                {...messageProps}
              />
            )
          )}
        </div>
      </div>
    )
  }
)

TextareaWithoutNamespace.displayName = 'Textarea'

TextareaWithoutNamespace.propTypes = {
  isInvalid: PropTypes.bool,
  message: PropTypes.string,
  charLimitMessage: PropTypes.string,
  helpText: PropTypes.string,
  messageProps: PropTypes.object,
  lightBackground: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  maxLength: PropTypes.number,
  hasCounter: PropTypes.bool,
}

TextareaWithoutNamespace.defaultProps = {
  lightBackground: true,
  value: '',
  hasCounter: true,
  charLimitMessage: 'The text hits the character limit',
}

const BrandTextareaWithoutNamespace = withBrand(TextareaWithoutNamespace)

const textareaSubComponents: TextareaSubComponents = {
  Container: Container,
  TopContent: TopContent,
  Label: StyledLabel,
  Counter: Counter,
  Textarea: StyledTextarea,
  Body: StyledBody,
  BrandBody: StyledBrandBody,
  UIMessage: UIMessage,
}

export const Textarea = addNamespace(TextareaWithoutNamespace, textareaSubComponents)
export const BrandTextarea = addNamespace(BrandTextareaWithoutNamespace, textareaSubComponents)
