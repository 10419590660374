import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const LocateIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M20 12C20 16.4183 16.4183 20 12 20M20 12C20 7.58172 16.4183 4 12 4M20 12H22M12 20C7.58172 20 4 16.4183 4 12M12 20V22M4 12C4 7.58172 7.58172 4 12 4M4 12H2M12 4V2M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z" />
  </SVG>
)

LocateIcon.propTypes = defaultIconPropTypes
LocateIcon.defaultProps = defaultPictographIconProps

export { LocateIcon }
