import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const DoorIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M9.50195 20H7.50195C6.39738 20 5.50195 19.1046 5.50195 18V6C5.50195 4.89543 6.39738 4 7.50195 4H16.502C17.6065 4 18.502 4.89543 18.502 6V12" />
    <Path d="M16.1097 4.47845L10.1097 5.67845C9.17487 5.86542 8.50195 6.68625 8.50195 7.63961V19.5604C8.50195 20.8225 9.6566 21.7691 10.8942 21.5216L16.8942 20.3216C17.829 20.1346 18.502 19.3138 18.502 18.3604V6.43961C18.502 5.17751 17.3473 4.23093 16.1097 4.47845Z" />
    <Path d="M12.502 13L11.502 13.2" />
  </SVG>
)

DoorIcon.propTypes = defaultIconPropTypes
DoorIcon.defaultProps = defaultPictographIconProps

export { DoorIcon }
