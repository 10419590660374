import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, Rect, SVG } from '../icons.styled'

const LaptopIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.23395 3C4.677 3 4.22939 3.44986 4.22939 4L4.2294 14H20.2704L20.2704 4C20.2704 3.44986 19.8228 3 19.2659 3L5.23395 3ZM20.5502 16H3.94964L3.0528 18.6844C2.83753 19.3288 3.31739 20 4.00602 20H20.4938C21.1825 20 21.6623 19.3288 21.4471 18.6844L20.5502 16ZM2.2294 14.8374L2.22939 4C2.22939 2.341 3.57673 1 5.23395 1H19.2659C20.9231 1 22.2704 2.341 22.2704 4L22.2704 14.8374L23.344 18.0507C23.9941 19.9965 22.5417 22 20.4938 22H4.00602C1.9582 22 0.505786 19.9965 1.15586 18.0507L2.2294 14.8374ZM12.25 4.70801C12.1119 4.70801 12 4.59608 12 4.45801C12 4.31994 12.1119 4.20801 12.25 4.20801C12.3881 4.20801 12.5 4.31994 12.5 4.45801C12.5 4.59608 12.3881 4.70801 12.25 4.70801ZM13.5 4.45801C13.5 3.76765 12.9404 3.20801 12.25 3.20801C11.5596 3.20801 11 3.76765 11 4.45801C11 5.14836 11.5596 5.70801 12.25 5.70801C12.9404 5.70801 13.5 5.14836 13.5 4.45801ZM5.03125 17C4.46171 17 4 17.4477 4 18C4 18.5523 4.46171 19 5.03125 19H19.4688C20.0383 19 20.5 18.5523 20.5 18C20.5 17.4477 20.0383 17 19.4688 17H5.03125Z"
      fill={color}
    />
  </SVG>
)

LaptopIcon.propTypes = defaultIconPropTypes
LaptopIcon.defaultProps = defaultPictographIconProps

export { LaptopIcon }
