import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'
import { useTheme } from '../../../utils/useTheme'

const ParcelLargePlusIcon: React.FC<DefaultIconProps> = (props) => {
  const theme = useTheme()
  return (
    <SVG {...props}>
      <Path
        d="M0 19.5C0 9.97029 5.27703 0 22 0C38.723 0 44 9.97029 44 19.5C44 29.0304 38.723 39 22 39C5.27703 39.0007 0 29.1036 0 19.5Z"
        fill={theme.color.brandPeach}
      />
      <Path d="M26.353 24.7061V29H13.0637V10.9276H18.5806V24.7061H26.353Z" fill={theme.color.brandNavy} />
      <Path
        d="M36.789 16.4824H33.3556V20.0287H30.2836V16.4824H26.8501V13.6136H30.2836V10.0673H33.3556V13.6136H36.789V16.4824Z"
        fill={theme.color.brandNavy}
      />
    </SVG>
  )
}

ParcelLargePlusIcon.defaultProps = {
  viewBox: '0 0 44 39',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}

export { ParcelLargePlusIcon }
