import React from 'react'
import { DefaultTheme, StyledComponent } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps, IconProps } from '../../design-tokens/icons/icons.types'
import { StyledListContainer, RadioCheck, StyledContent, StyledIcon, CheckboxCheck } from './ListItemSection.styles'
import { useTheme } from '../../utils/useTheme'
import { FilterButtonProps } from '../FilterButton/FilterButton.types'
import { CheckmarkIcon } from '../../brand'

export interface ListItemSectionProps extends Omit<FilterButtonProps, 'withCloseIcon'> {
  icon?: React.ComponentType<DefaultIconProps>
  isCard?: boolean
  isCheckbox?: boolean
}

export interface ListItemSectionSubComponents {
  Container: StyledComponent<'button', DefaultTheme, { selected: boolean }, never>
  Content: StyledComponent<'div', DefaultTheme, NonNullable<unknown>, never>
  Radio?: StyledComponent<'div', DefaultTheme, { selected: boolean }, never>
  Checkbox?: StyledComponent<'div', DefaultTheme, { selected: boolean }, never>
  Icon: StyledComponent<React.FC<DefaultIconProps>, DefaultTheme, IconProps, never>
}

export const ListItemSection: React.FC<ListItemSectionProps> & ListItemSectionSubComponents = (props) => {
  const { icon: Icon, disabled, isCard, isCheckbox, children, selected, onClick, value, ...rest } = props
  const theme = useTheme()

  const handleClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    onClick(e, value)
  }

  return (
    <StyledListContainer
      {...rest}
      disabled={disabled}
      isCard={isCard}
      isCheckbox={isCheckbox}
      selected={selected}
      aria-disabled={disabled}
      aria-pressed={selected}
      value={value}
      onClick={handleClick}
    >
      {Icon && (
        <StyledIcon
          as={Icon}
          width={`${theme.iconSize.s}rem`}
          height={`${theme.iconSize.s}rem`}
          color={disabled ? theme.brand.color.gray30 : theme.brand.color.gray70}
        />
      )}
      <StyledContent>{children}</StyledContent>
      {children &&
        (isCheckbox ? (
          <CheckboxCheck selected={selected} disabled={disabled}>
            {selected && <CheckmarkIcon color={theme.color.neutralWhite} />}
          </CheckboxCheck>
        ) : (
          <RadioCheck selected={selected} disabled={disabled} />
        ))}
    </StyledListContainer>
  )
}

ListItemSection.Container = StyledListContainer
ListItemSection.Content = StyledContent
ListItemSection.Radio = RadioCheck
ListItemSection.Checkbox = CheckboxCheck
ListItemSection.Icon = StyledIcon

ListItemSection.propTypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  isCard: PropTypes.bool,
  isCheckbox: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

ListItemSection.defaultProps = {
  isCard: false,
  isCheckbox: false,
  disabled: false,
}
