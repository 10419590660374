import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const PostiLockersIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M14 4H2V9L14 9V4ZM15 4V12L22 12V4H15ZM22 13H15V20H22V13ZM14 20V16L2 16V20H14ZM2 15L14 15V10L2 10V15ZM17 6V10H18V9H19C19.5523 9 20 8.55228 20 8V7C20 6.44772 19.5523 6 19 6H17ZM19 7V8H18V7H19Z"
      fill={color}
    />
  </SVG>
)

PostiLockersIcon.propTypes = defaultIconPropTypes

PostiLockersIcon.defaultProps = defaultPictographIconProps
