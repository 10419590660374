import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Rect } from '../icons.styled'

const MinusIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Rect y="0.5" width="16" height="3" fill={color} />
  </SVG>
)

MinusIcon.propTypes = defaultIconPropTypes

MinusIcon.defaultProps = {
  ...defaultPictographIconProps,
  viewBox: '0 0 16 4',
}

export { MinusIcon }
