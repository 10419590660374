import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const FaceSmileIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M8.5 14C8.5 14 10 16 12.5 16C15 16 16.5 14 16.5 14M15.5 9H15.51M9.5 9H9.51M22.5 12C22.5 17.5228 18.0228 22 12.5 22C6.97715 22 2.5 17.5228 2.5 12C2.5 6.47715 6.97715 2 12.5 2C18.0228 2 22.5 6.47715 22.5 12ZM16 9C16 9.27614 15.7761 9.5 15.5 9.5C15.2239 9.5 15 9.27614 15 9C15 8.72386 15.2239 8.5 15.5 8.5C15.7761 8.5 16 8.72386 16 9ZM10 9C10 9.27614 9.77614 9.5 9.5 9.5C9.22386 9.5 9 9.27614 9 9C9 8.72386 9.22386 8.5 9.5 8.5C9.77614 8.5 10 8.72386 10 9Z" />
  </SVG>
)

FaceSmileIcon.propTypes = defaultIconPropTypes
FaceSmileIcon.defaultProps = defaultPictographIconProps

export { FaceSmileIcon }
