import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const SortIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M7.91 21l-.7.7.7.72.71-.72-.7-.7zm4.2-5.66l-4.9 4.96 1.41 1.4 4.91-4.95-1.42-1.4zM8.62 20.3l-4.91-4.96-1.42 1.41L7.2 21.7l1.42-1.4z"
      fill={color}
    />
    <Path d="M7.91 9.54V20.7" stroke={color} strokeWidth="2" strokeLinejoin="round" />
    <Path
      d="M16.09 3l.7-.7-.7-.72-.71.72.7.7zm-4.2 5.66l4.9-4.96-1.41-1.4-4.91 4.95 1.42 1.4zm3.49-4.96l4.91 4.96 1.42-1.41L16.8 2.3l-1.42 1.4z"
      fill={color}
    />
    <Path d="M16.09 14.46V3.3" stroke={color} strokeWidth="2" strokeLinejoin="round" />
  </SVG>
)

SortIcon.propTypes = defaultIconPropTypes

SortIcon.defaultProps = defaultPictographIconProps

export { SortIcon }
