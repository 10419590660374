import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const ArrowLeftIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M6.70339 12L12.7606 18.127L10.909 20L3.00006 12L10.909 4L12.7606 5.87299L6.70339 12Z"
      fill={color}
    />
    <Path fillRule="evenodd" d="M5.28811 10.6755L21 10.6755L21 13.3243L5.28811 13.3243L5.28811 10.6755Z" fill={color} />
  </SVG>
)

ArrowLeftIcon.propTypes = defaultIconPropTypes

ArrowLeftIcon.defaultProps = defaultPictographIconProps
