import styled, { css } from 'styled-components'

import { CheckmarkCircledIcon } from '../../xyz'
import { Position } from '../../utils/enums'
import { MessageContainer } from '../UIMessage/UIMessage'

export const StyledRadio = styled.div(
  ({ theme: { color } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0.1875rem solid ${color.neutralIconGray};
    border-radius: 50%;
    background-color: ${color.neutralWhite};
    opacity: 1;
    transition: all 100ms ease-in;
  `
)

export const StyledSuccessIcon = styled(CheckmarkCircledIcon)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: calc(100% + 0.7rem);
  height: calc(100% + 0.7rem);
`

export const StyledInput = styled.input(
  ({ theme: { zIndex } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: ${zIndex.zIndex2};
    opacity: 0;
  `
)

export const StyledIconWrapper = styled.div(
  ({ theme: { color } }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 100ms ease-in;
    background-color: ${color.neutralWhite};
    border-radius: 50%;
  `
)

export const StyledContainer = styled.div<{ small: boolean; success: boolean; isInvalid: boolean; isBrand: boolean }>(
  ({ theme: { color, brand }, isInvalid, small, success, isBrand }) => css`
    position: relative;
    width: ${small ? 1.5 : 2}rem;
    min-width: ${small ? 1.5 : 2}rem;
    height: ${small ? 1.5 : 2}rem;
    display: inline-block;
    vertical-align: middle;
    user-select: none;
    transition: all 100ms ease-in;
    align-self: flex-start;

    ${StyledInput} {
      &:not([aria-disabled='true']) {
        cursor: pointer;
      }
    }

    ${StyledInput} ~ ${StyledRadio} {
      ${isBrand &&
      css`
        border-color: ${brand.color.gray50};
      `}
    }

    /* Focus styles */
    ${StyledInput} {
      &:not([aria-disabled='true']) {
        &:checked:focus ~ ${StyledRadio} {
          border-color: ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
        }
        &:focus ~ ${StyledRadio}, &:focus ~ ${StyledIconWrapper} {
          box-shadow: ${isBrand
            ? `0 0 0 2px ${brand.color.white}, 0 0 0 4px ${brand.color.postiOrange60}`
            : `0 0 0.4375rem ${color.signalBlue}`};
          border-color: ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
        }
      }
    }

    ${StyledInput}:focus:not(:focus-visible) ~ ${StyledRadio}, 
    ${StyledInput}:focus:not(:focus-visible) ~ ${StyledIconWrapper} {
      outline: none;
      box-shadow: none;
      ${isBrand &&
      css`
        border-color: ${brand.color.gray50};
      `}
    }

    /* Active styles */
    ${!success &&
    `
      ${StyledInput}:checked ~ ${StyledRadio} {
        border: ${small ? 0.5 : 0.625}rem solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
      }
    `}
    ${isBrand &&
    css`
      ${StyledInput} {
        &:not([aria-disabled='true']) {
          &:hover:active ~ ${StyledRadio} {
            border-color: ${brand.color.gray70};
            background-color: ${brand.color.gray20};
          }
          &:checked:hover:active ~ ${StyledRadio} {
            border-color: ${brand.color.postiOrange80};
            background-color: ${brand.color.gray20};
          }
        }
      }
    `}


    /* Hover styles */
    @media (hover: hover) {
      ${StyledInput} {
        &:not([aria-disabled='true']) {
          &:hover ~ ${StyledRadio} {
            border-color: ${isBrand ? brand.color.gray60 : color.neutralNetworkGray};
            background-color: ${isBrand ? brand.color.gray10 : color.neutralWhite};
          }
          &:checked:hover ~ ${StyledRadio} {
            border-color: ${isBrand ? brand.color.postiOrange70 : color.signalBlue};
          }
        }
      }
    }

    ${success &&
    !isInvalid &&
    css`
      ${StyledInput}:checked ~ ${StyledRadio} {
        opacity: 0;
      }

      ${StyledInput}:checked ~ ${StyledIconWrapper} {
        opacity: 1;
      }
    `}

    /* Disabled styles */
      ${StyledInput} {
      &[aria-disabled='true'] {
        ~ ${StyledRadio}, ${StyledSuccessIcon} {
          border-color: ${isBrand ? brand.color.gray30 : color.neutralGray5};
        }
      }
    }

    ${isInvalid &&
    css`
      ${StyledInput} {
        &:not([aria-disabled='true']) {
          ~ ${StyledRadio}, &:focus ~ ${StyledRadio}, &:checked ~ ${StyledRadio}, &:checked:focus ~ ${StyledRadio} {
            border: 0.1875rem solid ${isBrand ? brand.color.red60 : color.signalRed};
          }
        }
      }

      @media (hover: hover) {
        ${StyledInput} {
          &:not([aria-disabled='true']) {
            &:hover ~ ${StyledRadio} {
              border: 0.1875rem solid ${isBrand ? brand.color.red70 : color.signalRed};
            }
            &:checked:hover {
              border: 0.1875rem solid ${isBrand ? brand.color.red60 : color.signalRed};
            }
            &:focus:hover ~ ${StyledRadio} {
              border: 0.1875rem solid ${isBrand ? brand.color.red80 : color.signalRed};
            }
          }
        }
      }
    `}
  `
)

export const StyledLabel = styled.label<{ labelPosition: Position; isBrand: boolean }>(
  ({ theme: { spacing, brand }, labelPosition, isBrand }) => css`
    display: flex;
    align-items: center;
    cursor: pointer;
    &[aria-disabled='true'] {
      cursor: default;
      pointer-events: none;
    }

    ${isBrand &&
    css`
      font-family: ${brand.fontFamily.PostiFont};
      line-height: ${brand.lineHeight.label};
      font-weight: ${brand.fontWeight.medium};
    `}

    ${labelPosition === Position.left &&
    `
      ${StyledContainer} {
        margin-left: ${spacing.space7}rem;
      }
    `}

    ${labelPosition === Position.right &&
    `
      ${StyledContainer} {
        margin-right: ${spacing.space3}rem;
      }
    `}
  `
)

export const StyledRoot = styled.div<{ isBrand?: boolean }>`
  ${({ isBrand }) => css`
    display: inline-block;
    ${MessageContainer} {
      ${isBrand &&
      css`
        padding: 0;
      `}
    }
  `}
`
