import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, Rect, SVG } from '../brand-icons.styled'

const AdminIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M11 15.5H9.5C8.10444 15.5 7.40665 15.5 6.83886 15.6722C5.56045 16.06 4.56004 17.0605 4.17224 18.3389C4 18.9067 4 19.6044 4 21M16.5 7.5C16.5 9.98528 14.4853 12 12 12C9.51472 12 7.5 9.98528 7.5 7.5C7.5 5.01472 9.51472 3 12 3C14.4853 3 16.5 5.01472 16.5 7.5Z" />
    <Rect x="14" y="18" width="8" height="4" rx="1" />
    <Path d="M20 17V16C20 14.8954 19.1046 14 18 14V14C16.8954 14 16 14.8954 16 16V17" />
  </SVG>
)

AdminIcon.propTypes = defaultIconPropTypes
AdminIcon.defaultProps = defaultPictographIconProps

export { AdminIcon }
