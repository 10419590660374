import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { StyledProgressIndicator } from './ProgressIndicator.styles'
import { LabelSize } from '../../design-tokens/typography'
import { Size, LabelDirections, Placement } from '../../utils/enums'
import { ProgressIndicatorProps, ProgressIndicatorSubComponents } from './ProgressIndicator.types'

export function setLabelSize(type: Size | keyof typeof Size) {
  switch (type) {
    case 'sm':
      return LabelSize.Six
    case 'md':
      return LabelSize.Five
    case 'lg':
      return LabelSize.Four
    default:
      return LabelSize.Six
  }
}

export const ProgressIndicator: React.FC<ProgressIndicatorProps> & ProgressIndicatorSubComponents = ({
  vertical,
  stepSize,
  activeStep,
  tooltipPlacement,
  highlightColor,
  labelDirection,
  children,
}) => {
  return (
    <StyledProgressIndicator
      vertical={vertical}
      stepSize={stepSize}
      tooltipPlacement={tooltipPlacement}
      activeStep={activeStep}
      data-design-system-id="progress-indicator"
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child as React.ReactElement<any>, {
          labelDirection: labelDirection ? labelDirection : LabelDirections.left,
          highlightColor: highlightColor,
        })
      )}
    </StyledProgressIndicator>
  )
}

ProgressIndicator.Main = StyledProgressIndicator

ProgressIndicator.propTypes = {
  vertical: PropTypes.bool,
  stepSize: PropTypes.oneOf(Object.keys(Size)) as Validator<Size>,
  activeStep: PropTypes.number,
  tooltipPlacement: PropTypes.oneOf(Object.keys(Placement)) as Validator<Placement>,
  highlightColor: PropTypes.string,
  labelDirection: PropTypes.oneOf(Object.values(LabelDirections)) as Validator<LabelDirections>,
}

ProgressIndicator.defaultProps = {
  vertical: false,
  stepSize: Size.sm,
  activeStep: 0,
}
