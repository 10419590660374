import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, Rect, SVG } from '../brand-icons.styled'

const DoorstepIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M10 20H16C17.1046 20 18 19.1046 18 18V6C18 4.89543 17.1046 4 16 4H8C6.89543 4 6 4.89543 6 6V16" />
    <Rect x="4" y="17" width="6" height="5" rx="1" />
    <Path d="M10 12H9" />
  </SVG>
)

DoorstepIcon.propTypes = defaultIconPropTypes
DoorstepIcon.defaultProps = defaultPictographIconProps

export { DoorstepIcon }
