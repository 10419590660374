import React, { HTMLAttributes } from 'react'
import PropTypes, { Validator } from 'prop-types'
import styled, { css, DefaultTheme, StyledComponent } from 'styled-components'
import { CheckmarkCircledIcon } from '../../xyz'
import { useTheme } from '../../utils/useTheme'
import { Body, BodySize, BodyProps, Headline, HeadlineSize, HeadlineProps } from '../../design-tokens/typography'
import { Status } from '../../utils/enums'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'

export interface AllProps extends HTMLAttributes<HTMLDivElement> {
  status: keyof typeof Status
  title: string
  body: React.ReactNode
  statusPrefix?: string
}

export interface FormStepSubComponents {
  Container: StyledComponent<'div', DefaultTheme, Pick<AllProps, 'status'>, never>
  Title: StyledComponent<'div', DefaultTheme, HeadlineProps, never>
  Body: StyledComponent<'p', DefaultTheme, BodyProps, never>
  Icon: StyledComponent<React.FC<DefaultIconProps>, DefaultTheme, NonNullable<unknown>, never>
}

const StyledContainer = styled.div<{ status: keyof typeof Status }>(
  ({ theme: { spacing, color }, status, tabIndex }) => css`
    padding: ${spacing.space3}rem ${spacing.space4}rem ${spacing.space3}rem 1.25rem;
    position: relative;
    color: ${color.neutralBlack};
    max-width: 22rem;
    overflow: hidden;
    position: relative;
    outline: none;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 0.1875rem;
      background-color: ${status === Status.completed
        ? color.signalGreen
        : status === Status.active
          ? color.neutralPassiveGray
          : color.neutralGray5};
      transition: all 200ms ease-in;
    }

    & + & {
      margin-top: 0.125rem;
    }

    ${tabIndex >= 0 &&
    css`
      cursor: pointer;
      @media (hover: hover) {
        &:hover {
          background-color: ${color.neutralOnPressGray};
        }
      }
      &:focus {
        background-color: ${color.neutralOnPressGray};
      }
    `}
  `
)

const StyledTitle = styled(Headline)`
  ${({ theme: { spacing } }) => css`
    margin-bottom: ${spacing.space2}rem;
  `}
`

const StyledBody = styled(Body)`
  ${({ theme: { spacing } }) => css`
    margin-bottom: ${spacing.space0}rem;
  `}
`

const StyledIcon = styled(CheckmarkCircledIcon)`
  ${({ theme: { spacing } }) => css`
    position: absolute;
    right: ${spacing.space4}rem;
    top: ${spacing.space3}rem;
  `}
`

const FormStep: React.FC<AllProps> & FormStepSubComponents = (props) => {
  const { title, body, status, tabIndex, statusPrefix, ...rest } = props
  const { color } = useTheme()

  return (
    <StyledContainer
      status={status}
      tabIndex={tabIndex}
      {...rest}
      aria-label={rest['aria-label'] || `${statusPrefix ? `${statusPrefix}: ` : ''}${title}, ${body}`}
    >
      <StyledTitle size={HeadlineSize.Seven}>{title}</StyledTitle>
      <StyledBody size={BodySize.Four}>{body}</StyledBody>
      <StyledIcon
        width="1.25em"
        height="1.25em"
        aria-hidden={true}
        color={
          status === Status.completed
            ? color.signalGreen
            : status === Status.active
              ? color.neutralIconGray
              : color.neutralGray5
        }
      />
    </StyledContainer>
  )
}

FormStep.Container = StyledContainer
FormStep.Title = StyledTitle
FormStep.Body = StyledBody
FormStep.Icon = StyledIcon

FormStep.propTypes = {
  status: PropTypes.oneOf(Object.keys(Status)).isRequired as Validator<Status>,
  title: PropTypes.string.isRequired,
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  tabIndex: PropTypes.number,
  statusPrefix: PropTypes.string,
}

FormStep.defaultProps = {
  status: Status.incomplete,
  tabIndex: -1,
  // Role attribute is defined as "text" in order to VoiceOver to read all content.
  // It's valid, but is something that makes VoiceOver to work as expected
  role: 'text',
}

export default FormStep
