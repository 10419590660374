import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const ArchiveIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M19.125 9C19.2245 9 19.5 9 19.5 9C19.5 9 19.5 8.72446 19.5 8.625V4.5C19.5 4.10218 19.342 3.72064 19.0607 3.43934C18.7794 3.15804 18.3978 3 18 3H6.00001C5.60218 3 5.22065 3.15804 4.93935 3.43934C4.65804 3.72064 4.50001 4.10218 4.50001 4.5V8.625C4.50001 8.72446 4.5 9 4.5 9C4.5 9 4.77555 9 4.87501 9H19.125ZM13.6913 6.612L13.566 6.987C13.5163 7.13628 13.4209 7.26615 13.2933 7.35821C13.1657 7.45028 13.0124 7.49988 12.855 7.5H11.145C10.9877 7.49988 10.8343 7.45028 10.7067 7.35821C10.5791 7.26615 10.4837 7.13628 10.434 6.987L10.3088 6.612C10.2712 6.49925 10.261 6.37919 10.2789 6.26172C10.2969 6.14424 10.3425 6.03271 10.412 5.93632C10.4815 5.83993 10.5729 5.76144 10.6787 5.70731C10.7845 5.65318 10.9017 5.62497 11.0205 5.625H12.9795C13.0983 5.62497 13.2155 5.65318 13.3213 5.70731C13.4271 5.76144 13.5185 5.83993 13.588 5.93632C13.6575 6.03271 13.7031 6.14424 13.7211 6.26172C13.739 6.37919 13.7288 6.49925 13.6913 6.612Z"
      fill={color}
    />
    <Path
      d="M19.125 14.625C19.2245 14.625 19.5 14.625 19.5 14.625C19.5 14.625 19.5 14.3495 19.5 14.25V10.125C19.5 10.0255 19.5 9.75 19.5 9.75C19.5 9.75 19.2245 9.75001 19.125 9.75001H4.87502C4.77557 9.75001 4.5 9.75 4.5 9.75C4.5 9.75 4.50002 10.0255 4.50002 10.125V14.25C4.50002 14.3495 4.50012 14.625 4.50012 14.625C4.50012 14.625 4.77557 14.625 4.87502 14.625H19.125ZM10.4115 11.562C10.481 11.4654 10.5725 11.3868 10.6784 11.3325C10.7843 11.2783 10.9015 11.25 11.0205 11.25H12.9795C13.0984 11.25 13.2155 11.2782 13.3213 11.3323C13.4271 11.3864 13.5185 11.4649 13.588 11.5613C13.6575 11.6577 13.7031 11.7692 13.7211 11.8867C13.739 12.0042 13.7288 12.1243 13.6913 12.237L13.566 12.612C13.5163 12.7613 13.4209 12.8912 13.2933 12.9832C13.1657 13.0753 13.0124 13.1249 12.855 13.125H11.145C10.9877 13.1249 10.8344 13.0753 10.7068 12.9832C10.5792 12.8912 10.4837 12.7613 10.434 12.612L10.3088 12.237C10.2713 12.1244 10.261 12.0045 10.2789 11.8871C10.2967 11.7698 10.3422 11.6584 10.4115 11.562Z"
      fill={color}
    />
    <Path
      d="M4.87501 15.375C4.77555 15.375 4.5 15.375 4.5 15.375C4.5 15.375 4.50001 15.6505 4.50001 15.75V19.5C4.50001 19.8978 4.65805 20.2794 4.93935 20.5607C5.22065 20.842 5.60219 21 6.00001 21H18C18.3978 21 18.7794 20.842 19.0607 20.5607C19.342 20.2794 19.5 19.8978 19.5 19.5V15.75C19.5 15.6505 19.5 15.375 19.5 15.375C19.5 15.375 19.2245 15.375 19.125 15.375H4.87501ZM10.4115 17.187C10.481 17.0904 10.5725 17.0118 10.6784 16.9575C10.7842 16.9033 10.9015 16.875 11.0205 16.875H12.9795C13.0983 16.875 13.2155 16.9032 13.3213 16.9573C13.4271 17.0114 13.5185 17.0899 13.588 17.1863C13.6575 17.2827 13.7031 17.3942 13.7211 17.5117C13.739 17.6292 13.7288 17.7493 13.6913 17.862L13.566 18.237C13.5163 18.3863 13.4209 18.5162 13.2933 18.6082C13.1657 18.7003 13.0124 18.7499 12.855 18.75H11.145C10.9877 18.7499 10.8343 18.7003 10.7067 18.6082C10.5791 18.5162 10.4837 18.3863 10.434 18.237L10.3088 17.862C10.2713 17.7494 10.261 17.6295 10.2789 17.5121C10.2967 17.3948 10.3422 17.2834 10.4115 17.187Z"
      fill={color}
    />
  </SVG>
)

ArchiveIcon.propTypes = defaultIconPropTypes

ArchiveIcon.defaultProps = defaultPictographIconProps
