import React, {
  forwardRef,
  Ref,
  ForwardRefExoticComponent,
  WeakValidationMap,
  PropsWithoutRef,
  RefAttributes,
} from 'react'

type WithBrandProps<P> = P & { isBrand?: boolean }

const withBrand = <P extends { isBrand?: boolean }>(
  Component: ForwardRefExoticComponent<P>
): ForwardRefExoticComponent<Omit<P, 'isBrand'>> => {
  const WithBrand = forwardRef((props: WithBrandProps<P>, ref: Ref<unknown>) => {
    return <Component {...props} isBrand={true} ref={ref} />
  })

  // Hoist React static properties
  WithBrand.displayName = Component.displayName || Component.name

  // Define propTypes separately with the correct type
  WithBrand.propTypes = Component.propTypes as WeakValidationMap<
    PropsWithoutRef<WithBrandProps<P> & RefAttributes<unknown>>
  >

  return WithBrand as ForwardRefExoticComponent<Omit<P, 'isBrand'>>
}

export default withBrand
