import React, { useState } from 'react'

import { TabsContext, TabsContextValue } from './TabsContext'

// eslint-disable-next-line @typescript-eslint/ban-types
type TabContextProps = {}

/**
 * @deprecated This version of TabContext is deprecated
 */

function TabContext(props: React.PropsWithChildren<TabContextProps>): JSX.Element {
  const [selectedTab, setSelectedTab] = useState<string | number | boolean>('')
  const context: TabsContextValue = React.useMemo(
    () => ({
      selectedTab,
      setSelectedTab,
    }),
    [selectedTab, setSelectedTab]
  )

  return <TabsContext.Provider value={context} {...props} />
}

export default TabContext
