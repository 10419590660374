import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const MailboxIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M3 10.5C3 8.567 4.567 7 6.5 7V7C8.433 7 10 8.567 10 10.5V17H3V10.5Z" />
    <Path d="M8 2H10.5C10.7761 2 11 2.22386 11 2.5V2.5C11 2.77614 10.7761 3 10.5 3H8V2Z" />
    <Path d="M8 7L8 3" />
    <Path d="M12.002 21L12.002 18" />
    <Path d="M7 14H6" />
    <Path d="M7 7H17C19.2091 7 21 8.79086 21 11V17H7" />
  </SVG>
)

MailboxIcon.propTypes = defaultIconPropTypes
MailboxIcon.defaultProps = defaultPictographIconProps

export { MailboxIcon }
