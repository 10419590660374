import React from 'react'
import { Path, SVG } from '../icons/icons.styled'
import { DefaultIconProps } from '../icons/icons.types'

export interface PostiLogoProps extends DefaultIconProps {
  backgroundColor?: string
  textColor?: string
}

const PostiLogo: React.FC<PostiLogoProps> = ({
  backgroundColor = 'none',
  textColor = '#394B58',
  viewBox = '0 0 346 160',
  ...props
}) => (
  <SVG
    viewBox={viewBox}
    fill={backgroundColor}
    preserveAspectRatio="xMidYMid meet"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <Path
      d="M119.37 72.2162C120.668 59.2432 130.183 50.1622 143.59 50.1622C156.998 50.1622 166.513 59.2432 167.81 72.2162H189.003C187.273 48.4324 167.81 29.8378 143.59 29.8378C119.37 29.8378 99.9078 48.4324 98.1778 72.2162H119.37ZM70.4977 77.4054C70.0652 91.2432 59.6852 101.622 45.8451 101.622C32.0051 101.622 21.6251 91.6757 21.1926 77.4054H0V160H20.7601V111.568H21.1926C26.8151 117.189 35.0326 121.514 46.7101 121.514C71.7952 121.514 90.8252 102.919 91.6903 77.4054C91.6903 77.4054 70.4977 77.4054 70.4977 77.4054ZM21.1926 72.2162C22.4901 58.8108 32.4376 50.1622 45.8451 50.1622C59.2527 50.1622 69.2002 59.2432 70.4977 72.2162H91.6903C90.3927 48 71.7952 29.8378 47.5751 29.8378C35.8976 29.8378 26.8151 34.1622 21.1926 39.7838H20.7601V31.5676H0V72.2162H21.1926ZM313.996 119.784H334.756V77.4054H313.996V119.784ZM268.151 119.784H288.911V77.4054H268.151V119.784ZM243.931 77.4054H202.843C207.168 80.4324 214.088 83.8919 218.413 85.6216C227.063 89.0811 230.091 90.8108 230.091 95.1351C230.091 99.027 225.333 101.622 219.711 101.622C212.791 101.622 208.033 99.027 202.843 94.7027L189.003 108.541C197.221 116.757 205.438 121.946 219.711 121.946C235.281 121.946 250.851 111.135 250.851 94.7027C250.851 86.9189 248.256 81.2973 243.931 77.4054ZM168.243 77.4054C167.81 91.2432 157.863 101.622 144.023 101.622C130.183 101.622 120.235 91.6757 119.803 77.4054H98.6103C99.4753 102.054 118.938 121.514 144.023 121.514C169.108 121.514 188.571 102.054 189.436 77.4054H168.243ZM335.188 31.5676H314.428V72.2162H335.188V31.5676ZM268.151 72.2162H288.911V49.7297H307.941V31.5676H288.911V0H268.151V31.5676H252.581V49.7297H268.151V72.2162ZM237.876 72.2162C233.551 69.6216 229.226 67.8919 224.468 66.1622C216.251 63.5676 212.791 61.4054 212.791 56.6486C212.791 52.7568 216.251 50.1622 221.441 50.1622C226.631 50.1622 231.821 51.8919 236.146 56.2162L249.986 41.5135C241.768 34.1622 233.551 29.8378 221.873 29.8378C209.763 29.8378 193.328 39.3513 193.328 57.0811C193.328 63.1351 195.491 67.8919 198.518 71.7838L237.876 72.2162Z"
      fill={textColor}
    />
    <Path
      d="M331.727 4.75713C329.997 4.75713 326.97 7.35172 326.97 17.2977C329.997 14.7031 333.025 9.51388 333.025 6.48686C333.457 5.62199 333.025 4.75713 331.727 4.75713ZM318.32 11.676C317.022 11.676 316.59 12.5409 316.59 13.4058C316.59 16.0004 319.617 18.1625 322.645 18.595C322.645 18.1625 322.645 17.7301 322.645 17.7301C322.645 14.7031 320.915 11.676 318.32 11.676ZM310.967 19.4598C313.562 21.622 315.725 23.3517 317.887 23.3517C318.752 23.3517 319.617 22.9193 320.05 22.4869C315.292 21.1896 312.265 16.8652 312.265 13.4058C312.265 9.94631 314.86 7.35172 318.32 7.35172C320.482 7.35172 322.645 8.21659 323.51 9.94632C324.807 4.32469 328.267 0.865234 332.16 0.865234C335.62 0.865234 337.35 3.45983 337.35 7.35172C337.35 12.1085 333.025 19.4598 327.835 22.4869C328.267 23.3517 329.565 23.7842 330.862 23.7842C335.187 23.7842 339.512 19.8923 342.107 16.4328C342.54 16.0004 343.405 15.5679 343.837 15.5679C345.135 15.5679 346 16.4328 346 17.7301C346 18.1625 346 18.595 345.567 19.4598C342.972 22.4869 337.782 28.1085 331.295 28.1085C328.267 28.1085 326.105 26.8112 324.807 24.649C323.077 26.3787 320.915 28.1085 317.887 28.1085C314.86 28.1085 311.832 25.9463 309.237 24.2166C307.94 23.3517 306.642 22.0544 305.777 21.622C304.912 20.7571 304.047 20.3247 303.615 20.3247C301.885 20.3247 301.02 19.4598 301.02 18.1625C301.02 16.8652 301.885 16.0004 303.615 16.0004C304.48 16.0004 305.777 16.4328 307.075 17.2977C308.372 17.2977 309.67 18.1625 310.967 19.4598Z"
      fill={textColor}
    />
  </SVG>
)

PostiLogo.defaultProps = {
  width: '67',
  height: '32',
}

export { PostiLogo }
