import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'
import { useTheme } from '../../../utils/useTheme'

const ParcelXLIcon: React.FC<DefaultIconProps> = (props) => {
  const theme = useTheme()
  return (
    <SVG {...props}>
      <Path
        d="M0 19.5C0 9.97029 5.27703 0 22 0C38.723 0 44 9.97029 44 19.5C44 29.0304 38.723 39 22 39C5.27703 39.0007 0 29.1036 0 19.5Z"
        fill={theme.color.brandPurple}
      />
      <Path
        d="M19.0845 27L15.8065 22.864L12.8805 27H7.51248L13.2545 19.674L7.44648 12.37H12.8585L16.0925 16.418L18.9745 12.37H24.2765L18.6445 19.608L24.5625 27H19.0845ZM36.4361 23.524V27H25.6781V12.37H30.1441V23.524H36.4361Z"
        fill={theme.color.brandPink}
      />
    </SVG>
  )
}

ParcelXLIcon.defaultProps = {
  viewBox: '0 0 44 39',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}

export { ParcelXLIcon }
