import React from 'react'
import { DefaultTheme } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { useTheme } from '../../utils/useTheme'
import { StyledButton, Label } from './ToolButton.styles'
import { ToolButtonProps, ToolButtonSubComponents } from './ToolButton.types'
import { addNamespace } from '../../utils/helpers'

export const getToolButtonIconColor = (
  { selected, disabled }: Pick<ToolButtonProps, 'selected' | 'disabled'>,
  theme: DefaultTheme
): string => {
  if (disabled) {
    return theme.color.neutralPassiveGray
  }
  if (selected) {
    return theme.color.neutralWhite
  }
  return theme.color.neutralIconGray
}

const ToolButtonWithoutNamespace = React.forwardRef<HTMLButtonElement, ToolButtonProps>((props, ref) => {
  const { icon: Icon, text, selected, disabled, children, id, onClick, value, ...rest } = props
  const theme = useTheme()
  const iconProps = {
    width: `${theme.iconSize.s}em`,
    height: `${theme.iconSize.s}em`,
    color: getToolButtonIconColor({ selected, disabled }, theme),
  }

  const handleClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    onClick(e, value)
  }

  return (
    <StyledButton
      {...rest}
      ref={ref}
      selected={selected}
      aria-disabled={disabled}
      id={id}
      onClick={handleClick}
      aria-pressed={selected}
      value={value}
    >
      {!!Icon && <Icon aria-hidden={true} {...iconProps} />}
      {!!text && <Label withLeftMargin={!!Icon}>{text}</Label>}
      {children}
    </StyledButton>
  )
})

const toolbuttonSubComponents: ToolButtonSubComponents = {
  Button: StyledButton,
  Label: Label,
}

ToolButtonWithoutNamespace.displayName = 'ToolButton'

ToolButtonWithoutNamespace.propTypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  text: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}

ToolButtonWithoutNamespace.defaultProps = {
  selected: false,
}

export const ToolButton = addNamespace(ToolButtonWithoutNamespace, toolbuttonSubComponents)
