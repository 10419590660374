import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

const FacebookIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M17.6625 13.92C17.6514 14.0115 17.625 14.25 17.625 14.25C17.625 14.25 17.3796 14.2507 17.2875 14.25H15.75C15.6505 14.25 15.5552 14.2895 15.4848 14.3598C15.4145 14.4302 15.375 14.5255 15.375 14.625V20.625C15.375 20.7245 15.375 21 15.375 21C15.375 21 15.6505 21 15.75 21H20.25C20.4489 21 20.6397 20.921 20.7803 20.7803C20.921 20.6397 21 20.4489 21 20.25V3.75C21 3.55109 20.921 3.36032 20.7803 3.21967C20.6397 3.07902 20.4489 3 20.25 3H3.75C3.55109 3 3.36032 3.07902 3.21967 3.21967C3.07902 3.36032 3 3.55109 3 3.75V20.25C3 20.4489 3.07902 20.6397 3.21967 20.7803C3.36032 20.921 3.55109 21 3.75 21H12C12.0995 21 12.375 21 12.375 21C12.375 21 12.375 20.7245 12.375 20.625V14.625C12.375 14.5255 12.3355 14.4302 12.2652 14.3598C12.1948 14.2895 12.0995 14.25 12 14.25H10.5C10.4005 14.25 10.125 14.25 10.125 14.25C10.125 14.25 10.125 13.9745 10.125 13.875V11.625C10.125 11.5255 10.125 11.25 10.125 11.25C10.125 11.25 10.4005 11.25 10.5 11.25H12C12.0995 11.25 12.1948 11.2105 12.2652 11.1402C12.3355 11.0698 12.375 10.9745 12.375 10.875V9.8925C12.375 8.76069 12.8246 7.67523 13.6249 6.87492C14.4252 6.07461 15.5107 5.625 16.6425 5.625H17.625C17.7245 5.625 18 5.625 18 5.625C18 5.625 18 5.90054 18 6V8.25C18 8.34946 18 8.5 18 8.625C18 8.625 17.7245 8.625 17.625 8.625H16.6425C16.3063 8.625 15.9839 8.75854 15.7462 8.99624C15.5085 9.23394 15.375 9.55634 15.375 9.8925V10.875C15.375 10.9745 15.4145 11.0698 15.4848 11.1402C15.5552 11.2105 15.6505 11.25 15.75 11.25H17.5725C17.7133 11.249 18 11.25 18 11.25C18 11.25 17.9644 11.5305 17.9475 11.67L17.6625 13.92Z"
      fill={color}
    />
  </SVG>
)

FacebookIcon.propTypes = defaultIconPropTypes
FacebookIcon.defaultProps = defaultPictographIconProps

export { FacebookIcon }
