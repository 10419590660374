import React, { HTMLAttributes, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { DefaultTheme, StyledComponent } from 'styled-components'
import { nextItem, previousItem, ownerDocument, moveFocus } from '../../utils/keyboardNavigation'
import { StyledButton, StyledFilterList, StyledGroupLabel } from './FilterSelection.style'
import { FilterButton } from '../FilterButton/FilterButton'
import { FilterButtonProps } from '../FilterButton/FilterButton.types'
import { Headline, HeadlineProps, HeadlineSize } from '../../design-tokens/typography'
import { Mode, Size, KeyboardKeys } from '../../utils/enums'
import useClickOutside from '../../utils/useClickOutside'
import { ButtonProps } from '../Button/Button.types'
export interface FilterSelectionProps extends HTMLAttributes<HTMLDivElement> {
  id: string
  isOpen: boolean
  onOutsideClick: (e: Event) => void
  onClose: React.MouseEventHandler
  closeText: string
  /*
   * List ids for elements that are excluded from triggering onClose event. The array is passed to the useClickOutside hook
   */
  exclusions?: string[]
  maxWidth?: string
}
interface FilterSelectionSubComponents {
  Heading: React.FC<React.HTMLAttributes<HTMLDivElement>> & FilterSelectionHeadingSubComponents
  Button: React.ForwardRefExoticComponent<FilterButtonProps & React.RefAttributes<HTMLButtonElement>>
  InnerButton: StyledComponent<React.ForwardRefExoticComponent<ButtonProps>, DefaultTheme, NonNullable<unknown>, never>
  FilterList: StyledComponent<'div', DefaultTheme, { maxWidth: string }, never>
}

interface FilterSelectionHeadingSubComponents {
  Headline: React.FC<HeadlineProps>
  GroupLabel: StyledComponent<'div', DefaultTheme, NonNullable<unknown>, never>
}

export const FilterSelectionHeading: React.FC<HTMLAttributes<HTMLDivElement>> & FilterSelectionHeadingSubComponents = ({
  children,
  id,
  ...rest
}) => (
  <StyledGroupLabel id={id} {...rest}>
    <Headline as="span" size={HeadlineSize.Seven}>
      {children}
    </Headline>
  </StyledGroupLabel>
)

FilterSelectionHeading.GroupLabel = StyledGroupLabel
FilterSelectionHeading.Headline = Headline

const FilterSelection: React.FC<FilterSelectionProps> & FilterSelectionSubComponents = ({
  isOpen,
  onOutsideClick,
  children,
  closeText,
  onClose,
  exclusions,
  maxWidth,
  ...rest
}) => {
  const optionsRef = useRef<HTMLDivElement>()

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!isOpen) {
      return
    }

    const key = e.key
    const list = optionsRef.current
    const currentFocus = ownerDocument(list).activeElement

    if (key === KeyboardKeys.ArrowDown || key === KeyboardKeys.Home) {
      e.preventDefault()
      moveFocus(list, currentFocus, true, nextItem)
    } else if (key === KeyboardKeys.ArrowUp || key === KeyboardKeys.End) {
      e.preventDefault()
      moveFocus(list, currentFocus, true, previousItem)
    } else if (key === KeyboardKeys.Escape) {
      e.preventDefault()
    }
  }

  useEffect(() => {
    // focus on first filter button on open
    if (isOpen) {
      const list = optionsRef.current
      moveFocus(list, list.children[0], true, nextItem)
    }
  }, [isOpen])

  useClickOutside(optionsRef, onOutsideClick, isOpen, exclusions)

  return (
    <>
      {isOpen && (
        <StyledFilterList
          aria-multiselectable={true}
          aria-busy={isOpen}
          role="listbox"
          onKeyDown={handleKeyDown}
          ref={optionsRef}
          maxWidth={maxWidth}
          {...rest}
        >
          {children}
          <StyledButton contentMode={Mode.secondary} size={Size.sm} onClick={onClose}>
            {closeText}
          </StyledButton>
        </StyledFilterList>
      )}
    </>
  )
}

FilterSelection.InnerButton = StyledButton
FilterSelection.FilterList = StyledFilterList
FilterSelection.Heading = FilterSelectionHeading
FilterSelection.Button = FilterButton

FilterSelection.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onOutsideClick: PropTypes.func.isRequired,
  closeText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  exclusions: PropTypes.arrayOf(PropTypes.string),
  maxWidth: PropTypes.string,
}

export { FilterSelection }
