import React, { HTMLAttributes } from 'react'
import styled, { css, StyledComponent, DefaultTheme } from 'styled-components'
import { StyledTableRow } from '../TableRow'
import TableContext from '../../composite-components/Table/TableContext'

export interface TableBodyProps extends HTMLAttributes<HTMLTableSectionElement> {
  stripes?: boolean
  isBrand?: boolean
}

interface TableBodySubComponents {
  Body: StyledComponent<'tbody', DefaultTheme, TableBodyProps, never>
}

const StyledTableBody = styled.tbody<TableBodyProps>(
  ({ theme: { color, brand }, stripes, isBrand }) => css`
    ${stripes &&
    css`
      ${StyledTableRow}:nth-of-type(even) {
        background-color: ${isBrand ? brand.color.gray1 : color.neutralGray2};
      }
    `}
    @media (hover: hover) {
      ${StyledTableRow}:hover {
        background-color: ${isBrand ? brand.color.gray10 : color.neutralOnHoverGray};
      }
    }
  `
)

export const TableBody: React.FC<TableBodyProps> & TableBodySubComponents = ({ children, ...rest }) => {
  const table = React.useContext(TableContext)

  return (
    <StyledTableBody stripes={table.stripes} isBrand={table.isBrand} {...rest}>
      {children}
    </StyledTableBody>
  )
}

TableBody.Body = StyledTableBody
