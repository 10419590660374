import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const AsteriskIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M12 4V20M18 6L6 18M20 12H4M18 18L6 6" />
  </SVG>
)

AsteriskIcon.propTypes = defaultIconPropTypes
AsteriskIcon.defaultProps = defaultPictographIconProps

export { AsteriskIcon }
