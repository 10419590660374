import React, { HTMLAttributes } from 'react'
import styled, { css, useTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { LanguageIcon } from '../../design-tokens/icons/pictograph'
import { Headline, HeadlineSize } from '../../design-tokens/typography'
import { LanguageBox } from '../../basic-components/LanguageBox'
import { Label as BrandLabel, LabelSize as BrandLabelSize } from '../../design-tokens/brand-typography'

export interface LanguageProps {
  lang: string
  langAriaLabel: string
}

export interface LanguageSelectionProps extends HTMLAttributes<HTMLElement> {
  onLanguageToggle: (value: string) => void
  language: string
  languageLabel: string
  supportedLanguages: LanguageProps[]
  /** background for LanguageBoxes */
  hasBackground?: boolean
  'aria-label': string
  isNarrow?: boolean
  isBrand?: boolean
}

const Container = styled.div<{ isNarrow?: boolean }>`
  ${({ theme: { spacing, breakpoint }, isNarrow }) => css`
    display: inline-flex;
    padding: ${spacing.space2}rem 0;

    svg {
      flex-shrink: 0;
    }

    ${isNarrow &&
    css`
      display: block;

      & > div {
        margin-bottom: ${spacing.space3}rem;
      }
    `}

    @media screen and (max-width: ${breakpoint.xs}) {
      display: block;

      & > div {
        margin-bottom: ${spacing.space3}rem;
      }
    }
  `}
`
const InnerContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flew-wrap: nowrap;
`

const Header = styled.div`
  ${({ theme: { spacing, brand } }) => css`
    margin: 0 ${spacing.space3}rem;
    overflow: hidden;
    max-width: 6rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    ${BrandLabel} {
      font-weight: ${brand.fontWeight.bold};
    }
  `}
`
/**
 * @deprecated LanguageSelection component is deprecated. Please use ButtonGroup instead.
 */
export const LanguageSelection: React.FC<LanguageSelectionProps> = ({
  languageLabel,
  supportedLanguages,
  language,
  onLanguageToggle,
  hasBackground,
  isBrand,
  ...props
}) => {
  const theme = useTheme()
  return (
    <Container {...props} role="listbox">
      <InnerContainer>
        <LanguageIcon
          width={`${theme.iconSize.s}rem`}
          height={`${theme.iconSize.s}rem`}
          color={isBrand ? theme.brand.color.gray90 : theme.color.neutralNetworkGray}
        />
        <Header>
          {isBrand ? (
            <BrandLabel size={BrandLabelSize.Four}>{languageLabel}:</BrandLabel>
          ) : (
            <Headline size={HeadlineSize.Seven}>{languageLabel}:</Headline>
          )}
        </Header>
      </InnerContainer>
      <InnerContainer>
        {supportedLanguages.map((value) => {
          return (
            <LanguageBox
              key={value.lang}
              selected={value.lang == language}
              text={value.lang}
              onClick={() => onLanguageToggle(value.lang)}
              aria-label={value.langAriaLabel}
              hasBackground={hasBackground}
              isBrand={isBrand}
            />
          )
        })}
      </InnerContainer>
    </Container>
  )
}

export const LanguagePropsType = PropTypes.shape({
  lang: PropTypes.string.isRequired,
  langAriaLabel: PropTypes.string.isRequired,
})

export const LanguageSelectionPropsType = PropTypes.shape({
  onLanguageToggle: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  languageLabel: PropTypes.string.isRequired,
  supportedLanguages: LanguagePropsType.isRequired,
  'aria-label': PropTypes.string.isRequired,
  hasBackground: PropTypes.bool,
})
