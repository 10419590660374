import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

const LinkedInIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M17.25 3H6.75C4.67925 3 3 4.67925 3 6.75V17.25C3 19.3207 4.67925 21 6.75 21H17.25C19.3215 21 21 19.3207 21 17.25V6.75C21 4.67925 19.3215 3 17.25 3ZM9 17.25H6.75V9H9V17.25ZM7.875 8.049C7.1505 8.049 6.5625 7.4565 6.5625 6.726C6.5625 5.9955 7.1505 5.403 7.875 5.403C8.5995 5.403 9.1875 5.9955 9.1875 6.726C9.1875 7.4565 8.60025 8.049 7.875 8.049ZM18 17.25H15.75V13.047C15.75 10.521 12.75 10.7122 12.75 13.047V17.25H10.5V9H12.75V10.3238C13.797 8.38425 18 8.241 18 12.1807V17.25Z"
      fill={color}
    />
  </SVG>
)

LinkedInIcon.propTypes = defaultIconPropTypes
LinkedInIcon.defaultProps = defaultPictographIconProps

export { LinkedInIcon }
