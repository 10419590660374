import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const BoxIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M13 1.57734C12.3812 1.22007 11.6188 1.22007 11 1.57734L3.2364 6.05968L12 11.1334L20.7637 6.05968L13 1.57734ZM21.4868 6.4772L21.5263 6.54538V6.49999L21.4868 6.4772ZM21.5263 7.3514L12.75 12.4324V22.567L21.0263 17.7887C21.3357 17.61 21.5263 17.2799 21.5263 16.9226V7.3514ZM11.25 22.567V12.4324L2.47375 7.3514V16.9226C2.47375 17.2799 2.66435 17.61 2.97376 17.7887L11.25 22.567ZM2.47375 6.54538L2.51323 6.4772L2.47375 6.49999V6.54538Z"
      fill={color}
    />
  </SVG>
)

BoxIcon.propTypes = defaultIconPropTypes

BoxIcon.defaultProps = defaultPictographIconProps
