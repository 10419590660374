import styled, { css } from 'styled-components'
import { HeaderProps, LanguageBoxProps } from './LanguageBox.types'
import { Label, Headline } from '../../design-tokens/typography'
import { Label as BrandLabel } from '../../design-tokens/brand-typography'

export const StyledButton = styled.button<Omit<LanguageBoxProps, 'text'>>`
  ${({ theme: { color, fontWeight, spacing, borderRadius, brand }, selected, hasBackground, isBrand }) => css`
    width: 2.5rem;
    height: 2.5rem;
    margin-right: ${spacing.space1}rem;
    border: none;
    border-radius: ${borderRadius.xs}rem;
    background-clip: padding-box;
    font-weight: ${fontWeight.semiBold};
    color: ${selected ? color.neutralWhite : isBrand ? brand.color.gray90 : color.neutralNetworkGray};
    background-color: ${selected
      ? isBrand
        ? brand.color.gray90
        : color.neutralNetworkGray
      : !hasBackground
        ? 'transparent'
        : color.neutralWhite};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    transition: all 100ms ease-in;
    @media (hover: hover) {
      &:hover {
        background-color: ${selected ? color.neutralNetworkGray : color.neutralOnHoverGray};
      }
    }

    &:active {
      background-color: ${selected ? color.neutralNetworkGray : color.neutralOnPressGray};
    }

    &:focus {
      outline: none;
      box-shadow: 0px 0px 7px 0px ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
      &[aria-disabled='true'] {
        box-shadow: none;
      }
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &[aria-disabled='true'] {
      background-color: ${color.neutralGray5};
      color: ${color.neutralPassiveGray};
      cursor: default;
    }

    &:last-of-type {
      margin-right: 0;
    }
  `}
`

export const Header = styled.div<HeaderProps>(
  ({ theme: { color, lineHeight, fontWeight, brand }, selected, disabled }) => css`
    flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;

    ${BrandLabel} {
      font-weight: ${brand.fontWeight.medium};
      color: ${selected ? color.neutralWhite : brand.color.gray90};
    }

    ${BrandLabel}, ${Label} {
      cursor: pointer;
      font-weight: ${fontWeight.semiBold};
      line-height: ${lineHeight.body.two}rem;
      color: ${selected ? color.neutralWhite : color.neutralNetworkGray};
      ${disabled &&
      `
        color: ${color.neutralPassiveGray};
        cursor: default;
      `}
    }
  `
)

export const LanguageSelectionContainer = styled.div<{ isNarrow?: boolean; isBrand?: boolean }>`
  ${({ theme: { spacing, breakpoint }, isNarrow, isBrand }) => css`
    display: inline-flex;
    padding: ${spacing.space2}rem 0;
    ${isBrand &&
    css`
      max-width: 20rem;
      width: 100%;
      justify-content: space-between;
    `}

    svg {
      flex-shrink: 0;
    }

    ${isNarrow &&
    css`
      @media screen and (max-width: ${breakpoint.xs}) {
        display: block;

        & > div {
          margin-bottom: ${spacing.space3}rem;
        }
      }
    `}
  `}
`

export const LanguageSelectionInner = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  flew-wrap: nowrap;
`

/** LanguageSelection */

const languageLabel = `
  overflow: hidden;
  max-width: 6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
`

export const LanguageSelectionBrandLabel = styled(BrandLabel)`
  ${({ theme: { brand } }) => css`
    ${languageLabel}
    font-weight: ${brand.fontWeight.medium};
    line-height: ${brand.lineHeight.body};
    color: ${brand.color.gray70};
  `}
`

export const LanguageSelectionStyledLabel = styled(Headline)`
  ${({ theme: { spacing } }) => css`
    ${languageLabel}
    margin: 0 ${spacing.space3}rem;
  `}
`

export const SelectedLanguage = styled(BrandLabel)`
  ${({ theme: { brand } }) => css`
    ${languageLabel}
    font-weight: ${brand.fontWeight.bold};
    line-height: ${brand.lineHeight.body};
  `}
`

export const StyledBrandLabelContainer = styled.div`
  ${({ theme: { brand } }) => css`
    display: flex;
    flex-direction: column;
    margin: 0 ${brand.spacing.space4}rem;
  `}
`
