import React, { ComponentType, useEffect } from 'react'
import PropTypes, { Validator } from 'prop-types'
import {
  useTable,
  useRowSelect,
  useSortBy,
  HeaderGroup,
  usePagination,
  SortingRule,
  Column,
  TableRowProps,
  TableBodyProps,
  TableHeaderProps,
  TableCellProps,
  PluginHook,
  Row,
  Cell,
  TableOptions,
} from 'react-table'
import { Table } from '../Table'
import { TableProps } from './types'
import { TableSize } from '../../utils/enums'
import { Pagination } from './Pagination'
import { useTheme } from '../../utils/useTheme'
import {
  CheckboxHeader,
  CheckboxCell,
  BrandCheckboxCell,
  BrandCheckboxHeader,
  StyledArrowDownIcon,
  StyledBrandArrowDownIcon,
  TableHeaderWrapper,
} from './DataTable.components'

interface Props {
  children: React.ReactNode
}

export interface DataTableProps<D extends object> extends TableProps {
  data: D[]
  columns: Column<D>[]
  rowsPerPageText: string
  pageComparisonText: string
  pageText: string
  goPreviousPageAriaLabel: string
  goNextPageAriaLabel: string
  onRowSelect?: (ids: string[], data: D[]) => void
  onPageChanged?: (pageState: { pageIndex: number; pageSize: number }) => void
  onSortChanged?: (sortState: SortingRule<D>[]) => void
  initialSortBy?: SortingRule<D>[]
  initialPageIndex?: number
  getRowId?: TableOptions<D>['getRowId']
  manualSortBy?: boolean
  manualPagination?: boolean
  pageCount?: number
  withRowSelection?: boolean
  defaultPageSize?: number
  pageSizeOptions?: number[]
  components?: {
    TableComponent?: ComponentType<TableProps & Props>
    TableHeaderComponent?: ComponentType<TableHeaderProps & Props>
    TableHeaderRowComponent?: ComponentType<TableRowProps & Props>
    TableHeaderCellComponent?: ComponentType<TableCellProps & Props>
    TableBodyComponent?: ComponentType<TableBodyProps & Props>
    TableRowComponent?: ComponentType<TableRowProps & Props & { selected?: boolean; row: Row<D> }>
    TableCellComponent?: ComponentType<TableCellProps & Props & { cell: Cell<D> }>
    PaginationComponent?: typeof Pagination
  }
  extraPlugins?: PluginHook<D>[]
}

const checkBoxColumn = (isBrand: boolean) => ({
  id: 'selection',
  Header: isBrand ? BrandCheckboxHeader : CheckboxHeader,
  Cell: isBrand ? BrandCheckboxCell : CheckboxCell,
})

const defaultComponents = {
  TableComponent: Table,
  TableHeaderComponent: Table.Head,
  TableHeaderRowComponent: Table.Row,
  TableHeaderCellComponent: Table.Cell,
  TableBodyComponent: Table.Body,
  TableRowComponent: Table.Row,
  TableCellComponent: Table.Cell,
  PaginationComponent: Pagination,
}

export const DataTable = <D extends object>({
  columns,
  data,
  onRowSelect,
  withRowSelection,
  getRowId,
  defaultPageSize,
  pageSizeOptions,
  rowsPerPageText,
  pageComparisonText,
  pageText,
  goPreviousPageAriaLabel,
  goNextPageAriaLabel,
  onPageChanged,
  onSortChanged,
  initialSortBy = [],
  initialPageIndex,
  manualSortBy,
  manualPagination,
  pageCount: externalPageCount,
  components = defaultComponents,
  extraPlugins = [],
  isBrand,
  ...rest
}: DataTableProps<D>) => {
  const theme = useTheme()
  const hasCheckboxesEnabled = withRowSelection && rest.size !== TableSize.sm
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    pageOptions,
    state: { pageIndex, pageSize, selectedRowIds, sortBy },
  } = useTable<D>(
    {
      columns,
      data,
      initialState: {
        pageSize: defaultPageSize,
        sortBy: initialSortBy,
        pageIndex: initialPageIndex,
      },
      manualPagination: manualPagination,
      pageCount: manualPagination ? externalPageCount : Math.ceil(data.length / defaultPageSize),
      autoResetPage: !manualPagination,
      manualSortBy: manualSortBy,
      autoResetSortBy: !manualSortBy,
      getRowId,
    },
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (hasCheckboxesEnabled) {
        hooks.visibleColumns.push((columns) => [checkBoxColumn(isBrand), ...columns])
      }
    },
    ...extraPlugins
  )

  const paginationProps = {
    totalPages: pageOptions.length,
    pageComparisonText,
    pageText,
    rowsPerPageText,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    gotoPage,
    pageSize,
    setPageSize,
    pageIndex,
    pageSizeOptions,
    goPreviousPageAriaLabel,
    goNextPageAriaLabel,
  }

  useEffect(() => {
    if (hasCheckboxesEnabled && onRowSelect) {
      onRowSelect(
        Object.keys(selectedRowIds),
        selectedFlatRows.map((r) => r.original)
      )
    }
  }, [hasCheckboxesEnabled, selectedRowIds, selectedFlatRows, onRowSelect])

  useEffect(() => {
    onPageChanged?.({ pageIndex, pageSize })
  }, [pageIndex, pageSize, onPageChanged])
  useEffect(() => {
    onSortChanged?.(sortBy)
  }, [sortBy, onSortChanged])

  const {
    TableComponent = Table,
    TableHeaderComponent = Table.Head,
    TableHeaderRowComponent = Table.Row,
    TableHeaderCellComponent = Table.Cell,
    TableRowComponent = Table.Row,
    TableBodyComponent = Table.Body,
    TableCellComponent = Table.Cell,
    PaginationComponent = Pagination,
  } = components

  return (
    <>
      <TableComponent {...getTableProps()} {...rest} isBrand={isBrand} withRowSelection={withRowSelection}>
        <TableHeaderComponent key="table-header">
          {headerGroups.map((headerGroup) => {
            const headerGroupProps = headerGroup.getHeaderGroupProps()
            return (
              <TableHeaderRowComponent key={headerGroupProps.key} {...headerGroupProps}>
                {headerGroup.headers.map((column: HeaderGroup<D>) => {
                  const headerProps = column.getHeaderProps(column.getSortByToggleProps())

                  return (
                    <TableHeaderCellComponent key={headerProps.key} {...headerProps}>
                      <TableHeaderWrapper>{column.render('Header')}</TableHeaderWrapper>
                      {isBrand ? (
                        <StyledBrandArrowDownIcon
                          isVisible={column.isSorted}
                          aria-hidden={!column.isSorted}
                          isUp={!column.isSortedDesc}
                          width={`${theme.iconSize.s}rem`}
                          height={`${theme.iconSize.s}rem`}
                          color={theme.brand.color.gray70}
                        />
                      ) : (
                        <StyledArrowDownIcon
                          isVisible={column.isSorted}
                          aria-hidden={!column.isSorted}
                          isUp={!column.isSortedDesc}
                          width={`${theme.iconSize.xs}rem`}
                          height={`${theme.iconSize.xs}rem`}
                        />
                      )}
                    </TableHeaderCellComponent>
                  )
                })}
              </TableHeaderRowComponent>
            )
          })}
        </TableHeaderComponent>
        <TableBodyComponent {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row)
            // `prepareRow` must be run before `getRowProps`
            const rowProps = row.getRowProps()
            return (
              <TableRowComponent
                key={rowProps.key}
                row={row}
                {...rowProps}
                selected={selectedFlatRows.some((selectedRow) => selectedRow.id === row.id)}
              >
                {row.cells.map((cell) => {
                  const cellProps = cell.getCellProps()
                  return (
                    <TableCellComponent key={cellProps.key} cell={cell} {...cellProps}>
                      {cell.render('Cell')}
                    </TableCellComponent>
                  )
                })}
              </TableRowComponent>
            )
          })}
        </TableBodyComponent>
      </TableComponent>
      <PaginationComponent isBrand={isBrand} {...paginationProps} />
    </>
  )
}

DataTable.displayName = 'DataTable'

DataTable.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.string.isRequired,
      accessor: PropTypes.oneOfType([PropTypes.func, PropTypes.string]).isRequired,
      sortType: PropTypes.func,
    })
  ).isRequired as Validator<DataTableProps<any>['columns']>,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  onRowSelect: PropTypes.func,
  withRowSelection: PropTypes.bool,
  defaultPageSize: PropTypes.number,
  pageSizeOptions: PropTypes.arrayOf(PropTypes.number),
  rowsPerPageText: PropTypes.string.isRequired,
  pageComparisonText: PropTypes.string.isRequired,
  pageText: PropTypes.string.isRequired,
  goPreviousPageAriaLabel: PropTypes.string.isRequired,
  goNextPageAriaLabel: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.values(TableSize)),
  stripes: PropTypes.bool,
  manualPagination: PropTypes.bool,
  onPageChanged: PropTypes.func,
  manualSortBy: PropTypes.bool,
  onSortChanged: PropTypes.func,
  pageCount: PropTypes.number,
}

DataTable.defaultProps = {
  withRowSelection: true,
  defaultPageSize: 10,
  pageSizeOptions: [10, 50, 100],
  size: TableSize.lg,
  initialPageIndex: 0,
  initialSortBy: [],
  extraPlugins: [],
}
