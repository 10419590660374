import styled, { css } from 'styled-components'
import { NavButton } from '../NavButton'
import { ProfileIcon } from '../../xyz'

export const StyledDropdown = styled.div`
  display: inline-block;
  position: relative;
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;

  ul {
    width: 100%;
  }
`

export const NavDropdownItem = styled(NavButton).attrs({ contentMode: 'secondary', withArrow: false })`
  ${({ theme: { spacing, color } }) => css`
    width: 100%;
    padding: ${spacing.space3}rem;
    justify-content: left;

    span {
      text-align: left;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &:focus {
      outline: 0;
      box-shadow: none;
      background-color: ${color.neutralGray5};
    }
  `}
`

export const Icon = styled(ProfileIcon)`
  ${({ theme: { spacing, iconSize } }) => css`
    width: ${iconSize.s}em;
    height: ${iconSize.s}em;
    margin: 0 ${spacing.space2}rem 0 -${spacing.space1}rem;
    flex-shrink: 0;
  `}
`
