import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const ChevronLeftIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M15 18L9 12L15 6" />
  </SVG>
)

ChevronLeftIcon.propTypes = defaultIconPropTypes
ChevronLeftIcon.defaultProps = defaultPictographIconProps

export { ChevronLeftIcon }
