import styled, { css } from 'styled-components'
import { XyzThemeColor } from '../../utils/helpers'
import { IconButton } from '../../deprecated/IconButton'
import { Headline } from '../../design-tokens/typography'
import { ModalMainContent, ModalHeroImage, ModalContent } from '../Modal/Modal.style'
import { BottomSheet } from 'react-spring-bottom-sheet'
import { BottomSheetProps } from './BottomSheet.types'
import { mediaQuery } from '../../utils/mediaQuery'
import { SelectWrapper } from '../../basic-components/Dropdown/Dropdown.styles'
import { BrandButton } from '../../basic-components/BrandButton'

export const BottomSheetContentWrapper = styled.div.attrs({
  'aria-labelledby': 'bottomsheet-title',
  'aria-describedby': 'bottomsheet-content',
  'aria-live': 'assertive',
  role: 'text',
})`
  flex-shrink: 1;
  flex-grow: 1;
  -webkit-tap-highlight-color: revert;
  -webkit-touch-callout: revert;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  -moz-user-select: auto;
  user-select: auto;
  -ms-scroll-chaining: none;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;
  height: 100%;
`

export const BottomSheetHeadlineAligner = styled.div`
  ${({ theme: { spacing } }) => css`
    position: relative;
    display: flex;
    word-break: break-word;
    padding-bottom: ${spacing.space2}rem;
  `}
`

export const StyledBottomSheetContainer = styled.div.attrs({ tabIndex: -1 })<
  Pick<BottomSheetProps, 'useDefaultPadding' | 'scrollbarColor' | 'scrollbarBackgroundColor' | 'hasImgPadding'> & {
    $contentHeight: string
  }
>`
  ${({
    theme: { spacing, color },
    useDefaultPadding,
    scrollbarColor,
    scrollbarBackgroundColor,
    $contentHeight,
    hasImgPadding,
  }) => css`
    ${BottomSheetContent}, ${ModalMainContent} {
      width: 100%;
      scrollbar-width: thin;
      word-break: break-word;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;
      padding-right: ${useDefaultPadding ? spacing.space4 : spacing.space0}rem;
      scrollbar-color: ${scrollbarColor ? color[scrollbarColor] || scrollbarColor : color.neutralPassiveGray}
        ${scrollbarBackgroundColor ? color[scrollbarBackgroundColor] || scrollbarBackgroundColor : color.neutralWhite};
      scrollbar-gutter: stable;
      max-height: calc(${$contentHeight ? $contentHeight : '96vh'} - 1.5rem - 2rem - 3.5rem);
      ${hasImgPadding &&
      css`
        max-height: calc(${$contentHeight ? $contentHeight : '96vh'} - 1.5rem - 2rem - 12.5rem);
        ${mediaQuery.md`
          max-height: calc(${$contentHeight ? $contentHeight : '96vh'} - 1.5rem - 2rem - 12.5rem - 2.5rem);
        `}
      `}

      ::-webkit-scrollbar {
        display: inherit;
        -webkit-appearance: none;
        width: 0.5rem;
        background-color: ${scrollbarBackgroundColor
          ? color[scrollbarBackgroundColor] || scrollbarBackgroundColor
          : color.neutralWhite};
      }

      ::-webkit-scrollbar-thumb {
        background: ${scrollbarColor ? color[scrollbarColor] || scrollbarColor : color.neutralPassiveGray};
        background-clip: content-box;
        border-radius: 1rem;
      }

      ::-webkit-scrollbar-track {
        background-color: ${scrollbarBackgroundColor
          ? color[scrollbarBackgroundColor] || scrollbarBackgroundColor
          : color.neutralWhite};
      }
      @media (hover: hover) {
        &:hover {
          ::-webkit-scrollbar-thumb {
            background-color: ${color.neutralIconGray};
          }
        }
      }

      &:focus {
        outline: none;
        ::-webkit-scrollbar,
        ::-webkit-scrollbar-thumb {
          outline: none;
          box-shadow: none;
        }
      }
    }

    ${BottomSheetHeadlineAligner} {
      padding-top: ${useDefaultPadding ? 0 : `${spacing.space5}rem`};
    }

    ${ModalContent} {
      overflow: visible;
    }

    ${SelectWrapper} {
      margin-left: 1px;
    }
  `}
`

export const BottomSheetContent = styled.div.attrs({
  id: 'bottomsheet-content',
})``

const bottomSheetCloseStyles = `
  position: absolute;
  z-index: 1000000;
`

export const BottomSheetClose = styled(IconButton)`
  ${({ theme: { spacing } }) => css`
    top: ${spacing.space2}rem;
    right: ${spacing.space2}rem;
    ${bottomSheetCloseStyles}
    border: 1px solid transparent;
    @media (hover: hover) {
      &:hover {
        background-color: transparent;
        border: 1px solid transparent;
      }
    }
    &:focus {
      background-color: transparent;
      border: 1px solid transparent;
    }
  `}
`

export const BottomSheetBrandClose = styled(BrandButton)`
  ${({
    theme: {
      brand: { spacing },
    },
  }) => css`
    top: ${spacing.space2}rem;
    right: ${spacing.space2}rem;
    ${bottomSheetCloseStyles}
  `}
`

export const BottomSheetTitle = styled(Headline).attrs({ as: 'h1', id: 'bottomsheet-title' })``

// To avoid react warnings for StyledBottomSheet which appear due to styled-components and tailwind combination, transient props should be used
export const StyledBottomSheet = styled(BottomSheet)<{
  $disableOutsideClick: boolean
  $backDropStyles: string
  $backgroundColor: XyzThemeColor | string
  $handlebarColor: XyzThemeColor | string
  $useDefaultPadding: boolean
  $isDraggable: boolean
  $hasImgPadding: boolean
  $isModal: boolean
  $zIndex: number
}>`
  ${({
    theme: { spacing, color, zIndex },
    $backDropStyles,
    $backgroundColor,
    $handlebarColor,
    $useDefaultPadding,
    $disableOutsideClick,
    $isDraggable,
    $hasImgPadding,
    $isModal,
    $zIndex,
  }) => css`
    :root {
      --rsbs-backdrop-bg: rgba(38, 44, 48, 0.64);
      --rsbs-handle-bg: hsla(0, 0%, 0%, 0.14);
      --rsbs-max-w: auto;
      --rsbs-ml: env(safe-area-inset-left);
      --rsbs-mr: env(safe-area-inset-right);
    }

    [data-rsbs-overlay] {
      border-radius: 6px 6px 0 0;
      overflow: hidden;
      background-color: ${$backgroundColor ? color[$backgroundColor] || $backgroundColor : color.neutralWhite};
      max-height: 96vh;
      display: flex;
      flex-direction: column;
      height: var(--rsbs-overlay-h, 0px);
      transform: translate3d(0, var(--rsbs-overlay-translate-y, 0px), 0);
      will-change: height;
    }

    [data-rsbs-backdrop] {
      top: -60px;
      bottom: -60px;
      background-color: var(--rsbs-backdrop-bg, rgba(38, 44, 48, 0.64));
      opacity: var(--rsbs-backdrop-opacity, 1);
      will-change: opacity;
      ${$backDropStyles}

      cursor: ${$disableOutsideClick ? 'unset' : 'pointer'};
    }

    [data-rsbs-overlay],
    [data-rsbs-backdrop],
    [data-rsbs-root]:after {
      z-index: ${$zIndex ?? zIndex.zIndex1000};
      -ms-scroll-chaining: none;

      touch-action: none;
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      -webkit-tap-highlight-color: transparent;
      -webkit-touch-callout: none;
    }

    [data-rsbs-root]:after {
      content: '';
      pointer-events: none;
      background: var(--rsbs-bg, #fff);
      height: 1px;
      transform-origin: bottom;
      transform: scale3d(1, var(--rsbs-antigap-scale-y, 0), 1);
      will-change: transform;
    }

    @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 2dppx) {
      [data-rsbs-header]:before {
        transform: translateX(-50%) scaleY(0.75);
      }
    }
    [data-rsbs-is-dismissable='true'] [data-rsbs-header] > *,
    [data-rsbs-is-dismissable='true'] [data-rsbs-scroll] > *,
    [data-rsbs-is-dismissable='true'] [data-rsbs-footer] > *,
    [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-header] > *,
    [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-header] > *,
    [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-scroll] > *,
    [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-scroll] > *,
    [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-footer] > *,
    [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-footer] > * {
      opacity: var(--rsbs-content-opacity, 1);
    }

    [data-rsbs-is-dismissable='true'] [data-rsbs-backdrop],
    [data-rsbs-is-dismissable='false'][data-rsbs-state='opening'] [data-rsbs-backdrop],
    [data-rsbs-is-dismissable='false'][data-rsbs-state='closing'] [data-rsbs-backdrop] {
      opacity: var(--rsbs-backdrop-opacity, 1);
    }

    [data-rsbs-state='closed'],
    [data-rsbs-state='closing'] {
      /* Allows interactions on the rest of the page before the close transition is finished */
      pointer-events: none;
    }

    [data-rsbs-header] {
      user-select: none;
      z-index: 1000000;
      padding-top: ${spacing.space5}rem;
      ${!$isDraggable &&
      css`
        pointer-events: none;
      `}
    }

    [data-rsbs-header]:before {
      position: absolute;
      content: '';
      display: block;
      background-color: ${$handlebarColor ? $handlebarColor : 'rgb(115, 128, 140)'};
      border-radius: 0.375rem;
      top: ${spacing.space2}rem;
      left: 50%;
      transform: translateX(-50%);
      right: 50%;
      width: 3.25rem;
      height: 0.375rem;
      z-index: ${zIndex.zIndex1000};
    }

    [data-rsbs-content] {
      padding: ${$useDefaultPadding ? `0 ${spacing.space0}rem ${spacing.space4}rem ${spacing.space4}rem` : 0};

      ${$isModal &&
      $hasImgPadding &&
      css`
        padding: 0;
      `}
      height: 100%;
      &:before {
        background-color: ${$handlebarColor ? color[$handlebarColor] || $handlebarColor : color.neutralNetworkGray};
      }
    }
  `}
`

export const BottomSheetHero = styled(ModalHeroImage)`
  ${({ theme: { spacing } }) => css`
    ${mediaQuery.md`
      margin-top: -${spacing.space5}rem;
    `}
  `}
`
