import PropTypes, { Validator } from 'prop-types'
import styled, { css } from 'styled-components'
import { XyzThemeColor } from '../../../utils/helpers'
import { XyzTheme } from '@postidigital/posti-theme'

export enum LabelSize {
  One = 'One',
  Two = 'Two',
  Three = 'Three',
  Four = 'Four',
  Five = 'Five',
  Six = 'Six',
}

export interface LabelProps {
  size: LabelSize | keyof typeof LabelSize
  color?: XyzThemeColor | string
}

export const Label = styled.label<LabelProps>(
  ({ theme, size, color }) => css`
    margin: 0;
    font-family: ${theme.fontFamily.Montserrat};
    color: ${theme[color] || color};

    ${size === LabelSize.One &&
    `
      font-weight: ${theme.fontWeight.semiBold};
      font-size: ${theme.fontSize.label.one}rem;
      line-height: ${theme.lineHeight.label.one};
      `}

    ${size === LabelSize.Two &&
    `
      font-weight: ${theme.fontWeight.semiBold};
      font-size: ${theme.fontSize.label.two}rem;
      line-height: ${theme.lineHeight.label.two};
      `}
    
    ${size === LabelSize.Three &&
    `
      font-weight: ${theme.fontWeight.semiBold};
      font-size: ${theme.fontSize.label.three}rem;
      line-height: ${theme.lineHeight.label.three};
      `}
    
    ${size === LabelSize.Four &&
    `
      font-weight: ${theme.fontWeight.normal};
      font-size: ${theme.fontSize.label.four}rem;
      line-height: ${theme.lineHeight.label.four};
      `}    
    
    ${size === LabelSize.Five &&
    `
      font-weight: ${theme.fontWeight.medium};
      font-size: ${theme.fontSize.label.five}rem;
      line-height: ${theme.lineHeight.label.five};
      `}        
    
    ${size === LabelSize.Six &&
    `
      font-weight: ${theme.fontWeight.medium};
      font-size: ${theme.fontSize.label.six}rem;
      line-height: ${theme.lineHeight.label.six};
      `}
  `
)

Label.propTypes = {
  size: PropTypes.oneOf(Object.keys(LabelSize)).isRequired as Validator<LabelSize>,
  color: PropTypes.string,
}

Label.defaultProps = {
  color: XyzTheme.color.neutralNetworkGray,
}
