import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const BinIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M18.1363 8.625H5.86358C5.75508 8.625 5.53113 8.62477 5.45458 8.62482C5.4544 8.69127 5.45449 8.90055 5.45449 9V19.5C5.45449 19.8978 5.62689 20.2794 5.93377 20.5607C6.24065 20.842 6.65686 21 7.09085 21H16.909C17.343 21 17.7592 20.842 18.0661 20.5607C18.373 20.2794 18.5454 19.8978 18.5454 19.5V9C18.5454 8.90055 18.5454 8.74138 18.5454 8.625C18.4608 8.62544 18.2448 8.625 18.1363 8.625ZM10.5681 18.375C10.5681 18.5242 10.5035 18.6673 10.3884 18.7728C10.2733 18.8782 10.1172 18.9375 9.95449 18.9375C9.79174 18.9375 9.63566 18.8782 9.52058 18.7728C9.4055 18.6673 9.34086 18.5242 9.34086 18.375V11.625C9.34086 11.4758 9.4055 11.3327 9.52058 11.2273C9.63566 11.1218 9.79174 11.0625 9.95449 11.0625C10.1172 11.0625 10.2733 11.1218 10.3884 11.2273C10.5035 11.3327 10.5681 11.4758 10.5681 11.625V18.375ZM14.659 18.375C14.659 18.5242 14.5944 18.6673 14.4793 18.7728C14.3642 18.8782 14.2081 18.9375 14.0454 18.9375C13.8827 18.9375 13.7266 18.8782 13.6115 18.7728C13.4964 18.6673 13.4318 18.5242 13.4318 18.375V11.625C13.4318 11.4758 13.4964 11.3327 13.6115 11.2273C13.7266 11.1218 13.8827 11.0625 14.0454 11.0625C14.2081 11.0625 14.3642 11.1218 14.4793 11.2273C14.5944 11.3327 14.659 11.4758 14.659 11.625V18.375Z"
      fill={color}
    />
    <Path
      d="M20.1818 6H16.2954C16.2412 6 16.1891 5.98025 16.1508 5.94508C16.1124 5.90992 16.0909 5.86223 16.0909 5.8125V4.875C16.0909 4.37772 15.8754 3.90081 15.4918 3.54917C15.1082 3.19754 14.5879 3 14.0454 3H9.9545C9.41201 3 8.89175 3.19754 8.50815 3.54917C8.12455 3.90081 7.90905 4.37772 7.90905 4.875V5.8125C7.90905 5.86223 7.8875 5.90992 7.84914 5.94508C7.81078 5.98025 7.75875 6 7.7045 6H3.81814C3.60114 6 3.39304 6.07902 3.2396 6.21967C3.08616 6.36032 2.99996 6.55109 2.99996 6.75C2.99996 6.94891 2.99988 7.5 2.99988 7.5C2.99988 7.5 3.60114 7.5 3.81814 7.5H20.1818C20.3988 7.5 21 7.5 21 7.5C21 7.5 21 6.94891 21 6.75C21 6.55109 20.9138 6.36032 20.7603 6.21967C20.6069 6.07902 20.3988 6 20.1818 6ZM9.54541 5.8125V4.875C9.54541 4.77554 9.58851 4.68016 9.66523 4.60984C9.74195 4.53951 9.84601 4.5 9.9545 4.5H14.0454C14.1539 4.5 14.258 4.53951 14.3347 4.60984C14.4114 4.68016 14.4545 4.77554 14.4545 4.875V5.8125C14.4545 5.86223 14.4545 5.94508 14.4545 6C14.5 6 14.3042 6 14.25 6H9.74996C9.69571 6 9.49996 6 9.54541 6C9.54541 5.94508 9.54541 5.86223 9.54541 5.8125Z"
      fill={color}
    />
  </SVG>
)

BinIcon.propTypes = defaultIconPropTypes

BinIcon.defaultProps = defaultPictographIconProps
