import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { SliderProps, SliderSubComponents } from './Slider.types'
import { LabelContainer, Track, TrackFill, Thumb, SliderContainer, Tooltip } from './Slider.style'
import { Placement } from '../../utils/enums'
import { LabelSize, Label } from '../../design-tokens/typography'
import { Label as BrandLabel, LabelSize as BrandLabelSize } from '../../design-tokens/brand-typography'
import { THUMB_WIDTH, TOOLTIP_OFFSET_Y, isValidNumber, clamp } from './utils'
import { BrandTheme } from '@postidigital/posti-theme'

export const Slider: React.FC<SliderProps> & SliderSubComponents = ({
  minLabel,
  maxLabel,
  tooltipDisplay,
  tooltipText,
  value,
  defaultValue,
  isBrand,
  ...inputProps
}) => {
  const ariaValuetext = inputProps['aria-valuetext']
  const trackRef = useRef<HTMLDivElement>()
  const tooltipRef = useRef<HTMLDivElement>()
  const [sliderState, setSliderState] = useState({ value: 0, x: 0 })
  const [sliderFocused, setSliderFocused] = useState(false)
  const [sliderHovered, setSliderHovered] = useState(false)
  const [tooltipX, setTooltipX] = useState(0)

  useEffect(() => {
    const getThumbX = (value: number) => {
      // Return 0 for invalid min and max
      if (inputProps.min >= inputProps.max) {
        return 0
      }
      return Math.round(
        ((value - inputProps.min) * (trackRef.current.offsetWidth - THUMB_WIDTH)) / (inputProps.max - inputProps.min)
      )
    }

    let sliderValue
    if (isValidNumber(value)) {
      sliderValue = value
    } else if (isValidNumber(defaultValue)) {
      sliderValue = defaultValue
    } else {
      sliderValue = inputProps.min
    }
    const clampedValue = clamp(sliderValue, inputProps.min, inputProps.max)
    setSliderState({ value: clampedValue, x: getThumbX(clampedValue) })
  }, [value, defaultValue, inputProps.min, inputProps.max])

  useEffect(() => {
    const width = tooltipRef.current?.getBoundingClientRect().width ?? 0
    const x = sliderState.x - (width - THUMB_WIDTH) * 0.5
    setTooltipX(x)
  }, [sliderState, sliderHovered, sliderFocused])

  const handleHover = (event: React.SyntheticEvent<HTMLInputElement>, showTooltip: boolean, fnName: string) => {
    setSliderHovered(showTooltip)
    inputProps[fnName]?.(event)
  }

  const handleFocus = (event: React.SyntheticEvent<HTMLInputElement>, showTooltip: boolean, fnName: string) => {
    setSliderFocused(showTooltip)
    inputProps[fnName]?.(event)
  }

  const onMouseOver = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    handleHover(event, true, 'onMouseOver')
  }

  const onMouseLeave = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    handleHover(event, false, 'onMouseLeave')
  }

  const onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    handleFocus(event, true, 'onFocus')
  }

  const onBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    handleFocus(event, false, 'onBlur')
  }

  const onTouchStart = (event: React.TouchEvent<HTMLInputElement>) => {
    handleHover(event, true, 'onTouchStart')
  }

  const onTouchEnd = (event: React.TouchEvent<HTMLInputElement>) => {
    handleHover(event, false, 'onTouchEnd')
  }

  const sliderValueText = tooltipText ?? `${sliderState.value}`
  const showTooltip = tooltipDisplay && (sliderHovered || sliderFocused)

  return (
    <div>
      <SliderContainer aria-live="polite" isBrand={isBrand}>
        <Track ref={trackRef} isBrand={isBrand}>
          <TrackFill isBrand={isBrand} thumbX={sliderState.x} disabled={inputProps.disabled} />
        </Track>
        <Thumb
          role="slider"
          aria-valuemin={inputProps.min}
          aria-valuenow={sliderState.value}
          aria-valuemax={inputProps.max}
          aria-valuetext={ariaValuetext || sliderValueText}
          {...inputProps}
          type="range"
          value={sliderState.value}
          onMouseOver={onMouseOver}
          onMouseLeave={onMouseLeave}
          onFocus={onFocus}
          onBlur={onBlur}
          onTouchStart={onTouchStart}
          onTouchEnd={onTouchEnd}
          isBrand={isBrand}
        />
        {showTooltip && (
          <Tooltip
            ref={tooltipRef}
            open={showTooltip}
            isBrand={isBrand}
            placement={Placement.top}
            role="tooltip"
            style={{
              left: tooltipX,
              top: -(THUMB_WIDTH + TOOLTIP_OFFSET_Y),
            }}
          >
            {isBrand ? (
              <BrandLabel size={BrandLabelSize.Five}>{sliderValueText}</BrandLabel>
            ) : (
              <Label size={LabelSize.Five}>{sliderValueText}</Label>
            )}
          </Tooltip>
        )}
      </SliderContainer>
      {(minLabel || maxLabel) && (
        <LabelContainer isBrand={isBrand}>
          {isBrand ? (
            <>
              <BrandLabel color={BrandTheme.color.gray70} size={BrandLabelSize.Six}>
                {minLabel}
              </BrandLabel>
              <BrandLabel color={BrandTheme.color.gray70} size={BrandLabelSize.Six}>
                {maxLabel}
              </BrandLabel>
            </>
          ) : (
            <>
              <Label size={LabelSize.Five}>{minLabel}</Label>
              <Label size={LabelSize.Five}>{maxLabel}</Label>
            </>
          )}
        </LabelContainer>
      )}
    </div>
  )
}

Slider.Container = SliderContainer
Slider.Track = Track
Slider.TrackFill = TrackFill
Slider.Thumb = Thumb
Slider.Tooltip = Tooltip
Slider.BrandLabel = BrandLabel
Slider.Label = Label
Slider.LabelContainer = LabelContainer

Slider.defaultProps = {
  tooltipDisplay: true,
  min: 0,
  max: 100,
  step: 1,
}

Slider.propTypes = {
  minLabel: PropTypes.string,
  maxLabel: PropTypes.string,
  tooltipDisplay: PropTypes.bool,
  tooltipText: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  value: PropTypes.number,
  defaultValue: PropTypes.number,
  isBrand: PropTypes.bool,
}
