import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Headline, Body } from '../../xyz'
import { mediaQuery } from '../../utils/mediaQuery'
import { CardSubComponents, CardProps } from './Card.types'

export const CardWrapper = styled.div<Omit<CardProps, 'children'>>`
  ${({ theme: { color, spacing, borderRadius }, isWide, isBrand, maxWidth }) => css`
    width: 100%;
    border-radius: ${borderRadius.sm}rem;
    box-shadow: 0 8px 22px rgba(27, 31, 35, 0.07);
    background: ${color.neutralWhite};
    padding: ${spacing.space4}rem;
    max-width: ${maxWidth ?? (isWide ? '39rem' : '30rem')};

    ${Body} {
      &:last-of-type {
        margin-bottom: 0;
      }
    }

    ${isBrand &&
    css`
      ${CardHeader} {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: ${spacing.space3}rem;

        & > * {
          margin-bottom: 0;
        }
        & > button {
          margin-left: ${spacing.space2}rem;
          ${mediaQuery.sm`
            margin-left: ${spacing.space3}rem;
          `}
          ${mediaQuery.xl`
            margin-left: ${isWide ? spacing.space6 : spacing.space4}rem;
          `}
        }
        ${Headline} {
          margin-bottom: 0;
        }
      }
    `}
  `}
`
export const CardHeader = styled.div`
  ${({ theme: { spacing } }) => css`
    margin-bottom: ${spacing.space4}rem;
  `}
`

export const CardContent = styled.div`
  ${({ theme: { spacing } }) => css`
    ${Headline} {
      margin-bottom: ${spacing.space2}rem;
    }
  `}
`

export const CardContainerImage = styled.div`
  ${({ theme: { spacing, borderRadius } }) => css`
    margin: -${spacing.space4}rem -${spacing.space4}rem ${spacing.space4}rem;
    border-radius: ${borderRadius.sm}rem ${borderRadius.sm}rem 0 0;
    overflow: hidden;
    img {
      width: 100%;
    }
  `}
`

export const CardContentBlock = styled.div`
  ${({ theme: { color, spacing } }) => css`
    &:after {
      content: '';
      width: 100%;
      height: 1px;
      display: block;
      background-color: ${color.neutralPassiveGray};
      margin: ${spacing.space4}rem 0;
    }
    & > * {
      margin-bottom: 0;
    }
    &:last-of-type {
      &:after {
        content: none;
      }
    }
  `}
`

export const CardFooter = styled.div`
  ${({ theme: { spacing } }) => css`
    margin-top: ${spacing.space4}rem;
  `}
`

export const Card: React.FC<CardProps> & CardSubComponents = (props) => {
  const { children, ...rest } = props

  return <CardWrapper {...rest}>{children}</CardWrapper>
}

Card.Header = CardHeader
Card.Footer = CardFooter
Card.ContentBlock = CardContentBlock
Card.Content = CardContent
Card.ContainerImage = CardContainerImage
Card.CardWrapper = CardWrapper

Card.propTypes = {
  isWide: PropTypes.bool,
  isBrand: PropTypes.bool,
  maxWidth: PropTypes.string,
}

Card.defaultProps = {
  isWide: false,
  isBrand: false,
}
