import React, { useState, ReactElement, useRef, useCallback, HTMLAttributes } from 'react'
import PropTypes, { Validator } from 'prop-types'
import { StyledComponent, DefaultTheme } from 'styled-components'
import { createPortal } from 'react-dom'
import { StyledTooltip } from './Tooltip.style'
import { LabelSize, Label, LabelProps } from '../../design-tokens/typography'
import {
  Label as BrandLabel,
  LabelSize as BrandLabelSize,
  LabelProps as BrandLabelProps,
} from '../../design-tokens/brand-typography'
import { Placement } from '../../utils/enums'
import { addNamespace } from '../../utils/helpers'

export interface TooltipProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'id'> {
  title: React.ReactNode
  id: string
  placement?: keyof typeof Placement
  /* If vertical scroll is on any other element than <body /> pass that element here */
  scrollParent?: HTMLElement | string
  isBrand?: boolean
  zIndex?: string
  children: ReactElement
}

interface TooltipPlacement {
  top: number
  left: number
  maxWidth: number
  height: number
}

interface TooltipSubComponents {
  Main: StyledComponent<'div', DefaultTheme, { placement: Placement; open: boolean }, never>
  Label: StyledComponent<'label', DefaultTheme, LabelProps, never>
  BrandLabel: StyledComponent<'label', DefaultTheme, BrandLabelProps, never>
}

const extraPadding = 8 // in pixels
const horizontalMargin = 16 // in pixels

const getMaxWidth = (origWidth: number, calculatedWidth: number) =>
  origWidth > calculatedWidth ? calculatedWidth : origWidth

const TooltipWithoutNamespace = React.forwardRef<HTMLDivElement, TooltipProps>((props, ref) => {
  const { id, title, placement, scrollParent: scrollParentProp, children, zIndex, isBrand, ...rest } = props
  const [open, setOpen] = useState<boolean>(false)
  const [bound, setBound] = useState(null)
  const [tooltipPlacement, setTooltipPlacement] = useState<TooltipPlacement | null>(null)
  const rendered = useRef<boolean>()
  const scrollParent =
    typeof scrollParentProp === 'string' ? document.querySelector(scrollParentProp) : scrollParentProp

  const tooltipRef = useCallback(
    (node) => {
      const calculateTooltipPlacementAndSize = (width, height) => {
        const { top: scrollTop, right: scrollRight } = (
          scrollParent || document.querySelector('body')
        ).getBoundingClientRect()
        const { x: parentLeft } = scrollParent ? scrollParent.getBoundingClientRect() : { x: 0 }

        const anchor = {
          top: Math.abs(scrollTop) + bound.y,
          x: bound.x - parentLeft,
          right: bound.right - parentLeft,
        }

        let maxWidth = 0
        let top = 0
        let left = 0

        // Calculate vertical position
        if (placement.includes(Placement.bottom)) {
          top = anchor.top + bound.height + extraPadding
        } else if (placement.includes(Placement.top)) {
          top = anchor.top - height - extraPadding
        } else if (placement === Placement.start || placement === Placement.end) {
          top = anchor.top - height / 2 + bound.height / 2
        }

        // Calculate horizontal position
        if (placement === Placement.bottom || placement === Placement.top) {
          const anchorCenter = anchor.x + bound.width / 2
          const anchorRightSide = scrollRight - anchorCenter - horizontalMargin
          const anchorLeftSide = anchorCenter - parentLeft - horizontalMargin

          maxWidth = getMaxWidth(width, anchorLeftSide > anchorRightSide ? anchorRightSide * 2 : anchorLeftSide * 2)
          left = anchorCenter - maxWidth / 2
        } else if (placement === Placement['bottom-end'] || placement === Placement['top-end']) {
          maxWidth = getMaxWidth(width, anchor.right - horizontalMargin - extraPadding)
          left = anchor.right - maxWidth - horizontalMargin
        } else if (placement === Placement['bottom-start'] || placement === Placement['top-start']) {
          maxWidth = getMaxWidth(width, scrollRight - bound.x - horizontalMargin)
          left = anchor.x
        } else if (placement === Placement.start) {
          maxWidth = getMaxWidth(width, bound.x - parentLeft - horizontalMargin - extraPadding)
          left = anchor.x - maxWidth - extraPadding
        } else if (placement === Placement.end) {
          left = anchor.x + bound.width + extraPadding
          maxWidth = maxWidth - extraPadding
        }

        setTooltipPlacement({
          top,
          left,
          maxWidth,
          height,
        })

        rendered.current = true
      }

      if (node !== null) {
        const { width, height } = node.getBoundingClientRect()

        calculateTooltipPlacementAndSize(width, height)
      }
    },
    [bound, placement, scrollParent]
  )

  const handleEnter = (e: React.MouseEvent | React.FocusEvent) => {
    if ((e.type === 'mouseover' || e.type === 'focus' || e.type === 'touchstart') && !open) {
      e.preventDefault()
      setOpen(true)
      setBound(e.currentTarget.getBoundingClientRect())
    }
  }

  const handleLeave = (e: React.MouseEvent | React.FocusEvent) => {
    if ((e.type === 'mouseleave' || e.type === 'blur' || e.type === 'touchleave') && open) {
      e.preventDefault()

      setOpen(false)
      setBound(null)
      setTooltipPlacement(null)
    }
  }

  const childrenProps = {
    'aria-describedby': open ? id : null,
    ...children?.props,
    title: open ? null : title,
    style: {
      ...children?.props?.style,
      cursor: 'pointer',
    },
    tabIndex: 0,
    onMouseOver: handleEnter,
    onMouseLeave: handleLeave,
    onFocus: handleEnter,
    onBlur: handleLeave,
    onTouchStart: handleEnter,
    onTouchEnd: handleLeave,
  }

  return (
    <>
      {React.cloneElement(children, childrenProps)}
      {open &&
        createPortal(
          <StyledTooltip
            ref={tooltipRef}
            id={id}
            role="tooltip"
            placement={placement as Placement}
            open={open}
            style={{
              transform: `translate3d(${tooltipPlacement?.left}px, ${tooltipPlacement?.top}px, 0)`,
              maxWidth: `${tooltipPlacement?.maxWidth}px`,
            }}
            $zIndex={zIndex}
            isBrand={isBrand}
            {...rest}
          >
            {isBrand ? (
              <BrandLabel size={BrandLabelSize.Five}>{title}</BrandLabel>
            ) : (
              <Label size={LabelSize.Five}>{title}</Label>
            )}
          </StyledTooltip>,
          scrollParent || document.body
        )}
    </>
  )
})

const tooltipSubComponents: TooltipSubComponents = {
  Main: StyledTooltip,
  Label: Label,
  BrandLabel: BrandLabel,
}

TooltipWithoutNamespace.displayName = 'Tooltip'

TooltipWithoutNamespace.defaultProps = {
  placement: Placement['bottom'],
  isBrand: false,
}

TooltipWithoutNamespace.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.node,
  placement: PropTypes.oneOf(Object.keys(Placement)) as Validator<Placement>,
  isBrand: PropTypes.bool,
  zIndex: PropTypes.string,
  children: PropTypes.element,
}

export const Tooltip = addNamespace(TooltipWithoutNamespace, tooltipSubComponents)
