import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { CloseIcon } from '../../design-tokens/icons/pictograph'
import {
  AlertIndicatorIcon,
  CheckmarkIndicatorIcon,
  InfoIndicatorIcon,
  WarningIndicatorIcon,
} from '../../design-tokens/icons/indicator'
import {
  WarningIcon,
  CheckmarkCircledIcon,
  InfoIcon,
  WarningTriangleIcon,
  CloseIcon as BrandCloseIcon,
} from '../../design-tokens/icons/brand-ui-icons'
import { BodySize } from '../../design-tokens/typography'
import {
  ButtonText,
  colors,
  brandColors,
  Container,
  ContentContainer,
  IndicatorIconContainer,
  StyledCloseButton,
  StyledBrandCloseButton,
} from './Notification.style'
import { NotificationType, BrandSize, BrandMode } from '../../utils/enums'
import { NotificationProps, NotificationSubComponents } from './Notification.types'
import { BrandTheme, XyzTheme } from '@postidigital/posti-theme'
import { defaultIconProps } from '../../design-tokens/icons/icons.types'

const brandDefaultProps = {
  ...defaultIconProps,
  width: `${XyzTheme.iconSize.xs}em`,
  height: `${XyzTheme.iconSize.xs}em`,
}

const renderIcon = (type: NotificationType | keyof typeof NotificationType, isBrand: boolean) => {
  switch (type) {
    case NotificationType.alert:
      if (isBrand) {
        return <WarningIcon {...brandDefaultProps} color={BrandTheme.color.red60} />
      } else {
        return <AlertIndicatorIcon inverted={true} />
      }
    case NotificationType.informational:
      if (isBrand) {
        return <InfoIcon {...brandDefaultProps} color={BrandTheme.color.blue50} />
      } else {
        return <InfoIndicatorIcon inverted={true} />
      }
    case NotificationType.success:
      if (isBrand) {
        return <CheckmarkCircledIcon {...brandDefaultProps} color={BrandTheme.color.green80} />
      } else {
        return <CheckmarkIndicatorIcon inverted={true} />
      }
    case NotificationType.warning:
      if (isBrand) {
        return <WarningTriangleIcon {...brandDefaultProps} color={BrandTheme.color.yellow80} />
      } else {
        return <WarningIndicatorIcon inverted={true} color={XyzTheme.color.neutralNetworkGray} />
      }
    case NotificationType.text:
      return null
    default:
      if (isBrand) {
        return <InfoIcon {...brandDefaultProps} color={BrandTheme.color.blue50} />
      } else {
        return <InfoIndicatorIcon inverted={true} />
      }
  }
}

export const Notification: React.FC<NotificationProps> & NotificationSubComponents = ({
  children,
  dismissible,
  showIcon,
  onClose,
  closeText,
  contentType,
  isBrand,
  ...rest
}) => {
  const closeButtonProps = {
    onClick: onClose,
    'aria-label': closeText,
    iconColor: isBrand ? brandColors[contentType].closeButtonColor : colors[contentType].closeButtonColor,
    icon: isBrand ? BrandCloseIcon : CloseIcon,
  }

  return (
    <Container contentType={contentType} isBrand={isBrand} {...rest}>
      <ContentContainer isBrand={isBrand}>
        {showIcon && contentType !== NotificationType.text && (
          <IndicatorIconContainer dismissible={dismissible} isBrand={isBrand}>
            {renderIcon(contentType, isBrand)}
          </IndicatorIconContainer>
        )}
        <ButtonText size={BodySize.Five} as="span">
          {children}
        </ButtonText>
        {dismissible &&
          (isBrand ? (
            <StyledBrandCloseButton size={BrandSize.xxxs} contentMode={BrandMode.tertiary} {...closeButtonProps} />
          ) : (
            <StyledCloseButton {...closeButtonProps} />
          ))}
      </ContentContainer>
    </Container>
  )
}

export const BrandNotification: React.FC<NotificationProps> & NotificationSubComponents = (props) => {
  const { isBrand, ...rest } = props
  return <Notification {...rest} isBrand={true} />
}

Notification.Container = Container
Notification.ContentContainer = ContentContainer
Notification.IndicatorIconContainer = IndicatorIconContainer
Notification.Text = ButtonText
Notification.CloseButton = StyledCloseButton

BrandNotification.Container = Container
BrandNotification.ContentContainer = ContentContainer
BrandNotification.IndicatorIconContainer = IndicatorIconContainer
BrandNotification.Text = ButtonText
BrandNotification.BrandCloseButton = StyledBrandCloseButton

BrandNotification.displayName = 'BrandNotification'
Notification.displayName = 'Notification'

const notificationPropTypes = {
  contentType: PropTypes.oneOf(Object.values(NotificationType)) as Validator<NotificationType>,
  children: PropTypes.any.isRequired,
  dismissible: PropTypes.bool,
  showIcon: PropTypes.bool,
  onClose: PropTypes.func,
  closeText: PropTypes.string,
}

const notificationDefaultProps = {
  contentType: NotificationType.informational,
  dismissible: true,
  showIcon: true,
  width: '100%',
}

Notification.propTypes = notificationPropTypes
BrandNotification.propTypes = notificationPropTypes
Notification.defaultProps = notificationDefaultProps
BrandNotification.defaultProps = notificationDefaultProps
