import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const ExpressParcelIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M15.36 18.75v.23l.2-.1 5.12-2.43c.1-.04.17-.12.23-.21a.6.6 0 00.09-.31v-6.4-.23l-.2.09-5.36 2.55-.08.04v6.77zM12.25 10.99a.13.13 0 00-.13.01.15.15 0 00-.06.12v1.15c0 .12-.05.23-.13.3a.4.4 0 01-.29.14.4.4 0 01-.29-.13.44.44 0 01-.12-.3v-1.64c0-.03 0-.06-.02-.08a.14.14 0 00-.06-.05l-2.6-1.14a8.1 8.1 0 00-.2-.07v6.62c0 .1.03.22.1.31.05.1.14.17.24.22l5.66 2.46.19.09v-6.92-.1l-.09-.03-2.2-.96zM16.82 7.18a.14.14 0 00.06-.05.15.15 0 000-.16.14.14 0 00-.06-.05l-1.92-.87a.53.53 0 00-.44 0l-5.29 2.4a.14.14 0 00-.06.05.15.15 0 000 .16c.02.03.04.04.06.05l2.32 1.01a.13.13 0 00.1 0l5.23-2.54zM14.89 11.2a.13.13 0 00.1 0l5.22-2.48a.14.14 0 00.06-.05.15.15 0 000-.16.14.14 0 00-.06-.05l-2.05-.93a.13.13 0 00-.1 0l-5.2 2.53a.14.14 0 00-.06.05.15.15 0 000 .16l.06.05 2.03.88z"
      fill={color}
    />
    <Path
      d="M4.95 9.5c0-.28.21-.5.48-.5H7a.5.5 0 01.49.5.5.5 0 01-.49.5H5.43a.5.5 0 01-.48-.5zm-.98 3c0-.28.22-.5.49-.5h2.53a.5.5 0 01.49.5.5.5 0 01-.49.5H4.46a.5.5 0 01-.49-.5zm3.5 3A.5.5 0 017 16h-3.5a.5.5 0 01-.49-.5c0-.28.22-.5.49-.5h3.5a.5.5 0 01.49.5z"
      fill={color}
    />
  </SVG>
)

ExpressParcelIcon.propTypes = defaultIconPropTypes

ExpressParcelIcon.defaultProps = defaultPictographIconProps

export { ExpressParcelIcon }
