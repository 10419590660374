import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const MapIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M12 14c.06 0 .11-.02.16-.06.15-.12 3.63-3 3.63-5.08 0-1.03-.4-2-1.1-2.73a3.76 3.76 0 00-5.37 0A3.89 3.89 0 008.2 8.86c0 2.08 3.48 4.96 3.63 5.08.05.04.1.06.16.06zm0-6.69a1.25 1.25 0 011.17.8 1.3 1.3 0 01-.28 1.4 1.26 1.26 0 01-2.15-.91c0-.34.13-.67.37-.9.23-.25.55-.39.89-.39z"
      fill={color}
    />
    <Path
      d="M16.44 9.5h1l1.08 8.25H5.48L6.55 9.5h1.01c-.07-.26-.11-.5-.11-.75 0-.52.09-1.02.26-1.5H5.2c-.37 0-.7.28-.74.65L3 19.15c-.06.45.3.85.75.85h16.48c.46 0 .81-.4.75-.85L19.53 7.9a.76.76 0 00-.75-.65H16.3c.17.48.26.98.26 1.5 0 .24-.04.5-.11.75z"
      fill={color}
    />
    <Path
      d="M8.97 14.38H4.79v-2.26h4.18v2.26zM13.14 15.39v4.12h-2.28V15.4h2.28zM19.2 14.38h-4.17v-2.26h4.18v2.26z"
      fill={color}
    />
  </SVG>
)

MapIcon.propTypes = defaultIconPropTypes

MapIcon.defaultProps = defaultPictographIconProps

export { MapIcon }
