import styled from 'styled-components'
import { DefaultIconProps } from './icons.types'

export const Path = styled.path.attrs(
  ({ theme: { color }, stroke, fill = 'none', strokeLinecap = 'round', strokeLinejoin = 'round' }) => ({
    stroke: color[stroke] || stroke,
    fill,
    strokeLinecap,
    strokeLinejoin,
  })
)``

export const Rect = styled.rect.attrs(
  ({ theme: { color }, fill = 'none', stroke, strokeLinecap = 'round', strokeLinejoin = 'round' }) => ({
    stroke: color[stroke] || stroke,
    fill,
    strokeLinecap,
    strokeLinejoin,
  })
)``

export const Circle = styled.circle.attrs(({ theme: { color }, stroke, fill = 'none' }) => ({
  stroke: color[stroke] || stroke,
  fill,
}))``

export const SVG = styled.svg.attrs<Pick<DefaultIconProps, 'color'>>(({ viewBox, focusable }) => ({
  viewBox: viewBox || '0 0 24 24',
  focusable: focusable || false,
}))`
  ${Path}, ${Rect}, ${Circle} {
    stroke-width: 2px;
    stroke-width: clamp(1.5px, calc(100% * 0.0625 + 0.5px), 2.5px);
    stroke: ${(props) => props.color};
  }
`
