import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Circle, SVG } from '../brand-icons.styled'

const NumPadIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Circle cx="5" cy="3" r="1" />
    <Circle cx="5" cy="9" r="1" />
    <Circle cx="5" cy="15" r="1" />
    <Circle cx="12" cy="3" r="1" />
    <Circle cx="12" cy="9" r="1" />
    <Circle cx="12" cy="15" r="1" />
    <Circle cx="12" cy="21" r="1" />
    <Circle cx="19" cy="3" r="1" />
    <Circle cx="19" cy="9" r="1" />
    <Circle cx="19" cy="15" r="1" />
  </SVG>
)

NumPadIcon.propTypes = defaultIconPropTypes
NumPadIcon.defaultProps = defaultPictographIconProps

export { NumPadIcon }
