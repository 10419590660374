import React, { useId } from 'react'
import { UseRowSelectInstanceProps } from 'react-table'
import styled, { css } from 'styled-components'
import { ArrowLeftIcon } from '../../xyz'
import { ArrowLeftIcon as BrandArrowLeftIcon } from '../../brand'
import { Checkbox } from '../../basic-components/Checkbox/Checkbox'

const StyledCheckbox = styled(Checkbox)`
  margin-right: 0;
`

export const StyledArrowDownIcon = styled(ArrowLeftIcon)<{ isVisible: boolean; isUp: boolean }>`
  ${({ isVisible, isUp, theme: { spacing } }) => css`
    display: inline-block;
    margin-left: ${spacing.space3}rem;
    visibility: ${isVisible ? 'visible' : 'hidden'};
    transform: ${isUp ? 'rotate(90deg)' : 'rotate(-90deg)'};
  `}
`
export const StyledBrandArrowDownIcon = styled(BrandArrowLeftIcon)<{ isVisible: boolean; isUp: boolean }>`
  ${({
    isVisible,
    isUp,
    theme: {
      brand: { spacing },
    },
  }) => css`
    display: inline-block;
    margin-left: ${spacing.space2}rem;
    visibility: ${isVisible ? 'visible' : 'hidden'};
    transform: ${isUp ? 'rotate(90deg)' : 'rotate(-90deg)'};
  `}
`

export const CheckboxHeader = <D extends object>({ getToggleAllRowsSelectedProps }: UseRowSelectInstanceProps<D>) => {
  const { title, ...props } = getToggleAllRowsSelectedProps()
  return (
    <StyledCheckbox
      {...props}
      onChange={props.onChange as any}
      value={title || 'Check all checkboxes'}
      id={useId()}
      aria-label={title}
      enterKeyHint={undefined}
    />
  )
}

export const BrandCheckboxHeader = <D extends object>({
  getToggleAllRowsSelectedProps,
}: UseRowSelectInstanceProps<D>) => {
  const { title, ...props } = getToggleAllRowsSelectedProps()
  return (
    <StyledCheckbox
      {...props}
      isBrand={true}
      onChange={props.onChange as any}
      value={title || 'Check all checkboxes'}
      id={useId()}
      aria-label={title}
      enterKeyHint={undefined}
    />
  )
}

CheckboxHeader.displayName = 'Header'
BrandCheckboxHeader.displayName = 'BrandHeader'

export const CheckboxCell = <D extends object>({ row }: { row: any }) => {
  const { title, ...props } = row.getToggleRowSelectedProps()
  return (
    <StyledCheckbox
      {...props}
      id={useId()}
      onChange={props.onChange as any}
      value="Toggle row checkbox"
      aria-label={title}
      enterKeyHint={undefined}
    />
  )
}

export const BrandCheckboxCell = <D extends object>({ row }: { row: any }) => {
  const { title, ...props } = row.getToggleRowSelectedProps()
  return (
    <StyledCheckbox
      {...props}
      isBrand={true}
      id={useId()}
      onChange={props.onChange as any}
      value="Toggle row checkbox"
      aria-label={title}
      enterKeyHint={undefined}
    />
  )
}

CheckboxCell.displayName = 'Cell'
BrandCheckboxCell.displayName = 'Cell'

export const TableHeaderWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`
