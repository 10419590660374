import styled, { css } from 'styled-components'
import { BrandExpanderProps, ExpanderProps } from './Expander.types'

export const ExpanderWrapper = styled.div<ExpanderProps & Pick<BrandExpanderProps, 'hasShadow' | 'hasRoundedCorners'>>(
  ({
    theme: { brand, color, borderRadius },
    changeColorOnClick,
    customBackgroundColorClosed,
    customBackgroundColorOpened,
    customBackgroundColorOnHover,
    alignChevronToCenter,
    open,
    hasShadow,
    hasRoundedCorners,
    isBrand,
  }) => css`
    transition: all 200ms ease-in-out;
    border-radius: ${isBrand ? (hasRoundedCorners ? `${borderRadius.sm}rem` : `0`) : `${borderRadius.sm}rem`};
    overflow: hidden;

    ${!isBrand &&
    css`
      background-color: ${changeColorOnClick
        ? open
          ? color[customBackgroundColorOpened] || customBackgroundColorOpened
          : color[customBackgroundColorClosed] || customBackgroundColorClosed
        : color[customBackgroundColorClosed] || customBackgroundColorClosed};

      @media (hover: hover) {
        &:hover {
          background-color: ${customBackgroundColorOnHover
            ? color[customBackgroundColorOnHover] || customBackgroundColorOnHover
            : color.neutralOnHoverGray};
        }
      }
    `}

    ${isBrand
      ? css`
          border: none;
          background-color: ${brand.color.white};

          @media (hover: hover) {
            &:hover {
              background-color: ${brand.color.gray10};
            }
          }

          ${hasShadow &&
          css`
            box-shadow:
              0px 15px 22px 0px rgba(0, 0, 0, 0.04),
              0px 3px 8px 0px rgba(0, 0, 0, 0.08),
              0px 1px 1px 0px rgba(0, 0, 0, 0.08),
              0px 3px 1px 0px rgba(0, 0, 0, 0.06);
          `}

          ${ExpanderBody} {
            background-color: ${brand.color.white};
          }

          ${hasRoundedCorners &&
          css`
            & > button {
              border-radius: ${open ? `${borderRadius.sm}rem ${borderRadius.sm}rem 0 0` : `${borderRadius.sm}rem`};
            }
          `}

          &:has(button:focus-visible) {
            border: none;
          }
        `
      : css`
          &:has(button:focus-visible) {
            box-shadow: 0 0 7px ${color.signalBlue};
          }
        `}

    ${TitleWrapper} {
      transition: all 100ms ease-in;
      align-items: ${isBrand ? 'center' : `${alignChevronToCenter ? 'center' : 'flex-start'}`};
      border-radius: ${isBrand
        ? hasRoundedCorners
          ? open
            ? `${borderRadius.sm}rem ${borderRadius.sm}rem 0 0`
            : `${borderRadius.sm}rem`
          : `0`
        : `${borderRadius.sm}rem`};
    }
  `
)

export const ExpanderBody = styled.div.attrs<{ open: boolean }>(({ open }) => ({
  role: 'region',
  'aria-hidden': !open,
}))<{ open: boolean }>(
  ({ theme: { spacing }, open }) => css`
    transition:
      opacity 200ms ease-in-out,
      height 200ms ease,
      padding 200ms ease,
      margin 200ms ease;
    padding: ${open ? `${spacing.space2}rem` : '0'};
    margin: 0;
    opacity: ${open ? 1 : 0};
    visibility: ${open ? 'visible' : 'hidden'};
    ${!open &&
    css`
      height: 0;
      display: none;
    `}
  `
)

export const ChevronWrapper = styled.div.attrs({ tabIndex: -1 })<{ open: boolean; isBrand?: boolean }>(
  ({ theme: { brand, spacing }, open, isBrand }) => css`
    display: flex;
    padding: ${isBrand
      ? `${brand.spacing.space5}rem ${brand.spacing.space5}rem ${brand.spacing.space5}rem 0`
      : `${spacing.space3}rem ${spacing.space3}rem ${spacing.space3}rem 0`};
    svg {
      transition: all 200ms ease;
      transform: translate3d(0, 0, 0) rotate(${open ? -180 : 0}deg);
    }
  `
)

export const TitleWrapper = styled.button<{ isBrand?: boolean }>(
  ({ theme: { brand, borderRadius }, isBrand }) => css`
    display: flex;
    outline: none;
    width: 100%;
    border: none;
    text-align: left;
    cursor: pointer;
    justify-content: space-between;
    background-color: ${isBrand ? brand.color.white : 'transparent'};
    padding: 0;
    border-radius: ${borderRadius.sm}rem;
    user-select: none;
    min-height: 5rem;

    ${isBrand &&
    css`
      @media (hover: hover) {
        &:hover {
          background-color: ${brand.color.gray10};
        }
      }

      &:active {
        background-color: ${brand.color.gray20};
      }

      &[aria-disabled='true'] {
        pointer-events: none;
        background-color: ${brand.color.gray10};

        label {
          color: ${brand.color.gray30};
        }
        svg path {
          stroke: ${brand.color.gray30};
        }
      }
    `}

    &:focus,
    &:focus:active {
      outline: none;
      ${isBrand &&
      css`
        box-shadow: inset 0 0 0 3px ${brand.color.postiOrange60};
      `}
    }
  `
)

export const ContentWrapper = styled.div.attrs({ tabIndex: -1 })`
  width: 100%;
  user-select: none;
`
