import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const MessageBubbleIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M19.8261 3.5H4.17391C3.86257 3.5 3.56398 3.62496 3.34383 3.84739C3.12368 4.06981 3 4.37149 3 4.68605V15.4016C3.00109 15.5551 3.03249 15.7069 3.09238 15.848C3.15227 15.9891 3.23944 16.1167 3.34877 16.2233C3.4581 16.33 3.5874 16.4135 3.72909 16.469C3.87078 16.5245 4.02201 16.5508 4.17391 16.5465H6.52174C6.52174 16.9205 6.52174 20.1047 6.52174 20.5C7.69565 19.7093 10.3169 17.8093 12.1221 16.5465H19.8261C20.1374 16.5465 20.436 16.4216 20.6562 16.1991C20.8763 15.9767 21 15.675 21 15.3605V4.68605C21 4.37149 20.8763 4.06981 20.6562 3.84739C20.436 3.62496 20.1374 3.5 19.8261 3.5ZM6.71739 11.8023C6.71739 11.645 6.77923 11.4942 6.88931 11.383C6.99938 11.2718 7.14868 11.2093 7.30435 11.2093H16.6957C16.8513 11.2093 17.0006 11.2718 17.1107 11.383C17.2208 11.4942 17.2826 11.645 17.2826 11.8023C17.2826 11.9596 17.2208 12.1104 17.1107 12.2217C17.0006 12.3329 16.8513 12.3953 16.6957 12.3953H7.30435C7.14868 12.3953 6.99938 12.3329 6.88931 12.2217C6.77923 12.1104 6.71739 11.9596 6.71739 11.8023ZM16.6957 8.83721H7.30435C7.14868 8.83721 6.99938 8.77473 6.88931 8.66352C6.77923 8.5523 6.71739 8.40147 6.71739 8.24419C6.71739 8.08691 6.77923 7.93607 6.88931 7.82486C6.99938 7.71364 7.14868 7.65116 7.30435 7.65116H16.6957C16.8513 7.65116 17.0006 7.71364 17.1107 7.82486C17.2208 7.93607 17.2826 8.08691 17.2826 8.24419C17.2826 8.40147 17.2208 8.5523 17.1107 8.66352C17.0006 8.77473 16.8513 8.83721 16.6957 8.83721Z"
      fill={color}
    />
  </SVG>
)

MessageBubbleIcon.propTypes = defaultIconPropTypes

MessageBubbleIcon.defaultProps = defaultPictographIconProps

export { MessageBubbleIcon }
