import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const ChevronUpIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M18 15L12 9L6 15" />
  </SVG>
)

ChevronUpIcon.propTypes = defaultIconPropTypes
ChevronUpIcon.defaultProps = defaultPictographIconProps

export { ChevronUpIcon }
