import React from 'react'
import { HeadlineSize } from '../../design-tokens/typography'
import { CounterText } from './Textarea.style'
import { CounterProps, CounterSubComponents } from './Textarea.types'

export const Counter: React.FC<CounterProps> & CounterSubComponents = ({ length, maxLength, ...rest }) => {
  return (
    <CounterText size={HeadlineSize.Eight} {...rest}>
      {length}/{maxLength}
    </CounterText>
  )
}

Counter.Text = CounterText
