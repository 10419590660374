import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const BillIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M19 7.5L14.8 3H6.4c-.37 0-.73.16-.99.44S5 4.1 5 4.5v15c0 .4.15.78.41 1.06s.62.44.99.44H19V7.5zM8.49 10.96a4.3 4.3 0 007.47 2.36.75.75 0 10-1.12-1A2.8 2.8 0 0110 10.96h2.55a.75.75 0 100-1.5h-2.38c.41-1 1.4-1.71 2.58-1.71.83 0 1.58.36 2.09.93a.75.75 0 001.12-1 4.3 4.3 0 00-7.37 1.78h-.84a.75.75 0 100 1.5h.74z"
      fill={color}
    />
  </SVG>
)

BillIcon.propTypes = defaultIconPropTypes

BillIcon.defaultProps = defaultPictographIconProps

export { BillIcon }
