import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'
import PropTypes from 'prop-types'
import { useTheme } from '../../../utils/useTheme'

interface Props extends DefaultIconProps {
  isColor: boolean
}

const ThumbsUpIcon: React.FC<Props> = (props) => {
  const theme = useTheme()
  return (
    <SVG {...props}>
      <Path
        d="M0 10.5C0 5.36862 2.87838 0 12 0C21.1216 0 24 5.36862 24 10.5C24 15.6318 21.1216 21 12 21C2.87838 21.0004 0 15.6712 0 10.5Z"
        fill={props.isColor ? theme.color.signalLightGreen : theme.color.neutralGray5}
      />
      <Path
        d="M6.643 8.62206H5.5715C4.97973 8.62206 4.5 8.98797 4.5 9.43934V14.343C4.5 14.7944 4.97973 15.1603 5.5715 15.1603H6.643C7.23477 15.1603 7.7145 14.7944 7.7145 14.343V9.43934C7.7145 8.98797 7.23477 8.62206 6.643 8.62206Z"
        fill={props.isColor ? theme.color.brandPetrol : theme.color.neutralNetworkGray}
      />
      <Path
        d="M19.1881 9.27591C19.0384 9.0729 18.8443 8.90814 18.6211 8.79465C18.3979 8.68117 18.1518 8.62209 17.9023 8.62209H15.2986C15.2183 8.62155 15.1391 8.60266 15.067 8.56682C14.9948 8.53098 14.9315 8.47911 14.8818 8.41504C14.7786 8.21804 14.7481 7.98996 14.796 7.77212L15.2579 5.55894C15.3159 5.33635 15.3214 5.10296 15.274 4.8778C15.2265 4.65264 15.1273 4.44208 14.9846 4.26328C14.8378 4.09414 14.6557 3.96041 14.4516 3.87184C14.2476 3.78327 14.0267 3.74207 13.805 3.75126C13.5833 3.76044 13.3664 3.81978 13.1701 3.92494C12.9738 4.0301 12.803 4.17844 12.6702 4.35918L10.0857 8.39107C10.0374 8.46099 9.97318 8.51807 9.89859 8.55751C9.82401 8.59695 9.7412 8.6176 9.65714 8.61773H9.32176C9.17967 8.61773 9.0434 8.67513 8.94293 8.77731C8.84246 8.87949 8.78601 9.01808 8.78601 9.16258V14.2885C8.78591 14.3891 8.81319 14.4877 8.86482 14.5735C8.91645 14.6593 8.99042 14.7289 9.07853 14.7745C11.5333 16.0495 12.0852 16.25 13.4127 16.25C13.7181 16.25 14.765 16.2195 15.3897 16.2195C16.8812 16.2195 17.8702 15.3477 18.4091 13.5802L19.4313 10.711C19.5035 10.4691 19.519 10.2133 19.4768 9.96419C19.4346 9.71503 19.3357 9.47935 19.1881 9.27591Z"
        fill={props.isColor ? theme.color.brandPetrol : theme.color.neutralNetworkGray}
      />
    </SVG>
  )
}

ThumbsUpIcon.defaultProps = {
  viewBox: '0 0 24 21',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}

ThumbsUpIcon.propTypes = {
  isColor: PropTypes.bool.isRequired,
}

export { ThumbsUpIcon }
