import styled, { css } from 'styled-components'
import { ToolButtonProps } from './ToolButton.types'
import { ButtonGroup } from '../../composite-components/ButtonGroup'

export const StyledButton = styled.button<ToolButtonProps>`
  ${({ theme: { borderRadius, color, spacing }, selected, text }) => css`
    border: none;
    border-radius: ${borderRadius.xs}rem;
    background-clip: padding-box;
    box-shadow: inset 0px 0px 0px 2px ${color.neutralGray5};
    background-color: ${selected ? color.brandPetrol : color.neutralWhite};
    color: ${selected ? color.neutralWhite : color.neutralNetworkGray};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding: ${text ? `0.75rem ${spacing.space3}rem` : '0.75rem'};
    user-select: none;

    @media (hover: hover) {
      &:hover {
        background-color: ${selected ? color.brandPetrol : color.neutralOnPressGray};
        box-shadow: inset 0px 0px 0px 2px ${selected ? color.neutralGray5 : color.neutralOnPressGray};
      }
    }

    &:active {
      background-color: ${selected ? color.brandPetrol : color.neutralOnPressGray};
      box-shadow: inset 0px 0px 0px 2px ${selected ? color.neutralGray5 : color.neutralOnPressGray};
    }

    &:focus {
      z-index: 2;
      outline: none;
      box-shadow:
        inset 0px 0px 0px 2px ${selected ? color.neutralGray5 : color.brandPetrol},
        0px 0px 7px ${color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow: inset 0px 0px 0px 2px ${selected ? color.neutralGray5 : color.brandPetrol};
      &[aria-disabled='true'] {
        box-shadow: none;
      }
    }

    &[aria-disabled='true'] {
      background-color: ${color.neutralGray5};
      box-shadow: inset 0px 0px 0px 2px ${color.neutralPassiveGray};
      color: ${color.neutralPassiveGray};
    }
  `}
`

export const Label = styled.span<{ withLeftMargin: boolean }>`
  ${({ theme: { fontFamily, fontWeight, fontSize, lineHeight, spacing }, withLeftMargin }) => css`
    font-family: ${fontFamily.Montserrat};
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.body.three}rem;
    line-height: ${lineHeight.body.three};

    ${withLeftMargin &&
    css`
      margin-left: ${spacing.space3}rem;
    `}
  `}
`

export const toolbarStyles = ({ theme: { borderRadius } }) => css`
  display: flex;
  flex-wrap: nowrap;

  & > button:first-child,
  & > div:first-child .select__control {
    border-radius: ${borderRadius.xs}rem 0 0 ${borderRadius.xs}rem;
  }

  & > button:last-child,
  & > div:last-child .select__control {
    border-radius: 0 ${borderRadius.xs}rem ${borderRadius.xs}rem 0;
  }

  & > button,
  & > div > button {
    margin-right: 0;
    border-radius: 0;
  }
`

export const Toolbar = styled(ButtonGroup)`
  ${toolbarStyles}
`
