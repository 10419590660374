import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const KnockDoorIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M12.3984 2L12.3984 3.13114" />
    <Path d="M8.60547 3.29639L9.40531 4.09623" />
    <Path d="M16.1914 3.29639L15.3916 4.09623" />
    <Path d="M16.7213 13.5155L17.4882 10.6535C17.7928 9.51658 17.1181 8.34799 15.9812 8.04337C15.3845 7.88348 14.7791 7.99337 14.2944 8.29828C14.1026 8.16824 13.8861 8.0675 13.6497 8.00414C13.0529 7.84425 12.4475 7.95415 11.9628 8.25906C11.771 8.12901 11.5545 8.02828 11.3181 7.96492C10.7214 7.80503 10.1159 7.91493 9.63124 8.21984C9.43942 8.08979 9.22296 7.98906 8.9865 7.9257C7.8496 7.62107 6.68102 8.29575 6.37639 9.43264L5.20534 13.803C4.41565 16.7502 6.16464 19.7795 9.11181 20.5692C10.6834 20.9903 12.2795 20.6886 13.544 19.8705C13.6594 19.8225 13.7718 19.7638 13.8796 19.6944L17.4507 17.3962C18.4405 16.7593 18.7265 15.4405 18.0896 14.4508C17.7655 13.9473 17.2651 13.6259 16.7213 13.5155Z" />
  </SVG>
)

KnockDoorIcon.propTypes = defaultIconPropTypes
KnockDoorIcon.defaultProps = defaultPictographIconProps

export { KnockDoorIcon }
