import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { UIMessageProps } from '../UIMessage/UIMessage'
import * as ReactSelect from 'react-select'
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager'
import { AsyncAdditionalProps } from 'react-select/dist/declarations/src/useAsync'

export type DropdownInstance = ReactSelect.SelectInstance

export type CustomDropdownProps = {
  label: string
  isValid?: boolean
  /**
   * Should be enabled if the form has a light background
   */
  lightBackground?: boolean
  icon?: React.ComponentType<DefaultIconProps>
  iconColor?: string
  zIndex?: number
  message?: string
  messageProps?: Partial<UIMessageProps>
  placeholder?: never
  classNamePrefix?: never
  hasChevron?: boolean
  handleClose?: React.MouseEventHandler<HTMLButtonElement>
  isBrand?: boolean
  /**
   * Enables styling for Dropdown in a Toolbar, see the Toolbar story
   */
  isSelected?: boolean
  /**
   * Used for labeling disabled state for screen readers to announce it
   */
  screenReaderDisabledLabel?: string
}

export interface DropdownOption extends Record<string, unknown> {
  readonly label: string
}

export const DropdownPropTypes = {
  id: PropTypes.string,
  label: PropTypes.string.isRequired,
  isValid: PropTypes.bool,
  message: PropTypes.string,
  messageProps: PropTypes.object,
  zIndex: PropTypes.number,
  lightBackground: PropTypes.bool,
  inputId: PropTypes.string.isRequired,
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  iconColor: PropTypes.string,
  menuPlacement: PropTypes.oneOf<DropdownProps['menuPlacement']>(['auto', 'bottom', 'top']),
  hasChevron: PropTypes.bool,
  handleClose: PropTypes.func,
  isBrand: PropTypes.bool,
  isSelected: PropTypes.bool,
  'aria-disabled': PropTypes.bool,
}

export type DropdownProps = ReactSelect.Props & CustomDropdownProps
export type BrandAsyncDropdownProps<Option, Group extends ReactSelect.GroupBase<Option>> = StateManagerProps &
  AsyncAdditionalProps<Option, Group> &
  CustomDropdownProps
