import styled, { css } from 'styled-components'
import { Placement } from '../../utils/enums'
import { Label } from '../../design-tokens/typography'
import { Label as BrandLabel } from '../../design-tokens/brand-typography'

export const StyledTooltip = styled.div<{ placement: Placement; open: boolean; $zIndex?: string; isBrand?: boolean }>(
  ({ theme: { brand, spacing, color, borderRadius, zIndex }, placement, open, $zIndex, isBrand }) => css`
    background-color: ${isBrand ? brand.color.gray70 : color.neutralNetworkGray};
    padding: ${isBrand ? `${brand.spacing.space2}rem` : `${spacing.space2}rem`};
    border-radius: ${isBrand ? '0.25rem' : `${borderRadius.xs}rem`};
    position: absolute;
    display: ${open ? 'block' : 'none'};
    z-index: ${$zIndex ?? zIndex.zIndex101};
    top: 0;
    left: 0;
    ${BrandLabel} {
      line-height: 1.2;
    }

    ${Label}, ${BrandLabel} {
      color: ${isBrand ? brand.color.white : color.neutralWhite};
    }

    ${String(placement).includes('bottom') &&
    `
      margin-top: ${isBrand ? `${brand.spacing.space1}rem` : `${spacing.space2}rem`};
    `}

    ${String(placement).includes('top') &&
    `
      margin-top: ${isBrand ? `-${brand.spacing.space1}rem` : `-${spacing.space2}rem`};
    `}

  ${(placement === Placement['bottom-end'] || placement === Placement['top-end']) &&
    `
    margin-left: ${spacing.space3}rem;
  `}

  ${(placement === Placement['bottom-start'] || placement === Placement['top-start']) &&
    `
    margin-right: ${spacing.space3}rem;
  `}
  ${placement === Placement.start &&
    `
    margin-left: ${isBrand ? `-${brand.spacing.space1}rem` : `-${spacing.space2}rem`};
    padding-right: ${isBrand ? `${brand.spacing.space2}rem` : `${spacing.space3}rem`};
  `}
  ${placement === Placement.end &&
    `
    margin-left: ${isBrand ? `${brand.spacing.space1}rem` : `${spacing.space2}rem`};
    padding-left: ${isBrand ? `${brand.spacing.space2}rem` : `${spacing.space3}rem`};
  `}

    &:before {
      content: '';
      position: absolute;
      margin: auto;
      display: block;
      width: ${isBrand ? `0.707rem` : `1rem`};
      height: ${isBrand ? `0.707rem` : `1rem`};
      background-color: ${isBrand ? brand.color.gray70 : color.neutralNetworkGray};
      transform: rotate(45deg);

      ${String(placement).includes('bottom') &&
      `
      top: ${isBrand ? `-0.35rem` : `-${spacing.space2}rem`};
    `}

      ${String(placement).includes('top') &&
      `
      bottom: ${isBrand ? `-0.35rem` : `-${spacing.space2}rem`};
    `}

    ${(placement === Placement['bottom-end'] || placement === Placement['top-end']) &&
      `
      right: ${isBrand ? `${brand.spacing.space4}rem` : `${spacing.space3}rem`};
    `}

    ${(placement === Placement['bottom-start'] || placement === Placement['top-start']) &&
      `
      left: ${isBrand ? `${brand.spacing.space4}rem` : `${spacing.space3}rem`};
    `}

    ${placement === Placement.start &&
      `
      transform: translateY(-50%) rotate(45deg);
      top: 50%;
      right: ${isBrand ? `-0.35rem` : `-${spacing.space2}rem`};
    `}

    ${placement === Placement.end &&
      `
      transform: translateY(-50%) rotate(45deg);
      top: 50%;
      left: ${isBrand ? `-0.35rem` : `-${spacing.space2}rem`};
    `}

    ${(placement === Placement.top || placement === Placement.bottom) &&
      `
      left: 50%;
      transform: translateX(-50%) rotate(45deg);
    `}
    }
  `
)
