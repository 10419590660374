import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const HomeIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M11.9999 4L3 12.6666C3.26426 12.6666 4.30795 12.6665 4.57491 12.6666H5.41867C5.46342 12.6666 5.52772 12.6666 5.58742 12.6666C5.58742 12.7257 5.58742 12.7891 5.58742 12.8333V19.3333C5.58742 19.5101 5.58742 19.7257 5.58742 20C5.69999 20 6.0834 20 6.26242 20H10.1437C10.1884 20 10.2 20 10.3124 20C10.3124 19.9375 10.3124 19.8775 10.3124 19.8333V16.6666C10.3124 16.2246 10.4902 15.8007 10.8067 15.4881C11.1232 15.1756 11.5524 15 11.9999 15C12.4475 15 12.8767 15.1756 13.1932 15.4881C13.5097 15.8007 13.6874 16.2246 13.6874 16.6666V19.8333C13.6874 19.8775 13.6874 19.9306 13.6874 20C13.7999 20 13.8114 20 13.8562 20H17.7375C17.9165 20 18.2999 20 18.4125 20C18.4125 19.9097 18.4125 19.5101 18.4125 19.3333V12.8333C18.4125 12.7891 18.4125 12.7222 18.4125 12.6666C18.4757 12.6666 18.5365 12.6666 18.5812 12.6666C19.0746 12.6666 20.9999 12.7154 20.9999 12.7154L11.9999 4Z"
      fill={color}
    />
  </SVG>
)

HomeIcon.propTypes = defaultIconPropTypes

HomeIcon.defaultProps = defaultPictographIconProps
