import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { useTheme } from 'styled-components'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { CloseIcon } from '../../xyz'
import { Label, LabelSize } from '../../design-tokens/typography/Label'
import { Label as BrandLabel, LabelSize as BrandLabelSize } from '../../design-tokens/brand-typography/Label'
import { CloseIcon as BrandCloseIcon } from '../../design-tokens/icons/brand-ui-icons'
import { StyledIcon, StyledIconContainer, StyledButton } from './FilterButton.styles'
import { FilterButtonProps, FilterButtonSubComponents } from './FilterButton.types'
import { addNamespace } from '../../utils/helpers'
import withBrand from '../../utils/withBrand'

const XyzInnerContent: React.FC<FilterButtonProps> = (props) => {
  const theme = useTheme()
  const { icon: Icon, disabled, selected, withCloseIcon, children } = props

  return (
    <>
      {!!Icon && (
        <StyledIcon
          as={Icon}
          width={`${theme.brand.spacing.space4}rem`}
          height={`${theme.brand.spacing.space4}rem`}
          aria-hidden={true}
          color={
            disabled
              ? theme.color.neutralPassiveGray
              : selected
                ? theme.color.neutralWhite
                : theme.color.neutralNetworkGray
          }
        />
      )}
      <Label size={LabelSize.Five}>{children}</Label>
      {withCloseIcon && selected && !disabled && (
        <StyledIconContainer>
          <CloseIcon
            width={`${theme.brand.spacing.space4}rem`}
            height={`${theme.brand.spacing.space4}rem`}
            aria-hidden={true}
            color={theme.brand.color.white}
          />
        </StyledIconContainer>
      )}
    </>
  )
}

const BrandInnerContent: React.FC<FilterButtonProps> = (props) => {
  const theme = useTheme()
  const { icon: Icon, disabled, selected, withCloseIcon, children } = props

  return (
    <>
      {!!Icon && (
        <StyledIcon
          as={Icon}
          width={`${theme.brand.spacing.space4}rem`}
          height={`${theme.brand.spacing.space4}rem`}
          aria-hidden={true}
          color={disabled ? theme.brand.color.gray30 : selected ? theme.brand.color.white : theme.brand.color.gray70}
        />
      )}
      <BrandLabel size={BrandLabelSize.Five}>{children}</BrandLabel>
      {withCloseIcon && selected && !disabled && (
        <StyledIconContainer>
          <BrandCloseIcon
            width={`${theme.brand.spacing.space4}rem`}
            height={`${theme.brand.spacing.space4}rem`}
            aria-hidden={true}
            color={theme.brand.color.white}
          />
        </StyledIconContainer>
      )}
    </>
  )
}

const FilterButtonWithoutNamespace = React.forwardRef<HTMLButtonElement, FilterButtonProps>(
  ({ isBrand, withCloseIcon, selected, children, disabled, value, onClick, icon: Icon, ...rest }, ref) => {
    const handleClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
      onClick(e, value)
    }

    return (
      <StyledButton
        aria-disabled={disabled}
        selected={selected}
        aria-selected={selected}
        role="option"
        ref={ref}
        onClick={handleClick}
        value={value}
        isBrand={isBrand}
        {...rest}
      >
        {isBrand ? (
          <BrandInnerContent icon={Icon} selected={selected} disabled={disabled} withCloseIcon={withCloseIcon}>
            {children}
          </BrandInnerContent>
        ) : (
          <XyzInnerContent icon={Icon} selected={selected} disabled={disabled} withCloseIcon={withCloseIcon}>
            {children}
          </XyzInnerContent>
        )}
      </StyledButton>
    )
  }
)

FilterButtonWithoutNamespace.propTypes = {
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  withCloseIcon: PropTypes.bool,
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  isBrand: PropTypes.bool,
}

FilterButtonWithoutNamespace.defaultProps = {
  selected: false,
  disabled: false,
  withCloseIcon: false,
}

FilterButtonWithoutNamespace.displayName = 'FilterButton'

const BrandFilterButtonWithoutNamespace = withBrand(FilterButtonWithoutNamespace)

const filterButtonSubComponents: FilterButtonSubComponents = {
  Button: StyledButton,
  Label: Label,
  BrandLabel: BrandLabel,
  IconContainer: StyledIconContainer,
  Icon: StyledIcon,
  CloseIcon: CloseIcon,
  BrandCloseIcon: BrandCloseIcon,
}

export const FilterButton = addNamespace(FilterButtonWithoutNamespace, filterButtonSubComponents)
export const BrandFilterButton = addNamespace(BrandFilterButtonWithoutNamespace, filterButtonSubComponents)
