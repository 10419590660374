import React from 'react'
import PropTypes from 'prop-types'

import { StyledTab } from './Tab.style'
import TabPanel from './TabPanel/TabPanel'
import TabContext from './TabContext'

export interface TabProps {
  value?: string | number | boolean
  disabled?: boolean
  id?: string
  panelId?: string
  selected?: boolean
  tabRef?: React.RefObject<HTMLDivElement>
  onChange?: (value: string | number | boolean) => void
  children?: React.ReactNode
  isBrand?: boolean
}

/**
 * @deprecated This version of component is deprecated, please use new Tab instead
 */

const TabComponent = React.forwardRef<HTMLButtonElement, TabProps>((props, ref) => {
  const { children, disabled, panelId, selected, tabRef, value, onChange, isBrand, ...rest } = props

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <StyledTab
      ref={ref}
      role="tab"
      selected={selected}
      disabled={disabled}
      aria-selected={selected}
      aria-disabled={disabled}
      aria-controls={panelId}
      tabIndex={selected ? 0 : -1}
      onClick={handleClick}
      isBrand={isBrand}
      {...rest}
    >
      {children}
    </StyledTab>
  )
})

TabComponent.displayName = 'Tab'

TabComponent.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  disabled: PropTypes.bool,
  id: PropTypes.string,
  panelId: PropTypes.string,
  isBrand: PropTypes.bool,
}

const TabNameSpace = Object.assign(TabComponent, { Panel: TabPanel, Context: TabContext })

export { TabNameSpace as OldTab }
