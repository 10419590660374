import styled, { css, DefaultTheme, FlattenSimpleInterpolation } from 'styled-components'
import { ContentProps, InputProps } from './Input.types'
import { Body, BodyProps } from '../../design-tokens/typography/Body/Body'
import { Body as BrandBody, BodyProps as BrandBodyProps } from '../../design-tokens/brand-typography/Body/Body'

export const contentStyle = ({
  theme,
  hasMessage,
  isInvalid,
  readOnly,
  lightBackground,
  noBorderChangeOnFocus,
  isDark,
  isBrand,
}: ContentProps & { theme: DefaultTheme }): FlattenSimpleInterpolation => {
  const { spacing, color, borderRadius, brand } = theme
  return css`
    background-color: ${readOnly ? 'transparent' : lightBackground ? color.neutralGray2 : color.neutralWhite};
    width: 100%;
    padding: ${spacing.space3}rem ${spacing.space4}rem;
    border-radius: ${borderRadius.sm}rem;
    transition: box-shadow 0.2s ease-in-out;

    &[aria-disabled='true'] {
      pointer-events: none;
      background-color: ${color.neutralGray5};
    }

    ${isBrand &&
    css`
      background-color: ${readOnly ? 'transparent' : lightBackground ? brand.color.white : brand.color.gray1};
      color: ${brand.color.gray10};
      transition: all 100ms ease-in;

      &[aria-disabled='true'] {
        background-color: ${brand.color.gray10};
        color: ${brand.color.gray30};
      }

      &:not([aria-disabled='true']) {
        ${!readOnly &&
        css`
          button {
            &:focus {
              background-color: ${brand.color.gray10};
            }
          }
          @media (hover: hover) {
            &:hover {
              background-color: ${brand.color.gray10};
              ${!(isInvalid || hasMessage) &&
              css`
                box-shadow: inset 0 0 0 1px ${brand.color.gray10};
              `}
              button {
                background-color: ${brand.color.gray20};
              }
            }
          }

          &:active {
            background-color: ${brand.color.gray20};
            ${!(isInvalid || hasMessage) &&
            css`
              box-shadow: inset 0 0 0 1px ${brand.color.gray20};
            `}
          }
        `}
      }

      svg {
        & > path {
          stroke: ${brand.color.gray50};
          &[aria-disabled='true'] {
            stroke: ${brand.color.gray30};
          }
        }
      }
    `}

    ${!isInvalid &&
    !hasMessage &&
    !readOnly &&
    css`
      box-shadow: ${isBrand
        ? `inset 0 0 0 1px ${brand.color.gray10}`
        : `inset 0px -2px 0px ${color.neutralPassiveGray}`};
    `}

    &[aria-disabled='true'] {
      box-shadow: ${isBrand
        ? `inset 0 0 0 1px ${brand.color.gray10}`
        : `inset 0px -2px 0px ${color.neutralPassiveGray}`};
    }

    ${!readOnly &&
    css`
      &:not([aria-disabled='true']) {
        &:focus-within {
          box-shadow: inset 0 0 0 3px
            ${isBrand ? brand.color.postiOrange60 : isDark ? color.brandBrightYellow : color.signalBlue};

          ${isBrand &&
          css`
            @media (hover: hover) {
              &:hover {
                background-color: ${lightBackground ? brand.color.white : brand.color.gray1};
              }
            }

            &:active {
              background-color: ${lightBackground ? brand.color.white : brand.color.gray1};
            }
          `}
        }
      }
    `}

    ${(isInvalid || hasMessage) &&
    css`
      box-shadow: inset 0 0 0 3px ${isInvalid ? color.signalRed : color.signalGreen};
      &[aria-disabled='true'] {
        box-shadow: inset 0px 2px 0px ${color.neutralPassiveGray};
      }

      ${isBrand &&
      css`
        box-shadow: inset 0 0 0 3px ${isInvalid ? brand.color.red50 : brand.color.green80};

        &[aria-disabled='true'] {
          box-shadow: inset 0 0 0 1px ${brand.color.gray10};
        }
      `}
    `}

  ${noBorderChangeOnFocus &&
    css`
      box-shadow: none;
    `}

    ${StyledInput} {
      ${isBrand &&
      css`
        color: ${brand.color.gray70};
        &[aria-disabled='true'] {
          color: ${brand.color.gray30};
        }
      `}
    }
  `
}

export const Content = styled.div<ContentProps>(contentStyle)

export const InputContent = styled(Content)(
  ({ theme: { spacing } }) => css`
    display: flex;
    align-items: center;
    min-height: ${spacing.space9}rem;
  `
)

export const StyledLabel = styled.label<Pick<InputProps, 'isBrand'>>(
  ({ theme: { fontSize, fontWeight, color, fontFamily, brand }, isBrand }) => {
    return css`
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      width: 100%;
      overflow: hidden;
      pointer-events: none;
      user-select: none;
      color: ${color.neutralNetworkGray};
      font-family: ${fontFamily.Montserrat};
      transition:
        font-size 0.2s ease-in-out,
        transform 0.2s ease-in-out,
        height 0.2s ease-in-out;
      height: 1.5rem;
      font-size: ${fontSize.body.three}rem;
      font-weight: ${isBrand ? brand.fontWeight.medium : fontWeight.semiBold};
      transform: translateY(0);
      cursor: text;

      ${isBrand &&
      css`
        color: ${brand.color.gray70};
        font-family: ${brand.fontFamily.PostiFont};
      `}

      &[aria-disabled='true'] {
        color: ${color.neutralPassiveGray};
        ${isBrand &&
        css`
          color: ${brand.color.gray30};
        `}
      }
    `
  }
)

const styledLabelStyles = ({ theme: { fontSize, fontWeight } }) => {
  return css`
    height: 1.25rem;
    font-size: ${fontSize.body.five}rem;
    font-weight: ${fontWeight.normal};
    transform: translateY(-0.7rem);
  `
}

export const LabelAndInputContainer = styled.div<
  Pick<InputProps, 'isBrand' | 'readOnly'> & {
    hasLeftElement?: boolean
    hasRightElement?: boolean
    hasValue?: boolean
  }
>(({ theme: { spacing }, hasLeftElement, hasRightElement, hasValue }) => {
  return css`
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;
    padding: ${hasValue ? `${spacing.space3}rem 0 0 0` : `${spacing.space2}rem 0`};

    &:focus-within {
      padding: ${spacing.space3}rem 0 0 0;
    }

    ${hasLeftElement && `margin-left: ${spacing.space3}rem;`}
    ${hasRightElement && `margin-right: ${spacing.space3}rem;`}

    ${hasValue &&
    css`
      ${StyledLabel} {
        ${styledLabelStyles}
      }
    `}

      &:focus-within {
      ${StyledLabel} {
        &:not([aria-disabled='true']) {
          ${styledLabelStyles}
        }
      }
    }
  `
})

export const inputStyle = ({
  theme: { color, fontFamily, fontWeight, fontSize, brand },
  isBrand,
}: {
  theme: DefaultTheme
  isBrand: boolean
}): FlattenSimpleInterpolation => {
  return css`
    background: none;
    border: 0;
    margin: 0 ${brand.spacing.space5}rem 0 0;
    padding: 0;
    width: 100%;
    font-family: ${fontFamily.Montserrat};
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.body.three}rem;
    color: ${color.neutralNetworkGray};
    /* Force the caret color to be darker than the actual typed text for clarity */
    caret-color: ${color.neutralBlack};
    &[aria-disabled='true'] {
      color: ${color.neutralPassiveGray};
    }

    ${isBrand &&
    css`
      font-size: ${brand.fontSize.label.four}rem;
      font-family: ${brand.fontFamily.PostiFont};
      font-weight: ${brand.fontWeight.regular};
    `}

    /* Remove search input default styling in webkit browsers */
    ::-webkit-search-decoration,
    ::-webkit-search-cancel-button,
    ::-webkit-search-results-button,
    ::-webkit-search-results-decoration {
      display: none;
    }

    /* Placeholder text could be a bit 'grayer' than the actual text */
    ::placeholder {
      font-family: ${fontFamily.Montserrat};
      font-weight: ${fontWeight.normal};
      color: ${color.neutralPassiveGray};
      font-size: ${fontSize.body.three}rem;
    }

    /* Hide the clear/cross button that appears in MS browsers. Instead we should add our own clear button if needed */
    ::-ms-reveal,
    ::-ms-clear {
      display: none;
    }

    /* Clear native input appearance from webkit browsers, so that it doesn't conflict our own */
    -webkit-appearance: none;
    -moz-appearance: none;

    :focus {
      outline: none;
    }

    /* Clear the red box shadow firefox adds around :invalid inputs */
    :not(output):-moz-ui-invalid {
      box-shadow: none;
    }

    /* Alter the default autofill background color (https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete) */
    :-webkit-autofill,
    :-webkit-autofill:hover,
    :-webkit-autofill:focus,
    :-webkit-autofill:active,
    :-internal-autofill-selected {
      background-color: transparent;
      -webkit-box-shadow: 0 0 0 1000px transparent inset;
      -webkit-text-fill-color: ${color.neutralNetworkGray};
      transition: background-color 5000s ease-in-out 0s;
    }

    /* Expose a JS-detectable bindings to detect autofill (https://github.com/klarna/ui/blob/v4.10.0/Field/styles.scss#L228-L241) */
    @keyframes onAutoFillStart {
      from {
        /**/
      }
      to {
        /**/
      }
    }
    @keyframes onAutoFillCancel {
      from {
        /**/
      }
      to {
        /**/
      }
    }
    :-webkit-autofill {
      animation-name: onAutoFillStart;
    }
    :not(:-webkit-autofill) {
      animation-name: onAutoFillCancel;
    }
  `
}

export const StyledInput = styled.input<Pick<InputProps, 'isBrand'>>(inputStyle)

export const StyledBody = styled(Body)<BodyProps>(
  ({ theme: { spacing } }) => css`
    margin: ${spacing.space2}rem 0 0;
    padding: 0 ${spacing.space4}rem;
  `
)

export const StyledBrandBody = styled(BrandBody)<BrandBodyProps>(
  ({ theme: { spacing } }) => css`
    margin: ${spacing.space2}rem 0 0;
    padding: 0 ${spacing.space4}rem;
  `
)

export const RightTemplate = styled.div(
  ({ theme: { spacing } }) => css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: calc(${spacing.space4}rem - 3px);
  `
)

export const InputWrapper = styled.div.attrs({ role: 'none' })`
  position: relative;
`

export const Wrapper = styled.div``
