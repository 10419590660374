import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const BarCodeIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M8.99805 14L8.99804 8" />
    <Path d="M5.99805 16L5.99805 8" />
    <Path d="M17.998 16L17.998 8" />
    <Path d="M11.998 16L11.998 8" />
    <Path d="M14.998 14L14.998 8" />
    <Path d="M7 5H5C3.89543 5 3 5.89543 3 7V9M17 5H19C20.1046 5 21 5.89543 21 7V9M3 15V17C3 18.1046 3.89543 19 5 19H7M17 19H19C20.1046 19 21 18.1046 21 17V15" />
  </SVG>
)

BarCodeIcon.propTypes = defaultIconPropTypes
BarCodeIcon.defaultProps = defaultPictographIconProps

export { BarCodeIcon }
