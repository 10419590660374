import React, { HTMLAttributes, useEffect } from 'react'
import PropTypes from 'prop-types'

import { StyledTabIndicator, StyledTabList, StyledTabs } from './Tabs.style'
import useEventCallback from './useEventCallback'
import { TabsContext } from '../OldTab/TabContext'
import { KeyboardKeys } from '../../utils/enums'
import { TabProps } from '../OldTab/Tab'

export interface TabsProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onChange'> {
  selectedTab: string | number | boolean
  orientation?: 'horizontal' | 'vertical'
  onChange: (value: string | number | boolean) => void
  isBrand?: boolean
}

interface TabsMetaProps {
  clientWidth: number
  scrollLeft: number
  scrollTop: number
  scrollWidth: number
  top: number
  bottom: number
  left: number
  right: number
}

/**
 * @deprecated This version of component is deprecated, please use new Tabs instead
 */

const OldTabs: React.FC<TabsProps> = (props) => {
  const { selectedTab, orientation = 'horizontal', onChange, children: childrenProp, isBrand, ...rest } = props
  const tabListRef = React.useRef<HTMLDivElement>(null)
  const tabsRef = React.useRef<HTMLDivElement>(null)
  const [indicatorStyle, setIndicatorStyle] = React.useState<{ [x: string]: number }>({})
  const context = React.useContext(TabsContext)
  const valueToIndex = new Map()
  let childIndex = 0
  const isHorizontal = orientation === 'horizontal'

  /**
   * UpdateIndicatorState is quite expensive operation which will recalculate on every render.
   * The status indicator might also get out-of-sync when resizing the window.
   **/
  useEffect(() => {
    updateIndicatorState()
  })

  useEffect(() => {
    // Context is nullable so let's call `setSelectedTab` only when it's available
    context?.setSelectedTab?.(selectedTab)
  }, [selectedTab, context])

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    // TODO: Unsafe cast to element.
    const target = e.target as Element
    const role = target.getAttribute('role')

    if (role !== 'tab') {
      return
    }

    let newFocusTarget = null
    const previousItemKey = isHorizontal ? KeyboardKeys.ArrowLeft : KeyboardKeys.ArrowUp
    const nextItemKey = isHorizontal ? KeyboardKeys.ArrowRight : KeyboardKeys.ArrowDown

    switch (e.key) {
      case previousItemKey:
        newFocusTarget = target.previousElementSibling || tabListRef.current.lastChild
        break
      case nextItemKey:
        newFocusTarget = target.nextElementSibling || tabListRef.current.firstChild
        break
      case KeyboardKeys.Home:
        newFocusTarget = tabListRef.current.firstChild
        break
      case KeyboardKeys.End:
        newFocusTarget = tabListRef.current.lastChild
        break
      default:
        break
    }

    if (newFocusTarget !== null) {
      newFocusTarget.focus()
      e.preventDefault()
    }
  }

  const getTabsMeta = () => {
    const tabsNode = tabsRef.current
    let tabsMeta: TabsMetaProps
    let tabMeta: DOMRect

    if (tabsNode) {
      const rect = tabsNode.getBoundingClientRect()

      tabsMeta = {
        clientWidth: tabsNode.clientWidth,
        scrollLeft: tabsNode.scrollLeft,
        scrollTop: tabsNode.scrollTop,
        scrollWidth: tabsNode.scrollWidth,
        top: rect.top,
        bottom: rect.bottom,
        left: rect.left,
        right: rect.right,
      }
    }

    if (tabsNode && selectedTab !== false) {
      const children = tabListRef.current.children

      if (children.length > 0) {
        const tab = children[valueToIndex.get(selectedTab)]

        tabMeta = tab ? tab.getBoundingClientRect() : null
      }
    }

    return { tabsMeta, tabMeta }
  }

  const updateIndicatorState = useEventCallback(() => {
    const start = isHorizontal ? 'left' : 'top'
    const size = isHorizontal ? 'width' : 'height'

    const { tabsMeta, tabMeta } = getTabsMeta()
    let startValue = 0

    if (tabMeta && tabsMeta) {
      startValue = isHorizontal
        ? tabMeta.left - tabsMeta.left + tabsMeta.scrollLeft
        : tabMeta.top - tabsMeta.top + tabsMeta.scrollTop
    }

    const newIndicatorStyle = {
      [start]: orientation === 'vertical' ? startValue : startValue + 16,
      [size]: tabMeta ? (orientation === 'vertical' ? tabMeta[size] : tabMeta[size] - 32) : 0,
    }

    if (isNaN(indicatorStyle[start]) || isNaN(indicatorStyle[size])) {
      setIndicatorStyle(newIndicatorStyle)
    } else {
      const dStart = Math.abs(indicatorStyle[start] - newIndicatorStyle[start])
      const dSize = Math.abs(indicatorStyle[size] - newIndicatorStyle[size])

      if (dStart >= 1 || dSize >= 1) {
        setIndicatorStyle(newIndicatorStyle)
      }
    }
  })

  const children = React.Children.map(childrenProp, (child) => {
    if (!React.isValidElement(child)) {
      return null
    }

    const childValue = child.props.value === undefined ? childIndex : child.props.value
    valueToIndex.set(childValue, childIndex)
    const selected = childValue === selectedTab

    childIndex += 1

    return React.cloneElement<TabProps>(child, {
      selected,
      onChange,
      value: childValue,
    })
  })

  return (
    <StyledTabList ref={tabsRef} isBrand={isBrand} vertical={!isHorizontal}>
      <StyledTabs role="tablist" ref={tabListRef} onKeyDown={handleKeyDown} vertical={!isHorizontal} {...rest}>
        {children}
      </StyledTabs>
      <StyledTabIndicator vertical={!isHorizontal} style={indicatorStyle} />
    </StyledTabList>
  )
}

OldTabs.defaultProps = {
  orientation: 'horizontal',
  isBrand: false,
}

OldTabs.propTypes = {
  selectedTab: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
  onChange: PropTypes.func.isRequired,
  orientation: PropTypes.oneOf(['horizontal', 'vertical']),
  isBrand: PropTypes.bool,
}

export default OldTabs
