import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import {
  MenuItemProps,
  MenuItemContentProps,
  MenuItemSubComponents,
  MenuItemGroupProps,
  MenuItemContentSubComponents,
} from './MenuItem.types'
import {
  MenuItemWrapper,
  StyledButton,
  StyledBrandButton,
  StyledIcon,
  StyledMenuItem,
  StyledMenuItemContent,
  StyledMenuItemGroup,
  StyledMenuItemGroupUL,
} from './MenuItem.style'
import { getColor, getBrandColor } from './utils'
import { KeyboardKeys, buttonTypeOption, BrandSize, BrandMode } from '../../utils/enums'
import { ChevronDownIcon, ChevronRightIcon } from '../../design-tokens/icons/pictograph'
import { useTheme } from '../../utils/useTheme'
import {
  ChevronDownIcon as BrandChevronDownIcon,
  ChevronRightIcon as BrandChevronRightIcon,
} from '../../design-tokens/icons/brand-ui-icons'
import { Headline, HeadlineSize } from '../../design-tokens/typography'
import { Label as BrandLabel, LabelSize as BrandLabelSize } from '../../design-tokens/brand-typography'

export const MenuItemGroup: React.FC<MenuItemGroupProps> = (props) => {
  const { title, isBrand, children } = props

  return (
    <StyledMenuItemGroup>
      {isBrand ? (
        <BrandLabel size={BrandLabelSize.Four}>{title}</BrandLabel>
      ) : (
        <Headline size={HeadlineSize.Seven} as="p">
          {title}
        </Headline>
      )}
      {children && <StyledMenuItemGroupUL>{children}</StyledMenuItemGroupUL>}
    </StyledMenuItemGroup>
  )
}

export const MenuItemContent: React.FC<MenuItemContentProps> & MenuItemContentSubComponents = (props) => {
  const {
    icon: Icon,
    disabled,
    selected,
    onButtonClick,
    value,
    chevronAriaLabel,
    buttonType,
    open,
    isBrand,
    children,
  } = props
  const theme = useTheme()

  const hasIcon = !!Icon
  const iconProps = {
    width: `${theme.iconSize.s}em`,
    height: `${theme.iconSize.s}em`,
    color: isBrand ? getBrandColor({ selected, disabled }) : getColor({ selected, disabled }),
  }

  const handleButtonKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === KeyboardKeys.Enter || e.key === KeyboardKeys.Space) {
      e.preventDefault()
      e.stopPropagation()
      onButtonClick(value)
    }
  }

  const handleButtonClick = (e: React.MouseEvent) => {
    e.stopPropagation()
    onButtonClick(value)
  }

  const hasSubItems = buttonType === buttonTypeOption.bottomArrow

  const chevronButtonProps = {
    'aria-label': chevronAriaLabel,
    'aria-disabled': disabled,
    hasSubItems: hasSubItems,
    open: open,
    onKeyDown: handleButtonKeyDown,
    onClick: handleButtonClick,
    iconColor: disabled
      ? theme.color.neutralPassiveGray
      : selected
        ? theme.color.neutralNetworkGray
        : theme.color.neutralIconGray,
  }

  return (
    <StyledMenuItemContent disabled={disabled} selected={selected} tabIndex={0} isBrand={isBrand}>
      {hasIcon && <StyledIcon {...iconProps} as={Icon} aria-hidden={true} />}
      <MenuItemWrapper hasIcon={hasIcon} buttonType={buttonType}>
        {children}
      </MenuItemWrapper>
      {(buttonType === buttonTypeOption.bottomArrow || buttonType === buttonTypeOption.rightArrow) &&
        (isBrand ? (
          <StyledBrandButton
            size={BrandSize.xxxs}
            contentMode={BrandMode.secondary}
            hasBackground
            icon={hasSubItems ? BrandChevronDownIcon : BrandChevronRightIcon}
            {...chevronButtonProps}
          />
        ) : (
          <StyledButton icon={hasSubItems ? ChevronDownIcon : ChevronRightIcon} {...chevronButtonProps} />
        ))}
    </StyledMenuItemContent>
  )
}

MenuItemContent.Main = StyledMenuItemContent
MenuItemContent.Icon = StyledIcon
MenuItemContent.Wrapper = MenuItemWrapper
MenuItemContent.Button = StyledButton
MenuItemContent.BrandButton = StyledBrandButton

export const MenuItem: React.FC<MenuItemProps> & MenuItemSubComponents = (props) => {
  const {
    id,
    icon: Icon,
    disabled,
    selected,
    onSelect,
    onButtonClick,
    role,
    value,
    buttonType,
    chevronAriaLabel,
    children,
    isBrand,
    ...rest
  } = props

  const handleClick = () => {
    onSelect(value)
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === KeyboardKeys.Enter || e.key === KeyboardKeys.Space) {
      e.preventDefault()

      onSelect(value)
    }
  }

  return (
    <StyledMenuItem
      {...rest}
      id={id}
      role={role}
      aria-disabled={disabled}
      aria-current={selected ? 'page' : 'false'}
      onKeyDown={handleKeyPress}
      onClick={handleClick}
      isBrand={isBrand}
    >
      <MenuItemContent
        icon={Icon}
        buttonType={buttonType}
        value={value}
        selected={selected}
        disabled={disabled}
        onSelect={onSelect}
        onButtonClick={onButtonClick}
        chevronAriaLabel={chevronAriaLabel}
        isBrand={isBrand}
      >
        {children}
      </MenuItemContent>
    </StyledMenuItem>
  )
}

MenuItem.Content = MenuItemContent
MenuItem.Group = MenuItemGroup

MenuItem.propTypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSelect: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  chevronAriaLabel: PropTypes.string,
  isBrand: PropTypes.bool,
}

MenuItem.defaultProps = {
  selected: false,
  disabled: false,
  isBrand: false,
}

MenuItemGroup.propTypes = {
  title: PropTypes.string,
}
