import styled, { css } from 'styled-components'
import { ArrowRightIcon } from '../../design-tokens/icons/brand-ui-icons'
import { FilterButtonProps } from './FilterButton.types'

export const StyledButton = styled.button<FilterButtonProps>(
  ({ theme: { color, spacing, lineHeight, brand }, selected, isBrand }) => css`
    margin: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-bottom: ${spacing.space3}rem;
    border-radius: 1.25rem;
    cursor: pointer;
    outline: none;
    padding: 0.375rem ${brand.spacing.space3}rem;
    background-color: ${isBrand
      ? selected
        ? brand.color.gray80
        : 'transparent'
      : selected
        ? color.signalBlue
        : color.neutralGray5};
    border: ${selected ? `2px solid ${color.signalBlue}` : `2px solid transparent`};
    user-select: none;

    ${isBrand &&
    css`
      border: none;
      padding: ${brand.spacing.space2}rem ${brand.spacing.space4}rem;
    `}

    label {
      line-height: ${lineHeight.label.one}rem;
      cursor: pointer;
      color: ${isBrand
        ? selected
          ? brand.color.white
          : brand.color.gray70
        : selected
          ? color.neutralWhite
          : color.neutralNetworkGray};

      &:active {
        color: ${isBrand
          ? selected
            ? brand.color.white
            : brand.color.gray99
          : selected
            ? color.neutralWhite
            : color.neutralNetworkGray};
      }
    }

    @media (hover: hover) {
      &:hover {
        background-color: ${isBrand
          ? selected
            ? brand.color.gray90
            : brand.color.gray10
          : selected
            ? color.signalBlue
            : color.neutralOnHoverGray};
      }
    }

    &:active {
      background-color: ${isBrand
        ? selected
          ? brand.color.gray99
          : brand.color.gray20
        : selected
          ? color.signalBlue
          : color.neutralOnPressGray};
    }

    &:focus {
      outline: none;
      border: 2px solid ${selected ? color.neutralWhite : color.signalBlue};
      box-shadow: 0px 0px 7px ${color.signalBlue};
      z-index: 1;
      &[aria-disabled='true'] {
        box-shadow: none;
      }

      ${isBrand &&
      css`
        border: none;
        box-shadow: inset 0 0 0 3px ${brand.color.postiOrange60};
      `}
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
      border: ${isBrand ? 'none' : `2px solid ${color.signalBlue}`};
    }

    &[aria-disabled='true'] {
      cursor: default;
      pointer-events: none;
      background-color: ${isBrand ? (selected ? brand.color.gray10 : 'transparent') : color.neutralGray2};
      ${!isBrand &&
      css`
        border: 2px solid transparent;
      `}

      label {
        color: ${isBrand ? brand.color.gray30 : color.neutralPassiveGray};
      }
    }
  `
)

export const StyledIcon = styled(ArrowRightIcon)`
  ${({ theme: { spacing } }) => css`
    flex-shrink: 0;
    margin-right: ${spacing.space2}rem;
  `}
`

export const StyledIconContainer = styled.div`
  ${({ theme: { spacing, iconSize } }) => css`
    margin-left: ${spacing.space2}rem;
    flex-shrink: 0;
    width: ${iconSize.xs}rem;
    height: ${iconSize.xs}rem;
    & > svg {
      vertical-align: baseline;
    }
  `}
`
