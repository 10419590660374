import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const ThumbsDownIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M16.9991 2V13M21.9991 9.8V5.2C21.9991 4.07989 21.9991 3.51984 21.7811 3.09202C21.5893 2.71569 21.2834 2.40973 20.9071 2.21799C20.4792 2 19.9192 2 18.7991 2H8.11709C6.65561 2 5.92486 2 5.33466 2.26743C4.81447 2.50314 4.37238 2.88242 4.06032 3.36072C3.70625 3.90339 3.59513 4.62564 3.3729 6.07012L2.84983 9.47012C2.55672 11.3753 2.41017 12.3279 2.69288 13.0691C2.94102 13.7197 3.40773 14.2637 4.013 14.6079C4.70261 15 5.66641 15 7.59401 15H8.39908C8.95913 15 9.23916 15 9.45307 15.109C9.64123 15.2049 9.79421 15.3578 9.89008 15.546C9.99908 15.7599 9.99908 16.0399 9.99908 16.6V19.5342C9.99908 20.896 11.1031 22 12.4649 22C12.7897 22 13.0841 21.8087 13.216 21.5119L16.5768 13.9502C16.7296 13.6062 16.8061 13.4343 16.9269 13.3082C17.0337 13.1967 17.1648 13.1115 17.31 13.0592C17.4743 13 17.6625 13 18.0389 13H18.7991C19.9192 13 20.4792 13 20.9071 12.782C21.2834 12.5903 21.5893 12.2843 21.7811 11.908C21.9991 11.4802 21.9991 10.9201 21.9991 9.8Z" />
  </SVG>
)

ThumbsDownIcon.propTypes = defaultIconPropTypes
ThumbsDownIcon.defaultProps = defaultPictographIconProps

export { ThumbsDownIcon }
