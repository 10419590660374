import React from 'react'
import PropTypes from 'prop-types'
import { StyledGroupWrapper } from './ButtonGroup.styles'
import { ButtonGroupProps } from './ButtonGroup.types'
import { isArray } from '../../utils/helpers'

export const ButtonGroup = ({
  disabled,
  selectedValue,
  onChange,
  children,
  ...rest
}: ButtonGroupProps): JSX.Element => {
  const allSelected = isArray(selectedValue) ? [...selectedValue] : [selectedValue]
  const handleOnSelect = (
    e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
    value: string | number
  ) => {
    onChange(e, value)
  }

  return (
    <StyledGroupWrapper aria-label={rest['aria-label']} role="listbox" {...rest}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          ...child.props,
          disabled: disabled,
          onClick: handleOnSelect,
          selected: allSelected.includes(child.props.value),
        })
      )}
    </StyledGroupWrapper>
  )
}

ButtonGroup.Wrapper = StyledGroupWrapper

ButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.arrayOf(PropTypes.number)]),
  ]),
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  'aria-label': PropTypes.string.isRequired,
}

ButtonGroup.defaultProps = {
  disabled: false,
  'aria-label': 'Button Group',
}
