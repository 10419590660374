import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const SendIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M10.5014 11.9998H5.0014M4.91675 12.2913L2.58183 19.266C2.39839 19.8139 2.30668 20.0879 2.3725 20.2566C2.42966 20.4031 2.55242 20.5142 2.7039 20.5565C2.87834 20.6052 3.1418 20.4866 3.66874 20.2495L20.3802 12.7293C20.8945 12.4979 21.1517 12.3822 21.2312 12.2214C21.3002 12.0817 21.3002 11.9179 21.2312 11.7782C21.1517 11.6174 20.8945 11.5017 20.3802 11.2703L3.66291 3.74751C3.13757 3.51111 2.87489 3.39291 2.70063 3.4414C2.54929 3.48351 2.42654 3.59429 2.36918 3.74054C2.30314 3.90893 2.39388 4.18231 2.57535 4.72906L4.9174 11.7853C4.94857 11.8792 4.96415 11.9262 4.9703 11.9742C4.97576 12.0168 4.97571 12.0599 4.97014 12.1025C4.96386 12.1506 4.94816 12.1975 4.91675 12.2913Z" />
  </SVG>
)

SendIcon.propTypes = defaultIconPropTypes
SendIcon.defaultProps = defaultPictographIconProps

export { SendIcon }
