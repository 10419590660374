import React, { ButtonHTMLAttributes } from 'react'
import styled, { css, StyledComponent, DefaultTheme } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps, IconProps } from '../../design-tokens/icons/icons.types'
import { XyzTheme } from '@postidigital/posti-theme'
import { useTheme } from '../../utils/useTheme'
import { ArrowRightIcon } from '../../xyz'
import { BrandButton as Button } from '../BrandButton/BrandButton'
import { BrandMode, BrandFloatingActionButtonSize } from '../../utils/enums'
import { BrandButtonProps } from '../BrandButton/BrandButton.types'

export interface FloatingActionButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'aria-label'> {
  icon: React.ComponentType<DefaultIconProps>
  iconColor?: string
  backgroundColor?: string
  hoverBackgroundColor?: string
  pressBackgroundColor?: string
  'aria-label': string
}

export interface ActionButtonSubComponents {
  Button: StyledComponent<'button', DefaultTheme, Omit<FloatingActionButtonProps, 'icon'>, never>
  Icon: StyledComponent<React.FC<DefaultIconProps>, DefaultTheme, IconProps, never>
}

const ActionStyledButton = styled.button<Omit<FloatingActionButtonProps, 'icon'>>`
  ${({ theme: { color, spacing }, backgroundColor, hoverBackgroundColor, pressBackgroundColor }) => css`
    cursor: pointer;
    border-radius: 50%;
    border: none;
    transition: all 100ms ease-in;
    box-shadow:
      0px 15px 22px rgba(0, 0, 0, 0.06),
      0px 3px 8px rgba(0, 0, 0, 0.08),
      0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    background-color: ${color[backgroundColor] || backgroundColor};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${spacing.space3}rem;
    @media (hover: hover) {
      &:hover {
        background-color: ${color[hoverBackgroundColor] || hoverBackgroundColor};
      }
    }

    &:active {
      background-color: ${color[pressBackgroundColor] || pressBackgroundColor};
    }

    &:focus {
      outline: 0;
      filter: drop-shadow(0px 0px 3px ${color.signalBlue});
      &[aria-disabled='true'] {
        filter: none;
      }
    }

    &:focus:not(:focus-visible) {
      filter: none;
    }

    &[aria-disabled='true'] {
      cursor: default;
      pointer-events: none;
      color: ${color.neutralPassiveGray};
      background-color: ${color.neutralGray5};
    }
  `}
`

const StyledIcon = styled(ArrowRightIcon)<IconProps>``

export const FloatingActionButton: React.FC<FloatingActionButtonProps> & ActionButtonSubComponents = ({
  icon: Icon,
  iconColor,
  disabled,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <ActionStyledButton {...rest} aria-disabled={disabled}>
      <StyledIcon as={Icon} aria-hidden={true} color={disabled ? theme.color.neutralPassiveGray : iconColor} />
    </ActionStyledButton>
  )
}

const boxShadowButton = `
  box-shadow:
  0px 15px 22px 0px rgba(0, 0, 0, 0.04),
  0px 3px 8px 0px rgba(0, 0, 0, 0.08),
  0px 1px 1px 0px rgba(0, 0, 0, 0.08),
  0px 3px 1px 0px rgba(0, 0, 0, 0.06);
`

export const BrandFloatingActionButton = styled(
  ({
    ...props
  }: Omit<
    BrandButtonProps,
    'iconPosition' | 'isLoading' | 'contentMode' | 'hasBackground' | 'loadingProps' | 'iconLabel'
  >) => <Button {...props} hasBackground contentMode={BrandMode.primary} />
)`
  ${boxShadowButton}
  @media (hover: hover) {
    &:hover {
      ${boxShadowButton}
    }
  }
  &:active,
  &:focus:not(:focus-visible),
  &[aria-disabled='true'] {
    ${boxShadowButton}
  }
`

FloatingActionButton.Button = ActionStyledButton
FloatingActionButton.Icon = StyledIcon

FloatingActionButton.propTypes = {
  icon: PropTypes.elementType.isRequired as Validator<React.ComponentType<DefaultIconProps>>,
  iconColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  hoverBackgroundColor: PropTypes.string,
  pressBackgroundColor: PropTypes.string,
  'aria-label': PropTypes.string.isRequired,
}

FloatingActionButton.defaultProps = {
  iconColor: XyzTheme.color.signalBlue,
  backgroundColor: XyzTheme.color.neutralWhite,
  hoverBackgroundColor: XyzTheme.color.neutralOnHoverGray,
  pressBackgroundColor: XyzTheme.color.neutralOnPressGray,
}

BrandFloatingActionButton.defaultProps = {
  size: BrandFloatingActionButtonSize.sm,
}
