import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

const InstagramIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M16.125 3H7.875C6.58268 3.00198 5.34386 3.51624 4.43005 4.43005C3.51624 5.34386 3.00198 6.58268 3 7.875V16.125C3.00198 17.4173 3.51624 18.6561 4.43005 19.57C5.34386 20.4838 6.58268 20.998 7.875 21H16.125C17.4173 20.998 18.6561 20.4838 19.57 19.57C20.4838 18.6561 20.998 17.4173 21 16.125V7.875C20.998 6.58268 20.4838 5.34386 19.57 4.43005C18.6561 3.51624 17.4173 3.00198 16.125 3V3ZM12 16.125C11.1842 16.125 10.3866 15.8831 9.70827 15.4298C9.02992 14.9766 8.50121 14.3323 8.189 13.5786C7.87679 12.8248 7.7951 11.9954 7.95426 11.1953C8.11342 10.3951 8.50629 9.66008 9.08318 9.08318C9.66008 8.50629 10.3951 8.11342 11.1953 7.95426C11.9954 7.7951 12.8248 7.87679 13.5786 8.189C14.3323 8.50121 14.9766 9.02992 15.4298 9.70827C15.8831 10.3866 16.125 11.1842 16.125 12C16.125 13.094 15.6904 14.1432 14.9168 14.9168C14.1432 15.6904 13.094 16.125 12 16.125ZM16.875 7.875C16.6525 7.875 16.435 7.80902 16.25 7.6854C16.065 7.56179 15.9208 7.38609 15.8356 7.18052C15.7505 6.97495 15.7282 6.74875 15.7716 6.53052C15.815 6.31229 15.9222 6.11184 16.0795 5.9545C16.2368 5.79717 16.4373 5.69003 16.6555 5.64662C16.8738 5.60321 17.1 5.62549 17.3055 5.71064C17.5111 5.79578 17.6868 5.93998 17.8104 6.12498C17.934 6.30999 18 6.5275 18 6.75C18 7.04837 17.8815 7.33452 17.6705 7.5455C17.4595 7.75647 17.1734 7.875 16.875 7.875Z"
      fill={color}
    />
  </SVG>
)

InstagramIcon.propTypes = defaultIconPropTypes
InstagramIcon.defaultProps = defaultPictographIconProps

export { InstagramIcon }
