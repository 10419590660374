import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Circle, Path, SVG } from '../brand-icons.styled'

const VehicleIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M5 17H3.6C3.03995 17 2.75992 17 2.54601 16.891C2.35785 16.7951 2.20487 16.6422 2.10899 16.454C2 16.2401 2 15.9601 2 15.4V8.2C2 7.0799 2 6.51984 2.21799 6.09202C2.40973 5.71569 2.71569 5.40973 3.09202 5.21799C3.51984 5 4.07989 5 5.2 5H12.8C13.9201 5 14.4802 5 14.908 5.21799C15.2843 5.40973 15.5903 5.71569 15.782 6.09202C16 6.51984 16 7.07989 16 8.2V15.8" />
    <Path d="M16 9H19.0111C19.3701 9 19.5496 9 19.708 9.05465C19.8482 9.10299 19.9758 9.18187 20.0817 9.28558C20.2014 9.40287 20.2817 9.5634 20.4422 9.88446L21.8311 12.6622C21.8941 12.7882 21.9256 12.8512 21.9478 12.9172C21.9675 12.9758 21.9818 13.0362 21.9903 13.0974C22 13.1664 22 13.2368 22 13.3777V15.4C22 15.9601 22 16.2401 21.891 16.454C21.7951 16.6422 21.6422 16.7951 21.454 16.891C21.2401 17 20.9601 17 20.4 17H19" />
    <Circle cx="17" cy="18" r="2" />
    <Circle cx="7" cy="18" r="2" />
    <Path d="M9 17L15 17" />
  </SVG>
)

VehicleIcon.propTypes = defaultIconPropTypes
VehicleIcon.defaultProps = defaultPictographIconProps

export { VehicleIcon }
