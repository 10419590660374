import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, DefaultTheme, StyledComponent } from 'styled-components'
import { XyzTheme } from '@postidigital/posti-theme'

export interface Props {
  /**
   * Reverses the order of the circle and the line
   */
  reverse: boolean
  /**
   * It accepts numbers and xyz theme specific values, such as `space0`, etc. Number values are used in `rem` units (1rem = 16px).
   */
  spacing: number | keyof (typeof XyzTheme)['spacing']
}

interface SkeletonSubComponents {
  Wrapper: StyledComponent<'div', DefaultTheme, Props, never>
  Line: StyledComponent<'div', DefaultTheme, NonNullable<unknown>, never>
  Circle: StyledComponent<'div', DefaultTheme, NonNullable<unknown>, never>
}

const StyledWrapper = styled.div<Props>(
  ({ theme, reverse, spacing }) => css`
    display: flex;
    flex-direction: ${reverse ? 'row-reverse' : 'row'};
    align-items: center;

    div + div {
      ${reverse ? `margin-right: ${theme.spacing.space4}rem` : `margin-left: ${theme.spacing.space4}rem`}
    }

    & + & {
      margin-top: ${typeof spacing === 'number' ? spacing : theme.spacing[spacing]}rem;
    }
  `
)

const StyledLine = styled.div(
  ({ theme: { color, borderRadius } }) => css`
    background-color: ${color.neutralGray5};
    height: 1rem;
    display: inline-block;
    min-width: 2rem;
    border-radius: ${borderRadius.xs}rem;
    flex-grow: 1;
  `
)

const StyledCircle = styled.div(
  ({ theme: { color, borderRadius } }) => css`
    background-color: ${color.neutralGray5};
    width: 2rem;
    height: 2rem;
    border-radius: ${borderRadius.sm}rem;
    display: inline-block;
  `
)

const Skeleton: React.FC<Props> & SkeletonSubComponents = ({ reverse, spacing, ...rest }) => {
  return (
    <StyledWrapper reverse={reverse} spacing={spacing} {...rest}>
      <StyledLine />
      <StyledCircle />
    </StyledWrapper>
  )
}

Skeleton.propTypes = {
  reverse: PropTypes.bool,
  spacing: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(Object.keys(XyzTheme.spacing) as (keyof (typeof XyzTheme)['spacing'])[]),
  ]),
}

Skeleton.defaultProps = {
  reverse: false,
  spacing: XyzTheme.spacing.space3,
}

Skeleton.Wrapper = StyledWrapper
Skeleton.Line = StyledLine
Skeleton.Circle = StyledCircle

export default Skeleton
