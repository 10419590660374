import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const NotificationIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M9.35493 21C10.0601 21.6224 10.9863 22 12.0008 22C13.0152 22 13.9414 21.6224 14.6466 21M18.6119 12.3158C18.9013 13.2328 19.2673 13.9887 19.6511 14.6054C20.3856 15.7859 20.7529 16.3761 20.7394 16.5408C20.7245 16.7231 20.6859 16.7926 20.539 16.9016C20.4063 17 19.8082 17 18.6119 17H5.38961C4.19334 17 3.59521 17 3.46253 16.9016C3.31561 16.7926 3.27699 16.7231 3.26208 16.5408C3.24861 16.3761 3.61588 15.7859 4.35042 14.6054C5.22122 13.206 6.00075 11.0902 6.00075 8C6.00075 6.4087 6.63289 4.88258 7.75811 3.75736C8.88333 2.63214 10.4095 2 12.0008 2C12.3378 2 12.672 2.02836 13 2.08377M20.1221 3.87868C21.2936 5.05025 21.2936 6.94975 20.1221 8.12132C18.9505 9.29289 17.051 9.29289 15.8794 8.12132C14.7079 6.94975 14.7079 5.05025 15.8794 3.87868C17.051 2.70711 18.9505 2.70711 20.1221 3.87868Z" />
  </SVG>
)

NotificationIcon.propTypes = defaultIconPropTypes
NotificationIcon.defaultProps = defaultPictographIconProps

export { NotificationIcon }
