import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { DefaultTheme, StyledComponent } from 'styled-components'
import {
  ComplexNotificationContainer,
  ComplexNotificationIllustrationWrapper,
  ComplexNotificationTextWrapper,
  ComplexNotificationTitle,
} from './ComplexNotification.style'
import { ContainerProps } from '../../basic-components/Notification/Notification.types'
import { NotificationType } from '../../utils/enums'
import { HeadlineProps } from '../../design-tokens/typography/Headline'
export interface ComplexNotificationSubComponents {
  TextWrapper: StyledComponent<'div', DefaultTheme, NonNullable<unknown>, never>
  Title: StyledComponent<'div', DefaultTheme, HeadlineProps, never>
  IllustrationWrapper: StyledComponent<
    'div',
    DefaultTheme,
    {
      isCutOnNarrowScreen?: boolean
      hasVerticalIndent?: boolean
      hasHorizontalIndent?: boolean
    },
    never
  >
  Container: StyledComponent<'div', DefaultTheme, ContainerProps, never>
}

/**
 * @deprecated ComplexNotification is deprecated. No replacement is planned.
 */
export const ComplexNotification: React.FC<ContainerProps> & ComplexNotificationSubComponents = ({
  children,
  ...rest
}) => {
  return <ComplexNotificationContainer {...rest}>{children}</ComplexNotificationContainer>
}

ComplexNotification.TextWrapper = ComplexNotificationTextWrapper
ComplexNotification.Title = ComplexNotificationTitle
ComplexNotification.IllustrationWrapper = ComplexNotificationIllustrationWrapper
ComplexNotification.Container = ComplexNotificationContainer

ComplexNotification.propTypes = {
  contentType: PropTypes.oneOf(Object.values(NotificationType)) as Validator<NotificationType>,
  width: PropTypes.string,
}

ComplexNotification.defaultProps = {
  contentType: NotificationType.informational,
}

ComplexNotificationIllustrationWrapper.propTypes = {
  isCutOnNarrowScreen: PropTypes.bool,
  hasVerticalIndent: PropTypes.bool,
  hasHorizontalIndent: PropTypes.bool,
}

ComplexNotificationIllustrationWrapper.defaultProps = {
  isCutOnNarrowScreen: false,
  hasVerticalIndent: false,
  hasHorizontalIndent: false,
}
