import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG, Rect } from '../brand-icons.styled'
import { G } from '../icons.styled'

const ReturnParcelIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG fill="none" {...props}>
    <mask
      id="mask0"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width={props.width}
      height={props.height}
    >
      <Path d="M9.5 5.62988L16.5 9.66655V11.6888" stroke={color} />
      <Path
        d="M6 9.74405V8.79576C6 8.08123 6.3812 7.42098 7 7.06371L12 4.17696C12.6188 3.81969 13.3812 3.81969 14 4.17696L19 7.06371C19.6188 7.42098 20 8.08123 20 8.79576V14.5693C20 15.2838 19.6188 15.944 19 16.3013L14 19.1881C13.3812 19.5453 12.6188 19.5453 12 19.1881L11.25 18.7551"
        stroke="black"
      />
      <Path d="M6.74536 8.07812L13.0001 11.6963L19.2548 8.07812" stroke={color} />
      <Path d="M13 19V12" stroke={color} />
      <Path d="M3 12L1 14L3 16" stroke={color} />
      <Path d="M8 20V16C8 14.8954 7.10457 14 6 14H3" stroke={color} />
    </mask>
    <G mask="url(#mask0)">
      <Rect width={props.width} height={props.height} fill={color} />
    </G>
  </SVG>
)

ReturnParcelIcon.propTypes = defaultIconPropTypes
ReturnParcelIcon.defaultProps = defaultPictographIconProps
export { ReturnParcelIcon }
