import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, Rect, SVG } from '../icons.styled'
import { BrandTheme } from '@postidigital/posti-theme'

const MasterCardIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props} fill="none">
    <Rect x="1.5" y="4.5" width="21" height="14.5294" rx="2.5" fill={BrandTheme.color.white} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1163 14.8898C11.3501 15.5356 10.3563 15.9255 9.27032 15.9255C6.84717 15.9255 4.88281 13.9845 4.88281 11.5902C4.88281 9.19586 6.84717 7.25488 9.27032 7.25488C10.3563 7.25488 11.3501 7.64473 12.1163 8.29053C12.8824 7.64473 13.8762 7.25488 14.9622 7.25488C17.3854 7.25488 19.3497 9.19586 19.3497 11.5902C19.3497 13.9845 17.3854 15.9255 14.9622 15.9255C13.8762 15.9255 12.8824 15.5356 12.1163 14.8898Z"
      fill="#ED0006"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1172 14.8898C13.0606 14.0947 13.6587 12.9114 13.6587 11.5902C13.6587 10.2689 13.0606 9.0857 12.1172 8.29053C12.8833 7.64473 13.8772 7.25488 14.9631 7.25488C17.3863 7.25488 19.3506 9.19586 19.3506 11.5902C19.3506 13.9845 17.3863 15.9255 14.9631 15.9255C13.8772 15.9255 12.8833 15.5356 12.1172 14.8898Z"
      fill="#F9A000"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.1158 14.8903C13.0591 14.0951 13.6573 12.9119 13.6573 11.5907C13.6573 10.2694 13.0591 9.08619 12.1158 8.29102C11.1724 9.08619 10.5742 10.2694 10.5742 11.5907C10.5742 12.9119 11.1724 14.0951 12.1158 14.8903Z"
      fill="#FF5E00"
    />
    <Rect x="1.5" y="4.5" width="21" height="14.5294" rx="2.5" stroke="#F2F4F7" />
  </SVG>
)

MasterCardIcon.propTypes = defaultIconPropTypes
MasterCardIcon.defaultProps = defaultPictographIconProps

export { MasterCardIcon }
