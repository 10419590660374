import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import {
  StyledBottomSheet,
  StyledBottomSheetContainer,
  BottomSheetContent,
  BottomSheetHeadlineAligner,
  BottomSheetClose,
  BottomSheetTitle,
  BottomSheetContentWrapper,
  BottomSheetHero,
  BottomSheetBrandClose,
} from './BottomSheet.styles'
import { BottomSheetRef } from 'react-spring-bottom-sheet/dist/index'
import { XyzTheme } from '@postidigital/posti-theme'
import { BottomSheetProps, BottomSheetSubComponents } from './BottomSheet.types'

export const BottomSheet: React.FC<BottomSheetProps> & BottomSheetSubComponents = ({
  isOpen,
  onClose,
  disableOutsideClick,
  scrollbarColor,
  scrollbarBackgroundColor,
  backDropStyles,
  backgroundColor,
  handlebarColor,
  useDefaultPadding,
  isDraggable,
  hasImgPadding,
  isModal,
  contentHeight,
  children,
  zIndex,
  ...props
}) => {
  const sheetRef = useRef<BottomSheetRef>()
  return (
    <StyledBottomSheet
      ref={sheetRef}
      open={isOpen}
      $disableOutsideClick={disableOutsideClick}
      onDismiss={disableOutsideClick ? null : onClose}
      $backDropStyles={backDropStyles}
      $backgroundColor={backgroundColor}
      $handlebarColor={handlebarColor}
      $useDefaultPadding={useDefaultPadding}
      $isDraggable={isDraggable}
      $hasImgPadding={hasImgPadding}
      $isModal={isModal}
      expandOnContentDrag={false}
      $zIndex={zIndex}
      scrollLocking={false}
      {...props}
    >
      <StyledBottomSheetContainer
        useDefaultPadding={useDefaultPadding}
        scrollbarColor={scrollbarColor}
        scrollbarBackgroundColor={scrollbarBackgroundColor}
        $contentHeight={contentHeight}
        hasImgPadding={hasImgPadding}
      >
        {children}
      </StyledBottomSheetContainer>
    </StyledBottomSheet>
  )
}

BottomSheet.defaultProps = {
  isDraggable: true,
  useDefaultPadding: true,
  disableOutsideClick: false,
  zIndex: XyzTheme.zIndex.zIndex1000,
}

BottomSheet.propTypes = {
  backgroundColor: PropTypes.string,
  onClose: PropTypes.func,
  scrollbarColor: PropTypes.string,
  handlebarColor: PropTypes.string,
  scrollbarBackgroundColor: PropTypes.string,
  useDefaultPadding: PropTypes.bool,
  backDropStyles: PropTypes.string,
  contentHeight: PropTypes.string,
  isOpen: PropTypes.bool,
  disableOutsideClick: PropTypes.bool,
  zIndex: PropTypes.number,
}

BottomSheet.Content = BottomSheetContent
BottomSheet.Headline = BottomSheetHeadlineAligner
BottomSheet.CloseButton = BottomSheetClose
BottomSheet.Title = BottomSheetTitle
BottomSheet.Wrapper = BottomSheetContentWrapper
BottomSheet.HeroImage = BottomSheetHero
BottomSheet.BrandCloseButton = BottomSheetBrandClose
