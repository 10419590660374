import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const ExpandIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M14 10L21 3M21 3H15M21 3V9M10 14L3 21M3 21H9M3 21L3 15" />
  </SVG>
)

ExpandIcon.propTypes = defaultIconPropTypes
ExpandIcon.defaultProps = defaultPictographIconProps

export { ExpandIcon }
