import React, { useRef, forwardRef, ForwardedRef, useImperativeHandle } from 'react'
import { UIMessage } from '../UIMessage'
import * as ReactSelect from 'react-select'
import AsyncSelect from 'react-select/async'
import { AsyncProps } from 'react-select/dist/declarations/src/useAsync'
import { CustomDropdownProps, DropdownPropTypes } from '../Dropdown/Dropdown.types'
import { commonProps } from '../Dropdown/Dropdown'
import { commonComponents, AsyncControl, DropdownCustomContext } from '../Dropdown/DropdownCustomComponents'
import { KeyboardKeys } from '../../utils/enums'
import { callAll } from '../../utils/helpers'
import { SelectWrapper } from '../Dropdown/Dropdown.styles'
import withBrand from '../../utils/withBrand'

export type AsyncDropdownInstance = AsyncSelect

export const AsyncDropdown = forwardRef(
  <
    Option,
    IsMulti extends boolean = false,
    Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
  >(
    props: AsyncProps<Option, IsMulti, Group> & CustomDropdownProps,
    forwardedRef: ForwardedRef<AsyncDropdownInstance>
  ) => {
    const {
      icon: Icon,
      isValid,
      label,
      zIndex,
      message,
      messageProps,
      maxMenuHeight,
      iconColor,
      lightBackground,
      hasChevron,
      handleClose,
      isBrand,
      id,
      isSelected,
      screenReaderDisabledLabel,
    } = props

    const messageId = props.id ? `${props.id}-message` : `async-dropdown-message`
    const labelId = props.id ? `${props.id}-label` : `dropdown-label`

    const ref = useRef(null)
    useImperativeHandle(forwardedRef, () => ref.current)

    const asyncDropdownProps = {
      ...commonProps,
      ...props,
      components: {
        ...commonComponents,
        Control: AsyncControl,
      },
      maxMenuHeight: maxMenuHeight !== undefined ? maxMenuHeight : 400,
    }

    const { onKeyDown, ...rest } = asyncDropdownProps

    /** By default react-select doesn't open on Enter due to a library bug.
     * handleKeyDown handles open on Enter for better accessibility.
     * Once react-select will fix the bug this should be removed. */
    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key == KeyboardKeys.Enter) {
        /**  on Enter functionality follows the same logic react-select is using for Space */
        if (ref.current.props.inputValue) {
          return
        }
        /** Dropdown should open on Enter if menu is closed and
         * when menu is open Enter should select a focused value */
        if (!ref.current.props.menuIsOpen) {
          ref.current.openMenu('first')
        }
      }
    }

    return (
      <DropdownCustomContext.Provider
        value={{
          icon: Icon,
          isValid,
          messageId,
          message,
          iconColor,
          lightBackground,
          zIndex,
          label,
          hasChevron,
          handleClose,
          isBrand,
          labelId,
          isSelected,
          screenReaderDisabledLabel,
        }}
      >
        <SelectWrapper id={`${id}-container`}>
          <AsyncSelect
            ref={ref}
            onKeyDown={asyncDropdownProps.inputValue !== undefined ? onKeyDown : callAll(handleKeyDown, onKeyDown)}
            aria-invalid={!isValid}
            {...rest}
          />
          <div id={messageId} aria-live="assertive">
            {message && <UIMessage isBrand={isBrand} success={isValid} message={message} {...messageProps} />}
          </div>
        </SelectWrapper>
      </DropdownCustomContext.Provider>
    )
  }
)

AsyncDropdown.displayName = 'AsyncDropdown'
AsyncDropdown.propTypes = DropdownPropTypes

export const BrandAsyncDropdown = withBrand(AsyncDropdown)
