import React from 'react'
import { Path, SVG } from '../icons/icons.styled'
import { DefaultIconProps } from '../icons/icons.types'
import { useTheme } from '../../utils/useTheme'

const DesignTeamLogo: React.FC<DefaultIconProps> = ({
  width = '230',
  height = '29',
  viewBox = '0 0 230 29',
  ...props
}) => {
  const theme = useTheme()
  return (
    <SVG width={width} height={height} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M11.4708 2.37301C13.7115 2.37301 15.3663 2.99965 16.4353 4.25292C17.5248 5.5062 18.0695 7.13957 18.0695 9.15303C18.0695 11.2692 17.5248 12.9745 16.4353 14.2689C15.3663 15.5427 13.7115 16.1796 11.4708 16.1796H5.4887V22.8672H0V2.37301H11.4708ZM9.65148 11.9883C10.5971 11.9883 11.2858 11.7418 11.7175 11.2487C12.1697 10.735 12.3958 10.0468 12.3958 9.18385C12.3958 7.41693 11.5016 6.53347 9.71315 6.53347H5.4887V11.9883H9.65148Z"
        fill={theme.color.neutralNetworkGray}
      />
      <Path
        d="M27.8807 23.3603C25.8045 23.3603 24.1085 22.9802 22.7929 22.22C21.4773 21.4598 20.5214 20.4633 19.9252 19.2306C19.3496 17.9979 19.0618 16.6419 19.0618 15.1626C19.0618 13.6833 19.3496 12.3273 19.9252 11.0946C20.5214 9.86185 21.4773 8.86539 22.7929 8.10521C24.1085 7.34502 25.8045 6.96493 27.8807 6.96493C29.957 6.96493 31.6529 7.34502 32.9686 8.10521C34.2842 8.86539 35.2401 9.86185 35.8363 11.0946C36.4324 12.3273 36.7305 13.6833 36.7305 15.1626C36.7305 16.6419 36.4324 17.9979 35.8363 19.2306C35.2401 20.4633 34.2842 21.4598 32.9686 22.22C31.6529 22.9802 29.957 23.3603 27.8807 23.3603ZM24.3655 15.1626C24.3655 16.683 24.6327 17.7411 25.1672 18.3369C25.7017 18.9327 26.6062 19.2306 27.8807 19.2306C29.1553 19.2306 30.0598 18.9327 30.5943 18.3369C31.1287 17.7411 31.396 16.683 31.396 15.1626C31.396 13.6422 31.1287 12.5841 30.5943 11.9883C30.0598 11.3925 29.1553 11.0946 27.8807 11.0946C26.6062 11.0946 25.7017 11.3925 25.1672 11.9883C24.6327 12.5841 24.3655 13.6422 24.3655 15.1626Z"
        fill={theme.color.neutralNetworkGray}
      />
      <Path
        d="M42.4895 17.9054C42.5512 18.5629 42.8492 19.1073 43.3837 19.5388C43.9388 19.9702 44.6891 20.186 45.6347 20.186C47.2381 20.186 48.0399 19.7032 48.0399 18.7375C48.0399 18.2855 47.896 17.926 47.6082 17.6589C47.3409 17.3918 46.7962 17.2069 45.9739 17.1041L42.7053 16.7343C41.2047 16.57 40.0638 16.0974 39.2826 15.3167C38.5014 14.5154 38.1109 13.447 38.1109 12.1116C38.1109 10.3652 38.7687 9.07085 40.0843 8.22848C41.4205 7.38611 43.1884 6.96493 45.388 6.96493C47.6287 6.96493 49.4069 7.37584 50.7225 8.19766C52.0382 9.01948 52.768 10.2933 52.9119 12.0191H48.1015C47.9371 10.7453 47.0429 10.1084 45.4189 10.1084C44.576 10.1084 43.949 10.2522 43.5379 10.5399C43.1473 10.8069 42.952 11.1665 42.952 11.6185C42.952 12.4198 43.5584 12.882 44.7713 13.0053L47.9474 13.3135C49.7564 13.4984 51.0617 13.9812 51.8634 14.762C52.6857 15.5221 53.0969 16.6419 53.0969 18.1211C53.0969 19.7237 52.511 20.9975 51.3392 21.9426C50.1675 22.8877 48.2352 23.3603 45.5422 23.3603C42.9109 23.3603 40.958 22.8466 39.6835 21.8193C38.4089 20.7921 37.7408 19.4874 37.6792 17.9054H42.4895Z"
        fill={theme.color.neutralNetworkGray}
      />
      <Path
        d="M61.9837 22.8672C59.7019 22.8672 58.0985 22.3741 57.1734 21.3879C56.2483 20.4017 55.7858 18.8711 55.7858 16.796V11.4336H53.0723V8.96812L56.2175 7.5813L58.4068 3.72901H61.0895V7.45802H65.2831V11.4336H61.0895V18.6759H64.7897V22.8672H61.9837Z"
        fill={theme.color.neutralNetworkGray}
      />
      <Path
        d="M72.4552 7.45802V22.8672H67.1516V7.45802H72.4552ZM72.5477 2.37301V5.57811H67.059V2.37301H72.5477Z"
        fill={theme.color.neutralNetworkGray}
      />
      <Path
        d="M93.6753 20.3692L84.9276 11.6113L93.6753 2.86839L96.1746 5.39645L89.9564 11.6113L96.1746 17.8411L93.6753 20.3692Z"
        fill={theme.color.neutralNetworkGray}
      />
      <Path
        d="M115.613 11.6113L106.865 20.3692L104.366 17.8411L110.599 11.6113L104.366 5.39645L106.865 2.86839L115.613 11.6113Z"
        fill={theme.color.neutralNetworkGray}
      />
      <Path
        d="M130.03 1.29437H138.818C141.1 1.29437 143.115 1.75664 144.862 2.68119C146.609 3.58519 147.966 4.85902 148.932 6.50266C149.898 8.12575 150.382 9.98512 150.382 12.0808C150.382 14.1764 149.898 16.0461 148.932 17.6897C147.966 19.3128 146.609 20.5866 144.862 21.5112C143.115 22.4152 141.1 22.8672 138.818 22.8672H130.03V1.29437ZM138.695 20.8948C140.586 20.8948 142.241 20.525 143.659 19.7853C145.078 19.0252 146.178 17.9876 146.959 16.6727C147.74 15.3372 148.131 13.8066 148.131 12.0808C148.131 10.3549 147.74 8.83457 146.959 7.51966C146.178 6.1842 145.078 5.14665 143.659 4.40701C142.241 3.64683 140.586 3.26674 138.695 3.26674H132.312V20.8948H138.695Z"
        fill={theme.color.neutralNetworkGray}
      />
      <Path
        d="M168.549 15.44H154.981C155.105 17.1247 155.752 18.491 156.924 19.5388C158.096 20.5661 159.576 21.0797 161.364 21.0797C162.372 21.0797 163.297 20.9051 164.139 20.5558C164.982 20.186 165.712 19.6518 166.329 18.9532L167.562 20.3709C166.843 21.2338 165.938 21.8913 164.849 22.3433C163.78 22.7953 162.598 23.0213 161.303 23.0213C159.637 23.0213 158.157 22.672 156.862 21.9734C155.588 21.2543 154.591 20.2682 153.871 19.0149C153.152 17.7616 152.792 16.344 152.792 14.762C152.792 13.18 153.131 11.7623 153.81 10.509C154.509 9.25576 155.454 8.27984 156.646 7.5813C157.859 6.88275 159.216 6.53347 160.717 6.53347C162.217 6.53347 163.564 6.88275 164.756 7.5813C165.948 8.27984 166.884 9.25576 167.562 10.509C168.241 11.7418 168.58 13.1594 168.58 14.762L168.549 15.44ZM160.717 8.41339C159.154 8.41339 157.839 8.91676 156.77 9.92349C155.721 10.9097 155.125 12.204 154.981 13.8066H166.483C166.339 12.204 165.733 10.9097 164.664 9.92349C163.615 8.91676 162.3 8.41339 160.717 8.41339Z"
        fill={theme.color.neutralNetworkGray}
      />
      <Path
        d="M176.767 23.0213C175.451 23.0213 174.187 22.8363 172.974 22.4665C171.782 22.0762 170.847 21.5933 170.168 21.0181L171.155 19.2922C171.833 19.8264 172.686 20.2682 173.714 20.6174C174.742 20.9462 175.811 21.1105 176.921 21.1105C178.401 21.1105 179.491 20.8845 180.19 20.4325C180.909 19.96 181.269 19.3025 181.269 18.4601C181.269 17.8643 181.074 17.4021 180.683 17.0733C180.293 16.7241 179.799 16.4672 179.203 16.3029C178.607 16.118 177.815 15.9433 176.829 15.779C175.513 15.5324 174.454 15.2859 173.653 15.0393C172.851 14.7722 172.162 14.3305 171.587 13.7141C171.032 13.0978 170.754 12.2451 170.754 11.1562C170.754 9.80021 171.319 8.69076 172.45 7.82784C173.581 6.96493 175.153 6.53347 177.168 6.53347C178.216 6.53347 179.265 6.67729 180.313 6.96493C181.361 7.23202 182.225 7.59157 182.903 8.04357L181.947 9.80021C180.611 8.87566 179.018 8.41339 177.168 8.41339C175.77 8.41339 174.711 8.65994 173.992 9.15303C173.293 9.64612 172.943 10.2933 172.943 11.0946C172.943 11.7109 173.139 12.204 173.529 12.5739C173.94 12.9437 174.444 13.221 175.04 13.406C175.636 13.5703 176.459 13.745 177.507 13.9299C178.802 14.1764 179.84 14.423 180.621 14.6695C181.403 14.916 182.071 15.3372 182.626 15.9331C183.181 16.5289 183.458 17.3507 183.458 18.3985C183.458 19.8162 182.862 20.9462 181.67 21.7885C180.498 22.6103 178.864 23.0213 176.767 23.0213Z"
        fill={theme.color.neutralNetworkGray}
      />
      <Path
        d="M186.889 6.65675H189.078V22.8672H186.889V6.65675ZM187.999 3.11265C187.547 3.11265 187.167 2.95855 186.858 2.65037C186.55 2.34219 186.396 1.97237 186.396 1.54091C186.396 1.13 186.55 0.770457 186.858 0.462274C187.167 0.154091 187.547 0 187.999 0C188.451 0 188.832 0.154091 189.14 0.462274C189.448 0.749911 189.603 1.09919 189.603 1.5101C189.603 1.9621 189.448 2.34219 189.14 2.65037C188.832 2.95855 188.451 3.11265 187.999 3.11265Z"
        fill={theme.color.neutralNetworkGray}
      />
      <Path
        d="M209.57 6.65675V20.8948C209.57 23.6479 208.892 25.6819 207.535 26.9968C206.199 28.3323 204.174 29 201.46 29C199.96 29 198.531 28.774 197.174 28.322C195.838 27.8905 194.749 27.2845 193.906 26.5037L195.016 24.8395C195.797 25.5381 196.743 26.0825 197.853 26.4729C198.983 26.8633 200.165 27.0584 201.399 27.0584C203.454 27.0584 204.965 26.5756 205.932 25.61C206.898 24.6649 207.381 23.1856 207.381 21.1722V19.1073C206.702 20.1346 205.808 20.9153 204.698 21.4495C203.609 21.9837 202.396 22.2508 201.06 22.2508C199.538 22.2508 198.151 21.9221 196.897 21.2646C195.663 20.5866 194.687 19.6518 193.967 18.4601C193.268 17.248 192.919 15.8817 192.919 14.3613C192.919 12.8409 193.268 11.4849 193.967 10.2933C194.687 9.10167 195.663 8.17712 196.897 7.51966C198.13 6.8622 199.518 6.53347 201.06 6.53347C202.437 6.53347 203.681 6.81084 204.791 7.36557C205.901 7.9203 206.795 8.72157 207.473 9.7694V6.65675H209.57ZM201.275 20.3092C202.447 20.3092 203.506 20.0627 204.451 19.5696C205.397 19.056 206.127 18.3472 206.641 17.4431C207.175 16.5391 207.442 15.5119 207.442 14.3613C207.442 13.2108 207.175 12.1938 206.641 11.3103C206.127 10.4063 205.397 9.70776 204.451 9.21467C203.526 8.70103 202.468 8.44421 201.275 8.44421C200.104 8.44421 199.045 8.69075 198.099 9.18385C197.174 9.67694 196.445 10.3755 195.91 11.2795C195.396 12.1835 195.139 13.2108 195.139 14.3613C195.139 15.5119 195.396 16.5391 195.91 17.4431C196.445 18.3472 197.174 19.056 198.099 19.5696C199.045 20.0627 200.104 20.3092 201.275 20.3092Z"
        fill={theme.color.neutralNetworkGray}
      />
      <Path
        d="M223.34 6.53347C225.375 6.53347 226.988 7.1293 228.181 8.32094C229.394 9.49203 230 11.2076 230 13.4676V22.8672H227.811V13.6833C227.811 11.9986 227.389 10.7145 226.546 9.83103C225.704 8.94757 224.501 8.50585 222.939 8.50585C221.191 8.50585 219.804 9.02976 218.776 10.0776C217.769 11.1049 217.265 12.5328 217.265 14.3613V22.8672H215.076V6.65675H217.172V9.64612C217.769 8.65994 218.591 7.89975 219.639 7.36557C220.708 6.81084 221.942 6.53347 223.34 6.53347Z"
        fill={theme.color.neutralNetworkGray}
      />
    </SVG>
  )
}

export { DesignTeamLogo }
