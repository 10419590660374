import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { NavButtonProps } from './NavButton.types'
import { StyledButton, Title, StyledChevron } from './NavButton.style'
import { XyzTheme } from '@postidigital/posti-theme'
import { Mode } from '../../utils/enums'
import { useTheme } from '../../utils/useTheme'

/**
 * NavButton Component
 * @deprecated if you require this component, please copy it to your project.
 */

const NavButton = React.forwardRef<HTMLButtonElement, NavButtonProps>(
  ({ title, arrowColor, selectedColor, selected, className, Icon, withArrow, ...rest }, buttonRef) => {
    const theme = useTheme()
    const onArrowClick: React.MouseEventHandler = (e) => {
      // Prevents opening the link, if the user just wants to open the dropdown
      e.preventDefault()
    }

    return (
      <StyledButton
        ref={buttonRef}
        selectedColor={selectedColor ?? XyzTheme.color.neutralNetworkGray}
        className={selected ? `${className}-selected` : className}
        {...rest}
      >
        {Icon && <Icon />}
        <Title>{title}</Title>
        {withArrow && (
          <StyledChevron
            width={`${theme.iconSize.s}rem`}
            height={`${theme.iconSize.s}rem`}
            color={arrowColor ?? XyzTheme.color.neutralNetworkGray}
            onClick={onArrowClick}
          />
        )}
      </StyledButton>
    )
  }
)

NavButton.displayName = 'NavButton'

NavButton.propTypes = {
  isOpen: PropTypes.bool,
  contentMode: PropTypes.oneOf(Object.keys(Mode)) as Validator<Mode>,
  title: PropTypes.string.isRequired,
  arrowColor: PropTypes.string,
  selectedColor: PropTypes.string,
  selected: PropTypes.bool,
  withArrow: PropTypes.bool,
}

NavButton.defaultProps = {
  isOpen: false,
  contentMode: Mode.secondary,
  arrowColor: XyzTheme.color.neutralNetworkGray,
  selectedColor: XyzTheme.color.neutralNetworkGray,
  selected: false,
  withArrow: true,
}

export { NavButton }
