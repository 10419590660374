import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const VideoIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M17.625 4.50008H6.375C5.4802 4.50108 3.98961 4.4998 3.00005 4.50018C3.00012 5.48969 3.00099 6.98027 3 7.87506V16.125C3.00099 17.0198 2.99994 18.3749 3.00006 19.5C3.75 19.5 5.4802 19.499 6.375 19.5H17.625C18.5198 19.499 20.25 19.5 21 19.5C21 18.5104 20.999 17.0198 21 16.125V7.87506C20.999 6.98027 21 5.62499 21 4.50003C20.0104 4.49981 18.5198 4.50108 17.625 4.50008ZM9 15.7499C9 13.25 9 10.75 9 8.24998C11.25 9.37497 13.997 10.7485 16.5 12C15.1102 12.6949 10.5 14.9999 9 15.7499Z"
      fill={color}
    />
  </SVG>
)

VideoIcon.propTypes = defaultIconPropTypes

VideoIcon.defaultProps = defaultPictographIconProps
