import React from 'react'
import PropTypes from 'prop-types'
import { XyzTheme } from '@postidigital/posti-theme'
import { ChevronDownIcon } from '../../xyz'
import { ChevronDownIcon as BrandChevronDownIcon } from '../../design-tokens/icons/brand-ui-icons/ChevronDownIcon'
import { ChevronWrapper, TitleWrapper, ContentWrapper } from './Expander.styles'
import { ExpanderTitleProps, ExpanderTitleCommonProps } from './Expander.types'

const iconDefaultProps = {
  width: `${XyzTheme.iconSize.s}em`,
  height: `${XyzTheme.iconSize.s}em`,
}

export const ExpanderTitle: React.FC<ExpanderTitleProps> = ({
  children,
  showChevron,
  open,
  chevronColor,
  chevronStyles,
  isBrand,
  ...rest
}) => {
  return (
    <TitleWrapper aria-expanded={open} {...rest} isBrand={isBrand}>
      <ContentWrapper>{children}</ContentWrapper>
      {showChevron && (
        <ChevronWrapper style={chevronStyles} open={open}>
          <ChevronDownIcon {...iconDefaultProps} color={chevronColor} />
        </ChevronWrapper>
      )}
    </TitleWrapper>
  )
}

export const BrandExpanderTitle: React.FC<ExpanderTitleCommonProps> = ({ children, isBrand, open, ...rest }) => {
  return (
    <TitleWrapper aria-expanded={open} {...rest} isBrand>
      <ContentWrapper>{children}</ContentWrapper>
      <ChevronWrapper open={open}>
        <BrandChevronDownIcon {...iconDefaultProps} />
      </ChevronWrapper>
    </TitleWrapper>
  )
}

ExpanderTitle.defaultProps = {
  showChevron: true,
  chevronColor: XyzTheme.color.neutralNetworkGray,
}

const titlePropTypes = {
  open: PropTypes.bool,
  isBrand: PropTypes.bool,
}

ExpanderTitle.propTypes = {
  ...titlePropTypes,
  showChevron: PropTypes.bool,
  chevronColor: PropTypes.string,
  chevronStyles: PropTypes.object,
}

BrandExpanderTitle.propTypes = {
  ...titlePropTypes,
}
