import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const DimensionsIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M10.52 4.14a1.06 1.06 0 00-1.04 0L5.4 6.46 10 9.08l4.6-2.62-4.08-2.32zm4.46 2.53l.02.04v-.02l-.02-.02zm.02.46l-4.6 2.63V15l4.34-2.47c.16-.1.26-.27.26-.45V7.13zM9.6 15V9.76L5 7.13v4.95c0 .18.1.36.26.45L9.61 15zM5 6.7l.02-.03L5 6.7v.02z"
      fill={color}
    />
    <Path
      d="M3.75 21h16.5a.75.75 0 00.75-.75V3.75a.75.75 0 00-.75-.75h-2.63a.38.38 0 00-.37.38v2.06a.38.38 0 00.38.37h1.5a.56.56 0 110 1.13h-1.5a.38.38 0 00-.38.37V9.2a.38.38 0 00.38.37h1.5a.56.56 0 110 1.13h-1.5a.38.38 0 00-.38.37v1.88a.38.38 0 00.38.37h1.5a.56.56 0 110 1.13h-1.5a.38.38 0 00-.38.37v2.06a.38.38 0 01-.38.38h-2.06a.38.38 0 00-.37.38v1.5a.56.56 0 11-1.13 0v-1.5a.38.38 0 00-.37-.38h-1.88a.38.38 0 00-.37.38v1.5a.56.56 0 11-1.13 0v-1.5a.38.38 0 00-.37-.38H7.3a.38.38 0 00-.37.38v1.5a.56.56 0 11-1.13 0v-1.5a.38.38 0 00-.37-.38H3.37a.38.38 0 00-.37.38v2.62a.75.75 0 00.75.75z"
      fill={color}
    />
  </SVG>
)

DimensionsIcon.propTypes = defaultIconPropTypes

DimensionsIcon.defaultProps = defaultPictographIconProps

export { DimensionsIcon }
