import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const MinusIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M5 12H19" />
  </SVG>
)

MinusIcon.propTypes = defaultIconPropTypes
MinusIcon.defaultProps = defaultPictographIconProps

export { MinusIcon }
