import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const InstagramIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M16.13 3H7.88A4.88 4.88 0 003 7.88v8.25A4.88 4.88 0 007.88 21h8.25A4.88 4.88 0 0021 16.12V7.88A4.88 4.88 0 0016.12 3zM12 16.13a4.12 4.12 0 110-8.25 4.12 4.12 0 010 8.25zm4.88-8.25a1.12 1.12 0 110-2.25 1.12 1.12 0 010 2.25z"
      fill={color}
    />
  </SVG>
)

InstagramIcon.propTypes = defaultIconPropTypes

InstagramIcon.defaultProps = defaultPictographIconProps

export { InstagramIcon }
