import React, { useEffect, useRef } from 'react'
import styled, { css } from 'styled-components'
import { SearchProps, SearchSubComponents } from './Search.types'
import { Input, inputDefaultProps, inputPropTypes } from '../Input/Input'
import { IconButton } from '../../deprecated/IconButton'
import { useDebounce } from '../../utils/useDebounce'
import { KeyboardKeys, BrandSize, BrandMode } from '../../utils/enums'
import { SearchIcon, CloseIcon } from '../../design-tokens/icons/pictograph'
import { SearchIcon as BrandSearchIcon, CloseIcon as BrandCloseIcon } from '../../design-tokens/icons/brand-ui-icons'
import { IconProps } from '../../design-tokens/icons/icons.types'
import { iconAttrs } from '../../design-tokens/icons/icons.styled'
import { addNamespace } from '../../utils/helpers'
import withBrand from '../../utils/withBrand'
import { BrandButton } from '../BrandButton'

const StyledSearchIcon = styled(SearchIcon).attrs<IconProps>(iconAttrs)<IconProps>`
  flex-shrink: 0;
`
const StyledBrandSearchIcon = styled(BrandSearchIcon).attrs<IconProps>(iconAttrs)<IconProps>`
  flex-shrink: 0;
`

export const StyledIconButton = styled(IconButton)``

export const StyledBrandIconButton = styled(BrandButton)(
  ({
    theme: {
      brand: { color },
    },
  }) => css`
    @media (hover: hover) {
      &:hover {
        background-color: ${color.gray20};
      }
    }
    &:active,
    &:focus {
      background-color: ${color.gray20};
    }

    &:focus {
      outline: 0;
      background-color: transparent;
      box-shadow: 0px 0px 0px 2px ${color.postiOrange60};
      border-color: transparent;
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }
  `
)

const SearchWithoutNamespace = React.forwardRef<HTMLInputElement, SearchProps>(
  (
    {
      onChangeWithDebounce,
      onEnter,
      debounceTime,
      clearButtonProps,
      leftTemplate,
      rightTemplate,
      size,
      noBorderChangeOnFocus,
      isBrand,
      ...props
    },
    forwardRef
  ) => {
    const localRef = useRef<HTMLInputElement>()
    const inputRef = (forwardRef as React.MutableRefObject<HTMLInputElement>) || localRef
    const debouncedSearchTerm = useDebounce<string>(props?.value as string, debounceTime)

    useEffect(() => {
      onChangeWithDebounce?.(debouncedSearchTerm || '')
    }, [debouncedSearchTerm, onChangeWithDebounce])

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange?.(event)
    }

    const clear = (event: any) => {
      onChange({
        ...event,
        currentTarget: {
          ...event.currentTarget,
          id: props.id,
          value: '',
        },
      } as React.ChangeEvent<HTMLInputElement>)
    }

    const onKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
      if (onEnter && e.key === KeyboardKeys.Enter) {
        onEnter(inputRef.current.value)
      }
    }

    const closeButtonProps = {
      'aria-label': clearButtonProps?.['aria-label'],
      disabled: props.disabled,
      onClick: clear,
      icon: isBrand ? BrandCloseIcon : CloseIcon,
      ...clearButtonProps,
    }

    const optionalCloseButton = Boolean(props.value) ? (
      isBrand ? (
        <StyledBrandIconButton
          size={BrandSize.xxxs}
          contentMode={BrandMode.secondary}
          hasBackground
          {...closeButtonProps}
        />
      ) : (
        <StyledIconButton {...closeButtonProps} />
      )
    ) : undefined

    return (
      <Input
        {...props}
        ref={inputRef}
        type="search"
        leftTemplate={
          leftTemplate || isBrand ? (
            <StyledBrandSearchIcon disabled={props.disabled} />
          ) : (
            <StyledSearchIcon disabled={props.disabled} />
          )
        }
        rightTemplate={rightTemplate || optionalCloseButton}
        onChange={onChange}
        onKeyDown={onKeyDown}
        size={size}
        noBorderChangeOnFocus={noBorderChangeOnFocus}
        isBrand={isBrand}
      />
    )
  }
)

SearchWithoutNamespace.displayName = 'Search'
SearchWithoutNamespace.propTypes = inputPropTypes
SearchWithoutNamespace.defaultProps = inputDefaultProps

const BrandSearchWithoutNamespace = withBrand(SearchWithoutNamespace)

const searchSubComponents: SearchSubComponents = {
  Input: Input,
  SearchIcon: StyledSearchIcon,
  BrandSearchIcon: StyledBrandSearchIcon,
  CloseIcon: StyledIconButton,
  BrandCloseIcon: StyledBrandIconButton,
}

export const Search = addNamespace(SearchWithoutNamespace, searchSubComponents)
export const BrandSearch = addNamespace(BrandSearchWithoutNamespace, searchSubComponents)
