import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const CloseIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M18 6L6 18M6 6L18 18" />
  </SVG>
)

CloseIcon.propTypes = defaultIconPropTypes
CloseIcon.defaultProps = defaultPictographIconProps

export { CloseIcon }
