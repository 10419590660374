import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const BoxIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M12.0001 20.9996L12 11.9996M12 11.9996L3.5 7.27739M12 11.9996L20.5 7.27739M12.8123 21.639L19.8123 18.5279C20.5345 18.2069 21 17.4907 21 16.7003V8.17685C21 7.45052 20.6062 6.78128 19.9713 6.42854L12.9713 2.53965C12.3672 2.20407 11.6328 2.20407 11.0287 2.53965L4.02871 6.42854C3.39378 6.78128 3 7.45052 3 8.17685V16.7003C3 17.4907 3.46547 18.2069 4.18772 18.5279L11.1877 21.639C11.7049 21.8689 12.2951 21.8689 12.8123 21.639Z" />
  </SVG>
)

BoxIcon.propTypes = defaultIconPropTypes
BoxIcon.defaultProps = defaultPictographIconProps

export { BoxIcon }
