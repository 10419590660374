import styled, { css } from 'styled-components'

import { XyzTheme } from '@postidigital/posti-theme'
import { ButtonProps } from './Button.types'
import { ArrowRightIcon } from '../../design-tokens/icons/pictograph'
import { Label } from '../../design-tokens/typography'
import Loading from '../Loading'
import { Size, Mode, Position } from '../../utils/enums'

export const buttonColors = {
  primary: {
    color: XyzTheme.color.neutralWhite,
    background: XyzTheme.color.signalBlue,
    backgroundHover: XyzTheme.color.signalHoverBlue,
    backgroundPress: XyzTheme.color.signalHoverBlue,
    backgroundDisabled: XyzTheme.color.neutralGray5,
    backgroundFocus: XyzTheme.color.signalBlue,
  },
  secondary: {
    color: XyzTheme.color.signalBlue,
    background: XyzTheme.color.neutralGray5,
    backgroundHover: XyzTheme.color.neutralOnHoverGray,
    backgroundPress: XyzTheme.color.neutralOnPressGray,
    backgroundDisabled: XyzTheme.color.neutralGray5,
    backgroundFocus: XyzTheme.color.neutralGray5,
  },
}

const padding = {
  [Size.lg]: '1.5rem 2.5rem',
  [Size.md]: '1.1875rem 2rem',
  [Size.sm]: '1rem 1.5rem',
}

const paddingWithIcon = {
  [Size.lg]: '1.5rem 2.5rem',
  [Size.md]: '1rem 2rem',
  [Size.sm]: '0.75rem 1.5rem',
}

const iconOnlyPadding = '0.25rem'

const fontSize = {
  [Size.lg]: `${XyzTheme.fontSize.label.one}rem`,
  [Size.md]: `${XyzTheme.fontSize.label.two}rem`,
  [Size.sm]: `${XyzTheme.fontSize.label.three}rem`,
}

const lineHeight = {
  [Size.lg]: XyzTheme.lineHeight.label.one,
  [Size.md]: XyzTheme.lineHeight.label.two,
  [Size.sm]: XyzTheme.lineHeight.label.three,
}

// In rems
const animationSize = 0.5

export const buttonCommonStyles = css`
  display: flex;
  align-items: center;
  border-style: none;
  border-radius: 3rem;
  cursor: pointer;
  transition: all 100ms ease-in;
  user-select: none;
`

export const StyledButton = styled.button<ButtonProps & { hasIcon: boolean; isOnlyIcon: boolean }>(
  ({ theme: { color, zIndex }, contentMode, size, hasBackground, hasIcon, isOnlyIcon }) => css`
    ${buttonCommonStyles}
    border: 3px solid transparent;
    background-clip: padding-box;
    position: relative;
    z-index: ${zIndex.zIndex0};
    color: ${buttonColors[contentMode].color};
    background-color: ${contentMode === Mode.secondary && !hasBackground && size === Size.sm
      ? 'transparent'
      : buttonColors[contentMode].background};
    padding: ${hasIcon ? paddingWithIcon[size] : padding[size]};
    ${isOnlyIcon &&
    css`
      padding: ${iconOnlyPadding};
    `}
    /* Needed to scale the container but not the content */
    &:before {
      content: '';
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      bottom: 0;
      z-index: ${zIndex.zIndexInvisible};
      border-radius: 3rem;
      background-color: ${contentMode === Mode.secondary && !hasBackground
        ? 'transparent'
        : buttonColors[contentMode].background};
      transition: all 100ms ease-in;
    }

    @media (hover: hover) {
      &:hover {
        &:before {
          background-color: ${buttonColors[contentMode].backgroundHover};
        }
      }
    }

    &:active {
      &:before {
        background-color: ${buttonColors[contentMode].backgroundPress};
      }
      ${StyledInner} {
        transform: scale(0.97);
        transform-origin: 50% 50%;
      }
    }
    &:focus {
      outline: 0;
      border: 3px solid ${color.signalLightBlue};
      box-shadow: 0px 0px 7px ${color.signalBlue};
    }
    &:focus:not(:focus-visible) {
      box-shadow: none;
      border: 3px solid transparent;
    }
    @media (hover: hover) {
      &:hover:not(:focus) {
        &:before {
          top: -${animationSize / 2}rem;
          left: -${animationSize / 2}rem;
          height: calc(100% + ${animationSize}rem);
          width: calc(100% + ${animationSize}rem);
          background-color: ${buttonColors[contentMode].backgroundPress};
        }
      }
    }
    &:active:not(:focus) {
      &:before {
        top: -${animationSize / 2}rem;
        left: -${animationSize / 2}rem;
        height: calc(100% + ${animationSize}rem);
        width: calc(100% + ${animationSize}rem);
        background-color: ${buttonColors[contentMode].backgroundPress};
      }
    }
    &[aria-disabled='true'] {
      cursor: default;
      pointer-events: none;
      color: ${color.neutralPassiveGray};
      background-color: ${buttonColors[contentMode].backgroundDisabled};
      &:before {
        content: none;
      }
      svg * {
        fill: ${color.neutralPassiveGray};
      }
    }
  `
)

export const innerCommonStyles = css`
  display: flex;
  align-items: center;
  justify-content: inherit;
  transition: all 100ms ease-in;
  width: 100%;
`

export const StyledInner = styled.span<Pick<ButtonProps, 'size' | 'contentMode'>>`
  ${({ theme: { fontFamily, fontWeight }, size }) => css`
    ${innerCommonStyles}
    font-family: ${fontFamily.Montserrat};
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize[size]};
    line-height: ${lineHeight[size]};
  `}
`

type IconProps = Pick<ButtonProps, 'iconPosition' | 'size' | 'contentMode'> & { isOnlyIcon: boolean }

export const StyledIcon = styled(ArrowRightIcon)<IconProps>`
  ${({ theme: { iconSize, spacing }, iconPosition, size, isOnlyIcon }) => css`
    flex-shrink: 0;
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
    ${!isOnlyIcon &&
    iconPosition === Position.left &&
    css`
      margin-right: ${size === Size.lg ? spacing.space4 : spacing.space3}rem;
    `}
    ${!isOnlyIcon &&
    iconPosition === Position.right &&
    css`
      margin-left: ${size === Size.lg ? spacing.space4 : spacing.space3}rem;
    `}
  `}
`

export const StyledLabel = styled(Label)<{ disabled: boolean }>`
  ${({ theme: { spacing, brand }, disabled }) => css`
    margin-top: ${spacing.space2}rem;
    color: ${brand.color.gray70};
    ${disabled &&
    css`
      color: ${brand.color.gray30};
      cursor: default;
      pointer-events: none;
    `}
  `}
`

export const StyledButtonContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    text-align: center;
    width: max-content;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: ${spacing.space3}rem;
  `}
`

export const StyledLoadingIcon = styled(Loading)(
  ({ theme: { iconSize, spacing } }) => css`
    flex-shrink: 0;
    margin-left: ${spacing.space4}rem;
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
  `
)
