import React from 'react'
import styled, { css } from 'styled-components'
import { ArrowLeftIcon, ArrowRightIcon } from '../../design-tokens/icons/pictograph'
import {
  ArrowLeftIcon as BrandArrowLeftIcon,
  ArrowRightIcon as BrandArrowRightIcon,
} from '../../design-tokens/icons/brand-ui-icons'
import { useTheme } from '../../utils/useTheme'
import { ButtonProps } from '../../basic-components/Button/Button.types'
import { IconButton } from '../../deprecated/IconButton'
import { BrandButton } from '../../basic-components/BrandButton'
import { BrandSize, BrandMode } from '../../utils/enums'

export type MonthNavigationButtonProps = ButtonProps & {
  orientation: 'left' | 'right'
  'aria-label'?: string
  isBrand?: boolean
}

const buttonStyles = ({ theme: { spacing } }) => css`
  position: absolute;
  top: ${spacing.space3}rem;
  border: 2px solid transparent;

  &:focus {
    box-shadow: none;
  }
  @media (hover: hover) {
    &:hover,
    &:hover:focus,
    &:focus:not(:focus-visible):hover {
      border: 2px solid transparent;
    }
  }
  &:focus:not(:focus-visible) {
    border: 2px solid transparent;
  }
`

export const StyledButton = styled(IconButton)<{ isLeft: boolean }>(
  ({ theme: { color, spacing }, isLeft }) => css`
    ${buttonStyles}
    ${isLeft
      ? `
      left: ${spacing.space3}rem;
      `
      : `
      right: ${spacing.space3}rem;
      `}
    &:focus {
      border: 2px solid ${color.signalBlue};
    }
    @media (hover: hover) {
      &:hover,
      &:hover:focus,
      &:focus:not(:focus-visible):hover {
        background-color: ${color.neutralOnPressGray};
      }

      &:focus:hover {
        background-color: ${color.neutralOnPressGray};
        border: 2px solid ${color.signalBlue};
      }
    }
    &:focus:not(:focus-visible) {
      background-color: ${color.neutralOnPressGray};
    }

    &:active,
    &:focus:active,
    &:focus:not(:focus-visible):active {
      background-color: #cdd1d6;
    }
  `
)

export const StyledBrandButton = styled(BrandButton)<{ isLeft: boolean }>(
  ({
    theme: {
      spacing,
      brand: { color },
    },
    isLeft,
  }) => css`
    ${buttonStyles}
    ${isLeft
      ? `
    left: ${spacing.space3}rem;
    `
      : `
    right: ${spacing.space3}rem;
    `}
    &:focus {
      border: 2px solid ${color.postiOrange60};
    }
    @media (hover: hover) {
      &:hover,
      &:hover:focus,
      &:focus:not(:focus-visible):hover {
        background-color: ${color.gray10};
      }

      &:focus:hover {
        background-color: ${color.gray10};
        border: 2px solid ${color.postiOrange60};
      }
    }
    &:focus:not(:focus-visible) {
      border: 2px solid transparent;
      background-color: ${color.gray10};
    }

    &:active,
    &:focus:active,
    &:focus:not(:focus-visible):active {
      background-color: ${color.gray20};
    }
  `
)

export const MonthNavigationButton: React.FC<MonthNavigationButtonProps> = ({ orientation, isBrand, ...props }) => {
  const theme = useTheme()
  const isLeft = orientation === 'left'

  const buttonProps = {
    isLeft: isLeft,
    icon: isBrand ? (isLeft ? BrandArrowLeftIcon : BrandArrowRightIcon) : isLeft ? ArrowLeftIcon : ArrowRightIcon,
    iconColor: isBrand ? theme.brand.color.gray50 : theme.color.neutralIconGray,
    ...props,
  }
  return isBrand ? (
    <StyledBrandButton size={BrandSize.xxxs} contentMode={BrandMode.secondary} {...buttonProps} />
  ) : (
    <StyledButton {...buttonProps} />
  )
}
