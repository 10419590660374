import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { useTheme } from '../../utils/useTheme'
import { XyzTheme } from '@postidigital/posti-theme'
import { Position } from '../../utils/enums'
import { Headline, HeadlineSize } from '../../design-tokens/typography/Headline/Headline'
import { StyledIcon, StyledIconButton } from './IconButton.style'
import { Label, LabelSize } from '../../design-tokens/brand-typography'
import { IconButtonProps, IconButtonSubComponents } from './IconButton.types'
import { addNamespace } from '../../utils/helpers'
import withBrand from '../../utils/withBrand'

const IconButtonWithoutNamespace = React.forwardRef<HTMLButtonElement, IconButtonProps>((props, ref) => {
  const { icon: Icon, disabled, iconColor, buttonLabel, iconPosition, isBrand, ...rest } = props
  const theme = useTheme()
  const color = disabled ? theme.color.neutralPassiveGray : iconColor
  const brandColor = disabled ? theme.brand.color.gray30 : iconColor

  const iconLabel = (isBrand?: boolean, buttonLabel?: string) => {
    if (isBrand) {
      return (
        <Label as="span" size={LabelSize.Four}>
          {buttonLabel}
        </Label>
      )
    } else {
      return (
        <Headline as="span" size={HeadlineSize.Seven}>
          {buttonLabel}
        </Headline>
      )
    }
  }

  return (
    <StyledIconButton
      ref={ref}
      aria-disabled={disabled}
      buttonLabel={buttonLabel}
      iconPosition={iconPosition}
      iconColor={iconColor}
      type="button"
      isBrand={isBrand}
      {...rest}
    >
      {!!buttonLabel && iconPosition === Position.right && iconLabel(isBrand, buttonLabel)}
      {!!Icon && <StyledIcon as={Icon} color={isBrand ? brandColor : color} aria-hidden={true} />}
      {!!buttonLabel && iconPosition === Position.left && iconLabel(isBrand, buttonLabel)}
    </StyledIconButton>
  )
})

const iconButtonSubComponents: IconButtonSubComponents = {
  Main: StyledIconButton,
  Icon: StyledIcon,
  Label: Label,
  Headline: Headline,
}

IconButtonWithoutNamespace.displayName = 'IconButton'

IconButtonWithoutNamespace.propTypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  iconColor: PropTypes.string,
  buttonLabel: PropTypes.string,
  iconPosition: PropTypes.oneOf(Object.values(Position)),
  isBrand: PropTypes.bool,
}

IconButtonWithoutNamespace.defaultProps = {
  iconColor: XyzTheme.color.neutralIconGray,
  buttonLabel: '',
  iconPosition: Position.left,
}

const BrandIconButtonWithoutNamespace = withBrand(IconButtonWithoutNamespace)

/**
 * @deprecated IconButton, BrandIconButton is deprecated. Please use BrandButton instead.
 */

export const IconButton = addNamespace(IconButtonWithoutNamespace, iconButtonSubComponents)
export const BrandIconButton = addNamespace(BrandIconButtonWithoutNamespace, iconButtonSubComponents)
