import { KeyboardKeys } from '../../../utils/enums'

export const getOptionElements = (el: HTMLElement, buttonId: string): NodeListOf<HTMLElement> => {
  return el.querySelectorAll<HTMLElement>(`[role="menuitem"]:not(#${buttonId})`)
}

export const focusOnButton = (el: HTMLElement, buttonId: string): void => {
  const button = el.querySelector<HTMLElement>(`#${buttonId}`)
  if (button) {
    button.focus()
  }
}

const focusOnFirstOption = (el: HTMLElement, buttonId: string) => {
  const firstOptionEl = getOptionElements(el, buttonId)[0]

  if (firstOptionEl && typeof firstOptionEl.focus === 'function') {
    firstOptionEl.focus()
  }
}

const focusOnLastOption = (el: HTMLElement, buttonId: string) => {
  const options = getOptionElements(el, buttonId)
  if (options.length) {
    const lastOptionEl = options[options.length - 1]

    if (lastOptionEl && typeof lastOptionEl.focus === 'function') {
      lastOptionEl.focus()
    }
  }
}

export const handleDropdownClose = (
  e: React.KeyboardEvent,
  el: HTMLElement,
  close: () => void,
  buttonId: string
): void => {
  if (
    [KeyboardKeys.Tab, KeyboardKeys.Escape, KeyboardKeys.ArrowLeft, KeyboardKeys.ArrowRight].includes(
      e.key as KeyboardKeys
    )
  ) {
    close()
    focusOnButton(el, buttonId)
  }
}

const buttonActionKeys: string[] = [
  KeyboardKeys.Enter,
  KeyboardKeys.Space,
  KeyboardKeys.ArrowDown,
  KeyboardKeys.ArrowUp,
]
const itemActionKeys: string[] = [KeyboardKeys.Enter, KeyboardKeys.Space]

export const handleDropdownOpen = (e: React.KeyboardEvent, el: HTMLElement | null, buttonId: string): void => {
  if (buttonActionKeys.includes(e.key)) {
    if (el && e.key === KeyboardKeys.ArrowUp) {
      focusOnLastOption(el, buttonId)
    } else {
      focusOnFirstOption(el, buttonId)
    }
  }
}

export const handleAction = (e: React.KeyboardEvent<HTMLElement>): void => {
  if (buttonActionKeys.includes(e.key)) {
    e.preventDefault()
    e.currentTarget.click()
  }
}

export const handleItemAction = (e: React.KeyboardEvent<HTMLElement>): void => {
  if (itemActionKeys.includes(e.key)) {
    e.preventDefault()
    e.currentTarget.click()
  }
}
