import React, { HTMLAttributes, useRef, useEffect } from 'react'
import PropTypes, { Validator } from 'prop-types'
import Square from '../Square'
import styled, { css } from 'styled-components'
import useClickOutside from '../../utils/useClickOutside'
import { useTheme } from '../../utils/useTheme'

const StyledContainer = styled.ul`
  ${({ theme: { color, spacing, borderRadius } }) => css`
    padding: ${spacing.space4}rem;
    border-radius: ${borderRadius.md}rem;
    box-shadow: 0 8px 22px rgba(27, 31, 35, 0.07);
    background: ${color.neutralWhite};
    width: 19.5rem;
    display: flex;
    flex-flow: row wrap;
    overflow-wrap: break-word;
    overflow: hidden;
    list-style-type: none;
    margin: 0;
  `}
`

const ServiceItemContainerLi = styled.li`
  flex: 1 1 33.33%;
  max-width: 33.33%;
`

const ServiceItemContainer = styled.a<{ isBrand: boolean }>`
  ${({ theme: { spacing, color, fontWeight, borderRadius, brand }, isBrand }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    color: ${color.neutralNetworkGray};
    font-size: 0.65rem;
    font-weight: ${fontWeight.bold};
    line-height: 1.2;
    padding: ${spacing.space2}rem;
    border-radius: ${borderRadius.xs}rem;

    &:focus {
      box-shadow: 0px 0px 7px ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
      outline: none;
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    &:hover,
    &:active {
      text-decoration: none;
    }
  `}
`

const ServiceItemLogo = styled(Square)<{ backgroundColor?: string }>(
  ({ theme: { spacing, color, fontSize }, backgroundColor }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${backgroundColor};
    color: ${color.neutralWhite};
    font-size: ${fontSize.body.three}rem;
    margin-bottom: ${spacing.space2}rem;
  `
)

const ServiceItemTitle = styled.p<{ isBrand?: boolean }>(
  ({ theme: { brand }, isBrand }) => css`
    text-align: center;
    word-break: normal;
    hyphens: auto;
    ${isBrand &&
    css`
      font-family: ${brand.fontFamily.PostiFont};
      font-weight: ${brand.fontWeight.medium};
      line-height: ${brand.lineHeight.label};
      font-size: ${brand.fontSize.label.seven}rem;
    `}
  `
)

interface ServiceItemProps extends HTMLAttributes<HTMLAnchorElement> {
  title: string
  href: string
  icon?: JSX.Element
  squareColor?: string
  onClick?: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
  isBrand?: boolean
}

const ServiceItem = React.forwardRef<HTMLAnchorElement, ServiceItemProps>((props, ref) => {
  const theme = useTheme()
  const { href, title, squareColor = theme.color.neutralNetworkGray, icon, onClick, isBrand, ...rest } = props

  const handleItemClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    if (onClick) {
      e.preventDefault()
      onClick(e)
    }
  }

  return (
    <ServiceItemContainerLi aria-label={title} role="menuitem">
      <ServiceItemContainer ref={ref} href={href} onClick={handleItemClick} isBrand={isBrand} {...rest}>
        <ServiceItemLogo backgroundColor={squareColor}>{icon || title.charAt(0).toUpperCase()}</ServiceItemLogo>
        <ServiceItemTitle isBrand={isBrand}>{title}</ServiceItemTitle>
      </ServiceItemContainer>
    </ServiceItemContainerLi>
  )
})

ServiceItem.displayName = 'ServiceItem'

export interface AccountOverlayProps extends HTMLAttributes<HTMLElement> {
  toggleOpen: (e) => void
  serviceItems?: ServiceItemProps[]
  /*
   * List ids for elements that are excluded from triggering onClose event. The array is passed to the useClickOutside hook
   */
  exclusions?: string[]
  isOpen: boolean
  isBrand?: boolean
}

/**
 * @deprecated Current version of component is deprecated, as it is a posti.fi specific component
 */
const AccountOverlay: React.FC<AccountOverlayProps> = (props) => {
  const { toggleOpen, serviceItems = [], exclusions, isOpen, isBrand, ...rest } = props
  const overlayRef = useRef<HTMLUListElement>()
  const firstChildRef = useRef<HTMLAnchorElement>(null)

  useClickOutside(overlayRef, toggleOpen, isOpen, exclusions)

  useEffect(() => {
    if (isOpen) {
      firstChildRef.current?.focus()
    }
  }, [isOpen])

  return (
    <>
      {isOpen && (
        <StyledContainer ref={overlayRef} role="menu" aria-hidden={!isOpen} {...rest}>
          {serviceItems?.map((item, index) => {
            const serviceItemProps = index === 0 ? { ref: firstChildRef } : {}
            return (
              <ServiceItem
                key={`${item.title}-${index}`}
                href={item.href}
                icon={item.icon}
                title={item.title}
                squareColor={item.squareColor}
                onClick={item.onClick}
                isBrand={isBrand}
                {...serviceItemProps}
              />
            )
          })}
        </StyledContainer>
      )}
    </>
  )
}

export const serviceItemType = PropTypes.shape({
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.node,
  squareColor: PropTypes.string,
  onClick: PropTypes.func,
  isBrand: PropTypes.bool,
}) as Validator<ServiceItemProps>

AccountOverlay.propTypes = {
  toggleOpen: PropTypes.func.isRequired,
  serviceItems: PropTypes.arrayOf(serviceItemType),
  isOpen: PropTypes.bool,
  exclusions: PropTypes.arrayOf(PropTypes.string),
  isBrand: PropTypes.bool,
}

/** @component */
export default AccountOverlay
