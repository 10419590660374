import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const MailboxIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M8.78322 5.99952H10.7132C11.111 5.99952 11.4924 5.84151 11.7737 5.56025C12.0549 5.27899 12.213 4.89752 12.213 4.49976C12.213 4.102 12.0549 3.72053 11.7737 3.43927C11.4924 3.15801 11.111 3 10.7132 3H7.71368C7.54321 3 7.37972 3.06772 7.25918 3.18826C7.13864 3.3088 7.07092 3.47229 7.07092 3.64275V7.07078H8.78493L8.78322 5.99952Z"
      fill={color}
    />
    <Path
      d="M19.1324 8.35577H9.20059C9.16093 8.35609 9.12213 8.36736 9.08848 8.38834C9.05483 8.40933 9.02763 8.4392 9.00989 8.47468C8.99216 8.51015 8.98457 8.54983 8.98798 8.58935C8.99138 8.62886 9.00564 8.66666 9.02919 8.69858C9.56552 9.39986 9.85614 10.2582 9.8562 11.141V17.1401C9.8562 17.1969 9.87877 17.2514 9.91895 17.2916C9.95913 17.3318 10.0136 17.3543 10.0705 17.3543H12.4272C12.484 17.3543 12.5644 17.3543 12.6415 17.3543C12.6415 17.4079 12.6415 17.5118 12.6415 17.5686V19.7106C12.6415 19.9379 12.6415 20.8019 12.6415 21.0003C13.235 21.0003 13.8041 21.0003 14.3555 21.0003C14.3555 20.7994 14.3555 19.9379 14.3555 19.7106V17.5686C14.3555 17.5118 14.3555 17.4246 14.3555 17.3543C14.4257 17.3543 14.5129 17.3543 14.5697 17.3543H20.807C20.8609 17.3512 20.9114 17.327 20.9475 17.2869C20.9836 17.2467 21.0024 17.194 20.9998 17.1401V10.284C21.0076 9.78078 20.8153 9.29501 20.4652 8.93345C20.115 8.57189 19.6357 8.36412 19.1324 8.35577Z"
      fill={color}
    />
    <Path
      d="M3.00002 11.141V17.1401C3.00002 17.1969 3 17.1401 3 17.3543C3.08369 17.3543 3.15745 17.3543 3.21428 17.3543H8.35631C8.41313 17.3543 8.49691 17.3543 8.57056 17.3543C8.57056 17.1401 8.57056 17.1969 8.57056 17.1401V11.141C8.57056 10.4023 8.27711 9.6939 7.75477 9.17156C7.23243 8.64922 6.52399 8.35577 5.78529 8.35577C5.04659 8.35577 4.33815 8.64922 3.81581 9.17156C3.29347 9.6939 3.00002 10.4023 3.00002 11.141ZM7.0708 14.9976C7.0708 15.1112 7.02565 15.2202 6.94529 15.3006C6.86493 15.3809 6.75594 15.4261 6.6423 15.4261H4.92829C4.81464 15.4261 4.70565 15.3809 4.62529 15.3006C4.54493 15.2202 4.49978 15.1112 4.49978 14.9976C4.49978 14.8839 4.54493 14.7749 4.62529 14.6946C4.70565 14.6142 4.81464 14.5691 4.92829 14.5691H6.6423C6.75594 14.5691 6.86493 14.6142 6.94529 14.6946C7.02565 14.7749 7.0708 14.8839 7.0708 14.9976Z"
      fill={color}
    />
  </SVG>
)

MailboxIcon.propTypes = defaultIconPropTypes

MailboxIcon.defaultProps = defaultPictographIconProps
