import React, { ButtonHTMLAttributes } from 'react'
import { ChatBubbleIcon } from '../../design-tokens/icons/misc'
import { ChatBubbleIconProps } from '../../design-tokens/icons/misc/ChatBubbleIcon'
import styled, { css, StyledComponent, DefaultTheme } from 'styled-components'
import { Tooltip } from '../../basic-components/Tooltip'
import { Placement } from '../../utils/enums'
import PropTypes from 'prop-types'
import { TooltipProps } from '../../basic-components/Tooltip/Tooltip'

export interface ChatButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  'aria-label': string
  title: string
  onClick: (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void
}

interface ChatButtonSubComponents {
  Tooltip: React.ForwardRefExoticComponent<TooltipProps & React.RefAttributes<HTMLDivElement>>
  Button: StyledComponent<'button', DefaultTheme, Omit<ChatButtonProps, 'title'>, never>
  Icon: React.FC<ChatBubbleIconProps>
}

const ChatBubbleButton = styled.button<Omit<ChatButtonProps, 'title'>>`
  ${({ theme: { color, spacing }, disabled }) => css`
    cursor: pointer;
    align-items: center;
    border-radius: 50%;
    width: ${spacing.space10}rem;
    height: ${spacing.space10}rem;
    border: none;
    transition: all 100ms ease-in;
    padding: 0;
    box-shadow: 0px 8px 22px rgba(38, 44, 48, 0.3);
    background-color: ${disabled ? color.neutralGray5 : color.brandPurple};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: ${spacing.space3}rem;

    &:focus {
      outline: 0;
      filter: drop-shadow(0px 0px 3px ${color.signalBlue});
      &[aria-disabled='true'] {
        filter: none;
      }
    }
    &:focus:not(:focus-visible) {
      filter: none;
    }

    &[aria-disabled='true'] {
      cursor: default;
      pointer-events: none;
      background-color: ${color.neutralGray5};
      box-shadow: 0px 8px 22px rgba(38, 44, 48, 0.3);
    }
  `}
`

/**
 * @deprecated ChatButton is deprecated. No replacement is planned.
 */
export const ChatButton: React.FC<ChatButtonProps> & ChatButtonSubComponents = ({ disabled, title, ...rest }) => {
  return (
    <Tooltip id="chatbutton" title={title} placement={Placement.top}>
      <ChatBubbleButton aria-disabled={disabled} {...rest}>
        <ChatBubbleIcon disabled={disabled} aria-hidden={true} />
      </ChatBubbleButton>
    </Tooltip>
  )
}

ChatButton.Tooltip = Tooltip
ChatButton.Button = ChatBubbleButton
ChatButton.Icon = ChatBubbleIcon

ChatButton.propTypes = {
  'aria-label': PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}
