import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, Rect, SVG } from '../icons.styled'
import { BrandTheme } from '@postidigital/posti-theme'

const VisaIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props} fill="none">
    <Rect x="1.5" y="4.5" width="21" height="14.5294" rx="2.5" fill={BrandTheme.color.white} />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.95563 14.2613H6.62286L5.62344 10.3363C5.576 10.1557 5.47528 9.99611 5.32712 9.92088C4.95738 9.73183 4.54994 9.58137 4.10547 9.50549V9.35438H6.25246C6.54878 9.35438 6.77102 9.58137 6.80805 9.845L7.32661 12.6762L8.65873 9.35438H9.95447L7.95563 14.2613ZM10.6953 14.2613H9.43657L10.473 9.35438H11.7317L10.6953 14.2613ZM13.3602 10.7137C13.3972 10.4494 13.6194 10.2983 13.8787 10.2983C14.2861 10.2604 14.73 10.3363 15.1004 10.5247L15.3226 9.46821C14.9522 9.31709 14.5448 9.24121 14.175 9.24121C12.9534 9.24121 12.0644 9.92089 12.0644 10.8642C12.0644 11.5818 12.6941 11.9586 13.1386 12.1856C13.6194 12.4119 13.8046 12.563 13.7676 12.7894C13.7676 13.1289 13.3972 13.28 13.0275 13.28C12.583 13.28 12.1385 13.1668 11.7317 12.9778L11.5095 14.0349C11.954 14.2233 12.4348 14.2992 12.8793 14.2992C14.2491 14.3365 15.1004 13.6575 15.1004 12.6383C15.1004 11.3548 13.3602 11.2796 13.3602 10.7137V10.7137ZM19.5055 14.2613L18.5061 9.35438H17.4326C17.2103 9.35438 16.9881 9.50549 16.914 9.73183L15.0633 14.2613H16.3591L16.6177 13.5443H18.2097L18.3579 14.2613H19.5055ZM17.6178 10.6758L17.9875 12.5251H16.951L17.6178 10.6758Z"
      fill="#172B85"
    />
    <Rect x="1.5" y="4.5" width="21" height="14.5294" rx="2.5" stroke="#F2F4F7" />
  </SVG>
)

VisaIcon.propTypes = defaultIconPropTypes
VisaIcon.defaultProps = defaultPictographIconProps

export { VisaIcon }
