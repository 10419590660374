import styled, { css } from 'styled-components'
import { Body, BodySize } from '../../design-tokens/typography'
import { DropdownContainerProps } from './FileUpload.types'
import { mediaQuery } from '../../utils/mediaQuery'

export const FileList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`

export const DropdownContainer = styled.div<DropdownContainerProps>`
  ${({ theme: { color, borderRadius, brand }, isDragActive, isBrand, isInvalid }) => css`
    background: ${color.neutralWhite};
    border: 0.125rem dashed
      ${isBrand
        ? isInvalid
          ? brand.color.red50
          : brand.color.gray10
        : isInvalid
          ? color.signalRed
          : color.neutralPassiveGray};
    border-radius: ${borderRadius.sm}rem;
    padding: 3.125rem 2.5rem;
    cursor: pointer;
    transition: all 100ms ease-in;

    &:not([aria-disabled='true']) {
      @media (hover: hover) {
        &:hover {
          border: 0.125rem ${isInvalid ? 'dashed' : 'solid'}
            ${isBrand
              ? isInvalid
                ? brand.color.red50
                : brand.color.postiOrange60
              : isInvalid
                ? color.signalRed
                : color.signalBlue};
          outline: none;
        }
      }
      &:focus {
        border: 0.125rem ${isInvalid ? 'dashed' : 'solid'}
          ${isBrand
            ? isInvalid
              ? brand.color.red50
              : brand.color.postiOrange60
            : isInvalid
              ? color.signalRed
              : color.signalBlue};
        outline: none;
      }

      ${isDragActive &&
      css`
        border: 0.125rem solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
      `}
    }

    &[aria-disabled='true'] {
      cursor: default;
      border: 0.125rem dashed ${isBrand ? brand.color.gray5 : color.neutralGray5};
      &:focus {
        outline: none;
      }
    }
  `}
`

export const Placeholder = styled.div<{ centerContent: boolean }>`
  ${({ centerContent }) => css`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    flex-direction: column;

    ${!centerContent &&
    css`
      ${mediaQuery.sm`
          flex-direction: row;
        `}
    `}
  `}
`

export const Text = styled(Body).attrs({ size: BodySize.Three })<{
  centerContent: boolean
  isBrand?: boolean
}>`
  ${({ theme: { color, brand }, centerContent, isBrand }) => css`
    margin: 0;
    color: ${isBrand ? brand.color.gray70 : color.neutralNetworkGray};
    text-align: center;
    user-select: none;

    &[aria-disabled='true'] {
      color: ${isBrand ? brand.color.gray30 : color.neutralGray5};
      & > * {
        color: ${isBrand ? brand.color.gray30 : color.neutralGray5} !important;
      }
    }
    ${!centerContent &&
    css`
      ${mediaQuery.sm`
          text-align: left;
        `}
    `}

    ${isBrand &&
    css`
      font-family: ${brand.fontFamily.PostiFont};
      font-weight: ${brand.fontWeight.regular};
      line-height: ${brand.lineHeight.body};
      font-size: ${brand.fontSize.body.three}rem;
    `}
  `}
`

export const StyledIconContainer = styled.div<{ centerContent: boolean }>`
  ${({ theme: { spacing }, centerContent }) => css`
    flex-shrink: 0;
    margin-bottom: ${spacing.space1}rem;

    ${!centerContent &&
    css`
      ${mediaQuery.sm`
          margin: 0 ${spacing.space4}rem ${spacing.space1}rem 0;
        `}
    `}
  `}
`

export const StyledWrapper = styled.div.attrs({ 'aria-live': 'polite' })<{ maxWidth: string }>`
  ${({ maxWidth }) => css`
    ${maxWidth && `max-width: ${maxWidth};`}
  `}
`

export const FileListMsgContainer = styled.div`
  width: 100%;
`
