import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, Rect, SVG } from '../brand-icons.styled'

const PostiPrivateLockersIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Rect x="3" y="9" width="7" height="6" rx="1" stroke={color} />
    <Rect x="3" y="9" width="18" height="12" rx="1" stroke={color} />
    <Rect x="3" y="9" width="7" height="12" rx="1" stroke={color} />
    <Rect width="11" height="12" rx="1" transform="matrix(-1 0 0 1 21 9)" stroke={color} />
    <Path
      d="M13.5 17.5H14.6792V16.1143H15.3035C16.8449 16.1143 17.5 15.15 17.5 14.3214C17.5 13.5 17.0299 12.5 15.2572 12.5H13.5V17.5ZM14.6792 15.15V13.4643H15.2726C15.9509 13.4643 16.3054 13.7786 16.3054 14.2929C16.3054 14.8071 15.9509 15.15 15.2881 15.15H14.6792Z"
      fill={color}
    />
    <Path d="M17 8C17 5.23858 14.7614 3 12 3C9.23858 3 7 5.23858 7 8" stroke={color} />
  </SVG>
)

PostiPrivateLockersIcon.propTypes = defaultIconPropTypes
PostiPrivateLockersIcon.defaultProps = defaultPictographIconProps

export { PostiPrivateLockersIcon }
