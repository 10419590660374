import styled, { css } from 'styled-components'
import { StyledTab } from '../OldTab/Tab.style'

export const StyledTabs = styled.div<{ vertical: boolean }>(
  ({ theme: { spacing }, vertical }) => css`
    display: flex;
    position: relative;
    margin-bottom: ${spacing.space4}rem;
    flex-direction: ${vertical ? 'column' : 'row'};
  `
)

export const StyledTabList = styled.div<{ isBrand: boolean; vertical: boolean }>(
  ({ theme: { color, brand }, isBrand, vertical }) => css`
    position: relative;

    ${StyledTabIndicator} {
      background-color: ${isBrand ? brand.color.postiOrange60 : color.neutralNetworkGray};
    }

    ${StyledTab} {
      color: ${isBrand ? brand.color.gray70 : color.neutralNetworkGray};
      & > * {
        cursor: pointer;
      }
      ${!vertical &&
      css`
        border-radius: 1.875rem;
      `}
    }
  `
)

export const StyledTabIndicator = styled.div<{ vertical: boolean }>(
  ({ vertical }) => css`
    display: inline-block;
    position: absolute;
    height: 0.1875rem;
    border-radius: 2px 2px 0px 0px;
    bottom: -0.0625rem;
    width: 100%;
    transition:
      top 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    ${vertical &&
    css`
      height: 100%;
      width: 0.1875rem;
      right: 0;
    `};
  `
)
