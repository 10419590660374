import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const ParcelIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M12.9774 20.66C12.9778 20.7264 12.9775 20.9659 12.9775 20.9659C12.9775 20.9659 13.194 20.8685 13.2537 20.8416L20.5399 17.472C20.6766 17.4088 20.7925 17.3068 20.8738 17.1784C20.9552 17.0499 20.9984 16.9003 20.9985 16.7475V7.87466C20.9985 7.87038 21.002 7.56974 20.9985 7.56741C20.9949 7.56509 20.7261 7.69136 20.7222 7.69313L13.0948 11.2219C13.0605 11.2374 12.9798 11.2809 12.9798 11.2809C12.9799 11.3275 12.9797 11.3644 12.9798 11.4026L12.9774 20.66Z"
      fill={color}
    />
    <Path
      d="M8.54117 9.90557C8.51149 9.89302 8.47923 9.88809 8.44724 9.89123C8.41525 9.89438 8.38451 9.90549 8.35774 9.92359C8.33097 9.9417 8.309 9.96623 8.29377 9.99503C8.27854 10.0238 8.27052 10.056 8.27041 10.0887V11.6867C8.27041 11.845 8.20857 11.9969 8.09851 12.1089C7.98844 12.2209 7.83916 12.2838 7.6835 12.2838C7.52785 12.2838 7.37856 12.2209 7.2685 12.1089C7.15843 11.9969 7.0966 11.845 7.0966 11.6867V9.42547C7.0972 9.38569 7.08603 9.34666 7.06453 9.31343C7.04304 9.28021 7.01223 9.25433 6.97609 9.23916L3.27076 7.67144C3.21495 7.6474 3.00003 7.56696 3.00003 7.56696C3.00003 7.47802 3.00001 7.85185 3 7.85457V16.729C2.99999 16.8861 3.04568 17.0398 3.13132 17.1705C3.21696 17.3013 3.33872 17.4034 3.48126 17.4639L11.5297 20.8772C11.5892 20.9024 11.8004 21 11.8004 21C11.8004 21 11.8005 20.7588 11.8004 20.6933V11.4207C11.8004 11.3814 11.8004 11.2869 11.8004 11.2869C11.8004 11.2869 11.7156 11.252 11.6799 11.2368L8.54117 9.90557Z"
      fill={color}
    />
    <Path
      d="M15.0512 4.63896C15.0856 4.62323 15.1147 4.59773 15.1352 4.56552C15.1557 4.53332 15.1666 4.49578 15.1666 4.45743C15.1666 4.41908 15.1557 4.38154 15.1352 4.34934C15.1147 4.31713 15.0856 4.29163 15.0512 4.2759L12.3123 3.06647C12.2136 3.02263 12.107 3 11.9993 3C11.8916 3 11.785 3.02263 11.6863 3.06647L4.1684 6.39299C4.13311 6.40829 4.10302 6.4338 4.08187 6.46636C4.06072 6.49892 4.04944 6.53709 4.04944 6.57611C4.04944 6.61514 4.06072 6.65331 4.08187 6.68587C4.10302 6.71843 4.13311 6.74394 4.1684 6.75924L7.46055 8.15179C7.48519 8.16289 7.51185 8.16863 7.5388 8.16863C7.56575 8.16863 7.59241 8.16289 7.61706 8.15179L15.0512 4.63896Z"
      fill={color}
    />
    <Path
      d="M12.3012 10.2052C12.3259 10.2161 12.3525 10.2216 12.3794 10.2216C12.4063 10.2216 12.433 10.2161 12.4577 10.2052L19.873 6.77043C19.9074 6.7547 19.9366 6.7292 19.9571 6.697C19.9775 6.66479 19.9884 6.62725 19.9884 6.5889C19.9884 6.55055 19.9775 6.51301 19.9571 6.48081C19.9366 6.4486 19.9074 6.4231 19.873 6.40737L16.9643 5.1239C16.9396 5.11303 16.913 5.10742 16.8861 5.10742C16.8592 5.10742 16.8325 5.11303 16.8078 5.1239L9.40812 8.61762C9.37384 8.634 9.34497 8.66016 9.32501 8.69291C9.30505 8.72566 9.29487 8.76361 9.2957 8.80214C9.29653 8.84067 9.30833 8.87812 9.32968 8.90996C9.35102 8.94179 9.38099 8.96664 9.41595 8.98148L12.3012 10.2052Z"
      fill={color}
    />
  </SVG>
)

ParcelIcon.propTypes = defaultIconPropTypes

ParcelIcon.defaultProps = defaultPictographIconProps

export { ParcelIcon }
