import { BrandTheme, XyzTheme } from '@postidigital/posti-theme'
import styled, { css } from 'styled-components'
import { IconButton } from '../../deprecated/IconButton'
import { ContainerProps } from './Notification.types'
import { NotificationType } from '../../utils/enums'
import { Body } from '../../design-tokens/typography/Body'
import { BrandButton } from '../BrandButton'

export const colors = {
  [NotificationType.alert]: {
    backgroundColor: XyzTheme.color.backgroundAlertRed,
    textColor: XyzTheme.color.brandPurple,
    closeButtonColor: XyzTheme.color.neutralBlack,
  },
  [NotificationType.informational]: {
    backgroundColor: XyzTheme.color.backgroundCTABlue,
    textColor: XyzTheme.color.brandNavy,
    closeButtonColor: XyzTheme.color.brandNavy,
  },
  [NotificationType.success]: {
    backgroundColor: XyzTheme.color.backgroundNotificationGreen,
    textColor: XyzTheme.color.brandPetrol,
    closeButtonColor: XyzTheme.color.brandPetrol,
  },
  [NotificationType.warning]: {
    backgroundColor: XyzTheme.color.backgroundWarningYellow,
    textColor: XyzTheme.color.neutralNetworkGray,
    closeButtonColor: XyzTheme.color.neutralNetworkGray,
  },
  [NotificationType.text]: {
    backgroundColor: XyzTheme.color.neutralOnHoverGray,
    textColor: XyzTheme.color.neutralNetworkGray,
    closeButtonColor: XyzTheme.color.neutralNetworkGray,
  },
}

export const brandColors = {
  [NotificationType.alert]: {
    backgroundColor: BrandTheme.color.red1,
    textColor: BrandTheme.color.black,
    closeButtonColor: BrandTheme.color.gray70,
    borderColor: BrandTheme.color.red40,
    linkColor: BrandTheme.color.red60,
  },
  [NotificationType.informational]: {
    backgroundColor: BrandTheme.color.blue1,
    textColor: BrandTheme.color.black,
    closeButtonColor: BrandTheme.color.gray70,
    borderColor: BrandTheme.color.blue50,
    linkColor: BrandTheme.color.blue50,
  },
  [NotificationType.success]: {
    backgroundColor: BrandTheme.color.green5,
    textColor: BrandTheme.color.black,
    closeButtonColor: BrandTheme.color.gray70,
    borderColor: BrandTheme.color.green80,
    linkColor: BrandTheme.color.green80,
  },
  [NotificationType.warning]: {
    backgroundColor: BrandTheme.color.yellow5,
    textColor: BrandTheme.color.black,
    closeButtonColor: BrandTheme.color.gray70,
    borderColor: BrandTheme.color.yellow80,
    linkColor: BrandTheme.color.yellow80,
  },
  [NotificationType.text]: {
    backgroundColor: BrandTheme.color.white,
    textColor: BrandTheme.color.black,
    closeButtonColor: BrandTheme.color.gray70,
    borderColor: BrandTheme.color.gray50,
    linkColor: BrandTheme.color.black,
  },
}

export const Container = styled.div<ContainerProps>(
  ({ contentType, theme: { borderRadius, brand }, width, isBrand }) => css`
    display: flex;
    position: relative;
    background-color: ${isBrand ? brandColors[contentType].backgroundColor : colors[contentType].backgroundColor};
    border-radius: ${borderRadius.xs}rem;
    ${!!width ? `width: ${width};` : ''}
    ${isBrand &&
    css`
      border: 1px solid ${brandColors[contentType].borderColor};
    `}

    ${Body} {
      color: ${isBrand ? brandColors[contentType].textColor : colors[contentType].textColor};
      word-break: break-word;
      ${isBrand &&
      css`
        margin-top: ${brand.spacing.space1}rem;
        font-family: ${brand.fontFamily.PostiFont};
        font-weight ${brand.fontWeight.medium};
        line-height: 150%;
        & > a {
          color: ${brandColors[contentType].linkColor};
          font-weight: ${brand.fontWeight.bold};
          &:focus {
            box-shadow: 0px 0px 0px 2px ${brand.color.postiOrange60};
            outline: none;
          }
        }
      `}
    }
  `
)

export const ContentContainer = styled.div<{ isBrand: boolean }>(
  ({ theme: { spacing, brand }, isBrand }) => css`
    display: flex;
    width: 100%;
    padding: ${isBrand
      ? `${brand.spacing.space3}rem ${brand.spacing.space4}rem`
      : `${spacing.space3}rem ${spacing.space4}rem`};
    align-items: baseline;
  `
)

export const IndicatorIconContainer = styled.div<{
  dismissible: boolean
  isBrand: boolean
}>(
  ({ theme: { brand, spacing }, isBrand }) => css`
    flex: 0 0 auto;
    line-height: 1;
    margin-right: ${isBrand ? `${brand.spacing.space3}rem` : `${spacing.space4}rem`};
    ${isBrand &&
    css`
      svg * {
        transform: translate(0, -1px);
      }
    `}
  `
)

export const ButtonText = styled(Body)`
  flex: 1 1 auto;
`

export const StyledCloseButton = styled(IconButton)(
  ({ theme: { spacing } }) => css`
    flex: 0 0 auto;
    margin-bottom: auto;
    margin-left: ${spacing.space1}rem;
    padding: ${spacing.space1}rem;
    @media (hover: hover) {
      &:hover {
        background: transparent;
      }
    }
    &:active,
    &:focus {
      background: transparent;
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
      background: transparent;
    }
  `
)

export const StyledBrandCloseButton = styled(BrandButton)(
  ({ theme: { brand } }) => css`
    flex: 0 0 auto;
    margin-left: ${brand.spacing.space3}rem;
    margin-bottom: auto;
  `
)
