import React from 'react'
import { defaultPictographIconProps, DefaultIconProps, defaultIconPropTypes } from '../icons.types'
import { Path, SVG } from '../icons.styled'

const AlertIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props} fill="none">
    <Path
      d="M12 21a9 9 0 100-18 9 9 0 000 18zm1.35-4.95a1.35 1.35 0 11-2.7 0 1.35 1.35 0 012.7 0zm.34-9.45H10.3l.56 6.75h2.26l.56-6.75z"
      fill={color}
    />
  </SVG>
)

AlertIcon.propTypes = defaultIconPropTypes

AlertIcon.defaultProps = defaultPictographIconProps

export { AlertIcon }
