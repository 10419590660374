import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path, Rect } from '../icons.styled'

const LockerIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path d="M2 4.85714L10 2V22L2 19.1429V4.85714Z" fill={color} />
    <Rect x="11" y="16" width="11" height="3" fill={color} />
    <Rect x="11" y="5" width="11" height="3" fill={color} />
    <Rect x="19" y="5" width="3" height="12" fill={color} />
  </SVG>
)

LockerIcon.propTypes = defaultIconPropTypes

LockerIcon.defaultProps = defaultPictographIconProps

export { LockerIcon }
