import styled, { css } from 'styled-components'
import { Headline } from '../../design-tokens/typography/Headline'

export const ListContainer = styled.div(
  ({ theme: { breakpoint } }) => css`
    width: 100%;
    @media screen and (min-width: ${breakpoint.md}) {
      display: inline-flex;
      flex-wrap: wrap;
      gap: 0.75rem;
    }
  `
)

export const ListSectionContainer = styled.div(
  ({ theme: { color, breakpoint } }) => css`
    margin-bottom: 0.75rem;
    padding: 0.75rem;
    border-radius: 1.25rem;
    border: 1px solid ${color.neutralOnHoverGray};

    ${Headline} {
      margin: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
    @media screen and (min-width: ${breakpoint.md}) {
      width: calc((100% - 0.75rem) / 2);
      margin-bottom: 0;
    }
  `
)
