import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'
import { TerribleFeedbackNoStrokePath } from './Feedback.styles'
import { useTheme } from '../../../utils/useTheme'

export interface FeedbackIconProps extends DefaultIconProps {
  disabled?: boolean
}

export const TerribleFeedbackIcon = (props: FeedbackIconProps): JSX.Element => {
  const theme = useTheme()
  return (
    <SVG {...props}>
      <Path
        d="M0.529297 10.3131C0.529297 5.27304 3.32019 0 12.1646 0C21.0089 0 23.7998 5.27304 23.7998 10.3131C23.7998 15.3535 21.0089 20.6261 12.1646 20.6261C3.32019 20.6265 0.529297 15.3922 0.529297 10.3131Z"
        fill={theme.color.neutralGray5}
      />
      <Path
        d="M7.16133 15.6074C7.86551 13.9116 9.85187 12.1641 12.1636 12.1641C14.4754 12.1641 16.4618 13.9116 17.166 15.6074C17.194 15.6749 17.1902 15.7199 17.1819 15.747C17.1732 15.7755 17.1535 15.8035 17.1196 15.8262C17.0499 15.873 16.9265 15.8903 16.8028 15.8139C15.8348 15.2161 14.2414 14.4998 12.1636 14.4998C10.0859 14.4998 8.49244 15.2161 7.52445 15.8139C7.40074 15.8903 7.27734 15.873 7.20765 15.8262C7.17375 15.8035 7.15408 15.7755 7.14536 15.747C7.1371 15.7199 7.13329 15.6749 7.16133 15.6074Z"
        fill={props.disabled ? theme.color.neutralPassiveGray : theme.color.neutralNetworkGray}
        stroke={props.disabled ? theme.color.neutralPassiveGray : theme.color.neutralNetworkGray}
        strokeWidth="0.528875"
      />
      <TerribleFeedbackNoStrokePath
        d="M10.5771 8.19792C10.5771 8.47658 10.4309 8.73481 10.192 8.87818L7.54761 10.4648C7.17192 10.6902 6.68462 10.5684 6.4592 10.1927C6.23378 9.81701 6.35561 9.32971 6.7313 9.10429L8.24191 8.19792L6.7313 7.29156C6.35561 7.06614 6.23378 6.57884 6.4592 6.20314C6.68462 5.82744 7.17192 5.70562 7.54762 5.93104L10.192 7.51766C10.4309 7.66103 10.5771 7.91926 10.5771 8.19792Z"
        fill={props.disabled ? theme.color.neutralPassiveGray : theme.color.neutralNetworkGray}
      />
      <TerribleFeedbackNoStrokePath
        d="M13.7505 8.19768C13.7505 7.91902 13.8967 7.66079 14.1356 7.51742L16.78 5.9308C17.1557 5.70538 17.643 5.8272 17.8684 6.2029C18.0939 6.5786 17.972 7.0659 17.5963 7.29132L16.0857 8.19768L17.5963 9.10405C17.972 9.32947 18.0939 9.81677 17.8684 10.1925C17.643 10.5682 17.1557 10.69 16.78 10.4646L14.1356 8.87794C13.8967 8.73457 13.7505 8.47635 13.7505 8.19768Z"
        fill={props.disabled ? theme.color.neutralPassiveGray : theme.color.neutralNetworkGray}
      />
    </SVG>
  )
}

export const feedbackButtonDefaultProps = {
  viewBox: '0 0 24 21',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
  disabled: false,
}

TerribleFeedbackIcon.defaultProps = {
  ...feedbackButtonDefaultProps,
}
