import { KeyboardKeys } from './enums'

const focusableElements = [
  'a[href]',
  'area[href]',
  'input:not([disabled]):not([type=hidden])',
  'select:not([disabled])',
  'textarea:not([disabled])',
  'button:not([disabled])',
  'object',
  'embed',
  '[tabindex="0"]',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]:not([contenteditable="false"])',
]

const getFocusableChildren = (el: Element) => el.querySelectorAll<HTMLElement>(focusableElements.join(', '))

const getFirstFocusableChild = (el: Element) => el.querySelector<HTMLElement>(focusableElements.join(', '))

const getFocusedChild = (el: Element) => {
  try {
    return document.activeElement
  } catch (err) {
    return getFirstFocusableChild(el)
  }
}

const findIndexActiveChild = (list: Element[], e: React.KeyboardEvent) => {
  return list.reduce((res, child, index) => (child.contains(e.target as Node) ? index : res), 0)
}

const handleInfiniteTab = (event: KeyboardEvent, el: HTMLElement): void => {
  if (event.key !== KeyboardKeys.Tab) {
    return
  }

  const focusableChildren = getFocusableChildren(el)
  const totalFocusable = focusableChildren.length
  const currentFocus = getFocusedChild(el)

  let focusedIndex = 0

  for (let i = 0; i < totalFocusable; i += 1) {
    if (focusableChildren[i] === currentFocus) {
      focusedIndex = i
      break
    }
  }

  if (event.shiftKey && focusedIndex === 0) {
    event.preventDefault()
    focusableChildren[totalFocusable - 1].focus()
  } else if (!event.shiftKey && focusedIndex === totalFocusable - 1) {
    event.preventDefault()
    focusableChildren[0].focus()
  }
}

const focusNextItem = (list: Element[], focusedIndex: number) => {
  const isLastItem = focusedIndex + 1 === list.length

  const nextItem = list[isLastItem ? 0 : focusedIndex + 1]
  const nextFocusableItem = getFirstFocusableChild(nextItem) || nextItem

  if (nextFocusableItem && typeof (nextFocusableItem as HTMLElement).focus === 'function') {
    ;(nextFocusableItem as HTMLElement).focus()
  }
}

const focusPreviousItem = (list: Element[], focusedIndex: number) => {
  const isFirstItem = focusedIndex - 1 < 0

  const previousItem = list[isFirstItem ? list.length - 1 : focusedIndex - 1]
  const previousFocusableItem = getFirstFocusableChild(previousItem) || previousItem

  if (previousFocusableItem && typeof (previousFocusableItem as HTMLElement).focus === 'function') {
    ;(previousFocusableItem as HTMLElement).focus()
  }
}

const handleHorizontalArrows = (event: React.KeyboardEvent, list: Element[]): void => {
  const focusedIndex = findIndexActiveChild(list, event)

  if (event.key === KeyboardKeys.ArrowRight) {
    focusNextItem(list, focusedIndex)
  } else if (event.key === KeyboardKeys.ArrowLeft) {
    focusPreviousItem(list, focusedIndex)
  }
}

const handleVerticalArrows = (event: React.KeyboardEvent, list: Element[]): void => {
  if (event.key === KeyboardKeys.ArrowDown || event.key === KeyboardKeys.ArrowUp) {
    event.preventDefault()
    const focusedIndex = findIndexActiveChild(list, event)

    if (event.key === KeyboardKeys.ArrowDown) {
      focusNextItem(list, focusedIndex)
    } else if (event.key === KeyboardKeys.ArrowUp) {
      focusPreviousItem(list, focusedIndex)
    }
  }
}

const handleHomeEnd = (event: React.KeyboardEvent, list: Element[]): void => {
  if (event.key === KeyboardKeys.Home || event.key === KeyboardKeys.End) {
    event.preventDefault()
    const item = event.key === KeyboardKeys.Home ? list[0] : list[list.length - 1]
    const focusableItem = getFirstFocusableChild(item) || item

    if (focusableItem && typeof (focusableItem as HTMLElement).focus === 'function') {
      ;(focusableItem as HTMLElement).focus()
    }
  }
}

const handleCharacterSearch = (event: React.KeyboardEvent, list: Element[]): void => {
  // only valid characters
  if (event.keyCode < 48 || event.keyCode > 90) {
    return
  }

  for (let i = 0; i < list.length; i += 1) {
    const item = list[i]
    const text = item.textContent

    if (text && event.key === text[0].toLowerCase()) {
      const focusableItem = getFirstFocusableChild(item) || item

      if (focusableItem && typeof (focusableItem as HTMLElement).focus === 'function') {
        ;(focusableItem as HTMLElement).focus()
      }
      break
    }
  }
}

const NavKeyboardUtils = {
  focusableElements,
  handleInfiniteTab,
  handleHorizontalArrows,
  handleVerticalArrows,
  handleHomeEnd,
  handleCharacterSearch,
}

export default NavKeyboardUtils
