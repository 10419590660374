import React from 'react'
import PropTypes from 'prop-types'
import { KeyboardKeys } from '../../utils/enums'
import { Label, LabelSize } from '../../design-tokens/typography'
import { Label as BrandLabel, LabelSize as BrandLabelSize, HeadlineSize } from '../../design-tokens/brand-typography'
import {
  LanguageBoxProps,
  LanguageSelectionLabelProps,
  LanguageBoxSubComponents,
  LanguageSelectionSubComponents,
} from './LanguageBox.types'
import {
  Header,
  StyledButton,
  LanguageSelectionContainer,
  LanguageSelectionInner,
  LanguageSelectionBrandLabel,
  LanguageSelectionStyledLabel,
  SelectedLanguage,
  StyledBrandLabelContainer,
} from './LanguageBox.styles'
import { useTheme } from '../../utils/useTheme'
import { LanguageIcon as BrandLanguageIcon } from '../../design-tokens/icons/brand-ui-icons'
import { LanguageIcon } from '../../design-tokens/icons/pictograph'

export const LanguageBox: React.FC<LanguageBoxProps> & LanguageBoxSubComponents = (props) => {
  const { text, selected, disabled, onClick, isBrand, value, ...rest } = props

  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === KeyboardKeys.Enter) {
      e.preventDefault()
    }
  }

  const handleClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    onClick(e, value)
  }

  return (
    <StyledButton
      role="option"
      selected={selected}
      aria-disabled={disabled}
      onClick={handleClick}
      onKeyDown={onKeyDown}
      aria-selected={selected}
      isBrand={isBrand}
      value={value}
      {...rest}
    >
      <Header selected={selected} disabled={disabled}>
        {isBrand ? (
          <BrandLabel size={BrandLabelSize.Four}>{text}</BrandLabel>
        ) : (
          <Label size={LabelSize.Four}>{text}</Label>
        )}
      </Header>
    </StyledButton>
  )
}

LanguageBox.Button = StyledButton
LanguageBox.Header = Header
LanguageBox.BrandLabel = BrandLabel
LanguageBox.Label = Label

export const LanguageSelectionLabel: React.FC<LanguageSelectionLabelProps> & LanguageSelectionSubComponents = ({
  isBrand,
  langGroupLabel,
  children,
  langName,
  ...rest
}) => {
  const theme = useTheme()
  return (
    <LanguageSelectionContainer {...rest} isBrand={isBrand}>
      <LanguageSelectionInner>
        {isBrand ? (
          <>
            <BrandLanguageIcon
              width={`${theme.iconSize.s}rem`}
              height={`${theme.iconSize.s}rem`}
              color={theme.brand.color.gray90}
            />
            <StyledBrandLabelContainer>
              <LanguageSelectionBrandLabel size={BrandLabelSize.Six}>{langGroupLabel}</LanguageSelectionBrandLabel>
              <SelectedLanguage size={BrandLabelSize.Four}>{langName}</SelectedLanguage>
            </StyledBrandLabelContainer>
          </>
        ) : (
          <>
            <LanguageIcon
              width={`${theme.iconSize.s}rem`}
              height={`${theme.iconSize.s}rem`}
              color={theme.color.neutralNetworkGray}
            />
            <LanguageSelectionStyledLabel size={HeadlineSize.Seven}>{langGroupLabel}:</LanguageSelectionStyledLabel>
          </>
        )}
      </LanguageSelectionInner>
      {children}
    </LanguageSelectionContainer>
  )
}

LanguageSelectionLabel.Container = LanguageSelectionContainer
LanguageSelectionLabel.Inner = LanguageSelectionInner
LanguageSelectionLabel.BrandLanguageIcon = BrandLanguageIcon
LanguageSelectionLabel.LanguageIcon = LanguageIcon
LanguageSelectionLabel.BrandLabelContainer = StyledBrandLabelContainer
LanguageSelectionLabel.BrandLabel = LanguageSelectionBrandLabel
LanguageSelectionLabel.Label = LanguageSelectionStyledLabel
LanguageSelectionLabel.SelectedLanguage = SelectedLanguage

LanguageBox.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  hasBackground: PropTypes.bool,
  isBrand: PropTypes.bool,
  value: PropTypes.string,
}

LanguageBox.defaultProps = {
  selected: false,
  disabled: false,
  hasBackground: true,
  isBrand: false,
}

LanguageSelectionLabel.propTypes = {
  langGroupLabel: PropTypes.string.isRequired,
  langName: PropTypes.string,
  isBrand: PropTypes.bool,
}

LanguageSelectionLabel.defaultProps = {
  langGroupLabel: '',
  langName: '',
  isBrand: false,
}
