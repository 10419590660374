import styled, { css } from 'styled-components'
import { Body } from '../../design-tokens/typography/Body/Body'
import {
  TerribleFeedbackIcon,
  BadFeedbackIcon,
  GoodFeedbackIcon,
  OkFeedbackIcon,
  GreatFeedbackIcon,
} from '../../design-tokens/icons/feedback'
import {
  TerribleFeedbackNoStrokePath,
  GreatFeedbackNoStrokePath,
} from '../../design-tokens/icons/feedback/Feedback.styles'
import { Path, Rect } from '../../design-tokens/icons/icons.styled'
import { Label } from '../../design-tokens/brand-typography'

export const FeedbackTerribleIcon = styled(TerribleFeedbackIcon)``
export const FeedbackBadIcon = styled(BadFeedbackIcon)``
export const FeedbackOkIcon = styled(OkFeedbackIcon)``
export const FeedbackGoodIcon = styled(GoodFeedbackIcon)``
export const FeedbackGreatIcon = styled(GreatFeedbackIcon)``

export const StyledBody = styled(Body)`
  ${({ theme: { spacing } }) => css`
    margin: ${spacing.space1}rem 0 0;
    cursor: pointer;
  `}
`

export const StyledLabel = styled(Label)`
  ${({ theme: { spacing, brand } }) => css`
    margin: ${spacing.space2}rem 0 0;
    color: ${brand.color.black};
    cursor: pointer;
  `}
`

export const StyledInnerButtonContainer = styled.div<{ isBrand?: boolean }>(
  ({
    theme: {
      brand: { color },
    },
    isBrand,
  }) => css`
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px ${color.gray10};
    background-color: ${color.white};
    transition: all 100ms ease-in;
    width: ${isBrand ? '3rem' : '3.5rem'};
    height: ${isBrand ? '3rem' : '3.5rem'};
    display: flex;
    align-items: center;
    justify-content: center;
  `
)

export const StyledFeedbackButton = styled.button<{ selected?: boolean; isBrand: boolean }>(
  ({ theme: { brand, spacing, color, borderRadius }, selected, isBrand }) => css`
    display: flex;
    align-items: center;
    background-color: transparent;
    flex-direction: column;
    padding: 0 ${spacing.space1}rem 0.125rem;
    border-radius: ${borderRadius.xs}rem;
    border: ${isBrand ? 'none' : `2px solid transparent`};
    cursor: pointer;
    user-select: none;

    &[aria-disabled='true'] {
      pointer-events: none;
    }

    &:focus {
      outline: none;
      border: ${isBrand ? 'none' : `2px solid ${color.signalBlue}`};
      box-shadow: ${isBrand ? 'none' : `0 0 0.4375rem ${color.signalBlue}`};

      &:not(:focus-visible) {
        box-shadow: none;
        border: ${isBrand ? 'none' : `2px solid transparent`};
      }
    }

    ${FeedbackTerribleIcon},
    ${FeedbackBadIcon},
    ${FeedbackOkIcon},
    ${FeedbackGoodIcon},
    ${FeedbackGreatIcon} {
      path,
      rect {
        transition: all 200ms ease-in 0s;
      }
    }

    ${selected &&
    css`
      ${FeedbackTerribleIcon} {
        ${Path}:first-of-type {
          fill: ${color.brandPeach};
          stroke: none;
        }
      }
      ${FeedbackBadIcon} {
        ${Path}:first-of-type {
          fill: ${color.brandPink};
          stroke: none;
        }
      }
      ${FeedbackOkIcon} {
        ${Path}:first-of-type {
          fill: ${color.brandMutedYellow};
          stroke: none;
        }
      }
      ${FeedbackGoodIcon}, ${FeedbackGreatIcon} {
        ${Path}:first-of-type {
          fill: ${color.brandBrightYellow};
          stroke: none;
        }
      }
    `}

    &[aria-disabled="false"] {
      @media (hover: hover) {
        &:hover {
          ${FeedbackTerribleIcon} {
            ${Path}:first-of-type {
              fill: ${color.brandPeach};
              stroke: none;
            }
          }
          ${FeedbackBadIcon} {
            ${Path}:first-of-type {
              fill: ${color.brandPink};
              stroke: none;
            }
          }
          ${FeedbackOkIcon} {
            ${Path}:first-of-type {
              fill: ${color.brandMutedYellow};
              stroke: none;
            }
          }
          ${FeedbackGoodIcon}, ${FeedbackGreatIcon} {
            ${Path}:first-of-type {
              fill: ${color.brandBrightYellow};
              stroke: none;
            }
          }
          ${FeedbackTerribleIcon}, ${FeedbackBadIcon} {
            ${Path} {
              fill: ${color.brandRed};
              stroke: ${color.brandRed};
            }
          }
          ${FeedbackOkIcon}, ${FeedbackGoodIcon}, ${FeedbackGreatIcon} {
            ${Path}, ${Rect} {
              fill: ${color.brandPurple};
              stroke: ${color.brandPurple};
            }
          }
          ${FeedbackTerribleIcon}, ${FeedbackBadIcon} {
            ${TerribleFeedbackNoStrokePath} {
              fill: ${color.brandRed};
              stroke: none;
            }
          }

          ${FeedbackOkIcon}, ${FeedbackGoodIcon}, ${FeedbackGreatIcon} {
            ${GreatFeedbackNoStrokePath} {
              fill: ${color.brandPurple};
              stroke: none;
            }
          }
        }
      }

      &:focus {
        outline: none;
        ${FeedbackTerribleIcon} {
          ${Path}:first-of-type {
            fill: ${color.brandPeach};
            stroke: none;
          }
        }
        ${FeedbackBadIcon} {
          ${Path}:first-of-type {
            fill: ${color.brandPink};
            stroke: none;
          }
        }
        ${FeedbackOkIcon} {
          ${Path}:first-of-type {
            fill: ${color.brandMutedYellow};
            stroke: none;
          }
        }
        ${FeedbackGoodIcon}, ${FeedbackGreatIcon} {
          ${Path}:first-of-type {
            fill: ${color.brandBrightYellow};
            stroke: none;
          }
        }
      }

      &:active,
      &:focus {
        outline: none;
        ${FeedbackTerribleIcon}, ${FeedbackBadIcon} {
          ${Path} {
            fill: ${color.brandRed};
            stroke: ${color.brandRed};
          }
        }
        ${FeedbackOkIcon}, ${FeedbackGoodIcon}, ${FeedbackGreatIcon} {
          ${Path}, ${Rect} {
            fill: ${color.brandPurple};
            stroke: ${color.brandPurple};
          }
        }
        ${FeedbackTerribleIcon}, ${FeedbackBadIcon} {
          ${TerribleFeedbackNoStrokePath} {
            fill: ${color.brandRed};
            stroke: none;
          }
        }

        ${FeedbackOkIcon}, ${FeedbackGoodIcon}, ${FeedbackGreatIcon} {
          ${GreatFeedbackNoStrokePath} {
            fill: ${color.brandPurple};
            stroke: none;
          }
        }
      }

      &:active {
        ${FeedbackTerribleIcon} {
          ${Path}:first-of-type {
            fill: #e2ac9f;
          }
        }
        ${FeedbackBadIcon} {
          ${Path}:first-of-type {
            fill: #ff9f9f;
          }
        }
        ${FeedbackOkIcon} {
          ${Path}:first-of-type {
            fill: #ffbb38;
          }
        }
        ${FeedbackGoodIcon} {
          ${Path}:first-of-type {
            fill: #ebb018;
          }
        }
        ${FeedbackGreatIcon} {
          ${Path}:first-of-type {
            fill: #e5a502;
          }
        }
      }
    }

    ${isBrand &&
    !selected &&
    css`
      &:not([aria-disabled='true']) {
        @media (hover: hover) {
          &:hover {
            ${StyledInnerButtonContainer} {
              background-color: ${brand.color.gray10};
              box-shadow: inset 0 0 0 1px ${brand.color.gray20};
              ${StyledIcon} path {
                stroke: ${brand.color.gray80};
              }
            }
          }
        }

        &:active:focus:not(:focus-visible) {
          ${StyledInnerButtonContainer} {
            background-color: ${brand.color.gray20};
            box-shadow: inset 0 0 0 1px ${brand.color.gray30};
            ${StyledIcon} path {
              stroke: ${brand.color.gray90};
            }
          }
        }

        &:focus {
          outline: none;
          ${StyledInnerButtonContainer} {
            box-shadow: inset 0 0 0 2px ${brand.color.postiOrange60};
            background-color: ${brand.color.white};
            ${StyledIcon} path {
              stroke: ${brand.color.gray50};
            }
            @media (hover: hover) {
              &:hover {
                background-color: ${brand.color.gray10};
                ${StyledIcon} path {
                  stroke: ${brand.color.gray80};
                }
              }
            }
            &:active {
              background-color: ${brand.color.gray20};
              ${StyledIcon} path {
                stroke: ${brand.color.gray90};
              }
            }
          }
          &:not(:focus-visible) ${StyledInnerButtonContainer} {
            box-shadow: inset 0 0 0 1px ${brand.color.gray10};
          }
        }
      }
    `}

    ${isBrand &&
    selected &&
    css`
      ${StyledInnerButtonContainer} {
        box-shadow: none;
        background-color: ${brand.color.postiOrange60};
        ${StyledIcon} path {
          stroke: ${brand.color.white};
        }
      }
      &:focus ${StyledInnerButtonContainer} {
        box-shadow:
          inset 0 0 0 2px ${brand.color.postiOrange60},
          inset 0 0 0 4px ${brand.color.white};
        background-color: ${brand.color.postiOrange60};
        ${StyledIcon} path {
          stroke: ${brand.color.white};
        }
        @media (hover: hover) {
          &:hover {
            background-color: ${brand.color.postiOrange70};
            box-shadow:
              inset 0 0 0 2px ${brand.color.postiOrange70},
              inset 0 0 0 4px ${brand.color.white};
          }
        }
        &:active {
          background-color: ${brand.color.postiOrange80};
          box-shadow:
            inset 0 0 0 2px ${brand.color.postiOrange80},
            inset 0 0 0 4px ${brand.color.white};
        }
      }

      &:focus:not(:focus-visible) ${StyledInnerButtonContainer} {
        box-shadow: none;
      }
    `}

    ${isBrand &&
    css`
      &[aria-disabled='true'] {
        cursor: default;
        pointer-events: none;
        ${StyledInnerButtonContainer} {
          background-color: ${brand.color.gray10};

          ${StyledIcon} path {
            stroke: ${brand.color.gray30};
          }
        }
        ${StyledLabel} {
          color: ${brand.color.gray30};
          cursor: default;
        }
      }
    `}
  `
)

export const StyledIcon = styled(TerribleFeedbackIcon)<{ disabled: boolean; isBrand: boolean }>`
  ${({ isBrand }) => css`
    width: ${isBrand ? '1.5rem' : '3rem'};
    height: auto;
  `}
`
