import styled, { CSSProperties, css } from 'styled-components'

export const Container = styled.div<{ isBrand?: boolean }>(
  ({ theme: { brand, borderRadius, color, fontSize, spacing, fontWeight }, isBrand }) => css`
    align-items: center;
    color: ${color.neutralWhite};
    position: relative;

    ${isBrand
      ? css`
          display: flex;
          padding: ${brand.spacing.space3}rem ${brand.spacing.space2}rem ${brand.spacing.space3}rem
            ${brand.spacing.space4}rem;
          background-color: ${brand.color.gray80};
          border-radius: ${brand.borderRadius.xs}rem;
          flex-direction: column;

          /** Specify gap between each content section */
          & > ${Content} + ${Content} {
            margin-top: ${spacing.space2}rem;
          }
        `
      : css`
          display: inline-flex;
          justify-content: flex-start;
          padding: ${spacing.space3}rem;
          background-color: ${color.neutralNetworkGray};
          font-size: ${fontSize.label.five}rem;
          border-radius: ${borderRadius.xs}rem;
          color: ${color.neutralWhite};
          font-weight: ${fontWeight.medium};
          font-size: ${fontSize.label.five}rem;
          user-select: none;
        `}
  `
)

export const RightSection = styled.div`
  display: inline-flex;
  align-items: center;
  width: auto;
`

export const LeftSection = styled(RightSection)``

export const CenterSection = styled.div(
  ({
    theme: {
      brand: { color, fontFamily, fontWeight, fontSize },
    },
  }) => css`
    display: block;
    flex: 1;
  `
)

export const Content = styled.div<{ gap?: CSSProperties['marginLeft'] }>(
  ({
    theme: {
      brand: { color, fontFamily, fontWeight, fontSize, spacing },
    },
    gap = `${spacing.space3}rem`,
  }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 2rem;

    /** Text styles */
    font-family: ${fontFamily.PostiFont};
    font-weight: ${fontWeight.medium};
    font-size: ${fontSize.label.five}rem;
    line-height: 1.05rem;
    color: ${color.white};

    & > * + * {
      margin-left: ${gap};
    }
  `
)
