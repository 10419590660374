import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const ArrowDownIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path d="M12 17.3l6.13-6.06L20 13.09 12 21l-8-7.9 1.87-1.86L12 17.3z" fill={color} />
    <Path d="M10.68 18.71V3h2.64v15.71h-2.64z" fill={color} />
  </SVG>
)

ArrowDownIcon.propTypes = defaultIconPropTypes

ArrowDownIcon.defaultProps = defaultPictographIconProps

export { ArrowDownIcon }
