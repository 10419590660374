import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const MenuIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path d="M3 7H21V10H3V7Z" fill={color} />
    <Path d="M3 15H16.2632V18H3V15Z" fill={color} />
  </SVG>
)

MenuIcon.propTypes = defaultIconPropTypes

MenuIcon.defaultProps = defaultPictographIconProps
