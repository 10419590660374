import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const CloseIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M3.05743 18.4281L18.4347 3.00204L21 5.57135L5.62268 20.9974L3.05743 18.4281Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      d="M5.5653 3.00259L20.9426 18.4286L18.3774 20.998L3.00004 5.5719L5.5653 3.00259Z"
      fill={color}
    />
  </SVG>
)

CloseIcon.propTypes = defaultIconPropTypes

CloseIcon.defaultProps = defaultPictographIconProps
