import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const HeadphonesIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M21 13.7248C21.0002 13.0122 20.7839 12.3171 20.381 11.7355C19.978 11.1538 19.4083 10.7143 18.7504 10.4775V9.8945C18.7504 8.06596 18.0393 6.31232 16.7736 5.01935C15.508 3.72638 13.7914 3 12.0014 3C10.2115 3 8.49491 3.72638 7.22924 5.01935C5.96357 6.31232 5.25253 8.06596 5.25253 9.8945V10.4775C4.59391 10.7147 4.02356 11.1547 3.62011 11.7371C3.21666 12.3194 3 13.0152 3 13.7286C3 14.442 3.21666 15.1378 3.62011 15.7202C4.02356 16.3025 4.59391 16.7426 5.25253 16.9797C5.42253 17.0411 5.60449 17.0599 5.78308 17.0344C5.96166 17.0089 6.13163 16.94 6.27866 16.8333C6.42568 16.7267 6.54546 16.5855 6.62789 16.4217C6.71032 16.2578 6.75298 16.0761 6.75229 15.8919V9.8945C6.75229 9.1903 6.88806 8.493 7.15185 7.8424C7.41565 7.19181 7.8023 6.60066 8.28973 6.10272C8.77716 5.60477 9.35582 5.20978 9.99268 4.9403C10.6295 4.67081 11.3121 4.53211 12.0014 4.53211C12.6908 4.53211 13.3734 4.67081 14.0102 4.9403C14.6471 5.20978 15.2257 5.60477 15.7132 6.10272C16.2006 6.60066 16.5872 7.19181 16.851 7.8424C17.1148 8.493 17.2506 9.1903 17.2506 9.8945V15.8919C17.2522 16.0513 17.2864 16.2086 17.351 16.3537C17.4155 16.4988 17.509 16.6286 17.6255 16.7346V17.1697C17.6255 18.2989 16.6574 18.7018 15.7508 18.7018H14.4176C14.2525 18.4097 13.9977 18.1815 13.6927 18.0524C13.3876 17.9233 13.0495 17.9007 12.7306 17.988C12.4117 18.0753 12.1299 18.2676 11.9289 18.5352C11.7279 18.8028 11.619 19.1306 11.619 19.4679C11.619 19.8052 11.619 20.0273 11.619 20.2339C12.244 20.2339 13.9196 20.2339 14.4176 20.2339H15.7508C17.7373 20.2339 19.1253 18.9738 19.1253 17.1697V16.8035C19.6869 16.5189 20.1598 16.0801 20.4909 15.5363C20.822 14.9925 20.9983 14.3652 21 13.7248Z"
      fill={color}
    />
  </SVG>
)

HeadphonesIcon.propTypes = defaultIconPropTypes

HeadphonesIcon.defaultProps = defaultPictographIconProps
