import styled, { css } from 'styled-components'

export const StyledSubMenu = styled.li`
  display: block;
  position: relative;
`

export const StyledSubMenuItem = styled.div``

export const SubMenuContent = styled.ul(
  ({ theme: { spacing } }) => css`
    padding-left: ${spacing.space5}rem;
    list-style-type: none;
  `
)
