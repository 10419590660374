import styled, { css } from 'styled-components'
import { InboxIcon } from '../../xyz'
import { Label, Headline } from '../../design-tokens/typography'
import { Label as BrandLabel } from '../../brand'
import { IconButton } from '../../deprecated/IconButton'
import { getBrandColor, getColor } from './utils'
import { ContentProps, MenuItemContentProps, MenuItemGroupProps } from './MenuItem.types'
import { buttonTypeOption } from '../../utils/enums'
import { BrandButton } from '../BrandButton'

export const StyledMenuItem = styled.li<{ isBrand: boolean }>(
  ({ theme: { spacing } }) => css`
    display: block;
    margin: ${spacing.space1}rem 0;
  `
)

export const StyledMenuItemContent = styled.div<ContentProps>(
  ({ theme: { color, spacing, brand }, selected, disabled, isBrand }) => css`
    display: flex;
    align-items: center;
    position: relative;
    border-radius: 2.5rem;
    cursor: pointer;
    color: ${selected ? color.neutralWhite : color.neutralNetworkGray};
    background-color: ${selected ? color.brandBrightYellow : color.neutralWhite};
    padding: ${spacing.space3}rem;
    user-select: none;

    ${Label}, ${BrandLabel} {
      display: block;
      padding: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      cursor: pointer;
      color: ${isBrand ? getBrandColor({ selected, disabled }) : getColor({ selected, disabled })};

      &:first-child:not(:last-child) {
        margin-bottom: ${spacing.space1}rem;
      }
    }

    ${Headline} {
      margin-bottom: ${spacing.space1}rem;
      &:last-child {
        margin: 0;
      }
    }
    @media (hover: hover) {
      &:hover {
        background-color: ${selected ? color.brandBrightYellow : color.neutralOnHoverGray};
      }
    }

    &:active {
      background-color: ${selected ? color.brandBrightYellow : color.neutralOnPressGray};
    }

    &:focus {
      outline: 0;
      box-shadow: 0px 0px 7px ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
      ${isBrand &&
      selected &&
      `
        outline: 1px solid ${brand.color.postiOrange60};
      `}
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
      outline: none;
    }

    ${disabled &&
    css`
      cursor: default;
      pointer-events: none;
      background-color: ${color.neutralGray5};
    `}
  `
)

export const MenuItemWrapper = styled.div<{ hasIcon: boolean; buttonType: keyof typeof buttonTypeOption }>(
  ({ hasIcon, buttonType }) => css`
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-overflow: ellipsis;
    width: calc(
      100% -
        ${buttonType === buttonTypeOption.bottomArrow || buttonType === buttonTypeOption.rightArrow
          ? '2.125rem'
          : '0rem'} - ${hasIcon ? '1.5rem' : '0rem'}
    );
  `
)

const chevronStyles = css`
  position: absolute;
  right: 1rem;
  margin-left: auto;
`

export const StyledButton = styled(IconButton)<Pick<MenuItemContentProps, 'open'> & { hasSubItems: boolean }>(
  ({ open, hasSubItems }) => css`
    ${hasSubItems && `transform: rotate(${open ? 180 : 0}deg);`}
    ${chevronStyles}
  `
)

export const StyledBrandButton = styled(BrandButton)<Pick<MenuItemContentProps, 'open'> & { hasSubItems: boolean }>(
  ({ theme: { brand }, open, hasSubItems }) => css`
    ${hasSubItems && `transform: rotate(${open ? 180 : 0}deg);`}
    ${chevronStyles}

    &:focus {
      box-shadow: 0px 0px 7px ${brand.color.postiOrange60};
    }
  `
)

export const StyledIcon = styled(InboxIcon)(
  ({ theme: { spacing } }) => css`
    width: ${spacing.space4}rem;
    height: ${spacing.space4}rem;
    flex-shrink: 0;
    margin-right: ${spacing.space3}rem;
  `
)

export const StyledMenuItemGroup = styled.li<MenuItemGroupProps>(
  ({ theme: { spacing } }) => css`
    user-select: none;
    & > ${Headline}, & > ${BrandLabel} {
      padding: ${spacing.space3}rem 0;
      margin: 0;
    }
  `
)

export const StyledMenuItemGroupUL = styled.ul`
  list-style-type: none;
  padding: 0;
`
