import styled, { css } from 'styled-components'
import { ProfileIcon, CheckmarkIcon, CloseIcon } from '../../xyz'
import { IconButton } from '../../deprecated/IconButton'
import { BrandButton } from '../BrandButton'

const invisibleInput = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
  margin: 0;
`

export const StyledIcon = styled(ProfileIcon)`
  ${({ theme: { iconSize, spacing } }) => css`
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
    min-width: ${iconSize.s}rem;
    margin-right: ${spacing.space3}rem;
  `}
`

export const ChevronContainer = styled.div`
  ${({ theme: { iconSize } }) => css`
    svg {
      width: ${iconSize.s}rem;
      height: ${iconSize.s}rem;
      transition: all 100ms ease;
    }
  `}
`

export const StyledControlContainer = styled.div<{
  isSearchable?: boolean
  isMulti: boolean
}>`
  ${({ theme: { spacing }, isSearchable, isMulti }) => css`
    display: flex;
    justify-content: start;
    width: calc(100% - 1.75rem);
    margin-right: ${spacing.space1}rem;
    ${isSearchable &&
    css`
      flex-direction: column;
      .select__value-container {
        .select__input-container {
          display: flex;

          input {
            position: relative;
            opacity: 1 !important;
            grid-area: unset !important;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            min-width: 100% !important;
            visibility: visible;
            ${!isMulti &&
            css`
              width: auto !important;
            `}
          }

          &:after {
            height: 0;
            display: block;
          }
        }
      }
    `}
  `}
`

export const StyledValueContainer = styled.div<{
  isShrinked: boolean
  isSearchable: boolean
  isMulti: boolean
  hasValue: boolean
}>`
  ${({
    theme: {
      brand: { spacing },
    },
    isShrinked,
    isSearchable,
    isMulti,
    hasValue,
  }) => css`
    display: flex;
    min-width: 0;
    align-items: normal;

    ${isSearchable
      ? css`
          padding-top: ${spacing.space5}rem;
          ${isMulti &&
          hasValue &&
          css`
            padding: ${spacing.space10}rem 0 0.875rem;
          `}
        `
      : css`
          overflow: hidden;
          display: block;
          float: left;
          width: 100%;
          position: relative;
          height: 1.5rem;
        `}

    ${!isShrinked &&
    css`
      ${invisibleInput}
    `}
  `}
`
export const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;
  min-width: 0;
`

export const StyledLabel = styled.label<{
  isShrinked: boolean
  isSearchable: boolean
  isBrand: boolean
}>`
  ${({ theme: { lineHeight, color, fontWeight, fontSize, brand }, isShrinked, isSearchable, isBrand }) => css`
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    pointer-events: none;
    line-height: ${lineHeight};
    color: ${isBrand ? brand.color.gray70 : color.neutralNetworkGray};
    height: 1.5rem;
    margin: 0;
    transform: translateY(0px);
    transition:
      font-size 0.2s ease-in-out 0s,
      font-weight 0.2s ease-in-out 0s,
      top 0.2s ease-in-out 0s;
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.label.four}rem;
    user-select: none;

    ${isBrand &&
    css`
      font-family: ${brand.fontFamily.PostiFont};
      font-weight: ${brand.fontWeight.medium};
    `}

    ${isShrinked &&
    css`
      line-height: 154%;
      font-size: ${fontSize.body.five}rem;
      font-weight: ${fontWeight.normal};
      height: 1.25rem;
      cursor: text;
      ${isBrand &&
      css`
        font-weight: ${brand.fontWeight.medium};
      `}
    `}

    ${isSearchable &&
    css`
      position: absolute;
      top: calc(50% - 0.75rem);
    `}

    ${isSearchable &&
    isShrinked &&
    css`
      top: 0.875rem;
    `}
  `}
`

export const StyledButtonContainer = styled.div<{
  isBrand: boolean
}>`
  ${({ theme: { color, spacing, borderRadius, brand }, isBrand }) => css`
    display: flex;
    align-items: center;
    text-align: left;
    width: 100%;
    border-radius: ${borderRadius.sm}rem;
    padding: 0 ${spacing.space4}rem;
    &[aria-disabled='true'] {
      ${StyledLabel} {
        color: ${isBrand ? brand.color.gray30 : color.neutralPassiveGray};
      }
    }
  `}
`

export const StyledChildrenWrapper = styled.span`
  max-width: calc(100% - 1.75rem);
  overflow: hidden;
  text-overflow: ellipsis;
  svg {
    margin-right: 0.25rem;
  }
  span {
    max-width: calc(100% - 1.5rem - 0.25rem);
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    float: right;
  }
`

export const StyledSelectPortal = styled.div<{ isBrand: boolean }>`
  ${({ theme: { spacing, fontFamily, fontSize, fontWeight, color, borderRadius, brand, zIndex }, isBrand }) => css`
    .select {
      &__menu {
        box-shadow: ${isBrand
          ? `0px 0px 7px ${brand.color.postiOrange60}`
          : `rgb(0 0 0 / 6%) 0px 15px 22px, rgb(0 0 0 / 8%) 0px 3px 8px, rgb(0 0 0 / 8%) 0px 1px 1px,
        rgb(0 0 0 / 4%) 0px 3px 1px`};
        filter: drop-shadow(${isBrand ? `${brand.color.postiOrange60}` : 'rgb(0, 81, 194)'} 0px 0px 3px);
        color: ${isBrand ? brand.color.gray90 : color.neutralNetworkGray};
        overflow: hidden auto;
        border-radius: ${borderRadius.sm}rem;
        transform-origin: 0 0;
        width: 100%;
        cursor: pointer;
        z-index: ${zIndex.zIndex3};
      }
      &__menu-list {
        padding: 0;
        overflow-y: auto;
        overflow-x: hidden;
        overflow: -moz-scrollbars-vertical;
        overflow: overlay;
        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 0.5rem;
          background-color: transparent;
        }

        ::-webkit-scrollbar-track {
          margin: ${spacing.space3}rem 0;
          background-color: transparent;
        }

        ::-webkit-scrollbar-thumb {
          background-color: ${isBrand ? brand.color.gray10 : color.neutralPassiveGray};
          background-clip: content-box;
          border-radius: ${borderRadius.sm}rem;
        }
        @media (hover: hover) {
          &:hover {
            ::-webkit-scrollbar-thumb {
              background-color: ${isBrand ? brand.color.gray70 : color.neutralIconGray};
              background-clip: content-box;
              border-radius: ${borderRadius.sm}rem;
            }
          }
        }
        &:focus {
          outline: none;
          ::-webkit-scrollbar,
          ::-webkit-scrollbar-thumb {
            outline: none;
            box-shadow: none;
          }
        }
      }
      &__option {
        font-family: ${fontFamily.Montserrat};
        font-size: ${fontSize.label.four}rem;
        font-weight: ${fontWeight.semiBold};
        color: ${isBrand ? brand.color.gray90 : color.neutralNetworkGray};
        cursor: pointer;
        user-select: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 1.125rem ${spacing.space4}rem;
        background-color: ${color.neutralWhite};

        ${isBrand &&
        css`
          font-family: ${brand.fontFamily.PostiFont};
          font-weight: ${brand.fontWeight.medium};
          font-size: ${brand.fontSize.label.four}rem;
        `}
        @media (hover: hover) {
          &:hover {
            background-color: ${isBrand ? brand.color.postiOrange1 : color.neutralOnHoverGray};
            color: ${isBrand ? brand.color.postiOrange70 : color.signalBlue};
          }
        }
        &:active {
          background-color: ${isBrand ? brand.color.postiOrange1 : color.neutralOnHoverGray};
          color: ${isBrand ? brand.color.postiOrange70 : color.signalBlue};
        }
        &--is-focused {
          outline: 0;
          background-color: ${isBrand ? brand.color.postiOrange1 : color.neutralOnHoverGray};
          ${isBrand &&
          css`
            color: ${brand.color.postiOrange70};
          `}
        }
      }
    }
  `}
`

export const cleanSlateDropdown = `
  box-shadow: none;
  border-color: transparent;
  background: #ffffff;
`

export const StyledSelectContainer = styled(StyledSelectPortal)<{
  hasValue: boolean
  lightBackground: boolean
  isOpen: boolean
  isValid: boolean
  isBrand: boolean
  isSearchable: boolean
}>`
  ${({
    theme: { color, borderRadius, fontFamily, fontSize, fontWeight, brand, spacing },
    hasValue,
    lightBackground,
    isOpen,
    isValid,
    isBrand,
    isSearchable,
  }) => css`
    .select {
      &__single-value,
      &__multi-value__label {
        font-family: ${isBrand ? brand.fontFamily.PostiFont : fontFamily.Montserrat};
        font-size: ${fontSize.label.four}rem;
        font-weight: ${fontWeight.semiBold};
        color: ${isBrand ? brand.color.gray70 : color.neutralNetworkGray};
      }
      &__single-value {
        margin-left: 0;
      }
      &__control {
        border-radius: ${borderRadius.sm}rem;
        outline: none;
        border: 0.1875rem solid
          ${!isValid
            ? isBrand
              ? brand.color.red50
              : color.signalRed
            : isOpen
              ? isBrand
                ? brand.color.postiOrange60
                : color.signalBlue
              : isBrand
                ? lightBackground
                  ? brand.color.white
                  : brand.color.gray1
                : color.neutralGray2};
        box-shadow: ${isBrand
          ? `0 0 0 1px ${isValid ? brand.color.gray10 : 'transparent'}`
          : `0px 2px 0px ${isValid ? color.neutralPassiveGray : 'transparent'}`};
        background: ${isBrand
          ? lightBackground
            ? brand.color.white
            : brand.color.gray1
          : lightBackground
            ? color.neutralWhite
            : color.neutralGray2};
        cursor: pointer;
        outline: none;
        color: ${isBrand ? brand.color.gray70 : color.neutralNetworkGray};
        min-height: 4.5rem;

        @media (hover: hover) {
          &:hover {
            background: ${isBrand ? brand.color.gray10 : color.neutralOnHoverGray};
            border-color: ${isBrand ? brand.color.gray10 : color.neutralOnHoverGray};
            ${!isValid &&
            css`
              border-color: ${color.signalRed};
            `}
            ${isOpen &&
            css`
              border-color: ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
            `}
          }
        }
        &:active {
          background: ${isBrand ? brand.color.gray20 : color.neutralOnHoverGray};
          border-color: ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
        }
        ${isOpen &&
        css`
          background: ${isBrand ? brand.color.gray1 : color.neutralOnHoverGray};
          box-shadow: 0px 2px 0px transparent;
        `}
        &[aria-disabled='true'] {
          border-color: ${isBrand ? brand.color.gray10 : color.neutralGray5};
          background: ${isBrand ? brand.color.gray10 : color.neutralGray5};
          color: ${isBrand ? brand.color.gray30 : color.neutralPassiveGray};
          ${StyledValueContainer} {
            color: ${isBrand ? brand.color.gray30 : color.neutralPassiveGray};
          }
        }
        &--is-focused {
          outline: none;
          border: 0.1875rem solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
          box-shadow: 0px 2px 0px transparent;

          // Safari fix
          &.css-cofman-control {
            border-color: ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
          }

          @media (hover: hover) {
            &:hover {
              border-color: ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
            }
          }
        }
        &--is-disabled {
          .select__single-value,
          .select__multi-value__label {
            color: ${isBrand ? brand.color.gray30 : color.neutralPassiveGray};
          }
        }
        ${hasValue &&
        css`
          ${StyledControlContainer} {
            display: block;
          }
          .select__input-container {
            margin: 0 0.125rem;
            padding: 0;
          }
        `}
        &--menu-is-open {
          ${ChevronContainer} {
            svg {
              transform: rotate(-180deg);
            }
          }
          ${StyledLabel} {
            ${isBrand &&
            css`
              font-weight: ${brand.fontWeight.medium};
            `}
          }
        }
      }
      &__input {
        cursor: pointer;
      }
      &__value-container {
        padding: 0;
        ${isSearchable &&
        css`
          text-overflow: ellipsis;
          white-space: nowrap;
          display: block;
        `}

        .select__input-container {
          margin: 0;
          padding: 0;
          font-family: ${fontFamily.Montserrat};
          font-size: ${fontSize.label.four}rem;
          font-weight: ${fontWeight.semiBold};
          color: ${color.neutralNetworkGray};

          ${isBrand &&
          css`
            font-size: ${brand.fontSize.label.four}rem;
            font-family: ${brand.fontFamily.PostiFont};
            font-weight: ${brand.fontWeight.bold};
            color: ${brand.color.gray70};
          `}

          &[aria-disabled='true'] {
            color: ${isBrand ? brand.color.gray30 : color.neutralPassiveGray};
            /* this style is applied to Safari only */
            @supports (background: - webkit-named-image(i)) {
              input {
                filter: brightness(80%);
              }
            }
          }
        }

        &--is-multi {
          font-family: ${isBrand ? brand.fontFamily.PostiFont : fontFamily.Montserrat};
          font-size: ${fontSize.label.four}rem;
          font-weight: ${fontWeight.semiBold};
          white-space: nowrap;
          overflow: hidden;
          min-width: 0;

          ${isSearchable
            ? css`
                white-space: unset;
                margin-right: ${spacing.space1}rem;

                .select__input-container {
                  display: inline-flex;
                  width: 100%;

                  ${!isOpen &&
                  css`
                    ${invisibleInput}
                  `}
                }
              `
            : css`
                text-overflow: ellipsis;
                display: block;
                width: 100%;

                input {
                  position: absolute;
                }

                .select__input-container {
                  ${invisibleInput}
                  ${hasValue &&
                  css`
                    margin-left: ${spacing.space1}rem;
                  `}
                }
              `}
        }
      }
      &__multi-value {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        display: inline-flex;

        ${!isSearchable &&
        hasValue &&
        css`
          max-width: 100%;
          flex-shrink: 0;
          display: inline;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        `}

        &:first-child {
          .select__multi-value__label {
            padding-left: 0;
          }
        }
        &__label {
          display: inline;
          padding: 0;
          text-overflow: unset;
          overflow: auto;
        }

        ${isSearchable &&
        css`
          max-width: fit-content;
          width: 100%;
          padding: ${brand.spacing.space1}rem ${brand.spacing.space3}rem;
          border-radius: 1.25rem;
          background-color: ${color.neutralOnPressGray};
          align-items: center;
          margin: 0 ${brand.spacing.space1}rem ${brand.spacing.space2}rem 0;
          overflow: hidden;
          position: relative;
          &[aria-disabled='true'] {
            background-color: ${color.neutralGray2};
            label {
              color: ${color.neutralPassiveGray};
            }
          }

          &__remove {
            padding: 0;
            margin-left: ${spacing.space2}rem;
            background-color: transparent;
            @media (hover: hover) {
              &:hover {
                ${!isBrand &&
                css`
                  background-color: ${color.neutralOnPressGray};
                  svg path {
                    fill: ${color.neutralNetworkGray};
                  }
                `}
              }
            }
          }

          &:focus {
            outline: none;
            box-shadow: inset 0px 0px 0px 3px ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
          }

          label {
            color: ${color.neutralNetworkGray};
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            width: 100%;
            line-height: 1.5rem;
            cursor: pointer;
            width: calc(100% - 1rem - 0.5rem);
          }
          &:last-child {
            margin: 0;
          }
        `}
      }
    }
  `}
`

export const StyledCheckItem = styled(CheckmarkIcon)`
  flex-shrink: 0;
`

const closeButtonStyling = css`
  position: absolute;
  top: calc((100% - 2.125rem) / 2);
`

/** This should be cleared up once xyz is removed */
export const CloseIconButton = styled(IconButton)`
  ${({ theme: { spacing } }) => css`
    right: ${spacing.space4}rem;
    ${closeButtonStyling}
  `}
`

export const BrandCloseIconButton = styled(BrandButton)`
  ${({
    theme: {
      brand: { spacing },
    },
  }) => css`
    right: ${spacing.space6}rem;
    ${closeButtonStyling}
  `}
`

export const ControlContainer = styled.div`
  position: relative;
`

export const StyledCloseIcon = styled(CloseIcon)`
  flex-shrink: 0;
`

export const SelectWrapper = styled.div``

export const StyledScreenReaderButton = styled.button`
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
`
