import styled, { css } from 'styled-components'
import { CheckboxProps } from './Checkbox.types'
import { MessageContainer } from '../UIMessage/UIMessage'
import { Position } from '../../utils/enums'

export const StyledBox = styled.div<Pick<CheckboxProps, 'disabled' | 'large'>>`
  display: inline-block;
  position: relative;
  cursor: pointer;
  vertical-align: middle;
  align-self: flex-start;
  &[aria-disabled='true'] {
    cursor: default;
    pointer-events: none;
  }

  ${({ large }) =>
    large
      ? `
    width: 2rem;
    height: 2rem;
    min-width: 2rem;
  `
      : `
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
  `}
`

export const StyledCheck = styled.div(
  ({ theme: { color, borderRadius } }) => css`
    width: 100%;
    height: 100%;
    border-radius: ${borderRadius.xs}rem;
    transition: all 200ms ease-in;
    background-color: ${color.neutralWhite};
    display: flex;
    align-items: center;
    justify-content: center;
  `
)

export const StyledInput = styled.input<Partial<CheckboxProps>>(
  ({ theme: { zIndex, color, brand }, isInvalid, checked, isBrand }) => css`
    top: 0;
    left: 0;
    width: 100%;
    cursor: inherit;
    height: 100%;
    margin: 0;
    opacity: 0;
    padding: 0;
    z-index: ${zIndex.zIndexDefault};
    position: absolute;

    &[aria-disabled='true'] {
      + ${StyledCheck} {
          border: 3px solid ${color.neutralGray5};
          ${
            isBrand &&
            css`
              border: 3px solid ${brand.color.gray30};
            `
          }
      }
      @media (hover: hover) {
            border: 3px solid ${color.neutralGray5};
            ${
              isBrand &&
              css`
                border: 3px solid ${brand.color.gray30};
                background-color: ${brand.color.white};
              `
            }
          }

    }

    &:checked[aria-disabled='true'] + ${StyledCheck} {
      border-color: ${color.neutralGray5};
      background-color: ${color.neutralGray5};
      ${
        isBrand &&
        css`
          border-color: ${brand.color.gray10};
          background-color: ${brand.color.gray10};
        `
      }
    }
  }

    + ${StyledCheck} {
      border: 3px solid ${isInvalid ? color.signalRed : color.neutralIconGray};
      ${
        isBrand &&
        css`
          border: 3px solid ${isInvalid ? brand.color.red60 : brand.color.gray50};
        `
      }
    }
    @media (hover: hover) {
      &:hover + ${StyledCheck} {
        border-color: ${isInvalid ? color.signalRed : color.neutralNetworkGray};
        ${
          isBrand &&
          css`
            border-color: ${isInvalid ? brand.color.red70 : brand.color.gray60};
            background-color: ${brand.color.gray10};
          `
        }
      }
      &:checked:hover + ${StyledCheck} {
        ${
          isBrand &&
          css`
            border-color: ${isInvalid ? brand.color.red70 : brand.color.postiOrange70};
            background-color: ${isInvalid ? brand.color.red70 : brand.color.postiOrange70};
          `
        }
      }
    }

    &:focus:active:not([aria-disabled='true']) + ${StyledCheck} {
      ${
        isBrand &&
        css`
          border-color: ${isInvalid ? brand.color.red70 : brand.color.gray70};
          background-color: ${brand.color.gray20};
        `
      }
    }

    &:checked + ${StyledCheck} {
      border-color: ${color.signalBlue};
      background-color: ${color.signalBlue};
      ${
        isBrand &&
        css`
          border-color: ${isInvalid ? brand.color.red60 : brand.color.postiOrange60};
          background-color: ${isInvalid ? brand.color.red60 : brand.color.postiOrange60};
        `
      }
    }

    &:not([aria-disabled='true']) {
      &:checked:focus:active + ${StyledCheck} {
        ${
          isBrand &&
          css`
            border-color: ${isInvalid ? brand.color.red80 : brand.color.postiOrange80};
            background-color: ${isInvalid ? brand.color.red80 : brand.color.postiOrange80};
          `
        }
      }
  
      &:focus + ${StyledCheck} {
        border-color: ${
          isInvalid && !checked
            ? isBrand
              ? brand.color.red60
              : color.signalRed
            : isBrand
              ? brand.color.postiOrange60
              : color.signalBlue
        };
        ${
          !isBrand
            ? css`
                filter: drop-shadow(0px 0px 7px ${isBrand ? brand.color.postiOrange60 : color.signalBlue});
              `
            : css`
                box-shadow:
                  0 0 0 2px ${brand.color.white},
                  0 0 0 4px ${brand.color.postiOrange60};
              `
        }
      }

      ${
        isBrand &&
        css`
          &:focus:hover + ${StyledCheck} {
            border-color: ${isInvalid && !checked ? brand.color.red60 : brand.color.postiOrange70};
          }

          &:focus:active + ${StyledCheck} {
            border-color: ${isInvalid && !checked ? brand.color.red60 : brand.color.postiOrange80};
          }
        `
      }
  
      &:focus:not(:focus-visible) + ${StyledCheck} {
        outline: none;
        box-shadow: none;
        filter: none;
        ${
          !checked &&
          css`
            border-color: ${isBrand
              ? isInvalid
                ? brand.color.red60
                : brand.color.gray50
              : isInvalid
                ? color.signalRed
                : color.neutralIconGray};
          `
        }
      }
    }
  `
)

export const StyledLabel = styled.label<{
  labelPosition: Position | keyof typeof Position
  isBrand: boolean
}>(
  ({ theme: { brand, spacing }, labelPosition, isBrand }) => css`
    display: flex;
    align-items: center;

    ${labelPosition === Position.left &&
    `
    ${StyledBox} {
      margin-left: ${spacing.space7}rem;
    }
  `}

    ${labelPosition === Position.right &&
    `
    ${StyledBox} {
      margin-right: ${spacing.space3}rem;
    }
    `}
    margin-bottom: 0;

    ${isBrand &&
    css`
      font-family: ${brand.fontFamily.PostiFont};
      line-height: ${brand.lineHeight.label};
      font-weight: ${brand.fontWeight.medium};
      color: ${brand.color.gray80};
      &[aria-disabled='true'] {
        color: ${brand.color.gray30};
      }
    `}
  `
)

export const StyledRoot = styled.div<Pick<CheckboxProps, 'isBrand'>>`
  ${({ isBrand }) => css`
    width: fit-content;
    ${MessageContainer} {
      ${isBrand &&
      css`
        padding: 0;
      `}
    }
  `}
`
