import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, Rect, SVG } from '../brand-icons.styled'

const PostiLockersIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Rect x="3" y="4" width="18" height="16" rx="2" strokeLinecap="butt" strokeLinejoin="miter" />
    <Path d="M21 15H10" strokeLinecap="butt" />
    <Path d="M10 10H3" strokeLinecap="butt" />
    <Path d="M9 19L9 5" />
    <Path
      d="M13.5 7H15.5C16.3284 7 17 7.67157 17 8.5V8.5C17 9.32843 16.3284 10 15.5 10H13.5V7Z"
      strokeLinecap="butt"
      strokeLinejoin="miter"
    />
    <Path d="M13.5 7V13" strokeLinecap="butt" strokeLinejoin="miter" />
  </SVG>
)

PostiLockersIcon.propTypes = defaultIconPropTypes
PostiLockersIcon.defaultProps = defaultPictographIconProps

export { PostiLockersIcon }
