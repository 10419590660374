import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const ProfileIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M11.9211 12.8325C14.6389 12.8325 16.8421 10.6314 16.8421 7.91623C16.8421 5.20107 14.6389 3 11.9211 3C9.20323 3 7 5.20107 7 7.91623C7 10.6314 9.20323 12.8325 11.9211 12.8325Z"
      fill={color}
    />
    <Path
      d="M12 14C9.6139 14.002 7.32632 14.7033 5.63909 15.95C3.95186 17.1967 3.00276 18.8869 3 20.65C3 20.7428 2.99998 20.825 3.00004 21C3.11842 21 3.34806 21 3.47369 21H20.5263C20.6519 21 20.9001 21 21 21C21 20.825 21 20.7428 21 20.65C20.9972 18.8869 20.0481 17.1967 18.3609 15.95C16.6737 14.7033 14.3861 14.002 12 14Z"
      fill={color}
    />
  </SVG>
)

ProfileIcon.propTypes = defaultIconPropTypes

ProfileIcon.defaultProps = defaultPictographIconProps

export { ProfileIcon }
