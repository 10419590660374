import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { useTheme } from '../../utils/useTheme'
import { StyledIndicatorStep, StyledContainer, StyledLabel } from './IndicatorStep.styles'
import { CheckmarkIcon } from '../../design-tokens/icons/pictograph'
import { WarningIndicatorIcon } from '../../design-tokens/icons/indicator'
import { Tooltip } from '../Tooltip'
import { StepStateValues, Size, LabelDirections, Placement } from '../../utils/enums'
import { IndicatorStepProps, IndicatorStepSubComponents } from './IndicatorStep.types'

export const IndicatorStep: React.FC<IndicatorStepProps> & IndicatorStepSubComponents = ({
  stepState,
  stepSize,
  vertical,
  tooltipTitle,
  tooltipId,
  tooltipPlacement,
  highlightColor,
  labelDirection,
  children,
  ...rest
}) => {
  const theme = useTheme()

  const indicatorStep = (
    <StyledIndicatorStep
      stepState={stepState}
      stepSize={stepSize ? stepSize : Size.sm}
      vertical={vertical}
      aria-labelledby={rest['id']}
      highlightColor={highlightColor}
    >
      {stepState === StepStateValues.completed && (
        <CheckmarkIcon width="0.625rem" height="0.625rem" color={theme.color.neutralWhite} />
      )}
      {stepState === StepStateValues.warning && (
        <WarningIndicatorIcon width="1rem" height="1rem" color={theme.color.neutralWhite} />
      )}
    </StyledIndicatorStep>
  )

  return (
    <StyledContainer
      id={rest['id']}
      stepSize={stepSize}
      stepState={stepState}
      vertical={vertical}
      data-design-system-id="indicator-step"
      highlightColor={highlightColor}
      labelDirection={labelDirection ? labelDirection : LabelDirections.left}
    >
      {tooltipTitle ? (
        <Tooltip id={tooltipId} title={tooltipTitle} placement={tooltipPlacement}>
          {indicatorStep}
        </Tooltip>
      ) : (
        indicatorStep
      )}
      {!!children && <StyledLabel>{children}</StyledLabel>}
    </StyledContainer>
  )
}

IndicatorStep.Container = StyledContainer
IndicatorStep.Tooltip = Tooltip
IndicatorStep.Label = StyledLabel
IndicatorStep.IndicatorStep = StyledIndicatorStep
IndicatorStep.CheckmarkIcon = CheckmarkIcon
IndicatorStep.WarningIndicatorIcon = WarningIndicatorIcon

IndicatorStep.propTypes = {
  id: PropTypes.string,
  stepState: PropTypes.oneOf(Object.keys(StepStateValues)) as Validator<StepStateValues>,
  stepSize: PropTypes.oneOf(Object.keys(Size)) as Validator<Size>,
  vertical: PropTypes.bool,
  optional: PropTypes.bool,
  tooltipId: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf(Object.keys(Placement)) as Validator<Placement>,
  highlightColor: PropTypes.string,
  labelDirection: PropTypes.oneOf(Object.values(LabelDirections)) as Validator<LabelDirections>,
}

IndicatorStep.defaultProps = {
  optional: false,
  vertical: false,
  stepSize: Size.sm,
}
