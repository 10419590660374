import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const ArrowUpIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M12 20V4M12 4L6 10M12 4L18 10" />
  </SVG>
)

ArrowUpIcon.propTypes = defaultIconPropTypes
ArrowUpIcon.defaultProps = defaultPictographIconProps

export { ArrowUpIcon }
