import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Circle, SVG } from '../icons.styled'

export const NumPadIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Circle cx="6" cy="3" r="2" fill={color} />
    <Circle cx="12" cy="3" r="2" fill={color} />
    <Circle cx="18" cy="3" r="2" fill={color} />
    <Circle cx="6" cy="9" r="2" fill={color} />
    <Circle cx="12" cy="9" r="2" fill={color} />
    <Circle cx="18" cy="9" r="2" fill={color} />
    <Circle cx="6" cy="15" r="2" fill={color} />
    <Circle cx="12" cy="15" r="2" fill={color} />
    <Circle cx="18" cy="15" r="2" fill={color} />
    <Circle cx="12" cy="21" r="2" fill={color} />
  </SVG>
)

NumPadIcon.propTypes = defaultIconPropTypes

NumPadIcon.defaultProps = defaultPictographIconProps
