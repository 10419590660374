import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const PinIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M12 21C12.1138 21 12.2242 20.9601 12.313 20.8869C12.607 20.6431 19.5 14.8893 19.5 10.7143C19.5 8.66833 18.7098 6.70617 17.3033 5.25946C15.8968 3.81275 13.9891 3 12 3C10.0109 3 8.10322 3.81275 6.6967 5.25946C5.29018 6.70617 4.5 8.66833 4.5 10.7143C4.5 14.8893 11.393 20.6431 11.687 20.8869C11.7758 20.9601 11.8862 21 12 21ZM12 7.62857C12.4945 7.62857 12.9778 7.77938 13.3889 8.06194C13.8 8.34449 14.1205 8.74609 14.3097 9.21596C14.4989 9.68582 14.5484 10.2029 14.452 10.7017C14.3555 11.2005 14.1174 11.6587 13.7678 12.0183C13.4181 12.3779 12.9727 12.6228 12.4877 12.722C12.0028 12.8212 11.5001 12.7703 11.0433 12.5757C10.5865 12.3811 10.196 12.0515 9.92133 11.6286C9.64662 11.2057 9.5 10.7086 9.5 10.2C9.5 9.51801 9.76339 8.86396 10.2322 8.38173C10.7011 7.89949 11.337 7.62857 12 7.62857Z"
      fill={color}
    />
  </SVG>
)

PinIcon.propTypes = defaultIconPropTypes

PinIcon.defaultProps = defaultPictographIconProps
