import styled, { css } from 'styled-components'
import { Dropdown } from '../../basic-components/Dropdown'
import { cleanSlateDropdown } from '../Dropdown/Dropdown.styles'

export const NavDropdown = styled(Dropdown)`
  ${({ theme: { brand, spacing, color }, isBrand }) => css`
    display: inline-block;
    position: relative;
    height: 100%;

    .styled-label.shrinked {
      display: none;
    }
    .select {
      &__option {
        padding: ${spacing.space3}rem;
        span {
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &__control {
        ${cleanSlateDropdown}
        border-radius: 0;
        &--is-focused {
          border: 0.1875rem solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
          @media (hover: hover) {
            &:hover {
              border: 0.1875rem solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
            }
          }
        }
      }
    }
  `}
`
