import styled, { css } from 'styled-components'
import { BrandTheme } from '@postidigital/posti-theme'
import { BrandButtonProps, InnerContentProps } from './BrandButton.types'
import { ArrowRightIcon } from '../../design-tokens/icons/pictograph'
import { Label } from '../../design-tokens/brand-typography'
import { innerCommonStyles, buttonCommonStyles, StyledLoadingIcon } from '../Button/Button.styles'
import { BrandSize, BrandMode, Position } from '../../utils/enums'

export const brandButtonColors = (hasBackground: boolean) => {
  return {
    primary: {
      color: hasBackground ? BrandTheme.color.white : BrandTheme.color.postiOrange60,
      background: hasBackground ? BrandTheme.color.postiOrange60 : 'transparent',
      backgroundHover: hasBackground ? BrandTheme.color.postiOrange70 : BrandTheme.color.postiOrange5,
      backgroundPress: hasBackground ? BrandTheme.color.postiOrange80 : BrandTheme.color.postiOrange10,
      backgroundDisabled: hasBackground ? BrandTheme.color.gray10 : 'transparent',
      backgroundFocus: hasBackground ? BrandTheme.color.postiOrange60 : 'transparent',
    },
    secondary: {
      color: BrandTheme.color.gray70,
      background: hasBackground ? BrandTheme.color.white : 'transparent',
      backgroundHover: BrandTheme.color.gray10,
      backgroundPress: BrandTheme.color.gray20,
      backgroundDisabled: hasBackground ? BrandTheme.color.gray10 : 'transparent',
      backgroundFocus: hasBackground ? BrandTheme.color.white : 'transparent',
    },
    tertiary: {
      color: BrandTheme.color.gray70,
      background: 'transparent',
      backgroundHover: BrandTheme.color.gray10,
      backgroundPress: BrandTheme.color.gray20,
      backgroundDisabled: 'transparent',
      backgroundFocus: 'transparent',
    },
    negativeActionButton: {
      color: BrandTheme.color.postiOrange40,
      background: 'transparent',
      backgroundHover: BrandTheme.color.gray90,
      backgroundPress: BrandTheme.color.gray99,
      backgroundDisabled: 'transparent',
      backgroundFocus: 'transparent',
    },
    negativeCloseButton: {
      color: BrandTheme.color.white,
      background: 'transparent',
      backgroundHover: BrandTheme.color.gray90,
      backgroundPress: BrandTheme.color.gray99,
      backgroundDisabled: 'transparent',
      backgroundFocus: 'transparent',
    },
  }
}

const brandPadding = {
  [BrandSize.xl]: `${BrandTheme.spacing.space6}rem ${BrandTheme.spacing.space8}rem`,
  [BrandSize.lg]: `${BrandTheme.spacing.space5}rem ${BrandTheme.spacing.space8}rem`,
  [BrandSize.md]: `1.125rem ${BrandTheme.spacing.space7}rem`,
  [BrandSize.sm]: `0.875rem ${BrandTheme.spacing.space5}rem`,
  [BrandSize.xs]: `0.875rem ${BrandTheme.spacing.space5}rem`,
  [BrandSize.xxs]: `${BrandTheme.spacing.space3}rem ${BrandTheme.spacing.space4}rem`,
  [BrandSize.xxxs]: `0.625rem ${BrandTheme.spacing.space4}rem`,
}

const brandIconOnlyPadding = {
  [BrandSize.xl]: `${BrandTheme.spacing.space6}rem`,
  [BrandSize.lg]: `${BrandTheme.spacing.space5}rem`,
  [BrandSize.md]: `${BrandTheme.spacing.space4}rem`,
  [BrandSize.sm]: `${BrandTheme.spacing.space3}rem`,
  [BrandSize.xs]: `${BrandTheme.spacing.space3}rem`,
  [BrandSize.xxs]: '0.625rem',
  [BrandSize.xxxs]: `0.4375rem`,
}

export const brandFontSize = {
  [BrandSize.xl]: `${BrandTheme.fontSize.label.one}rem`,
  [BrandSize.lg]: `${BrandTheme.fontSize.label.two}rem`,
  [BrandSize.md]: `${BrandTheme.fontSize.label.three}rem`,
  [BrandSize.sm]: `${BrandTheme.fontSize.label.three}rem`,
  [BrandSize.xs]: `${BrandTheme.fontSize.label.four}rem`,
  [BrandSize.xxs]: `${BrandTheme.fontSize.label.five}rem`,
  [BrandSize.xxxs]: `${BrandTheme.fontSize.label.six}rem`,
}

const heights = {
  [BrandSize.xl]: `4.5rem`,
  [BrandSize.lg]: `4rem`,
  [BrandSize.md]: `3.5rem`,
  [BrandSize.sm]: `3rem`,
  [BrandSize.xs]: `2.75rem`,
  [BrandSize.xxs]: `2.5rem`,
  [BrandSize.xxxs]: `2rem`,
}

export const StyledBrandButton = styled.button<BrandButtonProps & { isOnlyIcon: boolean }>(
  ({
    theme: {
      brand: { color },
    },
    contentMode,
    size,
    hasBackground,
    isOnlyIcon,
  }) => css`
    ${buttonCommonStyles}
    border: none;
    color: ${brandButtonColors(hasBackground)[contentMode].color};
    background-color: ${brandButtonColors(hasBackground)[contentMode].background};
    padding: ${isOnlyIcon ? brandIconOnlyPadding[size] : brandPadding[size]};
    min-height: ${heights[size]};

    &:has(${StyledBrandInner} svg:only-child) {
      padding: ${brandIconOnlyPadding[size]};
    }

    ${contentMode === BrandMode.primary &&
    !hasBackground &&
    css`
      box-shadow: inset 0 0 0 2px ${color.postiOrange60};
    `}

    ${contentMode === BrandMode.secondary &&
    css`
      ${hasBackground
        ? css`
            box-shadow: inset 0 0 0 1px ${color.gray10};
          `
        : css`
            box-shadow: inset 0 0 0 2px ${color.gray10};
          `}
    `}

    @media (hover: hover) {
      &:hover {
        background-color: ${brandButtonColors(hasBackground)[contentMode].backgroundHover};
        ${contentMode === BrandMode.primary &&
        !hasBackground &&
        css`
          box-shadow: inset 0 0 0 2px ${color.postiOrange70};
          color: ${color.postiOrange70};
          svg:not([role='status']) * {
            stroke: ${color.postiOrange70};
          }
        `}

        ${contentMode === BrandMode.secondary &&
        css`
          box-shadow: inset 0 0 0 1px ${color.gray20};
        `}

        ${(contentMode === BrandMode.secondary || contentMode === BrandMode.tertiary) &&
        css`
          color: ${color.gray80};
          svg:not([role='status']) * {
            stroke: ${color.gray80};
          }
        `}
        
        ${contentMode === BrandMode.negativeActionButton &&
        css`
          svg:not([role='status']) * {
            stroke: ${brandButtonColors(hasBackground).negativeActionButton.color};
          }
        `}

        ${contentMode === BrandMode.negativeCloseButton &&
        css`
          svg:not([role='status']) * {
            stroke: ${brandButtonColors(hasBackground).negativeCloseButton.color};
          }
        `}
      }
    }

    &:active {
      outline: none;
      background-color: ${brandButtonColors(hasBackground)[contentMode].backgroundPress};

      ${contentMode === BrandMode.primary &&
      !hasBackground &&
      css`
        box-shadow: inset 0 0 0 2px ${color.postiOrange80};
        color: ${color.postiOrange80};
        svg:not([role='status']) * {
          stroke: ${color.postiOrange80};
        }
      `}
      ${contentMode === BrandMode.secondary &&
      css`
        ${hasBackground
          ? css`
              box-shadow: inset 0 0 0 1px ${color.gray30};
            `
          : css`
              box-shadow: inset 0 0 0 2px ${color.gray30};
            `}
      `}
      ${(contentMode === BrandMode.secondary || contentMode === BrandMode.tertiary) &&
      css`
        color: ${color.gray90};
        svg:not([role='status']) * {
          stroke: ${color.gray90};
        }
      `}
      ${contentMode === BrandMode.negativeActionButton &&
      css`
        svg:not([role='status']) * {
          stroke: ${brandButtonColors(hasBackground).negativeActionButton.color};
        }
      `}

        ${contentMode === BrandMode.negativeCloseButton &&
      css`
        svg:not([role='status']) * {
          stroke: ${brandButtonColors(hasBackground).negativeCloseButton.color};
        }
      `}
    }

    &:focus {
      outline: none;
      background-color: ${brandButtonColors(hasBackground)[contentMode].backgroundFocus};

      ${contentMode === BrandMode.primary &&
      css`
        ${hasBackground
          ? css`
              box-shadow:
                inset 0 0 0 2px ${color.postiOrange60},
                inset 0 0 0 4px ${color.white};
              @media (hover: hover) {
                &:hover {
                  background-color: ${color.postiOrange70};
                  box-shadow:
                    inset 0 0 0 2px ${color.postiOrange70},
                    inset 0 0 0 4px ${color.white};
                }
              }
              &:active {
                background-color: ${color.postiOrange80};
                box-shadow:
                  inset 0 0 0 2px ${color.postiOrange80},
                  inset 0 0 0 4px ${color.white};
              }
            `
          : css`
              box-shadow: inset 0 0 0 4px ${color.postiOrange60};
              @media (hover: hover) {
                &:hover {
                  box-shadow: inset 0 0 0 4px ${color.postiOrange60};
                  background-color: ${color.postiOrange5};
                  color: ${color.postiOrange70};
                  svg:not([role='status']) * {
                    stroke: ${color.postiOrange70};
                  }
                }
              }
              &:active {
                box-shadow: inset 0 0 0 4px ${color.postiOrange60};
                background-color: ${color.postiOrange10};
                color: ${color.postiOrange80};
                svg:not([role='status']) * {
                  stroke: ${color.postiOrange80};
                }
              }
            `}
      `}

      &[aria-disabled='true'] {
        box-shadow: none;
        outline: none;
      }

      ${(contentMode === BrandMode.secondary || contentMode === BrandMode.tertiary) &&
      css`
        box-shadow: inset 0 0 0 2px ${color.postiOrange60};

        @media (hover: hover) {
          &:hover {
            background-color: ${color.gray10};
          }
        }
        &:active {
          background-color: ${color.gray20};
        }
      `}

      ${contentMode === BrandMode.negativeActionButton &&
      css`
        box-shadow: inset 0 0 0 2px ${color.postiOrange40};
        @media (hover: hover) {
          &:hover {
            background-color: ${color.gray90};
            color: ${color.postiOrange40};
            svg:not([role='status']) * {
              stroke: ${color.postiOrange40};
            }
          }
        }
        &:active {
          background-color: ${color.gray99};
          color: ${color.postiOrange40};
          svg:not([role='status']) * {
            stroke: ${color.postiOrange40};
          }
        }
      `}

        ${contentMode === BrandMode.negativeCloseButton &&
      css`
        box-shadow: inset 0 0 0 2px ${color.postiOrange60};
        @media (hover: hover) {
          &:hover {
            background-color: ${color.gray90};
            svg:not([role='status']) * {
              stroke: ${color.white};
            }
          }
        }
        &:active {
          background-color: ${color.gray99};
          svg:not([role='status']) * {
            stroke: ${color.white};
          }
        }
      `}

      &:not(:focus-visible) {
        ${contentMode === BrandMode.primary &&
        css`
          ${hasBackground
            ? css`
                box-shadow: none;
              `
            : css`
                box-shadow: inset 0 0 0 2px ${color.postiOrange60};
                &:active {
                  box-shadow: inset 0 0 0 2px ${color.postiOrange80};
                }
              `}
        `}

        ${contentMode === BrandMode.secondary &&
        css`
          ${hasBackground
            ? css`
                box-shadow: inset 0 0 0 1px ${color.gray10};
                @media (hover: hover) {
                  &:hover {
                    box-shadow: inset 0 0 0 1px ${color.gray20};
                  }
                }
                &:active {
                  box-shadow: inset 0 0 0 1px ${color.gray30};
                }
              `
            : css`
                box-shadow: inset 0 0 0 2px ${color.gray10};
                @media (hover: hover) {
                  &:hover {
                    box-shadow: inset 0 0 0 2px ${color.gray20};
                  }
                }
                &:active {
                  box-shadow: inset 0 0 0 2px ${color.gray30};
                }
              `}
        `}
        ${(contentMode === BrandMode.tertiary ||
          contentMode === BrandMode.negativeActionButton ||
          contentMode === BrandMode.negativeCloseButton) &&
        css`
          box-shadow: none;
        `}
      }
    }

    &[aria-disabled='true'] {
      cursor: default;
      pointer-events: none;
      color: ${color.gray30};
      background-color: ${brandButtonColors(hasBackground)[contentMode].backgroundDisabled};

      svg * {
        stroke: ${color.gray30};
      }
      ${(contentMode === BrandMode.primary || contentMode === BrandMode.secondary) &&
      !hasBackground &&
      css`
        box-shadow: inset 0 0 0 2px ${color.gray5};
      `}
    }

    ${StyledLoadingIcon} {
      ${(size === BrandSize.xs || size === BrandSize.xxs) &&
      css`
        width: 1.25rem;
        height: 1.25rem;
      `}
      ${size === BrandSize.xxxs &&
      css`
        width: 1.125rem;
        height: 1.125rem;
      `}
    }
  `
)

export const StyledBrandInner = styled.span<InnerContentProps>`
  ${({
    theme: {
      brand: { fontFamily, fontWeight },
    },
    size,
  }) => css`
    ${innerCommonStyles}
    font-family: ${fontFamily.PostiFont};
    font-weight: ${fontWeight.bold};
    font-size: ${brandFontSize[size]};
    line-height: 1;
  `}
`

export type IconProps = Pick<BrandButtonProps, 'iconPosition' | 'size'> & {
  isOnlyIcon: boolean
}

export const StyledBrandIcon = styled(ArrowRightIcon)<IconProps>`
  ${({ theme: { iconSize, spacing }, iconPosition, isOnlyIcon, size }) => css`
    flex-shrink: 0;
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;

    ${(size === BrandSize.xs || size === BrandSize.xxs) &&
    css`
      width: 1.25rem;
      height: 1.25rem;
    `}

    ${size === BrandSize.xxxs &&
    css`
      width: 1.125rem;
      height: 1.125rem;
    `}

    ${!isOnlyIcon &&
    css`
      ${iconPosition === Position.left &&
      css`
        margin-right: ${spacing.space2}rem;
      `}
      ${iconPosition === Position.right &&
      css`
        margin-left: ${spacing.space2}rem;
      `}
    `}
  `}
`

export const StyledLabel = styled(Label)<{ disabled: boolean }>`
  ${({ theme: { spacing, brand }, disabled }) => css`
    margin-top: ${spacing.space2}rem;
    color: ${brand.color.black};
    ${disabled &&
    css`
      color: ${brand.color.gray30};
      cursor: default;
      pointer-events: none;
    `}
  `}
`
