import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const PrintIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M14.25 3L17 5.87V7.7H7.83V3.96c0-.26.1-.5.27-.68A.9.9 0 018.75 3h5.5zM4.5 10.04c0-.86.75-1.56 1.67-1.56h11.66c.92 0 1.67.7 1.67 1.56v3.13c0 .87-.75 1.57-1.67 1.57h-.54l.96 4.38c.22.97-.57 1.88-1.63 1.88H7.48c-1.08 0-1.88-.97-1.6-1.96l1.17-4.3h-.88c-.92 0-1.67-.7-1.67-1.57v-3.13zm3.33 1.57c0 .43-.37.78-.83.78-.46 0-.83-.35-.83-.78 0-.43.37-.78.83-.78.46 0 .83.35.83.78zm.94 3.13h6.82l1.03 4.7H7.48l1.3-4.7z"
      fill={color}
    />
    <Path
      d="M9.5 16.1c0-.32.28-.58.63-.58h4.58c.34 0 .62.26.62.59 0 .32-.28.59-.62.59h-4.58c-.35 0-.63-.27-.63-.6zM9.5 18.07c0-.33.28-.6.63-.6h2.91c.35 0 .63.27.63.6 0 .32-.28.58-.63.58h-2.91c-.35 0-.63-.26-.63-.58z"
      fill={color}
    />
  </SVG>
)

PrintIcon.propTypes = defaultIconPropTypes

PrintIcon.defaultProps = defaultPictographIconProps

export { PrintIcon }
