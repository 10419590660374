import styled, { css } from 'styled-components'
import { Button } from '../Button/Button'
import { BrandButton } from '../BrandButton/BrandButton'

export const StyledFile = styled.li.attrs({ 'aria-live': 'polite' })<{ isDisabled: boolean; isBrand: boolean }>`
  ${({ theme: { brand, color }, isDisabled, isBrand }) => css`
    border-bottom: 0.0625rem solid ${isBrand ? brand.color.gray10 : color.neutralGray5};
    color: ${isBrand ? brand.color.gray70 : isDisabled ? color.neutralGray5 : color.neutralNetworkGray};
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: ${isBrand ? '0.5rem' : '0.15625rem'} 0;
    word-wrap: anywhere;
  `}
`

export const StyledButton = styled(Button)`
  ${({ theme: { color } }) => css`
    flex-shrink: 0;
    color: ${color.neutralIconGray};
    pointer-events: auto;
  `}
`

export const StyledBrandButton = styled(BrandButton)<{ hasLabel: boolean }>`
  ${({ theme: { breakpoint, spacing }, hasLabel }) => css`
    @media screen and (min-width: ${breakpoint.md}) {
      ${hasLabel &&
      css`
        padding: 0.875rem ${spacing.space4}rem;
        svg {
          margin-right: ${spacing.space2}rem;
        }
      `}
    }
  `}
`

export const LeftContainer = styled.div`
  ${({ theme: { iconSize, spacing } }) => css`
    svg {
      width: ${iconSize.s}rem;
      height: ${iconSize.s}rem;
      margin-right: ${spacing.space2}rem;
    }
  `}
`

export const RightContainer = styled.div<{ isBrand: boolean }>`
  ${({ theme: { spacing }, isBrand }) => css`
    align-self: flex-end;
    display: flex;
    align-items: center;

    height: ${isBrand ? '3.25rem' : '2.375rem'};

    & > span {
      margin-left: ${spacing.space2}rem;
    }
  `}
`
