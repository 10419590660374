import React, { ButtonHTMLAttributes } from 'react'
import PropTypes, { Validator } from 'prop-types'
import {
  StyledFeedbackButton,
  StyledIcon,
  StyledBody,
  FeedbackTerribleIcon,
  StyledLabel,
  StyledInnerButtonContainer,
} from './FeedbackButton.styles'
import { BodySize, BodyProps } from '../../design-tokens/typography/Body/Body'
import { LabelSize, LabelProps } from '../../design-tokens/brand-typography'
import { StyledComponent, DefaultTheme } from 'styled-components'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { FeedbackIconProps } from '../../design-tokens/icons/feedback/TerribleFeedbackIcon'

export interface FeedbackButtonProps extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  label: string
  icon:
    | StyledComponent<typeof FeedbackTerribleIcon, DefaultTheme, object, never>
    | React.ComponentType<DefaultIconProps>
  selected?: boolean
  value?: string | number
  onClick?(e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>, value?: string | number): void
  isBrand?: boolean
}

interface FeedbackButtonSubComponents {
  Button: StyledComponent<'button', DefaultTheme, { selected?: boolean; isBrand: boolean }, never>
  Inner: StyledComponent<'div', DefaultTheme, NonNullable<unknown>, never>
  Label: StyledComponent<'label', DefaultTheme, LabelProps, never>
  Body: StyledComponent<'p', DefaultTheme, BodyProps, never>
  Icon: StyledComponent<
    {
      (props: FeedbackIconProps): JSX.Element
      defaultProps: {
        viewBox: string
        height: string
        width: string
        disabled: boolean
      }
    },
    DefaultTheme,
    {
      disabled: boolean
      isBrand: boolean
    },
    never
  >
}

export const FeedbackButton = ({
  icon: Icon,
  label,
  disabled,
  value,
  selected,
  onClick,
  isBrand,
  ...rest
}: FeedbackButtonProps): JSX.Element => {
  const handleClick = (e: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
    onClick(e, value)
  }

  return (
    <StyledFeedbackButton
      isBrand={isBrand}
      aria-disabled={disabled}
      onClick={handleClick}
      aria-pressed={selected}
      selected={selected}
      {...rest}
    >
      {isBrand ? (
        <>
          <StyledInnerButtonContainer isBrand={isBrand}>
            {!!Icon && <StyledIcon isBrand as={Icon} aria-hidden={true} aria-disabled={disabled} />}
          </StyledInnerButtonContainer>
          <StyledLabel size={LabelSize.Five}>{label}</StyledLabel>
        </>
      ) : (
        <>
          {!!Icon && <StyledIcon as={Icon} aria-hidden={true} disabled={disabled} aria-disabled={disabled} />}
          <StyledBody size={BodySize.Five}>{label}</StyledBody>
        </>
      )}
    </StyledFeedbackButton>
  )
}

FeedbackButton.Icon = StyledIcon
FeedbackButton.Button = StyledFeedbackButton
FeedbackButton.Inner = StyledInnerButtonContainer
FeedbackButton.Label = StyledLabel
FeedbackButton.Body = StyledBody

FeedbackButton.propTypes = {
  isBrand: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.elementType as Validator<
    React.ComponentType<StyledComponent<typeof FeedbackTerribleIcon, DefaultTheme, object, never>>
  >,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
}

FeedbackButton.defaultProps = {
  isBrand: false,
  selected: false,
}
