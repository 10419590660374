import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { LinkSize } from '../../utils/enums'
import { StyledIcon, StyledLink } from './Link.styles'
import { LinkProps, LinkSubComponents } from './Link.types'
import { Icon } from '../../deprecated/OldNavDropdown/OldNavDropdown.styles'

export const Link: React.FC<LinkProps> & LinkSubComponents = (props) => {
  const { icon: Icon, disabled, children, isBrand, size, ...rest } = props

  return (
    <StyledLink aria-disabled={disabled} disabled={disabled} isBrand={isBrand} size={size ?? LinkSize.sm} {...rest}>
      {children}
      {!!Icon && <StyledIcon as={Icon} aria-hidden={true} />}
    </StyledLink>
  )
}

export const BrandLink: React.FC<LinkProps> & LinkSubComponents = (props) => {
  const { children, isBrand, ...rest } = props
  return (
    <Link {...rest} isBrand={true}>
      {children}
    </Link>
  )
}

Link.Main = StyledLink
Link.Icon = Icon

const linkPropTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  isBrand: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(LinkSize)),
}

Link.propTypes = linkPropTypes
BrandLink.propTypes = linkPropTypes

const linkDefaultProps = {
  disabled: false,
  isBrand: false,
}

Link.defaultProps = linkDefaultProps

BrandLink.defaultProps = {
  ...linkDefaultProps,
  size: LinkSize.sm,
}

BrandLink.Main = StyledLink
BrandLink.Icon = Icon
