import styled, { css } from 'styled-components'

export const StyledGroupWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;

  & > * {
    margin-bottom: 0;
  }
`
