import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const ChevronLeftIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M10.1213 12L16.0607 17.9393L13.9393 20.0607L5.87869 12L13.9393 3.93935L16.0607 6.06067L10.1213 12Z"
      fill={color}
    />
  </SVG>
)

ChevronLeftIcon.propTypes = defaultIconPropTypes

ChevronLeftIcon.defaultProps = defaultPictographIconProps
