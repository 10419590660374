import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const ArrowDownIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M12 4V20M12 20L18 14M12 20L6 14" />
  </SVG>
)

ArrowDownIcon.propTypes = defaultIconPropTypes
ArrowDownIcon.defaultProps = defaultPictographIconProps

export { ArrowDownIcon }
