import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Circle, Path, SVG } from '../brand-icons.styled'

const StampIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M3.78918 8.36754L3.09261 6.27784C3.03127 6.09382 3 5.90111 3 5.70713C3 4.21202 4.21202 3 5.70713 3C5.90111 3 6.09382 3.03127 6.27784 3.09261L8.36754 3.78918C8.77808 3.92603 9.22192 3.92603 9.63246 3.78918L11.3675 3.21082C11.7781 3.07397 12.2219 3.07397 12.6325 3.21082L14.3675 3.78918C14.7781 3.92603 15.2219 3.92603 15.6325 3.78918L17.7222 3.09261C17.9062 3.03127 18.0989 3 18.2929 3C19.788 3 21 4.21202 21 5.70713C21 5.90111 20.9687 6.09382 20.9074 6.27784L20.2108 8.36754C20.074 8.77808 20.074 9.22192 20.2108 9.63246L20.7892 11.3675C20.926 11.7781 20.926 12.2219 20.7892 12.6325L20.2108 14.3675C20.074 14.7781 20.074 15.2219 20.2108 15.6325L20.9074 17.7222C20.9687 17.9062 21 18.0989 21 18.2929C21 19.788 19.788 21 18.2929 21C18.0989 21 17.9062 20.9687 17.7222 20.9074L15.6325 20.2108C15.2219 20.074 14.7781 20.074 14.3675 20.2108L12.6325 20.7892C12.2219 20.926 11.7781 20.926 11.3675 20.7892L9.63246 20.2108C9.22192 20.074 8.77808 20.074 8.36754 20.2108L6.27784 20.9074C6.09382 20.9687 5.90111 21 5.70713 21C4.21202 21 3 19.788 3 18.2929C3 18.0989 3.03127 17.9062 3.09261 17.7222L3.78918 15.6325C3.92603 15.2219 3.92603 14.7781 3.78918 14.3675L3.21082 12.6325C3.07397 12.2219 3.07397 11.7781 3.21082 11.3675L3.78918 9.63246C3.92603 9.22192 3.92603 8.77808 3.78918 8.36754Z" />
    <Circle cx="9" cy="9" r="2" />
    <Path d="M8 20L12 14L14 16L17 11L20 14" />
  </SVG>
)

StampIcon.propTypes = defaultIconPropTypes
StampIcon.defaultProps = defaultPictographIconProps

export { StampIcon }
