import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { DefaultTheme, StyledComponent } from 'styled-components'
import { useTheme } from '../../utils/useTheme'
import { BinIcon } from '../../design-tokens/icons/pictograph'
import { BinIcon as BrandBinIcon } from '../../design-tokens/icons/brand-ui-icons'
import { Body, BodySize, BodyProps } from '../../design-tokens/typography'
import {
  Body as BrandBody,
  BodySize as BrandBodySize,
  BodyProps as BrandBodyProps,
  Label as BrandLabel,
  LabelProps as BrandLabelProps,
  LabelSize as BrandLabelSize,
} from '../../design-tokens/brand-typography'
import { Position, Size, Mode, LoadingAnimationSize } from '../../utils/enums'
import { StyledFile, StyledButton, StyledBrandButton, RightContainer, LeftContainer } from './FileDisplay.styles'
import Loading, { LoadingProps } from '../../basic-components/Loading/Loading'
import { ButtonProps } from '../Button/Button.types'
import { BrandButtonProps } from '../BrandButton/BrandButton.types'

export interface FileDisplayProps {
  file: File
  disabled?: boolean
  removeFile: (fileName: string) => void
  removeFileLabel?: string
  /**
   * Show Remove label next to bin icon
   */
  showRemoveLabel?: boolean
  /**
   * Toggle bin icon left or right if showRemoveLabel is enabled
   */
  removeIconPosition: keyof typeof Position
  isBrand?: boolean
  isUploading?: boolean
  loadingColor?: string
  percentageValue?: string
  /** Accessibility label to indicate the loading status */
  loadingLabel?: string
  icon?: React.ReactNode
  children?: React.ReactNode
  displayName?: string
}

interface FileDisplaySubComponents {
  File: StyledComponent<'li', DefaultTheme, { isDisabled: boolean; isBrand: boolean }, 'aria-live'>
  RightContainer: StyledComponent<'div', DefaultTheme, { isBrand: boolean }, never>
  LeftContainer: StyledComponent<'div', DefaultTheme, NonNullable<unknown>, never>
  BrandBody: StyledComponent<'p', DefaultTheme, BrandBodyProps, never>
  Body: StyledComponent<'p', DefaultTheme, BodyProps, never>
  BrandLabel: StyledComponent<'label', DefaultTheme, BrandLabelProps, never>
  Loading: React.FC<LoadingProps>
  BrandButton: StyledComponent<
    React.ForwardRefExoticComponent<BrandButtonProps>,
    DefaultTheme,
    NonNullable<unknown>,
    never
  >
  Button: StyledComponent<React.ForwardRefExoticComponent<ButtonProps>, DefaultTheme, NonNullable<unknown>, never>
}

export const FileDisplay: React.FC<FileDisplayProps> & FileDisplaySubComponents = ({
  file,
  disabled,
  removeFile,
  removeFileLabel,
  showRemoveLabel,
  removeIconPosition,
  isBrand,
  isUploading,
  loadingColor,
  percentageValue,
  loadingLabel,
  icon,
  displayName,
}) => {
  const theme = useTheme()
  const removeFileAriaLabel = `${removeFileLabel} ${file.name}`

  const styledButtonProps = (isBrand: boolean) => {
    return {
      contentMode: Mode.secondary,
      hasBackground: false,
      size: Size.sm,
      icon: isBrand ? BrandBinIcon : BinIcon,
      iconPosition: removeIconPosition,
      'aria-label': removeFileAriaLabel,
    }
  }

  return (
    <StyledFile isDisabled={disabled} isBrand={isBrand}>
      <LeftContainer>
        {icon}
        {isBrand ? (
          <BrandBody size={BrandBodySize.Five} as="span">
            {displayName ?? file.name}
          </BrandBody>
        ) : (
          <Body size={BodySize.Four} as="span">
            {displayName ?? file.name}
          </Body>
        )}
      </LeftContainer>
      <RightContainer isBrand={isBrand}>
        {isUploading ? (
          <>
            <Loading size={LoadingAnimationSize.sm} color={loadingColor} preset={undefined} />
            {isBrand ? (
              <BrandLabel size={BrandLabelSize.Four} as="span" aria-label={`${loadingLabel} ${percentageValue}`}>
                {percentageValue}
              </BrandLabel>
            ) : (
              <Body size={BodySize.Three} as="span" aria-label={`${loadingLabel} ${percentageValue}`}>
                {percentageValue}
              </Body>
            )}
          </>
        ) : isBrand ? (
          <StyledBrandButton
            {...styledButtonProps(true)}
            onClick={() => removeFile(file.name)}
            aria-disabled={disabled}
            hasLabel={showRemoveLabel && removeFileLabel !== undefined}
            type="button"
          >
            {showRemoveLabel ? removeFileLabel : null}
          </StyledBrandButton>
        ) : (
          <StyledButton
            {...styledButtonProps(false)}
            onClick={() => removeFile(file.name)}
            aria-disabled={disabled}
            iconColor={theme.color.neutralIconGray}
            type="button"
          >
            {showRemoveLabel ? removeFileLabel : null}
          </StyledButton>
        )}
      </RightContainer>
    </StyledFile>
  )
}

export const BrandFileDisplay: React.FC<FileDisplayProps> = (props) => {
  const { children, isBrand, ...rest } = props
  return (
    <FileDisplay {...rest} isBrand={true}>
      {children}
    </FileDisplay>
  )
}

FileDisplay.File = StyledFile
FileDisplay.RightContainer = RightContainer
FileDisplay.LeftContainer = LeftContainer
FileDisplay.BrandBody = BrandBody
FileDisplay.Body = Body
FileDisplay.Loading = Loading
FileDisplay.BrandLabel = BrandLabel
FileDisplay.BrandButton = StyledBrandButton
FileDisplay.Button = StyledButton

FileDisplay.propTypes = {
  file: PropTypes.shape({
    lastModified: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    size: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired as Validator<File>,
  disabled: PropTypes.bool,
  removeFile: PropTypes.func.isRequired,
  removeFileLabel: PropTypes.string,
  showRemoveLabel: PropTypes.bool,
  removeIconPosition: PropTypes.oneOf(Object.values(Position)).isRequired,
  isBrand: PropTypes.bool,
  loadingColor: PropTypes.string,
  isUploading: PropTypes.bool,
  loadingLabel: PropTypes.string,
  icon: PropTypes.node,
  displayName: PropTypes.string,
}
