import React, { HTMLAttributes } from 'react'
import styled, { css, StyledComponent, DefaultTheme } from 'styled-components'
import Tablelvl2Context from '../../composite-components/Table/Tablelvl2Context'
import TableContext from '../../composite-components/Table/TableContext'

export type TableHeadProps = HTMLAttributes<HTMLTableSectionElement>
interface TableRowSubComponents {
  Head: StyledComponent<'thead', DefaultTheme, NonNullable<unknown>, never>
  isBrand?: boolean
}

const StyledTableHead = styled.thead<{ isBrand: boolean }>(
  ({ theme: { color, brand }, isBrand }) => css`
    border-bottom: 2px solid ${isBrand ? brand.color.gray50 : color.neutralGray5};
  `
)

const tablelvl2 = {
  variant: 'head',
}

export const TableHead: React.FC<TableHeadProps> & TableRowSubComponents = (props) => {
  const table = React.useContext(TableContext)
  return (
    <Tablelvl2Context.Provider value={tablelvl2}>
      <StyledTableHead isBrand={table.isBrand} {...props} />
    </Tablelvl2Context.Provider>
  )
}

TableHead.Head = StyledTableHead
