import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import styled, { css } from 'styled-components'
import { BrandThemeColor, BrandThemeFontWeight } from '../../../utils/helpers'
import { FontWeight } from '../../../utils/enums'
import { BrandTheme } from '@postidigital/posti-theme'

export enum BodySize {
  /**
   * 24px
   */
  One = 'One',
  /**
   * 22px
   */
  Two = 'Two',
  /**
   * 19px
   */
  Three = 'Three',
  /**
   * 16px
   */
  Four = 'Four',
  /**
   * 14px
   */
  Five = 'Five',
}

export type FontWeights = 'normal' | 'bold'

export interface BodyProps extends React.HtmlHTMLAttributes<HTMLParagraphElement> {
  /**
   * The size of the body text. Use the exported enum BodySize for the values or just a
   * plain string matching one of the keys
   */
  size: BodySize | keyof typeof BodySize
  color?: BrandThemeColor | string
  fontWeight?: BrandThemeFontWeight | FontWeights | number
}

export const Body = styled.p<BodyProps>(
  ({ theme: { brand }, size, color, fontWeight }) => css`
    font-family: ${brand.fontFamily.PostiFont};
    font-weight: ${brand.fontWeight[fontWeight] ?? fontWeight ?? brand.fontWeight.regular};
    line-height: ${brand.lineHeight.body};
    color: ${brand[color] ?? color ?? brand.color.gray90};

    ${size === BodySize.One &&
    `
      font-size: ${brand.fontSize.body.one}rem;
      `}
    ${size === BodySize.Two &&
    `
      font-size: ${brand.fontSize.body.two}rem;
      `}
    
    ${size === BodySize.Three &&
    `
      font-size: ${brand.fontSize.body.three}rem;
      `}
    
    ${size === BodySize.Four &&
    `
      font-size: ${brand.fontSize.body.four}rem;
      `}    
    
    ${size === BodySize.Five &&
    `
      font-size: ${brand.fontSize.body.five}rem;
      `}
  `
)

Body.propTypes = {
  fontWeight: PropTypes.oneOfType([
    PropTypes.oneOf(['normal', 'bold']) as Validator<FontWeights>,
    PropTypes.oneOf(Object.values(FontWeight)) as Validator<BrandThemeFontWeight>,
    PropTypes.number,
  ]),
  size: PropTypes.oneOf(Object.keys(BodySize)).isRequired as Validator<BodySize>,
  color: PropTypes.string,
}

Body.defaultProps = {
  color: BrandTheme.color.gray90,
  fontWeight: BrandTheme.fontWeight.regular,
}
