import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const ParcelsIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M11.8839 16.5008L10 17.5885C9.3812 17.9457 8.6188 17.9457 8 17.5885L3 14.7017C2.3812 14.3444 2 13.6842 2 12.9697V7.19615C2 6.48162 2.3812 5.82137 3 5.4641L8 2.57735C8.6188 2.22008 9.3812 2.22008 10 2.57735L15 5.4641C15.6188 5.82137 16 6.48162 16 7.19615V10.4" />
    <Path d="M2.61133 6.39941L9.00165 10.096L15.392 6.39941" />
    <Path d="M9 17.3994V10.3994" />
    <Path d="M16 10.5774C16.6188 10.2201 17.3812 10.2201 18 10.5774L21 12.3094C21.6188 12.6667 22 13.3269 22 14.0415V17.5056C22 18.2201 21.6188 18.8803 21 19.2376L18 20.9697C17.3812 21.3269 16.6188 21.3269 16 20.9697L13 19.2376C12.3812 18.8803 12 18.2201 12 17.5056V14.0415C12 13.3269 12.3812 12.6667 13 12.3094L16 10.5774Z" />
    <Path d="M12.502 13.1797L17.002 15.7828L21.502 13.1797" />
    <Path d="M17.002 20.3999L17.002 16.3999" />
  </SVG>
)

ParcelsIcon.propTypes = defaultIconPropTypes
ParcelsIcon.defaultProps = defaultPictographIconProps

export { ParcelsIcon }
