import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const HeadphonesIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M20 13.3333V10C20 5.58172 16.4183 2 12 2C7.58172 2 4 5.58172 4 10V13.3333" />
    <Path d="M4 12.0001C4 10.7728 4.99492 9.77783 6.22222 9.77783C7.44952 9.77783 8.44444 10.7728 8.44444 12.0001V13.7778C8.44444 15.0051 7.44952 16.0001 6.22222 16.0001C4.99492 16.0001 4 15.0051 4 13.7778V12.0001Z" />
    <Path d="M15.5556 12.0001C15.5556 10.7728 16.5505 9.77783 17.7778 9.77783C19.0051 9.77783 20 10.7728 20 12.0001V13.7778C20 15.0051 19.0051 16.0001 17.7778 16.0001C16.5505 16.0001 15.5556 15.0051 15.5556 13.7778V12.0001Z" />
    <Path d="M15 20V20C17.7614 20 20 17.7614 20 15L20 14" />
    <Path d="M13.5 22C12.67 22 12 21.33 12 20.5C12 19.67 12.67 19 13.5 19C14.33 19 15 19.67 15 20.5C15 21.33 14.33 22 13.5 22Z" />
  </SVG>
)

HeadphonesIcon.propTypes = defaultIconPropTypes
HeadphonesIcon.defaultProps = defaultPictographIconProps

export { HeadphonesIcon }
