import React from 'react'
import * as ReactSelect from 'react-select'
import { useTheme } from '../../utils/useTheme'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import type { MenuPortalProps } from 'react-select/dist/declarations/src/components/Menu'
import {
  StyledIcon,
  StyledSelectContainer,
  StyledButtonContainer,
  StyledSelectPortal,
  StyledControlContainer,
  StyledLabel,
  StyledContentContainer,
  StyledCheckItem,
  StyledValueContainer,
  StyledChildrenWrapper,
  CloseIconButton,
  BrandCloseIconButton,
  ControlContainer,
  StyledCloseIcon,
  ChevronContainer,
  StyledScreenReaderButton,
} from './Dropdown.styles'
import { CloseIcon, ChevronDownIcon } from '../../design-tokens/icons/pictograph'
import {
  ChevronDownIcon as BrandChevronDownIcon,
  CloseIcon as BrandCloseIcon,
} from '../../design-tokens/icons/brand-ui-icons'
import { isArray } from '../../utils/helpers'
import { Label, LabelSize } from '../../design-tokens/typography'
import { BrandSize, BrandMode } from '../../utils/enums'

export const DropdownCustomContext = React.createContext<{
  message: string
  messageId: string
  labelId: string
  icon: React.ComponentType<DefaultIconProps>
  isValid: boolean
  label: string
  iconColor: string
  lightBackground: boolean
  zIndex: number
  hasChevron: boolean
  handleClose: (e: React.SyntheticEvent<HTMLButtonElement>) => void
  isBrand: boolean
  isSelected: boolean
  screenReaderDisabledLabel: string
}>(null)

export function useCustomProps() {
  const ctx = React.useContext(DropdownCustomContext)
  if (!ctx) throw new Error('Not able to use DropdownCustomContext outside of provider')
  return ctx
}

const CustomInput = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
>(
  props: ReactSelect.InputProps<Option, IsMulti, Group>
) => {
  const { isValid } = useCustomProps()
  const isValidCheck = props.hasValue || props.selectProps.inputValue || isValid

  return (
    <ReactSelect.components.Input
      {...props}
      id={props.selectProps.inputId}
      aria-disabled={props.selectProps.isDisabled}
      aria-invalid={!isValidCheck}
      readOnly={!props.selectProps.isSearchable}
    />
  )
}

const CustomDownChevron = (props: { isDisabled: boolean; iconColor: string }) => {
  const theme = useTheme()
  const { iconColor, isBrand } = useCustomProps()
  const disabledColor = isBrand ? theme.brand.color.gray30 : theme.color.neutralPassiveGray
  const activeColor = iconColor ?? isBrand ? theme.brand.color.gray50 : theme.color.neutralPassiveGray

  return isBrand ? (
    <BrandChevronDownIcon color={props.isDisabled ? disabledColor : activeColor} />
  ) : (
    <ChevronDownIcon color={props.isDisabled ? disabledColor : activeColor} />
  )
}

const CustomControl = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
>(
  props: ReactSelect.ControlProps<Option, IsMulti, Group> & { isSearchWithSuggestion: boolean }
) => {
  const theme = useTheme()
  const { children, hasValue, selectProps, isSearchWithSuggestion, isMulti } = props
  const {
    iconColor,
    icon: Icon,
    label,
    hasChevron,
    handleClose,
    isBrand,
    labelId,
    screenReaderDisabledLabel,
  } = useCustomProps()

  const isShrinked = selectProps.isSearchable
    ? selectProps.menuIsOpen || Boolean(selectProps.inputValue) || hasValue
    : hasValue
  const hasCloseButton = ((selectProps.inputValue || hasValue) && selectProps.isSearchable) || handleClose !== undefined

  const disabledColor = isBrand ? theme.brand.color.gray30 : theme.color.neutralPassiveGray
  const activeColor = iconColor ?? isBrand ? theme.brand.color.gray50 : theme.color.neutralPassiveGray

  const handleCloseClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.preventDefault()
    handleClose !== undefined ? handleClose(e) : props.setValue(null, 'deselect-option')
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === 'Enter' || e.code === 'Space') {
      e.preventDefault()
      handleCloseClick(e)
    }
  }

  /** This should be cleared up once xyz is removed */
  const closeButtonProps = {
    'aria-disabled': selectProps.isDisabled,
    'aria-label': 'Close',
    icon: isBrand ? BrandCloseIcon : CloseIcon,
    iconColor: selectProps.isDisabled ? disabledColor : activeColor,
    onMouseDown: handleCloseClick,
    onKeyDown: handleKeyDown,
  }

  return (
    <ControlContainer>
      <ReactSelect.components.Control {...props}>
        <StyledButtonContainer isBrand={isBrand} aria-disabled={selectProps.isDisabled}>
          {!!Icon && (
            <StyledIcon as={Icon} color={selectProps.isDisabled ? disabledColor : activeColor} aria-hidden={true} />
          )}
          <StyledContentContainer>
            <StyledControlContainer
              aria-live="polite"
              aria-atomic="false"
              aria-relevant="additions text"
              aria-disabled={selectProps.isDisabled}
              isSearchable={selectProps.isSearchable}
              isMulti={isMulti}
            >
              <StyledLabel
                id={labelId}
                isShrinked={isShrinked}
                isSearchable={selectProps.isSearchable}
                htmlFor={selectProps.inputId}
                isBrand={isBrand}
                className={`styled-label ${isShrinked && 'shrinked'}`}
                aria-disabled={selectProps.isDisabled}
              >
                {label}
              </StyledLabel>
              <StyledValueContainer
                isSearchable={selectProps.isSearchable}
                isShrinked={isShrinked}
                isMulti={isMulti}
                hasValue={hasValue}
              >
                {children}
              </StyledValueContainer>
            </StyledControlContainer>
            <ChevronContainer>
              {(hasChevron === undefined || hasChevron) && !hasCloseButton && !isSearchWithSuggestion && (
                <CustomDownChevron
                  isDisabled={selectProps.isDisabled}
                  iconColor={iconColor ?? theme.color.neutralPassiveGray}
                />
              )}
            </ChevronContainer>
          </StyledContentContainer>
        </StyledButtonContainer>
      </ReactSelect.components.Control>
      {(selectProps.isClearable ?? hasCloseButton) &&
        (isBrand ? (
          <BrandCloseIconButton
            size={BrandSize.xxxs}
            contentMode={BrandMode.tertiary}
            hasBackground
            {...closeButtonProps}
          />
        ) : (
          <CloseIconButton {...closeButtonProps} />
        ))}
      {selectProps.isDisabled && (
        <StyledScreenReaderButton aria-disabled={selectProps.isDisabled}>
          {screenReaderDisabledLabel}
        </StyledScreenReaderButton>
      )}
    </ControlContainer>
  )
}

export const AsyncControl = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
>(
  props: ReactSelect.ControlProps<Option, IsMulti, Group>
) => {
  return <CustomControl {...props} isSearchWithSuggestion={true} />
}

export const Control = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
>(
  props: ReactSelect.ControlProps<Option, IsMulti, Group>
) => {
  return <CustomControl {...props} isSearchWithSuggestion={false} />
}

const CustomMenuPortal = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
>(
  props: MenuPortalProps<Option, IsMulti, Group>
) => {
  const { zIndex, isBrand } = useCustomProps()
  const innerProps = { ...props.innerProps, style: { zIndex: zIndex !== undefined ? zIndex : 400 } }
  return (
    <ReactSelect.components.MenuPortal {...props} innerProps={innerProps}>
      <StyledSelectPortal isBrand={isBrand}>{props.children}</StyledSelectPortal>
    </ReactSelect.components.MenuPortal>
  )
}

const CustomSelectContainer = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
>(
  props: ReactSelect.ContainerProps<Option, IsMulti, Group>
) => {
  const {
    selectProps: { menuIsOpen, isSearchable, isDisabled, inputValue },
    hasValue,
    children,
  } = props
  const { lightBackground, isValid, isBrand } = useCustomProps()
  const isValidCheck = isValid !== undefined ? (hasValue || inputValue ? true : isValid) : true

  return (
    <StyledSelectContainer
      hasValue={hasValue}
      lightBackground={lightBackground}
      isOpen={menuIsOpen}
      isValid={isValidCheck}
      aria-invalid={isValidCheck}
      aria-disabled={isDisabled}
      isBrand={isBrand}
      isSearchable={isSearchable}
    >
      <ReactSelect.components.SelectContainer {...props}>{children}</ReactSelect.components.SelectContainer>
    </StyledSelectContainer>
  )
}

const CustomMultiValueLabel = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
>({
  selectProps: { getOptionLabel, value, isSearchable, isMulti },
  data,
}: ReactSelect.MultiValueGenericProps<Option, IsMulti, Group>) => {
  const allSelected = isArray(value) ? [...value] : [value]
  const optionLabel = getOptionLabel(data)
  const stringified =
    getOptionLabel(allSelected[allSelected.length - 1]) === getOptionLabel(data)
      ? getOptionLabel(data)
      : getOptionLabel(data) + ', '

  return <>{isSearchable && isMulti ? <Label size={LabelSize.Five}>{optionLabel}</Label> : stringified}</>
}

const CustomMultiValueRemove = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
>(
  props: ReactSelect.MultiValueGenericProps<Option, IsMulti, Group>
) => {
  const theme = useTheme()
  return (
    <>
      {props.selectProps.isSearchable ? (
        <ReactSelect.components.MultiValueRemove {...props}>
          <StyledCloseIcon
            width={`${theme.spacing.space3}rem`}
            height={`${theme.spacing.space3}rem`}
            aria-hidden={true}
            color={props.selectProps.isDisabled ? theme.color.neutralPassiveGray : theme.color.neutralIconGray}
          />
        </ReactSelect.components.MultiValueRemove>
      ) : null}
    </>
  )
}

const CustomOption = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
>(
  props: ReactSelect.OptionProps<Option, IsMulti, Group>
) => {
  const theme = useTheme()
  const { isBrand } = useCustomProps()
  const disabledColor = isBrand ? theme.brand.color.gray30 : theme.color.neutralPassiveGray
  const activeColor = isBrand ? theme.brand.color.postiOrange70 : theme.color.signalBlue
  return (
    <ReactSelect.components.Option {...props}>
      <StyledChildrenWrapper>{props.children}</StyledChildrenWrapper>
      {props.isSelected && (
        <StyledCheckItem
          width={`${theme.iconSize.s}rem`}
          height={`${theme.iconSize.s}rem`}
          color={props.selectProps.isDisabled ? disabledColor : activeColor}
        />
      )}
    </ReactSelect.components.Option>
  )
}

const CustomMenuList = <
  Option,
  IsMulti extends boolean = false,
  Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
>(
  props: ReactSelect.MenuListProps<Option, IsMulti, Group>
) => {
  const innerProps = { ...props.innerProps, role: 'listbox', 'aria-expanded': true }
  return <ReactSelect.components.MenuList {...props} innerProps={innerProps} />
}

export const commonComponents = {
  IndicatorSeparator: () => null,
  IndicatorsContainer: () => null,
  DropdownIndicator: () => null,
  MenuPortal: CustomMenuPortal,
  SelectContainer: CustomSelectContainer,
  MenuList: CustomMenuList,
  Option: CustomOption,
  Placeholder: () => null,
  MultiValueRemove: CustomMultiValueRemove,
  MultiValueLabel: CustomMultiValueLabel,
  Input: CustomInput,
}
