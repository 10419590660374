import React, { useState } from 'react'
import PropTypes, { Validator } from 'prop-types'
import { SubMenuProps, SubMenuSubComponents } from './SubMenu.types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { Headline, HeadlineSize, LabelSize, Label } from '../../design-tokens/typography'
import { Label as BrandLabel, LabelSize as BrandLabelSize } from '../../design-tokens/brand-typography'
import { StyledSubMenu, StyledSubMenuItem, SubMenuContent } from './SubMenu.styles'
import { buttonTypeOption } from '../../utils/enums'
import { MenuItemContent } from '../../basic-components/MenuItem/MenuItem'

export const SubMenu: React.FC<SubMenuProps> & SubMenuSubComponents = (props) => {
  const {
    icon: Icon,
    disabled,
    selected,
    chevronAriaLabel,
    open,
    role,
    onSelect,
    onButtonClick,
    label,
    subtitle,
    children,
    isBrand,
    ...rest
  } = props

  const [visible, setVisible] = useState(open ?? false)

  const handleClick = () => {
    setVisible(!visible)
  }

  return (
    <StyledSubMenu {...rest} aria-current={selected ? 'page' : 'false'}>
      <StyledSubMenuItem>
        <MenuItemContent
          icon={Icon}
          buttonType={buttonTypeOption.bottomArrow}
          chevronAriaLabel={chevronAriaLabel}
          onSelect={onSelect}
          onButtonClick={handleClick}
          disabled={disabled}
          selected={selected}
          open={visible}
          isBrand={isBrand}
        >
          {isBrand ? (
            <>
              <BrandLabel size={BrandLabelSize.Four}>{label}</BrandLabel>
              {subtitle && <BrandLabel size={BrandLabelSize.Seven}>{subtitle}</BrandLabel>}
            </>
          ) : (
            <>
              <Headline size={HeadlineSize.Seven}>{label}</Headline>
              {subtitle && <Label size={LabelSize.Six}>{subtitle}</Label>}
            </>
          )}
        </MenuItemContent>
      </StyledSubMenuItem>
      {visible && <SubMenuContent>{children}</SubMenuContent>}
    </StyledSubMenu>
  )
}

SubMenu.Main = StyledSubMenu
SubMenu.Item = StyledSubMenuItem
SubMenu.BrandLabel = BrandLabel
SubMenu.Headline = Headline
SubMenu.Label = Label
SubMenu.Content = SubMenuContent

SubMenu.propTypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  selected: PropTypes.bool,
  chevronAriaLabel: PropTypes.string,
  open: PropTypes.bool,
  subtitle: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  isBrand: PropTypes.bool,
}

SubMenu.defaultProps = {
  open: false,
  selected: false,
  disabled: false,
  role: 'menuitem',
  isBrand: false,
}
