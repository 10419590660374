import styled, { css } from 'styled-components'
import { ArrowRightIcon } from '../../design-tokens/icons/pictograph'
import { IconProps } from '../../design-tokens/icons/icons.types'
import { LinkProps } from './Link.types'
import { BrandSize } from '../../utils/enums'
import { BrandTheme } from '@postidigital/posti-theme'

const brandLinkFontSize = {
  [BrandSize.xl]: `${BrandTheme.fontSize.label.one}rem`,
  [BrandSize.lg]: `${BrandTheme.fontSize.label.two}rem`,
  [BrandSize.md]: `${BrandTheme.fontSize.label.three}rem`,
  [BrandSize.sm]: `${BrandTheme.fontSize.label.four}rem`,
}

const brandLinkIconSize = {
  [BrandSize.xl]: `1.5rem`,
  [BrandSize.lg]: `1.375rem`,
  [BrandSize.md]: `1.25rem`,
  [BrandSize.sm]: `1rem`,
}

export const StyledIcon = styled(ArrowRightIcon)<IconProps>`
  ${({ theme: { spacing } }) => css`
    flex-shrink: 0;
    margin-left: ${spacing.space2}rem;
  `}
`

export const StyledLink = styled.a<Pick<LinkProps, 'disabled' | 'isBrand' | 'size'>>`
  ${({ theme: { color, fontFamily, fontSize, fontWeight, brand, iconSize }, disabled, isBrand, size }) => css`
    display: inline-flex;
    align-items: center;
    letter-spacing: 0.0125rem;
    cursor: pointer;
    border: 1px solid transparent;
    font-weight: ${fontWeight.semiBold};
    font-family: ${fontFamily.Montserrat};
    font-size: ${fontSize.body.three}rem;
    color: ${color.signalBlue};

    ${StyledIcon} * {
      fill: ${color.signalBlue};
    }

    ${disabled &&
    css`
      color: ${color.neutralPassiveGray};
      pointer-events: none;
    `}

    &:focus {
      outline-offset: 0;
      text-decoration: underline;
    }
    @media (hover: hover) {
      &:hover {
        outline-offset: 0;
        text-decoration: underline;
        color: ${color.signalHoverBlue};
        ${!isBrand &&
        css`
          ${StyledIcon} * {
            fill: ${color.signalHoverBlue};
          }
        `}
      }
    }

    &:focus {
      outline: 0;
      border: 1px solid ${color.signalLightBlue};
      box-shadow: 0px 0px 7px ${color.signalBlue};
      background-color: ${color.neutralWhite};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
      border: 1px solid transparent;
    }

    ${isBrand &&
    css`
      font-family: ${brand.fontFamily.PostiFont};
      font-size: ${brandLinkFontSize[size]};
      font-weight: ${brand.fontWeight.bold};
      line-height: ${brandLinkFontSize[size]};
      padding: ${brand.spacing.space05}rem 0;
      color: ${disabled ? brand.color.gray30 : brand.color.postiOrange70};
      border: 3px solid transparent;
      text-decoration: ${disabled ? 'underline' : 'none'};

      ${StyledIcon} {
        width: ${brandLinkIconSize[size]};
        height: ${brandLinkIconSize[size]};
        * {
          stroke: ${disabled ? brand.color.gray30 : brand.color.postiOrange70};
          fill: none;
        }
      }

      @media (hover: hover) {
        &:hover {
          color: ${brand.color.postiOrange80};
          ${StyledIcon} * {
            stroke: ${brand.color.postiOrange80};
          }
          &:focus {
            color: ${brand.color.postiOrange80};
            text-decoration: underline;
            ${StyledIcon} * {
              stroke: ${brand.color.postiOrange80};
            }
          }
        }
      }

      &:active,
      &:active:focus {
        color: ${brand.color.postiOrange90};
        text-decoration: underline;
        ${StyledIcon} * {
          stroke: ${brand.color.postiOrange90};
        }
      }

      &:focus {
        border: 3px solid transparent;
        box-shadow: 0px 0px 0px 3px ${disabled ? 'transparent' : brand.color.postiOrange60};
        text-decoration: ${disabled ? 'underline' : 'none'};
      }

      &:focus:not(:focus-visible) {
        border: 3px solid transparent;
        box-shadow: none;
      }

      &:focus-visible {
        text-decoration: none;
      }
    `}
  `}
`
