import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const ArrowSplitIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M6.00045 3.75736L1.75781 8L6.00045 12.2426" />
    <Path d="M12 21L12 13C12 10.2386 9.76142 8 7 8L4 8" />
    <Path d="M17.9995 3.75736L22.2422 8L17.9995 12.2426" />
    <Path d="M12 21L12 13C12 10.2386 14.2386 8 17 8L20 8" />
  </SVG>
)

ArrowSplitIcon.propTypes = defaultIconPropTypes
ArrowSplitIcon.defaultProps = defaultPictographIconProps

export { ArrowSplitIcon }
