import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const ChevronDownIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M6 9L12 15L18 9" />
  </SVG>
)

ChevronDownIcon.propTypes = defaultIconPropTypes
ChevronDownIcon.defaultProps = defaultPictographIconProps

export { ChevronDownIcon }
