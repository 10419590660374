import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const SecurityIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.4715 2.6089C9.76028 3.8139 6.82634 4.43652 3.85938 4.43652V13.4365C3.85938 19.4365 12.8594 22.4365 12.8594 22.4365C12.8594 22.4365 21.8594 19.4365 21.8594 13.4365V4.43652C18.8924 4.43652 15.9585 3.8139 13.2472 2.6089L12.8594 2.43652L12.4715 2.6089ZM12.8594 4.43652C12.5832 4.43652 12.3594 4.66038 12.3594 4.93652V19.9365C12.3594 20.2127 12.5832 20.4365 12.8594 20.4365C13.1355 20.4365 13.3594 20.2127 13.3594 19.9365V4.93652C13.3594 4.66038 13.1355 4.43652 12.8594 4.43652Z"
      fill={color}
    />
  </SVG>
)

SecurityIcon.propTypes = defaultIconPropTypes

SecurityIcon.defaultProps = defaultPictographIconProps
