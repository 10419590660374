import React, { HTMLAttributes, useState } from 'react'
import PropTypes, { Validator } from 'prop-types'
import { DefaultTheme, StyledComponent } from 'styled-components'
import { XyzTheme } from '@postidigital/posti-theme'
import { CheckmarkIcon } from '../../xyz'
import { XyzThemeColor } from '../../utils/helpers'
import { useTheme } from '../../utils/useTheme'
import { HeadlineProps, HeadlineSize } from '../../design-tokens/typography'
import { ButtonWrapper, StyledHeadline, StyledIcon, StyledCopyIcon } from './CopyTextToClipboard.styles'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'

export interface CopyTextToClipboardProps extends HTMLAttributes<HTMLButtonElement> {
  /**
   * Text to be copied to clipboard
   */
  text: string
  /**
   * Time how long the copySuccessfulText and CheckmarkIcon will be visible when copy is successful
   */
  timeoutThreshold?: number
  /**
   * Color of the CopyIcon
   */
  copyIconColor?: XyzThemeColor | string
  /**
   * Icon control
   */
  icon?: React.ComponentType<DefaultIconProps>
  /**
   * Making component inactive
   */
  disabled?: boolean
}

export interface CopyTextToClipboardSubComponents {
  ButtonWrapper: StyledComponent<'button', DefaultTheme, NonNullable<unknown>, never>
  Icon: StyledComponent<React.FC<DefaultIconProps>, DefaultTheme, DefaultIconProps, never>
  Headline: StyledComponent<'div', DefaultTheme, HeadlineProps, never>
  CheckmarkIcon: React.FC<DefaultIconProps>
  CopyIcon: StyledComponent<React.FC<DefaultIconProps>, DefaultTheme, DefaultIconProps, never>
}

const CopyTextToClipboard: React.FC<CopyTextToClipboardProps> & CopyTextToClipboardSubComponents = ({
  text,
  icon: Icon,
  timeoutThreshold,
  copyIconColor,
  disabled,
  ...rest
}) => {
  const [copied, setCopied] = useState(false)
  const theme = useTheme()

  const fallbackCopy = () => {
    if (navigator.clipboard.writeText(text)) {
      const textArea = document.createElement('textarea')
      textArea.value = text

      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'
      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        navigator.clipboard.writeText(text)
      } catch (error) {}
      document.body.removeChild(textArea)
    }
  }

  const onClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text)
    } else {
      fallbackCopy()
    }

    setCopied(true)
  }

  if (copied) {
    setTimeout(() => {
      setCopied(false)
    }, timeoutThreshold)
  }

  return (
    <ButtonWrapper onClick={onClick} aria-disabled={disabled} {...rest}>
      {!!Icon && (
        <StyledIcon
          as={Icon}
          color={disabled ? theme.color.neutralPassiveGray : theme.color.neutralNetworkGray}
          aria-hidden={true}
        />
      )}
      <StyledHeadline
        size={HeadlineSize.Seven}
        color={disabled ? theme.color.neutralPassiveGray : copied ? theme.color.signalGreen : theme.color.neutralBlack}
      >
        {text}
      </StyledHeadline>
      {copied ? (
        <CheckmarkIcon
          height={`${theme.iconSize.s}em`}
          width={`${theme.iconSize.s}em`}
          color={theme.color.signalGreen}
        />
      ) : (
        <StyledCopyIcon
          height={`${theme.iconSize.s}em`}
          width={`${theme.iconSize.s}em`}
          color={disabled ? theme.color.neutralPassiveGray : copyIconColor}
        />
      )}
    </ButtonWrapper>
  )
}

CopyTextToClipboard.ButtonWrapper = ButtonWrapper
CopyTextToClipboard.Icon = StyledIcon
CopyTextToClipboard.Headline = StyledHeadline
CopyTextToClipboard.CheckmarkIcon = CheckmarkIcon
CopyTextToClipboard.CopyIcon = StyledCopyIcon

CopyTextToClipboard.defaultProps = {
  timeoutThreshold: 1200,
  copyIconColor: XyzTheme.color.signalBlue,
  disabled: false,
}

CopyTextToClipboard.propTypes = {
  text: PropTypes.string.isRequired,
  timeoutThreshold: PropTypes.number,
  copyIconColor: PropTypes.string,
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  disabled: PropTypes.bool,
}

export default CopyTextToClipboard
