import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const RecycleIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      fill={color}
      d="M12.7244 4.44848C12.3714 3.84827 11.5006 3.85051 11.1515 4.45521L9.04963 8.09569L10.4083 7.73163C10.9418 7.58868 11.4901 7.90527 11.6331 8.43873C11.776 8.9722 11.4594 9.52054 10.926 9.66348L7.23532 10.6524C6.70185 10.7953 6.15351 10.4787 6.01057 9.94528L5.02167 6.25463C4.87873 5.72117 5.19531 5.17283 5.72877 5.02989C6.26224 4.88695 6.81058 5.20353 6.95352 5.737L7.31758 7.09569L9.41941 3.45521C10.5396 1.51493 13.3402 1.51493 14.4604 3.45521L14.4697 3.4717L15.8754 6.01649C16.1424 6.49992 15.967 7.1083 15.4836 7.37534C15.0001 7.64237 14.3917 7.46695 14.1247 6.98351L12.7244 4.44848ZM16.4011 8.82081C16.6307 8.6882 16.9037 8.65226 17.1599 8.72091L20.8505 9.70981C21.384 9.85275 21.7006 10.4011 21.5576 10.9346C21.4147 11.468 20.8663 11.7846 20.3329 11.6417L18.9742 11.2776L21.076 14.9181C22.1962 16.8584 20.796 19.2837 18.5555 19.2837H15.4194C14.8671 19.2837 14.4194 18.836 14.4194 18.2837C14.4194 17.7314 14.8671 17.2837 15.4194 17.2837H18.5555C19.2564 17.2837 19.6944 16.525 19.344 15.9181L17.2421 12.2776L16.8781 13.6363C16.7351 14.1698 16.1868 14.4863 15.6533 14.3434C15.1199 14.2005 14.8033 13.6521 14.9462 13.1187L15.9351 9.42801C16.0038 9.17183 16.1714 8.95341 16.4011 8.82081ZM5.48049 12.1229C5.96485 12.3882 6.14239 12.996 5.87704 13.4804L4.54416 15.9133L4.53317 15.9329C4.18276 16.5398 4.62078 17.2985 5.32162 17.2985H9.52528L8.53065 16.3039C8.14013 15.9133 8.14013 15.2802 8.53065 14.8897C8.92118 14.4991 9.55434 14.4991 9.94486 14.8897L12.6466 17.5914C13.0371 17.9819 13.0371 18.6151 12.6466 19.0056L9.94486 21.7073C9.55434 22.0979 8.92118 22.0979 8.53065 21.7073C8.14013 21.3168 8.14013 20.6837 8.53065 20.2931L9.52528 19.2985H5.32162C3.08528 19.2985 1.68603 16.882 2.795 14.9435L4.12301 12.5194C4.38836 12.0351 4.99613 11.8575 5.48049 12.1229Z"
    />
  </SVG>
)

RecycleIcon.propTypes = defaultIconPropTypes
RecycleIcon.defaultProps = defaultPictographIconProps

export { RecycleIcon }
