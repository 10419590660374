import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const DimensionIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M10.8238 4.49397L14.2238 6.53401C14.5071 6.70396 14.6487 6.78894 14.7516 6.90595C14.8427 7.00948 14.9113 7.13073 14.9533 7.26207C15.0007 7.41048 15.0007 7.57565 15.0007 7.90599V12.0942C15.0007 12.4245 15.0007 12.5897 14.9533 12.7381C14.9113 12.8695 14.8427 12.9907 14.7516 13.0942C14.6487 13.2112 14.5071 13.2962 14.2238 13.4662L10.8238 15.5062C10.5245 15.6858 10.3749 15.7756 10.2151 15.8106C10.0738 15.8417 9.92747 15.8417 9.78617 15.8107C9.62642 15.7756 9.47678 15.6858 9.17748 15.5063L5.77689 13.4662C5.49359 13.2962 5.35194 13.2112 5.24905 13.0942C5.158 12.9907 5.08935 12.8694 5.0474 12.7381C5 12.5897 5 12.4245 5 12.0941V7.90605C5 7.57569 5 7.41051 5.0474 7.26209C5.08935 7.13074 5.158 7.00949 5.24905 6.90595C5.35194 6.78895 5.49359 6.70397 5.77689 6.53402L9.17748 4.49393C9.47678 4.31437 9.62642 4.2246 9.78617 4.18952C9.92747 4.1585 10.0738 4.1585 10.2151 4.18954C10.3749 4.22462 10.5245 4.3144 10.8238 4.49397Z" />
    <Path d="M15 7L10.5142 9.69132C10.1975 9.88132 9.80185 9.88131 9.48517 9.69129L5 7" />
    <Path d="M10 10V16" />
    <Path d="M20 7H22" />
    <Path d="M20 17H22" />
    <Path d="M20 12H22" />
    <Path d="M3 20L3 22H22V2H20" />
    <Path d="M8 22L8 20" />
    <Path d="M18 22L18 20" />
    <Path d="M13 22L13 20" />
  </SVG>
)

DimensionIcon.propTypes = defaultIconPropTypes
DimensionIcon.defaultProps = defaultPictographIconProps

export { DimensionIcon }
