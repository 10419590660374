import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const YoutubeIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fill={color}
      d="M22.5308 6.67497C22.4066 6.2072 22.1617 5.78027 21.8206 5.43693C21.4795 5.09358 21.0542 4.84588 20.5873 4.71863C18.8737 4.2561 11.9999 4.2561 11.9999 4.2561C11.9999 4.2561 5.12619 4.2561 3.41165 4.71863C2.94492 4.84611 2.51985 5.0939 2.17894 5.43722C1.83803 5.78054 1.59324 6.20735 1.46905 6.67497C1.00928 8.4005 1.00928 12 1.00928 12C1.00928 12 1.00928 15.5994 1.46905 17.3249C1.59323 17.7927 1.83813 18.2196 2.17922 18.563C2.52032 18.9063 2.94563 19.154 3.41257 19.2813C5.12619 19.7438 11.9999 19.7438 11.9999 19.7438C11.9999 19.7438 18.8737 19.7438 20.5882 19.2813C21.0552 19.1541 21.4805 18.9064 21.8216 18.5631C22.1628 18.2197 22.4076 17.7927 22.5317 17.3249C22.9906 15.5994 22.9906 12 22.9906 12C22.9906 12 22.9906 8.4005 22.5308 6.67497ZM9.75142 15.2678V8.73206L15.4968 12L9.75142 15.2678Z"
    />
  </SVG>
)

YoutubeIcon.propTypes = defaultIconPropTypes
YoutubeIcon.defaultProps = defaultPictographIconProps

export { YoutubeIcon }
