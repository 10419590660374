import React from 'react'
import PropTypes from 'prop-types'
import { ExpanderTitle, BrandExpanderTitle } from './ExpanderTitle'
import { ExpanderBody, ExpanderWrapper } from './Expander.styles'
import { XyzTheme } from '@postidigital/posti-theme'
import { ExpanderProps, BrandExpanderProps, ExpanderSubComponents, BrandExpanderSubComponents } from './Expander.types'

export const Expander: React.FC<ExpanderProps> & ExpanderSubComponents = ({
  open,
  showChevron,
  chevronColor,
  chevronStyles,
  children,
  isBrand,
  isDisabled,
  ...rest
}) => {
  const commonProps = {
    open: open,
    'aria-disabled': isDisabled,
  }
  return (
    <ExpanderWrapper {...rest} open={open} isBrand={isBrand} aria-live="assertive" aria-atomic="true">
      {React.Children.map(children, (child) =>
        React.cloneElement(
          child as React.ReactElement<any>,
          isBrand
            ? commonProps
            : {
                ...commonProps,
                showChevron: showChevron,
                chevronColor: chevronColor,
                chevronStyles: chevronStyles,
              }
        )
      )}
    </ExpanderWrapper>
  )
}

export const BrandExpander: React.FC<BrandExpanderProps> & BrandExpanderSubComponents = ({
  children,
  isBrand,
  ...rest
}) => {
  return (
    <Expander {...rest} isBrand={true}>
      {children}
    </Expander>
  )
}

Expander.defaultProps = {
  customBackgroundColorClosed: XyzTheme.color.neutralWhite,
  customBackgroundColorOpened: XyzTheme.color.neutralGray2,
  customBackgroundColorOnHover: XyzTheme.color.neutralOnHoverGray,
  chevronColor: XyzTheme.color.neutralNetworkGray,
  isBrand: false,
  open: false,
  changeColorOnClick: true,
  alignChevronToCenter: true,
  chevronStyles: {},
  showChevron: true,
}

BrandExpander.defaultProps = {
  isBrand: false,
  open: false,
  hasRoundedCorners: true,
  hasShadow: true,
  isDisabled: true,
}

Expander.propTypes = {
  open: PropTypes.bool.isRequired,
  changeColorOnClick: PropTypes.bool,
  customBackgroundColorClosed: PropTypes.string,
  customBackgroundColorOpened: PropTypes.string,
  customBackgroundColorOnHover: PropTypes.string,
  alignChevronToCenter: PropTypes.bool,
  chevronStyles: PropTypes.object,
  chevronColor: PropTypes.string,
  showChevron: PropTypes.bool,
  isBrand: PropTypes.bool,
}

BrandExpander.propTypes = {
  open: PropTypes.bool.isRequired,
  isBrand: PropTypes.bool,
  hasRoundedCorners: PropTypes.bool,
  hasShadow: PropTypes.bool,
  isDisabled: PropTypes.bool,
}

ExpanderBody.defaultProps = {
  open: false,
  'aria-hidden': false,
}

ExpanderBody.propTypes = {
  open: PropTypes.bool,
}

ExpanderTitle.defaultProps = {
  open: false,
  'aria-hidden': false,
}

ExpanderTitle.propTypes = {
  open: PropTypes.bool,
}

Expander.Body = ExpanderBody
Expander.Title = ExpanderTitle

BrandExpander.Body = ExpanderBody
BrandExpander.Title = BrandExpanderTitle
