import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import styled, { css } from 'styled-components'
import { BrandThemeColor, BrandThemeFontWeight } from '../../../utils/helpers'
import { FontWeight } from '../../../utils/enums'
import { BrandTheme } from '@postidigital/posti-theme'
import { FontWeights } from '../Body/Body'

export enum HeadlineSize {
  /**
   * 80px
   */
  One = 'One',
  /**
   * 48px
   */
  Two = 'Two',
  /**
   * 40px
   */
  Three = 'Three',
  /**
   * 36px
   */
  Four = 'Four',
  /**
   * 32px
   */
  Five = 'Five',
  /**
   * 28px
   */
  Six = 'Six',
  /**
   * 24px
   */
  Seven = 'Seven',
  /**
   * 22px
   */
  Eight = 'Eight',
  /**
   * 19px
   */
  Nine = 'Nine',
}

export interface HeadlineProps extends React.HTMLAttributes<HTMLHeadingElement> {
  /**
   * The size of the headline. Use the exported enum HeadlineSize for the values or just a
   * plain string matching one of the keys
   */
  size: HeadlineSize | keyof typeof HeadlineSize
  color?: BrandThemeColor | string
  fontWeight?: BrandThemeFontWeight | FontWeights | number
}

export const Headline = styled.div<HeadlineProps>(
  ({ theme: { brand }, size, color, fontWeight }) => css`
    font-family: ${brand.fontFamily.PostiFont};
    font-weight: ${brand.fontWeight[fontWeight] ?? fontWeight ?? brand.fontWeight.bold};
    line-height: ${brand.lineHeight.headline};
    color: ${brand[color] ?? color ?? brand.color.gray90};

    ${size === HeadlineSize.One &&
    `
      font-size: ${brand.fontSize.headline.one}rem;
      `}
    ${size === HeadlineSize.Two &&
    `
      font-size: ${brand.fontSize.headline.two}rem;
      `}
    
    ${size === HeadlineSize.Three &&
    `
      font-size: ${brand.fontSize.headline.three}rem;
      `}
    
    ${size === HeadlineSize.Four &&
    `
      font-size: ${brand.fontSize.headline.four}rem;
      `}    
    
    ${size === HeadlineSize.Five &&
    `
      font-size: ${brand.fontSize.headline.five}rem;
      `}
    
    ${size === HeadlineSize.Six &&
    `
      font-size: ${brand.fontSize.headline.six}rem;
      `}                   
    
    ${size === HeadlineSize.Seven &&
    `
      font-size: ${brand.fontSize.headline.seven}rem;
      `}           
    
    ${size === HeadlineSize.Eight &&
    `
      font-size: ${brand.fontSize.headline.eight}rem;
      `}
      ${size === HeadlineSize.Nine &&
    `
        font-size: ${brand.fontSize.headline.nine}rem;
      `}
  `
)

Headline.propTypes = {
  size: PropTypes.oneOf(Object.keys(HeadlineSize)).isRequired as Validator<HeadlineSize>,
  color: PropTypes.string,
  fontWeight: PropTypes.oneOfType([
    PropTypes.oneOf(['normal', 'bold']) as Validator<FontWeights>,
    PropTypes.oneOf(Object.values(FontWeight)) as Validator<BrandThemeFontWeight>,
    PropTypes.number,
  ]),
}

Headline.defaultProps = {
  color: BrandTheme.color.gray90,
  fontWeight: BrandTheme.fontWeight.bold,
}
