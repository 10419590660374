import React, { useRef, useState, HTMLAttributes, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { nextItem, previousItem, ownerDocument, moveFocus } from '../../utils/keyboardNavigation'
import useClickOutside from '../../utils/useClickOutside'
import { KeyboardKeys } from '../../utils/enums'

export enum MenuOverflowDirection {
  left = 'left',
  right = 'right',
}

export interface MenuProps extends HTMLAttributes<HTMLUListElement> {
  isOpen: boolean
  onClose: (e: Event | React.KeyboardEvent) => void
  transitionDuration?: number
  /**
   * Top offset for menu popup in CSS units px, em, rem, percents (example: 1px; 1rem; 10%)
   */
  menuOffset?: string | number
  /*
   * List ids for elements that are excluded from triggering onClose event. The array is passed to the useClickOutside hook
   */
  exclusions?: string[]
  zIndex?: number
  /**
   * Allows the menu to be wider than its parent container
   */
  overflowMenuContainer?: boolean
  /**
   * If `overflowMenuContainer` is set to true, you can choose in which direction the menu container should overflow to: left or right.
   * It is set to overflow to the right by default
   */
  overflowMenuContainerDirection?: keyof typeof MenuOverflowDirection
  /**
   * Enable dropdown styles if set to true
   */
  isInDropdown?: boolean
  maxMenuHeight?: string
}

enum MenuState {
  Opened,
  Closed,
  InTransition,
}

interface MenuListProps
  extends Pick<
    MenuProps,
    | 'transitionDuration'
    | 'menuOffset'
    | 'zIndex'
    | 'overflowMenuContainer'
    | 'overflowMenuContainerDirection'
    | 'isInDropdown'
  > {
  menuState: MenuState
  maxMenuHeight?: string
  focused: boolean
}

const MenuList = styled.ul<MenuListProps>`
  ${({
    theme: { spacing, color, fontWeight, zIndex, borderRadius },
    transitionDuration,
    menuOffset,
    zIndex: zIndexProp,
    overflowMenuContainerDirection,
    overflowMenuContainer,
    isInDropdown,
    maxMenuHeight,
    menuState,
    focused,
  }) => css`
    margin: 0;
    padding: 0;
    width: 100%;
    max-height: 400px;
    z-index: ${zIndexProp || 1};
    font-weight: ${fontWeight.medium};
    color: ${color.neutralNetworkGray};
    background-color: ${color.neutralWhite};
    transition:
      all ${transitionDuration}ms ease-in,
      filter 0;

    box-shadow:
      rgb(0 0 0 / 6%) 0px 15px 22px,
      rgb(0 0 0 / 8%) 0px 3px 8px,
      rgb(0 0 0 / 8%) 0px 1px 1px,
      rgb(0 0 0 / 4%) 0px 3px 1px;
    border-radius: ${borderRadius.sm}rem;
    transform-origin: 0 0;

    margin-top: ${menuOffset ? `${menuOffset}rem` : '0'};

    &:focus {
      outline: none;
      filter: drop-shadow(0px 0px 3px ${color.signalBlue});
    }

    ${focused &&
    css`
      filter: drop-shadow(0px 0px 3px ${color.signalBlue});
    `}

    ${isInDropdown &&
    css`
      box-sizing: border-box;
      position: absolute;
      background-color: ${color.neutralWhite};
      z-index: ${zIndex.zIndexTop};
      margin: ${menuOffset ? `${menuOffset}rem 0 ${spacing.space2}rem` : `${spacing.space2}rem 0 ${spacing.space2}rem`};
      max-height: ${maxMenuHeight ? maxMenuHeight : '400px'};
    `}

    ${(menuState === MenuState.Closed || menuState === null) &&
    css`
      transform: scaleY(0);
    `}

    ${menuState === MenuState.InTransition &&
    css`
      transform: scaleY(0);
    `}

    ${menuState === MenuState.Opened &&
    css`
      transform: scaleY(1);
    `}

    ${overflowMenuContainerDirection === MenuOverflowDirection.left &&
    css`
      right: 0;
    `}

    ${overflowMenuContainer &&
    css`
      width: auto;
      min-width: 100%;
    `}

    // Scrollbar

    overflow-y: auto;
    overflow-x: hidden;
    overflow: -moz-scrollbars-vertical;
    overflow: overlay;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0.5rem;
      background-color: transparent;
    }

    ::-webkit-scrollbar-track {
      margin: ${spacing.space3}rem 0;
      background-color: transparent;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${color.neutralPassiveGray};
      background-clip: content-box;
      border-radius: ${borderRadius.sm}rem;
    }

    &:hover {
      ::-webkit-scrollbar-thumb {
        background-color: ${color.neutralIconGray};
        background-clip: content-box;
        border-radius: ${borderRadius.sm}rem;
      }
    }

    &:focus {
      outline: none;
      ::-webkit-scrollbar,
      ::-webkit-scrollbar-thumb {
        outline: none;
        box-shadow: none;
      }
    }
  `}
`

/**
 * @deprecated Menu is deprecated, no replacement is planned
 */

export const Menu = React.forwardRef<HTMLUListElement, MenuProps>((props, ref) => {
  const { children, isOpen, onClose, transitionDuration, isInDropdown, exclusions, ...rest } = props
  const localRef = useRef<HTMLUListElement>(null)
  const optionsRef = (ref as React.RefObject<HTMLUListElement>) || localRef

  const [menuState, setMenuState] = useState(null)

  const handleKeyDown = (e: React.KeyboardEvent) => {
    const key = e.key
    const list = optionsRef.current
    const currentFocus = ownerDocument(list).activeElement
    if (key === KeyboardKeys.ArrowDown || key === KeyboardKeys.Home) {
      e.preventDefault()
      moveFocus(list, currentFocus, true, nextItem)
    } else if (key === KeyboardKeys.ArrowUp || key === KeyboardKeys.End) {
      e.preventDefault()
      moveFocus(list, currentFocus, true, previousItem)
    } else if (key === KeyboardKeys.Tab || key === KeyboardKeys.Escape) {
      e.preventDefault()
      onClose(e)
    }
  }

  const handleOutsideClick = (e: any) => {
    onClose(e)
  }

  useEffect(() => {
    const newMenuState = isOpen ? MenuState.Opened : MenuState.InTransition

    if (menuState) {
      setMenuState(MenuState.InTransition)

      setTimeout(() => {
        setMenuState(newMenuState)
      }, transitionDuration)
    } else {
      setMenuState(newMenuState)
    }
  }, [isOpen, menuState, transitionDuration])

  useClickOutside(optionsRef, handleOutsideClick, isOpen, exclusions)

  return (
    <MenuList
      focused={isOpen}
      onKeyDown={handleKeyDown}
      role="listbox"
      ref={optionsRef}
      menuState={menuState}
      transitionDuration={transitionDuration}
      aria-hidden={!isOpen}
      isInDropdown={isInDropdown}
      tabIndex={isOpen ? 0 : -1}
      {...rest}
    >
      {children}
    </MenuList>
  )
})

Menu.displayName = 'Menu'

Menu.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.any.isRequired,
  transitionDuration: PropTypes.number,
  menuOffset: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exclusions: PropTypes.arrayOf(PropTypes.string),
  zIndex: PropTypes.number,
  overflowMenuContainer: PropTypes.bool,
  overflowMenuContainerDirection: PropTypes.oneOf(Object.values(MenuOverflowDirection)),
  isInDropdown: PropTypes.bool,
  'aria-label': PropTypes.string.isRequired,
}

Menu.defaultProps = {
  transitionDuration: 200,
  menuOffset: 0,
  exclusions: [],
  isOpen: false,
  zIndex: 1,
  overflowMenuContainer: false,
  overflowMenuContainerDirection: MenuOverflowDirection.right,
  isInDropdown: false,
}
