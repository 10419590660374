import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const SendParcelIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M3.50003 7.27734L12 11.9996M12 11.9996L20.5 7.27734M12 11.9996L12 21.4996M21 10.4996V7.94104C21 7.5984 21 7.42708 20.9495 7.27428C20.9049 7.1391 20.8318 7.01502 20.7354 6.91033C20.6263 6.792 20.4766 6.70879 20.177 6.54239L12.777 2.43128C12.4934 2.27372 12.3516 2.19494 12.2014 2.16406C12.0685 2.13672 11.9315 2.13672 11.7985 2.16406C11.6484 2.19494 11.5066 2.27372 11.223 2.43128L3.82297 6.54239C3.52345 6.70879 3.37368 6.79199 3.26463 6.91033C3.16816 7.01502 3.09515 7.1391 3.05048 7.27428C3 7.42708 3 7.5984 3 7.94104V16.0582C3 16.4008 3 16.5721 3.05048 16.7249C3.09515 16.8601 3.16816 16.9842 3.26463 17.0889C3.37368 17.2072 3.52345 17.2904 3.82297 17.4568L11.223 21.5679C11.5066 21.7255 11.6484 21.8042 11.7985 21.8351C11.9315 21.8625 12.0685 21.8625 12.2014 21.8351C12.3516 21.8042 12.4934 21.7255 12.777 21.5679L16.477 19.5124" />
    <Path d="M16.5 9.5L7.5 4.5" />
    <Path d="M21 16L16 16" />
    <Path d="M20.5 18.5L22.9838 15.9998L20.5 13.5" />
  </SVG>
)

SendParcelIcon.propTypes = defaultIconPropTypes
SendParcelIcon.defaultProps = defaultPictographIconProps

export { SendParcelIcon }
