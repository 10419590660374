import React, { HTMLAttributes } from 'react'
import styled, { css, DefaultTheme, StyledComponent } from 'styled-components'
import { BrandTheme, XyzTheme } from '@postidigital/posti-theme'
import PropTypes, { Validator } from 'prop-types'
import { BrandBulletSize, BulletSize } from '../../utils/enums'
import { addNamespace } from '../../utils/helpers'

export interface BulletNavigationProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Total number of bullets
   */
  length: number
  /**
   * Index of currently active bullet
   */
  activeIndex: number
  /**
   * Color in hex
   */
  bulletColor?: string
  /**
   * Radius of a bullet (sizes from xxs to xxl)
   */
  bulletSize?: keyof typeof BulletSize | undefined
  bulletActiveColor?: string
  isBrand?: boolean
}

export interface BrandBulletNavigationProps extends Omit<BulletNavigationProps, 'bulletSize'> {
  /**
   * Radius of a bullet (sizes from sm to lg)
   */
  bulletSize?: keyof typeof BrandBulletSize | undefined
}

export interface BulletNavigationSubComponents {
  Container: StyledComponent<'div', DefaultTheme, NonNullable<unknown>, never>
  BulletItem: StyledComponent<
    'div',
    DefaultTheme,
    Pick<BulletNavigationProps, 'isBrand' | 'bulletColor' | 'bulletSize'> & {
      active: boolean
    },
    never
  >
}

const BulletSizes: { [key in BulletSize]: number } = {
  [BulletSize.xxs]: 0.375,
  [BulletSize.xs]: 0.5,
  [BulletSize.sm]: 0.625,
  [BulletSize.md]: 0.75,
  [BulletSize.lg]: 0.875,
  [BulletSize.xl]: 1,
  [BulletSize.xxl]: 1.125,
}

const BrandBulletSizes: { [key in BrandBulletSize]: number } = {
  [BrandBulletSize.sm]: 0.5,
  [BrandBulletSize.md]: 0.625,
  [BrandBulletSize.lg]: 0.75,
}

const BrandBulletActiveWidths: { [key in BrandBulletSize]: number } = {
  [BrandBulletSize.sm]: 1.5,
  [BrandBulletSize.md]: 1.75,
  [BrandBulletSize.lg]: 2,
}

const NavigationContainer = styled.div`
  display: flex;
`

const BulletItem = styled.div<
  Pick<BulletNavigationProps, 'bulletColor' | 'bulletSize' | 'isBrand' | 'bulletActiveColor'> & { active: boolean }
>(
  ({ theme: { spacing, brand, color }, active, bulletColor, bulletActiveColor, bulletSize, isBrand }) => css`
    margin: ${isBrand
      ? bulletSize === BrandBulletSize.sm
        ? brand.spacing.space1
        : brand.spacing.space2
      : spacing.space2}rem;
    width: ${isBrand ? `${BrandBulletSizes[bulletSize]}rem` : `${BulletSizes[bulletSize]}rem`};
    height: ${isBrand ? `${BrandBulletSizes[bulletSize]}rem` : `${BulletSizes[bulletSize]}rem`};
    background-color: ${bulletColor ?? (isBrand ? brand.color.gray30 : color.neutralBlack)};
    opacity: ${isBrand || active ? '1' : '0.4'};
    border-radius: 50%;

    ${active &&
    css`
      background-color: ${bulletActiveColor ?? (isBrand ? brand.color.gray70 : color.neutralBlack)};
      ${isBrand &&
      css`
        width: ${BrandBulletActiveWidths[bulletSize]}rem;
        border-radius: ${brand.spacing.space2}rem;
      `}
    `}
  `
)

export const BulletNavigationWithoutNamespace = React.forwardRef<
  HTMLDivElement,
  BulletNavigationProps & Pick<BrandBulletNavigationProps, 'bulletActiveColor'>
>((props, ref) => {
  const { length, activeIndex, bulletColor, bulletActiveColor, bulletSize, isBrand, ...rest } = props
  const bullets = [...Array(length)]
    .fill('')
    .map((_, index) => (
      <BulletItem
        active={index == activeIndex}
        bulletColor={bulletColor}
        bulletActiveColor={bulletActiveColor}
        bulletSize={bulletSize}
        key={index}
        isBrand={isBrand}
      />
    ))
  return (
    <NavigationContainer {...rest} ref={ref}>
      {bullets}
    </NavigationContainer>
  )
})

const BrandBulletNavigationWithoutNamespace = React.forwardRef<HTMLDivElement, BrandBulletNavigationProps>(
  ({ isBrand, bulletActiveColor, ...rest }, ref) => {
    return <BulletNavigationWithoutNamespace ref={ref} {...rest} isBrand={true} bulletActiveColor={bulletActiveColor} />
  }
)

const bulletNavigationSubComponents: BulletNavigationSubComponents = {
  Container: NavigationContainer,
  BulletItem: BulletItem,
}

BulletNavigationWithoutNamespace.displayName = 'BulletNavigation'
BrandBulletNavigationWithoutNamespace.displayName = 'BrandBulletNavigation'

const commonBulletNavigationPropTypes = {
  length: PropTypes.number,
  activeIndex: PropTypes.number,
  bulletColor: PropTypes.string,
  bulletActiveColor: PropTypes.string,
}

BulletNavigationWithoutNamespace.propTypes = {
  ...commonBulletNavigationPropTypes,
  isBrand: PropTypes.bool,
  bulletSize: PropTypes.oneOf(Object.keys(BulletSize)) as Validator<BulletSize>,
}

BrandBulletNavigationWithoutNamespace.propTypes = {
  ...BulletNavigationWithoutNamespace.propTypes,
  bulletSize: PropTypes.oneOf(Object.keys(BrandBulletSize)) as Validator<BrandBulletSize>,
}

BulletNavigationWithoutNamespace.defaultProps = {
  bulletColor: XyzTheme.color.neutralBlack,
  bulletSize: BulletSize.sm,
  isBrand: false,
}

BrandBulletNavigationWithoutNamespace.defaultProps = {
  bulletColor: BrandTheme.color.gray30,
  bulletActiveColor: BrandTheme.color.gray70,
  bulletSize: BrandBulletSize.sm,
}

export const BulletNavigation = addNamespace(BulletNavigationWithoutNamespace, bulletNavigationSubComponents)
export const BrandBulletNavigation = addNamespace(BrandBulletNavigationWithoutNamespace, bulletNavigationSubComponents)
