import styled, { css } from 'styled-components'

export const StyledTab = styled.button<{ selected: boolean; disabled: boolean; isBrand: boolean }>(
  ({ theme: { color, fontWeight, spacing, brand }, selected, disabled, isBrand }) => css`
    padding: ${spacing.space2}rem;
    border-radius: 0.25rem;
    outline: none;
    background-color: transparent;
    border: none;
    padding: ${spacing.space2}rem ${spacing.space3}rem;
    margin: 0 0 0.375rem;
    user-select: none;

    @media (hover: hover) {
      &:hover {
        background-color: ${isBrand ? brand.color.gray10 : color.neutralOnHoverGray};
      }
    }

    ${!selected &&
    css`
      &:active {
        background-color: ${isBrand ? brand.color.gray20 : color.neutralOnPressGray};
      }
    `}

    &:focus {
      outline: none;
      box-shadow: inset 0 0 0 3px ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    & > * {
      ${selected &&
      css`
        font-weight: ${fontWeight.semiBold};
        ${isBrand &&
        css`
          font-weight: ${brand.fontWeight.bold};
        `}
      `}
    }

    ${disabled &&
    css`
      color: ${color.neutralPassiveGray};
      pointer-events: none;
    `}
  `
)
