import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const DeclineShipmentIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path d="M11.55 19.67v-8.33l.1-.06 6.54-3.04.24-.1V12a4.33 4.33 0 00-4.4 6.52l-2.24 1.04-.24.11z" fill={color} />
    <Path
      d="M7.75 10.15a.16.16 0 00-.16.01.17.17 0 00-.07.14v1.38c0 .14-.06.27-.15.36a.5.5 0 01-.71 0 .52.52 0 01-.15-.36V9.73a.17.17 0 00-.1-.16L3.23 8.22 3 8.13v7.89a.7.7 0 00.41.64l6.9 2.93.23.11v-8.25-.11l-.1-.05-2.69-1.14zM13.33 5.61a.17.17 0 000-.31l-2.35-1.04a.66.66 0 00-.53 0L4 7.12a.17.17 0 000 .32l2.82 1.2a.16.16 0 00.14 0l6.37-3.03zM10.97 10.4a.17.17 0 00.14 0l6.35-2.95a.17.17 0 00.1-.16c0-.03 0-.06-.03-.1a.17.17 0 00-.07-.06l-2.5-1.1a.17.17 0 00-.12 0l-6.35 3a.17.17 0 000 .31l2.48 1.06z"
      fill={color}
    />
    <Path
      d="M15.55 17.5l1.09-1.09-1.1-1.1 1.1-1.09 1.09 1.1 1.09-1.1 1.09 1.1-1.1 1.1 1.1 1.09-1.1 1.1-1.08-1.1-1.1 1.1-1.08-1.1z"
      fill={color}
    />
  </SVG>
)

DeclineShipmentIcon.propTypes = defaultIconPropTypes

DeclineShipmentIcon.defaultProps = defaultPictographIconProps

export { DeclineShipmentIcon }
