import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

const VisaIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M3 7.49h18v8.8H3v-8.8z" fill="#F6F6F6" />
    <Path d="M19.65 6.5H3.9c-.45 0-.9.44-.9.88V8.7h18v-.88c0-.88-.45-1.32-1.35-1.32z" fill="#265697" />
    <Path d="M3 15.3v.88c0 .88.45 1.32 1.35 1.32h15.3c.9 0 1.35-.44 1.35-1.32v-.88H3z" fill="#D97B16" />
    <Path
      d="M15.56 9.78a3.3 3.3 0 00-1.14-.2c-1.26 0-2.15.63-2.16 1.54 0 .67.64 1.04 1.12 1.27.5.23.66.37.66.58 0 .31-.4.45-.76.45-.51 0-.78-.07-1.2-.24l-.17-.08-.17 1.05c.3.13.84.24 1.41.25 1.34 0 2.21-.63 2.22-1.6 0-.53-.33-.93-1.07-1.27-.44-.21-.72-.36-.71-.57 0-.2.23-.4.73-.4.32-.01.65.05.95.17l.11.06.18-1.02"
      fill="#265697"
    />
    <Path
      d="M18.82 9.67h-.98c-.3 0-.54.08-.67.38l-1.89 4.28h1.34l.27-.7h1.63l.15.7h1.18l-1.03-4.66zm-1.57 3l.5-1.3.18-.45.08.4.3 1.35h-1.06z"
      fill="#265697"
    />
    <Path
      d="M9.67 14.33l.8-4.67h1.26l-.8 4.67H9.68zM8.6 9.66l-1.25 3.19-.13-.65a3.65 3.65 0 00-1.77-1.96l1.15 4.08h1.34l2-4.66H8.6z"
      fill="#265697"
    />
    <Path d="M6.2 9.66H4.14l-.02.1c1.6.38 2.66 1.32 3.1 2.44l-.45-2.14c-.08-.3-.3-.39-.58-.4" fill="#D97B16" />
  </SVG>
)

VisaIcon.propTypes = defaultIconPropTypes

VisaIcon.defaultProps = defaultPictographIconProps

export { VisaIcon }
