import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const LanguageIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M4.76 3H21V21H3v-1.27V3a3140.46 3140.46 0 011.76 0zm3.52 10.68h2.64l.42 1.2h1.36L10.63 9H8.57L6.5 14.88h1.35l.43-1.2zM9.6 9.93l.96 2.72H8.64l.96-2.72zm3.98 4.72c.26.23.62.35 1.07.35.43 0 .78-.1 1.06-.28.28-.2.47-.46.58-.78 0 .32.02.63.07.95h1.14c-.05-.41-.07-.8-.07-1.16v-1.9c0-.48-.17-.86-.5-1.12-.34-.27-.84-.4-1.5-.4s-1.19.14-1.57.43c-.38.3-.57.68-.6 1.16h1.3c0-.46.31-.69.9-.69.28 0 .48.05.59.14.12.08.17.21.17.38 0 .13-.04.22-.12.29a1 1 0 01-.42.15l-1.05.18c-.54.09-.92.25-1.13.46-.21.22-.32.52-.32.9 0 .4.13.7.4.94zm2.33-.9c-.2.24-.47.36-.82.36a.73.73 0 01-.45-.14.5.5 0 01-.17-.4c0-.16.05-.29.15-.38.1-.1.27-.18.5-.23l.38-.1.4-.1c.1-.05.22-.1.32-.18v.2c0 .4-.1.73-.3.97z"
      fill={color}
    />
  </SVG>
)

LanguageIcon.propTypes = defaultIconPropTypes

LanguageIcon.defaultProps = defaultPictographIconProps

export { LanguageIcon }
