import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const EyeIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M20.5765 10.9034C18.5921 8.7407 15.3007 6.45463 11.9995 6.50071C8.6983 6.45389 5.40687 8.74144 3.42241 10.9034C3.15039 11.2047 2.5 12 2.5 12C2.5 12 3.15039 12.7931 3.42241 13.0943C5.38361 15.234 8.62852 17.5 11.8787 17.5H12.1098C15.3712 17.5 18.6153 15.234 20.5788 13.0936C20.8505 12.7922 21.5 12 21.5 12C21.5 12 20.8488 11.2043 20.5765 10.9034ZM8.54824 12.0004C8.54824 11.3242 8.75066 10.6632 9.12988 10.101C9.50911 9.53882 10.0481 9.10064 10.6787 8.84189C11.3094 8.58313 12.0033 8.51543 12.6728 8.64734C13.3423 8.77925 13.9572 9.10485 14.4399 9.58297C14.9225 10.0611 15.2512 10.6702 15.3844 11.3334C15.5176 11.9966 15.4492 12.6839 15.188 13.3086C14.9268 13.9333 14.4844 14.4672 13.9169 14.8429C13.3493 15.2185 12.6821 15.4191 11.9995 15.4191C11.0842 15.4191 10.2063 15.0589 9.55909 14.4177C8.91186 13.7766 8.54824 12.907 8.54824 12.0004Z"
      fill={color}
    />
    <Path
      d="M11.9996 13.4873C12.8283 13.4873 13.5001 12.8218 13.5001 12.0009C13.5001 11.18 12.8283 10.5145 11.9996 10.5145C11.1708 10.5145 10.499 11.18 10.499 12.0009C10.499 12.8218 11.1708 13.4873 11.9996 13.4873Z"
      fill={color}
    />
  </SVG>
)

EyeIcon.propTypes = defaultIconPropTypes

EyeIcon.defaultProps = defaultPictographIconProps
