import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const SearchIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      d="M10.2736 14.5471C12.6338 14.5471 14.5471 12.6338 14.5471 10.2736C14.5471 7.91334 12.6338 6 10.2736 6C7.91334 6 6 7.91334 6 10.2736C6 12.6338 7.91334 14.5471 10.2736 14.5471ZM10.2736 17.5471C14.2906 17.5471 17.5471 14.2906 17.5471 10.2736C17.5471 6.25649 14.2906 3 10.2736 3C6.25649 3 3 6.25649 3 10.2736C3 14.2906 6.25649 17.5471 10.2736 17.5471Z"
      fill={color}
    />
    <Path
      d="M13.566 15.4948L15.4939 13.5668L20.036 18.1089C20.5684 18.6413 20.5684 19.5045 20.036 20.0368C19.5037 20.5692 18.6405 20.5692 18.1081 20.0368L13.566 15.4948Z"
      fill={color}
    />
  </SVG>
)

SearchIcon.propTypes = defaultIconPropTypes

SearchIcon.defaultProps = defaultPictographIconProps
