import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Circle, SVG } from '../brand-icons.styled'

const ServicesIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Circle cx="7" cy="12" r="1" />
    <Circle cx="17" cy="12" r="1" />
    <Circle cx="12" cy="17" r="1" transform="rotate(-90 12 17)" />
    <Circle cx="12" cy="7" r="1" transform="rotate(-90 12 7)" />
  </SVG>
)

ServicesIcon.propTypes = defaultIconPropTypes
ServicesIcon.defaultProps = defaultPictographIconProps

export { ServicesIcon }
