import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Input, inputDefaultProps, inputPropTypes } from '../Input/Input'
import { PasswordProps, PasswordSubComponents } from './Password.types'
import { EyeIcon, HiddenEyeIcon } from '../../design-tokens/icons/pictograph'
import { EyeIcon as BrandEyeIcon, HiddenEyeIcon as BrandHiddenEyeIcon } from '../../design-tokens/icons/brand-ui-icons'
import { useTheme } from '../../utils/useTheme'
import { IconButton } from '../../deprecated/IconButton'
import { BrandButton } from '../BrandButton'
import { addNamespace } from '../../utils/helpers'
import withBrand from '../../utils/withBrand'
import { BrandSize, BrandMode } from '../../utils/enums'

const PasswordWithoutNamespace = React.forwardRef<HTMLInputElement, PasswordProps>((props, forwardRef) => {
  const theme = useTheme()
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const type = props.type || (showPassword ? 'text' : 'password')

  const iconRightTemplateProps = {
    onClick: toggleShowPassword,
    disabled: props.disabled,
    icon: props.isBrand ? (showPassword ? BrandHiddenEyeIcon : BrandEyeIcon) : showPassword ? HiddenEyeIcon : EyeIcon,
    iconColor: props.isBrand ? theme.brand.color.gray50 : theme.color.neutralIconGray,
    'aria-label': showPassword ? props.hideIconAriaLabel : props.showIconAriaLabel,
  }

  const rightTemplate =
    props.rightTemplate ||
    (props.isBrand ? (
      <BrandButton size={BrandSize.xxxs} contentMode={BrandMode.secondary} hasBackground {...iconRightTemplateProps} />
    ) : (
      <IconButton {...iconRightTemplateProps} />
    ))

  return (
    <Input
      {...props}
      ref={forwardRef}
      type={type}
      rightTemplate={rightTemplate}
      aria-atomic={true}
      aria-live="assertive"
    />
  )
})

const passwordSubComponents: PasswordSubComponents = {
  Icon: IconButton,
  Input: Input,
}

PasswordWithoutNamespace.displayName = 'Password'
PasswordWithoutNamespace.defaultProps = inputDefaultProps

PasswordWithoutNamespace.propTypes = {
  ...inputPropTypes,
  showIconAriaLabel: PropTypes.string,
  hideIconAriaLabel: PropTypes.string,
}

const BrandPasswordWithoutNamespace = withBrand(PasswordWithoutNamespace)

export const Password = addNamespace(PasswordWithoutNamespace, passwordSubComponents)
export const BrandPassword = addNamespace(BrandPasswordWithoutNamespace, passwordSubComponents)
