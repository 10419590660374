import React, { InputHTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import { useRadioGroup } from '../RadioGroup/RadioGroup'
import {
  StyledRoot,
  StyledContainer,
  StyledInput,
  StyledLabel,
  StyledSuccessIcon,
  StyledRadio,
  StyledIconWrapper,
} from './Radio.style'
import { useTheme } from '../../utils/useTheme'
import { UIMessage, UIMessageProps, UIMessageSubComponents } from '../UIMessage/UIMessage'
import { RadioSize, Position, RadioMode } from '../../utils/enums'
import { DefaultTheme, StyledComponent } from 'styled-components'
import { addNamespace } from '../../utils/helpers'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import withBrand from '../../utils/withBrand'

export interface RadioProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  label?: string | JSX.Element
  size?: RadioSize | keyof typeof RadioSize
  contentMode?: RadioMode | keyof typeof RadioMode
  labelPosition?: Position | keyof typeof Position
  isInvalid?: boolean
  disabled?: boolean
  checked?: boolean
  isBrand?: boolean
  errorMessage?: string
}

export interface RadioSubComponents {
  Root: StyledComponent<'div', DefaultTheme, { isBrand?: boolean }, never>
  Container: StyledComponent<
    'div',
    DefaultTheme,
    {
      small: boolean
      success: boolean
      isInvalid: boolean
      isBrand: boolean
    },
    never
  >
  Label: StyledComponent<
    'label',
    DefaultTheme,
    {
      labelPosition: Position
      isBrand: boolean
      disabled: boolean
    },
    never
  >
  Radio: StyledComponent<'div', DefaultTheme, NonNullable<unknown>, never>
  Input: StyledComponent<'input', DefaultTheme, NonNullable<unknown>, never>
  IconWrapper: StyledComponent<'div', DefaultTheme, NonNullable<unknown>, never>
  SuccessIcon: StyledComponent<React.FC<DefaultIconProps>, DefaultTheme, NonNullable<unknown>, never>
  UIMessage: React.FC<UIMessageProps> & UIMessageSubComponents
}

export const RadioWithoutNamespace = React.forwardRef<HTMLInputElement, RadioProps>((props, ref) => {
  const {
    id,
    name,
    value,
    label,
    size,
    contentMode,
    checked: checkedProp,
    disabled: disabledProp,
    labelPosition,
    isInvalid: isInvalidProp,
    onChange: onChangeProp,
    isBrand,
    errorMessage,
    ...rest
  } = props
  const theme = useTheme()
  const radioGroup = useRadioGroup()
  let checked = checkedProp
  let onChange = onChangeProp
  let disabled = disabledProp
  let isInvalid = isInvalidProp
  const successIconColor = isBrand
    ? disabled
      ? theme.brand.color.gray30
      : theme.brand.color.green80
    : disabled
      ? theme.color.neutralGray5
      : theme.color.signalGreen

  if (radioGroup) {
    if (typeof checked === 'undefined') {
      checked = radioGroup.value === value
    }

    if (radioGroup.onChange) {
      onChange = radioGroup.onChange
    }

    if (radioGroup.disabled) {
      disabled = radioGroup.disabled
    }

    if (radioGroup.isInvalid) {
      isInvalid = radioGroup.isInvalid
    }

    if (radioGroup.value) {
      isInvalid = false
    }
  }
  const messageId = id ? `${id}-message` : `radio-message`
  const showInvalid = isInvalid && !checked

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  const renderRadio = () => (
    <StyledContainer
      isBrand={isBrand}
      small={size === RadioSize.small}
      success={contentMode === RadioMode.success}
      isInvalid={showInvalid}
    >
      <StyledInput
        id={id}
        ref={ref}
        type="radio"
        name={name}
        value={value}
        checked={checked}
        aria-checked={checked}
        onChange={handleChange}
        aria-invalid={showInvalid}
        aria-describedby={messageId}
        aria-disabled={disabled}
        {...rest}
      />
      <StyledRadio />
      {contentMode === RadioMode.success && (
        <StyledIconWrapper>
          <StyledSuccessIcon color={successIconColor} />
        </StyledIconWrapper>
      )}
    </StyledContainer>
  )

  return (
    <StyledRoot isBrand={isBrand}>
      {label ? (
        <StyledLabel labelPosition={labelPosition as Position} isBrand={isBrand} aria-disabled={disabled}>
          {labelPosition === Position.left && label}
          {renderRadio()}
          {labelPosition === Position.right && label}
        </StyledLabel>
      ) : (
        renderRadio()
      )}
      <div id={messageId} aria-live="assertive">
        {errorMessage && showInvalid && !disabled && !radioGroup && (
          <UIMessage isBrand={isBrand} message={errorMessage} />
        )}
      </div>
    </StyledRoot>
  )
})

RadioWithoutNamespace.displayName = 'Radio'

RadioWithoutNamespace.propTypes = {
  label: PropTypes.string,
  size: PropTypes.oneOf(Object.values(RadioSize)),
  contentMode: PropTypes.oneOf(Object.values(RadioMode)),
  labelPosition: PropTypes.oneOf(Object.values(Position)),
  isInvalid: PropTypes.bool,
  isBrand: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
}

RadioWithoutNamespace.defaultProps = {
  size: RadioSize.default,
  contentMode: RadioMode.default,
  labelPosition: Position.left,
  isInvalid: false,
  name: 'radio',
  isBrand: false,
  disabled: false,
}

const BrandRadioWithoutNamespace = withBrand(RadioWithoutNamespace)

const radioSubComponents: RadioSubComponents = {
  Root: StyledRoot,
  Container: StyledContainer,
  Label: StyledLabel,
  Radio: StyledRadio,
  Input: StyledInput,
  IconWrapper: StyledIconWrapper,
  SuccessIcon: StyledSuccessIcon,
  UIMessage: UIMessage,
}

export const Radio = addNamespace(RadioWithoutNamespace, radioSubComponents)
export const BrandRadio = addNamespace(BrandRadioWithoutNamespace, radioSubComponents)
