import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const HelpostiIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M21 3H3v18h18V3zm-5.47 10.8v1.1h-4.98v-.87l2.54-2.11c.27-.23.45-.42.55-.58.09-.17.14-.33.14-.5a.6.6 0 00-.28-.52c-.17-.13-.43-.2-.78-.2-.29 0-.55.06-.78.16-.23.09-.43.23-.58.43l-1.11-.63c.25-.34.6-.6 1.06-.79.45-.2.97-.29 1.55-.29.5 0 .92.07 1.28.22.37.13.66.33.86.6.2.25.31.55.31.9 0 .31-.07.6-.22.88-.16.28-.45.6-.88.95l-1.52 1.26h2.84zm-7.37-2.34c.57.08 1.02.27 1.32.57.31.3.46.66.46 1.1 0 .33-.1.65-.3.93-.2.29-.5.51-.92.68-.4.17-.9.26-1.5.26-.47 0-.93-.05-1.38-.16a3.85 3.85 0 01-1.14-.46l.6-1.03c.24.17.53.3.86.39.33.09.67.13 1.02.13.38 0 .68-.06.9-.19a.62.62 0 00.33-.55c0-.48-.41-.72-1.23-.72h-.7v-.88l1.36-1.35H5.03V9.1h4.61v.87l-1.48 1.5zM18.7 14.9V9.1h-2.84v1.08h1.31v4.72h1.53z"
      fill={color}
    />
  </SVG>
)

HelpostiIcon.propTypes = defaultIconPropTypes

HelpostiIcon.defaultProps = defaultPictographIconProps

export { HelpostiIcon }
