import React, { HTMLAttributes } from 'react'
import { DefaultTheme, StyledComponent } from 'styled-components'
import PropTypes from 'prop-types'
import { Container, LeftSection, RightSection, CenterSection, Content } from './Snackbar.style'
import { addNamespace } from '../../utils/helpers'
import withBrand from '../../utils/withBrand'

export interface SnackbarProps extends HTMLAttributes<HTMLDivElement> {
  isBrand?: boolean
  children: React.ReactNode
}
interface SnackbarSubComponents {
  Container: StyledComponent<'div', DefaultTheme, NonNullable<unknown>, never>
}

interface BrandSnackbarSubComponents {
  Left: typeof LeftSection
  Right: typeof RightSection
  Center: typeof CenterSection
  Content: typeof Content
}

const SnackbarWithoutNamespace = React.forwardRef<HTMLDivElement, SnackbarProps>(
  ({ children, isBrand, ...rest }, ref) => {
    return (
      <Container ref={ref} isBrand={isBrand} role="status" aria-live="polite" {...rest}>
        {children}
      </Container>
    )
  }
)

const snackbarSubComponents: SnackbarSubComponents = {
  Container: Container,
}

const BrandSnackbarWithoutNamespace = withBrand(SnackbarWithoutNamespace)

const brandSnackbarSubComponents: BrandSnackbarSubComponents = {
  Left: LeftSection,
  Right: RightSection,
  Center: CenterSection,
  Content: Content,
}

SnackbarWithoutNamespace.propTypes = {
  isBrand: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

SnackbarWithoutNamespace.defaultProps = {
  isBrand: false,
}

SnackbarWithoutNamespace.displayName = 'Snackbar'
BrandSnackbarWithoutNamespace.displayName = 'Brand Snackbar'

export const Snackbar = addNamespace(SnackbarWithoutNamespace, snackbarSubComponents)
export const BrandSnackbar = addNamespace(BrandSnackbarWithoutNamespace, brandSnackbarSubComponents)
