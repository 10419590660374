import React, { ForwardedRef, forwardRef } from 'react'
import PropTypes from 'prop-types'
import styled, { css, StyledComponent, DefaultTheme } from 'styled-components'
import { Dropdown, DropdownInstance, DropdownProps, DropdownPropTypes } from '../Dropdown'
import {
  StyledControlContainer,
  cleanSlateDropdown,
  StyledButtonContainer,
  ControlContainer,
} from '../Dropdown/Dropdown.styles'
import { addNamespace } from '../../utils/helpers'

export interface ToolDropdownProps extends Omit<DropdownProps, 'isDisabled'> {
  isSelected?: boolean
  disabled?: boolean
}

interface ToolDropdownSubComponents {
  Main: StyledComponent<'div', DefaultTheme, Pick<ToolDropdownProps, 'isSelected' | 'value'>, never>
}

export const StyledToolDropdown = styled.div<Pick<ToolDropdownProps, 'isSelected' | 'value'>>`
  ${({ theme: { spacing, color, borderRadius }, value, isSelected }) => css`
    display: inline-block;
    position: relative;
    height: 100%;

    ${StyledControlContainer} {
      margin-right: ${spacing.space3}rem;
    }

    .styled-label.shrinked {
      display: none;
    }

    .select {
      &__menu {
        width: auto;
      }
      &__option {
        padding: ${spacing.space3}rem;
        span {
          text-align: left;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
      &__control {
        ${cleanSlateDropdown}
        min-height: 3rem;
        border: none;
        border-radius: ${borderRadius.xs}rem;
        box-shadow: inset 0px 0px 0px 2px ${color.neutralGray5};
        color: ${color.neutralNetworkGray};

        ${StyledButtonContainer} {
          padding: 0 0.75rem;
        }
        ${ControlContainer} {
          height: 3rem;
        }

        ${(isSelected !== undefined ? isSelected : !!value) &&
        css`
          background-color: ${color.brandPetrol};
          .select__single-value {
            color: ${color.neutralWhite};
          }
          svg * {
            fill: ${color.neutralWhite};
          }
          @media (hover: hover) {
            &:hover,
            &:active,
            &:focus {
              background-color: ${color.brandPetrol};
              .select__single-value {
                color: ${color.neutralWhite};
              }
              svg * {
                fill: ${color.neutralWhite};
              }
            }
          }
        `}
        &--is-focused {
          border-color: transparent;
          box-shadow:
            inset 0px 0px 0px 2px
              ${(isSelected !== undefined ? isSelected : !!value) ? color.neutralGray5 : color.brandPetrol},
            0px 0px 7px ${color.signalBlue};
        }
        &--is-disabled {
          box-shadow: inset 0px 0px 0px 2px ${color.neutralPassiveGray};
          border-color: ${color.neutralGray5};
          background: ${(isSelected !== undefined ? isSelected : !!value)
            ? color.neutralPassiveGray
            : color.neutralGray5};
          color: ${color.neutralPassiveGray};
        }
      }
    }
  `}
`
/**
 * HoC is needed to enable the correct work of the ButtonGroup component (Toolbar is an instance of a ButtonGroup),
 * which requires its chidlren to a `disabled` prop instead of `isDisabled`, as a Dropdown has
 */
export const ToolDropdownWithoutNamespace = forwardRef(
  (props: ToolDropdownProps, ref: ForwardedRef<DropdownInstance>) => {
    const { disabled, isSelected, value, ...rest } = props
    return (
      <StyledToolDropdown isSelected={isSelected} value={value}>
        <Dropdown {...rest} ref={ref} isDisabled={disabled} />
      </StyledToolDropdown>
    )
  }
)

const toolDropdownSubComponents: ToolDropdownSubComponents = {
  Main: StyledToolDropdown,
}

ToolDropdownWithoutNamespace.displayName = 'ToolDropdown'

ToolDropdownWithoutNamespace.propTypes = {
  ...DropdownPropTypes,
  isSelected: PropTypes.bool,
  disabled: PropTypes.bool,
}

ToolDropdownWithoutNamespace.defaultProps = {
  disabled: false,
}

export const ToolDropdown = addNamespace(ToolDropdownWithoutNamespace, toolDropdownSubComponents)
