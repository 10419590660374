import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'
import { useTheme } from '../../../utils/useTheme'

const ParcelSmallPlusIcon: React.FC<DefaultIconProps> = (props) => {
  const theme = useTheme()
  return (
    <SVG {...props}>
      <Path
        d="M0 19.5C0 9.97029 5.27703 0 22 0C38.723 0 44 9.97029 44 19.5C44 29.0304 38.723 39 22 39C5.27703 39.0007 0 29.1036 0 19.5Z"
        fill={theme.color.brandSky}
      />
      <Path
        d="M13.3865 23.1842C13.4771 23.8365 13.7669 24.3981 14.2561 24.8692C14.7634 25.3221 15.5153 25.5486 16.5118 25.5486C17.4177 25.5486 18.0971 25.4036 18.55 25.1138C19.0211 24.8058 19.2566 24.38 19.2566 23.8365C19.2566 23.3292 19.1026 22.9487 18.7946 22.6951C18.5047 22.4233 18.0699 22.2512 17.4901 22.1787L13.6582 21.7711C11.9733 21.5899 10.6688 21.0282 9.74483 20.0861C8.82083 19.144 8.35883 17.8939 8.35883 16.3358C8.35883 14.5421 9.03824 13.1199 10.3971 12.0691C11.774 11.0182 13.7579 10.4928 16.3487 10.4928C19.0482 10.4928 21.0593 11.0364 22.3819 12.1234C23.7226 13.2105 24.4564 14.6236 24.5832 16.3629H19.2022C19.1117 15.7469 18.8218 15.2668 18.3326 14.9226C17.8615 14.5784 17.2093 14.4062 16.3759 14.4062C15.6149 14.4062 14.9989 14.5421 14.5279 14.8139C14.0749 15.0856 13.8485 15.4571 13.8485 15.9281C13.8485 16.8159 14.4464 17.3141 15.6421 17.4228L18.7674 17.6946C20.8328 17.8758 22.3457 18.4465 23.3059 19.4067C24.2661 20.3488 24.7462 21.6805 24.7462 23.4016C24.7462 25.304 24.0849 26.7896 22.7624 27.8586C21.4398 28.9275 19.32 29.462 16.4031 29.462C13.6492 29.462 11.6109 28.8732 10.2884 27.6955C8.98389 26.4998 8.25012 24.996 8.08707 23.1842H13.3865Z"
        fill={theme.color.brandNavy}
      />
      <Path
        d="M36.789 16.4824H33.3556V20.0287H30.2836V16.4824H26.8501V13.6136H30.2836V10.0673H33.3556V13.6136H36.789V16.4824Z"
        fill={theme.color.brandNavy}
      />
    </SVG>
  )
}

ParcelSmallPlusIcon.defaultProps = {
  viewBox: '0 0 44 39',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}

export { ParcelSmallPlusIcon }
