import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const CheckmarkIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M20 6L9 17L4 12" />
  </SVG>
)

CheckmarkIcon.propTypes = defaultIconPropTypes
CheckmarkIcon.defaultProps = defaultPictographIconProps

export { CheckmarkIcon }
