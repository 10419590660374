import React, { forwardRef, useRef } from 'react'
/* eslint-disable */
import DatePicker from 'react-datepicker'
import { CustomInputProps } from '../../basic-components/Input/Input.types'
import {
  StyledMonthPickerWrapper,
  YearName,
  HeaderContainer,
  StyledMonthNavigationButton,
  StyledContainer,
  YearNameLabel,
} from './MonthPicker.styles'
import { HeadlineSize } from '../../design-tokens/typography'
import PropTypes, { Validator } from 'prop-types'
import { LabelSize as BrandLabelSize } from '../../design-tokens/brand-typography'
import { CustomInput } from '../DatePicker/DatePicker'
import { inputPropTypes } from '../../basic-components/Input/Input'
import { MonthPickerProps, MonthPickerRef, MonthPickerSubComponents } from './MonthPicker.types'
import { addNamespace } from '../../utils/helpers'
import withBrand from '../../utils/withBrand'

const MonthPickerWithoutNamespace = forwardRef<MonthPickerRef, MonthPickerProps>(
  (
    {
      prevYearButtonAriaLabel,
      nextYearButtonAriaLabel,
      selectsStart,
      selectsEnd,
      isBrand,
      inputProps,
      disabled,
      ...props
    },
    ref
  ) => {
    const monthRef = useRef<HTMLInputElement>()

    const validMonths = (date: Date) => {
      if (selectsStart || selectsEnd) {
        const time = date.getMonth()
        const from = props.startDate.getMonth()
        const to = props.endDate.getMonth()
        return time >= from && time <= to
      } else return true
    }

    return (
      <StyledContainer selectsStart={selectsStart} selectsEnd={selectsEnd} isBrand={isBrand}>
        <DatePicker
          ref={ref}
          dateFormat="MMMM"
          showMonthYearPicker
          customInput={<CustomInput ref={monthRef} isBrand={isBrand} disabled={disabled} {...inputProps} />}
          calendarContainer={StyledMonthPickerWrapper}
          showFourColumnMonthYearPicker
          filterDate={validMonths}
          disabled={disabled}
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 16],
              },
            },
          ]}
          renderCustomHeader={({ monthDate, decreaseYear, increaseYear }) => (
            <HeaderContainer>
              <StyledMonthNavigationButton
                orientation={'left'}
                aria-label={prevYearButtonAriaLabel}
                onClick={decreaseYear}
                isBrand={isBrand}
              />
              {isBrand ? (
                <YearNameLabel size={BrandLabelSize.Three}>
                  {monthDate.toLocaleString('en-US', {
                    year: 'numeric',
                  })}
                </YearNameLabel>
              ) : (
                <YearName size={HeadlineSize.Six}>
                  {monthDate.toLocaleString('en-US', {
                    year: 'numeric',
                  })}
                </YearName>
              )}
              <StyledMonthNavigationButton
                orientation={'right'}
                aria-label={nextYearButtonAriaLabel}
                onClick={increaseYear}
                isBrand={isBrand}
              />
            </HeaderContainer>
          )}
          {...props}
        />
      </StyledContainer>
    )
  }
)

MonthPickerWithoutNamespace.defaultProps = {
  locale: 'fi',
  prevYearButtonAriaLabel: 'Previous',
  nextYearButtonAriaLabel: 'Next',
}

MonthPickerWithoutNamespace.propTypes = {
  locale: PropTypes.string,
  prevYearButtonAriaLabel: PropTypes.string.isRequired,
  nextYearButtonAriaLabel: PropTypes.string.isRequired,
  isBrand: PropTypes.bool,
  inputProps: PropTypes.shape({
    ...inputPropTypes,
  }) as Validator<CustomInputProps>,
}

const BrandMonthPickerWithoutNamespace = withBrand(MonthPickerWithoutNamespace)

const monthPickerSubComponents: MonthPickerSubComponents = {
  Container: StyledContainer,
  Main: DatePicker,
  HeaderContainer: HeaderContainer,
  MonthNavigationButton: StyledMonthNavigationButton,
  YearNameLabel: YearNameLabel,
  YearName: YearName,
}

export const MonthPicker = addNamespace(MonthPickerWithoutNamespace, monthPickerSubComponents)
export const BrandMonthPicker = addNamespace(BrandMonthPickerWithoutNamespace, monthPickerSubComponents)
