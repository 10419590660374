export const ownerDocument = (node: Node): Document => {
  return (node && node.ownerDocument) || document
}

export const nextItem = (list: Node, item: Element, disableListWrap: boolean): ChildNode | null => {
  if (list === item) {
    return list.firstChild
  }

  if (item && item.nextElementSibling) {
    return item.nextElementSibling
  }

  return disableListWrap ? null : list.firstChild
}

export const previousItem = (list: Node, item: Element, disableListWrap: boolean): ChildNode | null => {
  if (list === item) {
    return disableListWrap ? list.firstChild : list.lastChild
  }

  if (item && item.previousElementSibling) {
    return item.previousElementSibling
  }

  return disableListWrap ? null : list.lastChild
}

type TraversalFunction = (list: Node, currentFocus: Element, disableListWrap: boolean) => null | ChildNode

export const moveFocus = (
  list: Node,
  currentFocus: Element,
  disableListWrap: boolean,
  traversalFunction: TraversalFunction
): boolean => {
  let wrappedOnce = false
  let nextFocus = traversalFunction(list, currentFocus, currentFocus ? disableListWrap : false) as HTMLElement

  while (nextFocus) {
    // Prevent infinite loop.
    if (nextFocus === list.firstChild) {
      if (wrappedOnce) {
        return false
      }

      wrappedOnce = true
    }
    // Move to the next element.
    if (
      !nextFocus.hasAttribute('tabindex') ||
      nextFocus.getAttribute('aria-hidden') === 'true' ||
      nextFocus.getAttribute('aria-disabled') === 'true'
    ) {
      nextFocus = traversalFunction(list, nextFocus, disableListWrap) as HTMLElement
    } else {
      nextFocus.focus()
      return true
    }
  }

  return false
}
