import React from 'react'
import styled, { css, StyledComponent, DefaultTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { UIMessage } from '../UIMessage'
import { UIMessageProps } from '../UIMessage/UIMessage'

export interface ErrorMessagesProps extends Omit<UIMessageProps, 'message'> {
  errorMessages: string[]
  isBrand?: boolean
}

export interface ErrorMessageSubComponents {
  Container: StyledComponent<'div', DefaultTheme, NonNullable<unknown>, never>
  Notification: StyledComponent<(props: any) => JSX.Element, DefaultTheme, NonNullable<unknown>, never>
}

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StyledNotification = styled((props) => <UIMessage {...props} />)`
  ${({ theme: { spacing } }) => css`
    margin: ${spacing.space2}rem 0;
    padding: 0;
  `}
`

const ErrorMessages: React.FC<ErrorMessagesProps> & ErrorMessageSubComponents = ({
  errorMessages,
  isBrand,
  ...rest
}) => {
  return (
    <ErrorContainer aria-live="polite" aria-hidden={!errorMessages.length}>
      {!!errorMessages.length &&
        errorMessages.map((msg) => <StyledNotification key={msg} message={msg} isBrand={isBrand} {...rest} />)}
    </ErrorContainer>
  )
}

ErrorMessages.Container = ErrorContainer
ErrorMessages.Notification = StyledNotification

ErrorMessages.propTypes = {
  errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  isBrand: PropTypes.bool,
}

export { ErrorMessages }
