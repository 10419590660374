import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, Rect, SVG } from '../brand-icons.styled'

const HelpostiIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Rect x="3" y="3" width="18" height="18" rx="3" stroke={color} strokeLinejoin="round" />
    <Path d="M8.44645 16H10.0855V8.3H8.62245L6.93945 9.455V11.061L8.44645 10.06V16Z" fill={color} />
    <Path
      d="M11.6144 16H17.1144V14.515H13.9794L15.0464 13.547C16.2454 12.491 16.9054 11.545 16.9054 10.522C16.9054 9.191 15.9154 8.146 14.2874 8.146C12.8244 8.146 11.8124 9.059 11.4164 10.225L12.8464 10.775C13.0884 10.071 13.6054 9.631 14.2324 9.631C14.9254 9.631 15.2664 10.038 15.2664 10.555C15.2664 11.138 14.8154 11.765 13.7484 12.799L11.6144 14.812V16Z"
      fill={color}
    />
  </SVG>
)

HelpostiIcon.propTypes = defaultIconPropTypes
HelpostiIcon.defaultProps = defaultPictographIconProps

export { HelpostiIcon }
