import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Circle, Path, SVG } from '../brand-icons.styled'

const PersonWheelchairIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M6.09193 12C5.40904 12.8437 5 13.918 5 15.0878C5 17.8007 7.19994 20 9.9137 20C11.6174 20 13.1185 19.1332 14 17.8167" />
    <Circle cx="10" cy="4" r="1" />
    <Path d="M19 18L16.96 15.28C16.608 14.8107 16.432 14.576 16.2089 14.4068C16.0114 14.2568 15.7877 14.145 15.5492 14.0769C15.28 14 14.9867 14 14.4 14H10V8" />
    <Path d="M10 10H13" />
  </SVG>
)

PersonWheelchairIcon.propTypes = defaultIconPropTypes
PersonWheelchairIcon.defaultProps = defaultPictographIconProps

export { PersonWheelchairIcon }
