import React from 'react'
import styled, { css, StyledComponent, DefaultTheme } from 'styled-components'
import PropTypes from 'prop-types'
import { Body, BodySize, BodyProps } from '../../design-tokens/typography'
import { AlertIndicatorIcon, CheckmarkIndicatorIcon } from '../../design-tokens/icons/indicator'
import { Label, LabelSize, LabelProps } from '../../design-tokens/brand-typography'
import { AlertIcon } from '../../design-tokens/icons/brand-ui-icons'
import { useTheme } from '../../utils/useTheme'
import { BrandTheme } from '@postidigital/posti-theme'

export interface UIMessageProps extends React.HTMLAttributes<HTMLDivElement> {
  success?: boolean
  message: string
  isBrand?: boolean
}

export interface UIMessageSubComponents {
  Container: StyledComponent<
    'div',
    DefaultTheme,
    {
      isBrand: boolean
    },
    never
  >
  Label: StyledComponent<'label', DefaultTheme, LabelProps, never>
  Body: StyledComponent<'p', DefaultTheme, BodyProps, never>
}

export const MessageContainer = styled.div<{ isBrand: boolean }>(
  ({ theme: { spacing }, isBrand }) => css`
    display: flex;
    margin-top: ${spacing.space2}rem;
    align-items: center;

    ${isBrand &&
    css`
      padding: 0 ${spacing.space4}rem;
    `}

    & > svg {
      flex-shrink: 0;
    }

    ${Body}, ${Label} {
      margin-left: ${spacing.space2}rem;
      margin-bottom: 0;
    }
  `
)

export const UIMessage: React.FC<UIMessageProps> & UIMessageSubComponents = ({
  success,
  message,
  isBrand,
  ...rest
}) => {
  const theme = useTheme()
  const Icon = success ? CheckmarkIndicatorIcon : isBrand ? AlertIcon : AlertIndicatorIcon
  const color = success ? theme.color.signalGreen : theme.color.signalRed
  const brandColor = success ? BrandTheme.color.green80 : BrandTheme.color.red60
  const iconColor = isBrand ? brandColor : color
  const iconProps = {
    width: `${theme.iconSize.xs}em`,
    height: `${theme.iconSize.xs}em`,
    color: iconColor,
  }

  return (
    <>
      {!!message && (
        <MessageContainer {...rest} isBrand={isBrand}>
          <Icon {...iconProps} aria-hidden="true" />
          {isBrand ? (
            <Label size={LabelSize.Six} color={iconColor}>
              {message}
            </Label>
          ) : (
            <Body size={BodySize.Five} color={iconColor}>
              {message}
            </Body>
          )}
        </MessageContainer>
      )}
    </>
  )
}

UIMessage.Container = MessageContainer
UIMessage.Label = Label
UIMessage.Body = Body

UIMessage.defaultProps = {
  success: false,
  isBrand: false,
}

UIMessage.propTypes = {
  success: PropTypes.bool,
  message: PropTypes.string.isRequired,
  isBrand: PropTypes.bool,
}
