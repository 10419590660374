import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

const TwitterIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M13.9027 10.6547L21.3482 2H19.5838L13.119 9.51478L7.95547 2H2L9.8082 13.3637L2 22.4395H3.76443L10.5915 14.5036L16.0445 22.4395H22L13.9023 10.6547H13.9027ZM11.4861 13.4638L10.695 12.3322L4.40018 3.32824H7.11025L12.1902 10.5948L12.9813 11.7263L19.5847 21.1717H16.8746L11.4861 13.4642V13.4638Z"
      fill={color}
    />
  </SVG>
)

TwitterIcon.propTypes = defaultIconPropTypes
TwitterIcon.defaultProps = defaultPictographIconProps

export { TwitterIcon }
