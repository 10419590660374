import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const DeliveryInPersonIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M13.4023 14.11C13.3644 13.9758 13.3265 13.8608 13.2697 13.7457C13.857 13.8224 14.4254 13.8416 14.7097 13.7457C14.9181 13.6499 15.4107 13.0938 15.676 12.787C15.9412 12.4994 16.1686 12.2501 16.396 12.02C16.7939 11.6174 17.097 11.3489 17.6655 11.2147C18.3476 11.0422 20.0907 10.8312 20.5644 11.9817C21.095 13.2089 21.0002 16.3534 20.9813 18.616C20.9813 18.8078 20.8486 18.9612 20.6592 18.9612H16.9644C16.737 18.9612 16.6613 18.8461 16.6234 18.6544C16.6044 18.5393 16.4907 16.3726 16.4149 14.8578C16.0549 15.203 15.7139 15.4906 15.316 15.6056C14.956 15.7207 14.5202 15.759 14.0654 15.759C13.6865 15.759 13.3075 15.7207 12.9665 15.6823C13.3644 15.2797 13.516 14.6853 13.4023 14.11Z"
      fill={color}
    />
    <Path
      d="M18.4424 10.5251C17.2076 10.5251 16.2066 9.51216 16.2066 8.26257C16.2066 7.01299 17.2076 6 18.4424 6C19.6772 6 20.6782 7.01299 20.6782 8.26257C20.6782 9.51216 19.6772 10.5251 18.4424 10.5251Z"
      fill={color}
    />
    <Path
      d="M3.41698 11.9822C3.90962 10.8318 5.63384 11.0235 6.3349 11.2153C6.90333 11.3687 7.20649 11.6371 7.60438 12.0206C7.83175 12.2315 8.07807 12.4808 8.32439 12.7876C8.58965 13.0943 9.10124 13.6504 9.29071 13.7463C9.78335 13.8997 10.636 13.7271 11.4128 13.5545C11.9434 13.4395 12.4739 13.7654 12.5876 14.3023C12.7013 14.8392 12.3792 15.3761 11.8486 15.4911C11.5644 15.5487 10.9581 15.7979 9.91598 15.7979C9.46124 15.7979 9.02545 15.7596 8.66544 15.6445C8.28649 15.5103 7.92649 15.2419 7.56649 14.8967C7.4907 16.4307 7.37701 18.5782 7.35807 18.6933C7.33912 18.885 7.24438 19 7.03596 19C6.80859 19 3.81488 19 3.3412 19C3.15172 19 3.01909 18.8466 3.01909 18.6549C3.00014 16.354 2.9054 13.2094 3.41698 11.9822Z"
      fill={color}
    />
    <Path
      d="M5.55814 10.5251C4.32334 10.5251 3.32234 9.51216 3.32234 8.26257C3.32234 7.01299 4.32334 6 5.55814 6C6.79294 6 7.79395 7.01299 7.79395 8.26257C7.79395 9.51216 6.79294 10.5251 5.55814 10.5251Z"
      fill={color}
    />
    <Path
      d="M13.5768 7.62506C14.1291 7.62506 14.5768 8.07278 14.5768 8.62506V12.5001H8.95654V8.62506C8.95654 8.07278 9.40426 7.62506 9.95654 7.62506H11.3653V9.25009H12.1682V7.62506H13.5768Z"
      fill={color}
      fillRule="evenodd"
    />
  </SVG>
)

DeliveryInPersonIcon.propTypes = defaultIconPropTypes

DeliveryInPersonIcon.defaultProps = defaultPictographIconProps

export { DeliveryInPersonIcon }
