import React, { Ref, useRef, ReactNode, useId } from 'react'
import PropTypes from 'prop-types'
import type { Node } from '@react-types/shared'
import { useTabList, useTab, type AriaTabListProps, useTabPanel, useFocusRing, mergeProps } from 'react-aria'
import { useObjectRef } from '@react-aria/utils'
import { TabButton, Underline, Panel, TabArea, TabList, ActionContainer } from './Tabs.styles'
import { useTabListState, Item, TabListState } from 'react-stately'
import { AnimatePresence, m } from 'framer-motion'

export interface TabProps<T> {
  item: Node<T>
  state: TabListState<T>
  orientation: AriaTabListProps<T>['orientation']
  layoutId: string
  isBrand?: boolean
}

export type TabsProps<T> = AriaTabListProps<T> & {
  /** Action slot that will be rendered next to tabs-panel. Allows attaching related actions next to tabs */
  actionSlot?: ReactNode
  className?: string
  tabListRef?: Ref<HTMLDivElement>
  tabPanelRef?: Ref<HTMLDivElement>
  isBrand?: boolean
}

export function Tabs<T extends object>(props: TabsProps<T>) {
  const {
    className: tabContainerStyles,
    tabListRef: forwardedListRef,
    tabPanelRef: forwardedPanelRef,
    isBrand,
    ...rest
  } = props
  const state = useTabListState(rest)
  const tabListRef = useObjectRef<HTMLDivElement>(forwardedListRef)
  const { tabListProps } = useTabList(rest, state, tabListRef)
  const layoutId = useId()

  return (
    <TabArea className={tabContainerStyles}>
      <TabList {...tabListProps} ref={tabListRef} orientation={rest.orientation}>
        {[...state.collection].map((item) => {
          return (
            <Tab
              key={item.key}
              item={item}
              state={state}
              orientation={rest.orientation}
              layoutId={layoutId}
              isBrand={isBrand}
            />
          )
        })}
      </TabList>
      <ActionContainer>{props.actionSlot}</ActionContainer>
      <AnimatePresence mode="wait">
        <TabPanel key={state.selectedItem?.key} state={state} tabPanelRef={forwardedPanelRef} isBrand={isBrand} />
      </AnimatePresence>
    </TabArea>
  )
}

export function Tab<T extends object>(props: TabProps<T>) {
  const { isBrand, layoutId, item, state, orientation } = props
  const focus = useFocusRing()
  const ref = useRef<HTMLButtonElement>(null)
  const { key, rendered } = item
  const { tabProps } = useTab(item, state, ref)
  const isSelected = state.selectedKey === key
  return (
    <TabButton
      {...mergeProps(tabProps, focus.focusProps)}
      ref={ref}
      aria-selected={isSelected}
      isBrand={isBrand}
      orientation={orientation}
      hasFocus={focus.isFocusVisible}
    >
      {rendered}
      {isSelected && (
        <m.div layout layoutRoot>
          <Underline
            layoutId={layoutId}
            aria-disabled={tabProps['aria-disabled']}
            data-testid="underline"
            transition={{ duration: 0.175 }}
          />
        </m.div>
      )}
    </TabButton>
  )
}

export function TabPanel<T extends object>({
  state,
  tabPanelRef,
  isBrand,
  ...props
}: {
  state: TabListState<T>
  tabPanelRef?: Ref<HTMLDivElement>
  isBrand: boolean
}) {
  const ref = useObjectRef<HTMLDivElement>(tabPanelRef)
  const { tabPanelProps } = useTabPanel(props, state, ref)
  return (
    <Panel {...tabPanelProps} ref={ref} isBrand={isBrand}>
      {state.selectedItem?.props.children}
    </Panel>
  )
}

Tabs.TabItem = Item
Tab.propTypes = {
  layoutId: PropTypes.string.isRequired,
  isBrand: PropTypes.bool,
}

Tab.defaultProps = {
  layoutId: '',
  isBrand: false,
}

Tabs.propTypes = {
  className: PropTypes.string,
  isBrand: PropTypes.bool,
  actionSlot: PropTypes.node,
}

Tabs.defaultProps = {
  className: '',
  isBrand: false,
}
