import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { XyzTheme } from '@postidigital/posti-theme'
import { useTheme } from '../../utils/useTheme'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { CardSelectorWrapper, StyledIcon, StyledCheckmarkIndicatorIcon } from './CardSelector.styles'
import { Label, LabelSize } from '../../design-tokens/typography/Label'
import { KeyboardKeys } from '../../utils/enums'
import { Label as BrandLabel, LabelSize as BrandLabelSize } from '../../design-tokens/brand-typography'
import { CardSelectorProps, CardSelectorSubComponents } from './CardSelector.types'

export const CardSelector: React.FC<CardSelectorProps> & CardSelectorSubComponents = (props) => {
  const { icon: Icon, disabled, iconColor, label, selected, onClick, isBrand, ...rest } = props
  const theme = useTheme()
  const color = disabled ? theme.color.neutralPassiveGray : iconColor
  const brandIconColor = isBrand ? theme.brand.color.postiOrange60 : theme.color.signalBlue

  const onKeyDown = (e: React.KeyboardEvent<HTMLButtonElement>) => {
    if (e.key === KeyboardKeys.Enter) {
      e.preventDefault()
      onClick(e)
    }
  }

  return (
    <CardSelectorWrapper
      aria-disabled={disabled}
      selected={selected}
      onClick={onClick}
      onKeyDown={onKeyDown}
      aria-pressed={selected}
      isBrand={isBrand}
      {...rest}
    >
      {selected && !disabled && <StyledCheckmarkIndicatorIcon inverted color={brandIconColor} />}
      {!!Icon && <StyledIcon as={Icon} color={color} aria-hidden={true} />}
      {isBrand ? (
        <BrandLabel size={BrandLabelSize.Five}>{label}</BrandLabel>
      ) : (
        <Label size={LabelSize.Five}>{label}</Label>
      )}
    </CardSelectorWrapper>
  )
}

CardSelector.Wrapper = CardSelectorWrapper
CardSelector.IndicatorIcon = StyledCheckmarkIndicatorIcon
CardSelector.Icon = StyledIcon
CardSelector.BrandLabel = BrandLabel
CardSelector.Label = Label

CardSelector.propTypes = {
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.elementType.isRequired as Validator<React.ComponentType<DefaultIconProps>>,
  isBrand: PropTypes.bool,
}

CardSelector.defaultProps = {
  disabled: false,
  selected: false,
  label: '',
  iconColor: XyzTheme.color.signalBlue,
  isBrand: false,
}
