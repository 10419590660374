import styled, { css } from 'styled-components'
import { Headline } from '../../design-tokens/typography'
import { inputStyle, Content } from '../Input/Input.style'
import { CounterProps, StyledTextareaProps } from './Textarea.types'
import { InputProps } from '../Input/Input.types'

const styledLabelStyles = ({ theme: { spacing, fontSize, fontWeight } }) => {
  return css`
    width: calc(100% - ${spacing.space7}rem - 3.125rem);
    top: calc(${spacing.space3}rem - 3px);
    white-space: nowrap;
    font-size: ${fontSize.body.five}rem;
    font-weight: ${fontWeight.normal};
  `
}

export const StyledLabel = styled.label<Pick<InputProps, 'isBrand'>>(
  ({ theme: { spacing, fontSize, fontWeight, color, fontFamily, brand }, isBrand }) => {
    return css`
      position: absolute;
      margin: auto;
      overflow: hidden;
      color: ${color.neutralBlack};
      font-family: ${fontFamily.Montserrat};
      user-select: none;
      height: auto;
      transition:
        top 0.2s ease-in-out,
        font-size 0.2s ease-in-out,
        height 0.2s ease-in-out;
      text-overflow: ellipsis;
      user-select: none;
      cursor: text;
      width: calc(100% - ${spacing.space7}rem);
      top: ${spacing.space6}rem;
      white-space: normal;
      font-size: ${fontSize.body.three}rem;
      font-weight: ${isBrand ? brand.fontWeight.medium : fontWeight.semiBold};
      &[aria-disabled='true'] {
        color: ${color.neutralPassiveGray};
      }

      ${isBrand &&
      css`
        font-weight: ${brand.fontWeight.medium};
      `}
    `
  }
)

export const Container = styled(Content)<{ isOverLimit: boolean; readOnly?: boolean; hasValue: boolean }>`
  ${({ theme: { spacing, color, brand }, isOverLimit, isBrand, readOnly, hasValue }) => css`
    padding: calc(${spacing.space3}rem - 3px) calc(${spacing.space4}rem - 3px);
    position: relative;

    ${isOverLimit &&
    css`
      border-color: ${isBrand ? brand.color.red50 : color.signalRed};
    `}

    ${(hasValue || readOnly) &&
    css`
      ${StyledLabel} {
        ${styledLabelStyles}
      }
    `}

    &:focus-within {
      ${StyledLabel} {
        cursor: default;
        ${styledLabelStyles}
      }
    }

    ${isBrand &&
    css`
      ${StyledLabel}, ${StyledTextarea}, ${CounterText} {
        color: ${brand.color.gray70};
        font-family: ${brand.fontFamily.PostiFont};
        &[aria-disabled='true'] {
          color: ${brand.color.gray30};
        }
      }
      ${CounterText} {
        font-weight: ${brand.fontWeight.medium};
      }
    `}
  `}
`

export const TopContent = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: row;
    margin-bottom: ${spacing.space2}rem;
    min-height: ${spacing.space3}rem;
    user-select: none;
  `
)

export const CounterText = styled(Headline)<Omit<CounterProps, 'length' | 'maxLength'>>(
  ({ theme: { color, spacing } }) => css`
    color: ${color.neutralBlack};
    margin-right: -${spacing.space2}rem;
    margin-left: auto;
    &[aria-disabled='true'] {
      color: ${color.neutralPassiveGray};
    }
  `
)

export const StyledTextarea = styled.textarea<StyledTextareaProps>(
  ({ theme: { color, fontSize, fontWeight, brand }, isBrand }) => css`
    ${inputStyle}

    color: ${color.neutralBlack};
    font-weight: ${isBrand ? brand.fontWeight.regular : fontWeight.medium};
    font-size: ${isBrand ? brand.fontSize.label.four : fontSize.body.four}rem;
    line-height: ${isBrand ? brand.fontSize.body.three : fontSize.body.four}rem;
    min-height: 6.0625rem;
    &[aria-disabled='true'] {
      color: ${color.neutralPassiveGray};
    }

    ::placeholder {
      font-weight: ${isBrand ? brand.fontWeight.regular : fontWeight.medium};
      font-size: ${isBrand ? brand.fontSize.label.four : fontSize.body.four}rem;
      line-height: ${isBrand ? brand.fontSize.body.three : fontSize.body.four}rem;
    }
  `
)
