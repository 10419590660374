import React from 'react'
import PropTypes, { Validator } from 'prop-types'
import { ButtonProps, ButtonSubComponents } from './Button.types'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { useTheme } from '../../utils/useTheme'
import { StyledIcon, StyledInner, StyledButton, buttonColors, StyledLoadingIcon } from './Button.styles'
import { LoadingProps } from '../Loading/Loading'
import { Position, Mode, Size, LoadingAnimationPresetColor } from '../../utils/enums'
import { addNamespace } from '../../utils/helpers'

export const ButtonWithoutNamespace = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const {
    icon: Icon,
    contentMode,
    iconPosition,
    size,
    iconColor: customIconColor,
    hasBackground,
    isLoading,
    loadingProps,
    children,
    disabled,
    ...rest
  } = props
  const theme = useTheme()
  const iconColor = disabled ? theme.color.neutralPassiveGray : customIconColor || buttonColors[contentMode].color
  const isOnlyIcon = !React.Children.count(children) && !!Icon

  /** Progress button only  */
  const color = disabled ? theme.color.neutralPassiveGray : theme.brand.color.white
  const backgroundColor = disabled ? theme.color.neutralPassiveGray : buttonColors[props.contentMode].background
  const loadingIconProps =
    props.contentMode === Mode.primary || disabled
      ? { color, backgroundColor }
      : { preset: LoadingAnimationPresetColor.brandPink }

  const RenderIcon = () => {
    return (
      <StyledIcon
        as={Icon}
        iconPosition={iconPosition}
        color={iconColor}
        size={size}
        contentMode={contentMode}
        isOnlyIcon={isOnlyIcon}
        aria-hidden={true}
      />
    )
  }

  return (
    <StyledButton
      ref={ref}
      aria-disabled={disabled}
      contentMode={contentMode}
      size={size}
      hasBackground={hasBackground}
      hasIcon={!!Icon}
      isOnlyIcon={isOnlyIcon}
      isLoading={isLoading}
      {...rest}
    >
      {!!Icon && iconPosition === Position.left && <RenderIcon />}
      <StyledInner size={size} contentMode={contentMode}>
        {children}
      </StyledInner>
      {!!Icon && iconPosition === Position.right && <RenderIcon />}
      {isLoading && !disabled && <StyledLoadingIcon {...(loadingProps ?? loadingIconProps)} />}
    </StyledButton>
  )
})

const buttonSubComponents: ButtonSubComponents = {
  Button: StyledButton,
  Icon: StyledIcon,
  Inner: StyledInner,
  LoadingIcon: StyledLoadingIcon,
}

ButtonWithoutNamespace.displayName = 'Button'

export const ButtonProptypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
  iconPosition: PropTypes.oneOf(Object.values(Position)),
  iconColor: PropTypes.string,
  loadingProps: PropTypes.object as Validator<LoadingProps>,
}

export const ButtonDefaultProps = {
  contentMode: Mode.primary,
  iconPosition: Position.left,
}

ButtonWithoutNamespace.propTypes = {
  ...ButtonProptypes,
  contentMode: PropTypes.oneOf(Object.values(Mode)),
  size: PropTypes.oneOf(Object.values(Size)),
}

ButtonWithoutNamespace.defaultProps = {
  ...ButtonDefaultProps,
  size: Size.md,
}

export const Button = addNamespace(ButtonWithoutNamespace, buttonSubComponents)
