import styled, { css } from 'styled-components'
import { m } from 'framer-motion'
import { Orientation } from 'react-aria'

export const TabButton = styled.button<{
  isBrand?: boolean
  orientation?: Orientation
  hasFocus?: boolean
}>(
  ({
    theme: { color, fontWeight, fontFamily, fontSize, lineHeight, spacing, brand },
    isBrand,
    orientation,
    hasFocus,
  }) => css`
    position: relative;
    padding: ${spacing.space2}rem;
    border-radius: ${orientation === 'vertical' ? '0.25rem' : '1.875rem'};
    background-color: transparent;
    border: none;
    padding: ${spacing.space2}rem ${spacing.space3}rem;
    user-select: none;
    outline: none;
    cursor: pointer;
    font-weight: ${fontWeight.normal};
    font-size: ${fontSize.label.four}rem;
    line-height: ${lineHeight.body.three};
    font-family: ${isBrand ? brand.fontFamily.PostiFont : fontFamily.Montserrat};
    color: ${isBrand ? brand.color.gray90 : color.neutralNetworkGray};
    transition: all 100ms ease-in;

    @media (hover: hover) {
      &:hover {
        background-color: ${isBrand ? brand.color.gray10 : color.neutralOnHoverGray};
      }
    }

    &:active {
      background-color: ${isBrand ? brand.color.gray20 : color.neutralOnPressGray};
    }

    &:focus,
    &:focus-visible {
      outline: none;
    }

    &:focus:not(:focus-visible) {
      color: ${isBrand ? brand.color.gray90 : color.neutralNetworkGray};
    }

    ${hasFocus &&
    css`
      box-shadow: inset 0 0 0 3px ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
      color: ${color.neutralBlack};
    `}

    &[aria-disabled='true'] {
      pointer-events: none;
      cursor: default;
      color: ${isBrand ? brand.color.gray30 : color.neutralPassiveGray};
      ${Underline} {
        background: ${isBrand ? brand.color.gray30 : color.neutralPassiveGray};
      }
    }

    ${Underline} {
      background-color: ${isBrand ? brand.color.postiOrange60 : color.neutralNetworkGray};

      ${orientation === 'vertical' &&
      css`
        height: 100%;
        width: 0.1875rem;
        right: 0;
        left: auto;
        bottom: 0;
      `};
    }
  `
)

export const Underline = styled(m.div)(
  ({ theme: { spacing } }) => css`
    position: absolute;
    display: inline-block;
    height: 0.25rem;
    border-radius: 2px 2px 0px 0px;
    bottom: -${spacing.space2}rem;
    left: ${spacing.space3}rem;
    right: ${spacing.space3}rem;
    width: calc(100% - 2 * ${spacing.space3}rem);
  `
)

export const TabList = styled.div<{ orientation: Orientation }>`
  ${({ theme: { spacing }, orientation }) => css`
    display: flex;
    flex-wrap: wrap;
    row-gap: ${spacing.space3}rem;

    ${orientation === 'vertical' &&
    css`
      flex-direction: column;
      row-gap: 0;
    `}
  `}
`

export const ActionContainer = styled.div`
  ${({ theme: { spacing } }) => css`
    &:not(:empty) {
      padding: ${spacing.space3}rem;
    }
  `}
`

export const Panel = styled.div<{ isBrand: boolean }>`
  ${({ theme: { spacing, color, brand }, isBrand }) => css`
    padding: ${spacing.space4}rem 0;
    margin-top: ${spacing.space1}rem;
    &:focus {
      outline-color: ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
    }
  `}
`

export const TabArea = styled.div`
  display: grid;
  grid-template-areas:
    'tabs'
    'action'
    'content-area';
  grid-template-columns: 1fr min-content;

  ${TabList} {
    grid-area: tabs;
  }

  ${ActionContainer} {
    grid-area: action;
  }

  ${Panel} {
    grid-area: content-area;
    overflow: hidden;
  }
`
