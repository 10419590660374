import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import { StyledGroupWrapper } from '../../composite-components/ButtonGroup/ButtonGroup.styles'

export interface CSATButtonGroupProps extends Omit<HTMLAttributes<HTMLDivElement>, 'onSelect'> {
  disabled?: boolean
  name: string
  selectedValue: string | number
  onSelect?(event: React.MouseEvent, value: string | number): void
  children: React.ReactElement[]
}

/**
 * @deprecated CSATButtonGroup is deprecated, please use ButtonGroup instead
 */

export const CSATButtonGroup = ({
  disabled,
  selectedValue,
  onSelect,
  name,
  children,
  ...rest
}: CSATButtonGroupProps): JSX.Element => {
  const handleOnSelect = (event: React.MouseEvent, value: string) => {
    onSelect(event, value)
  }

  return (
    <StyledGroupWrapper aria-label={name} role="group" {...rest}>
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          ...child.props,
          disabled: disabled,
          onSelect: handleOnSelect,
          selected: selectedValue === child.props.value,
        })
      )}
    </StyledGroupWrapper>
  )
}

CSATButtonGroup.propTypes = {
  disabled: PropTypes.bool,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func,
}

CSATButtonGroup.defaultProps = {
  disabled: false,
  name: 'Button Group',
}
