import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const PlusIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props} fill="none">
    <Path fill={color} d="M10.3 3h3.4v18h-3.4z" />
    <Path fill={color} d="M21 10.3v3.4H3v-3.4z" />
  </SVG>
)

PlusIcon.propTypes = defaultIconPropTypes

PlusIcon.defaultProps = defaultPictographIconProps

export { PlusIcon }
