import styled, { css } from 'styled-components'
import { mediaQuery } from '../../utils/mediaQuery'
import { Scroll, WrapperProps } from './Modal.types'
import { Headline } from '../../design-tokens/typography'
import { IconButton } from '../../deprecated/IconButton'
import { ModalScroll, MobileMode } from '../../utils/enums'
import { SelectWrapper } from '../../basic-components/Dropdown/Dropdown.styles'
import { BrandButton } from '../../basic-components/BrandButton'

const modalCloseStyles = ({ theme: { spacing } }) => css`
  position: absolute;
  top: ${spacing.space2}rem;
  right: ${spacing.space2}rem;
  z-index: 1000000;
  @media (hover: hover) {
    &:hover {
      background: transparent;
      border: 1px solid transparent;
    }
  }
  &:focus {
    background: transparent;
    border: 1px solid transparent;
  }
  ${mediaQuery.md`
      top: 1rem;
      right: 1rem;
    `}
`

export const ModalClose = styled(IconButton).attrs({ type: 'button' })`
  ${modalCloseStyles}
`

export const ModalBrandClose = styled(BrandButton).attrs({ type: 'button' })`
  ${modalCloseStyles}
`

export const Root = styled.div<{ scroll?: Scroll }>(
  ({ scroll, theme: { zIndex } }) => css`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: ${zIndex.zIndexTop};
    overflow-y: auto;
    background-color: rgba(38, 44, 48, 0.64);
    display: flex;

    ${mediaQuery.md`
      display: block;
    `}

    ${
      scroll === ModalScroll.content &&
      css`
        ${mediaQuery.md`
        overflow: hidden;
      `}
      `
    }

    ${
      scroll === ModalScroll.body &&
      css`
        overflow-x: hidden;
        overflow-y: auto;
      `
    }
    
    /* PATCH: iOS v12 Safari bug */
    -webkit-overflow-scrolling: touch;
    & * {
      -webkit-overflow-scrolling: touch;
    ${SelectWrapper} {
      margin-left: 1px;
    }
  `
)

export const ModalTitle = styled(Headline).attrs({ id: 'modal-title', as: 'h1' })`
  word-break: break-word;
  margin-bottom: 0;
`

export const ModalMainContent = styled.div.attrs({
  id: 'modal-content',
})(
  ({ theme: { spacing, color } }) => css`
    padding: 0;

    ${mediaQuery.md`
    overflow: auto;
    -webkit-scrollbar-gutter: stable;
    -moz-scrollbar-gutter: stable;
    -ms-scrollbar-gutter: stable;
    -webkit-scrollbar-color: #C2C9D1 #FFFFFF;
    -moz-scrollbar-color: #C2C9D1 #FFFFFF;
    -ms-scrollbar-color: #C2C9D1 #FFFFFF;
    scrollbar-color: #C2C9D1 #FFFFFF;
    scrollbar-gutter: stable;
    scrollbar-width: thin;
    padding-right: ${spacing.space4}rem;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 0.5rem;
    }

    ::-webkit-scrollbar-thumb {
      background: ${color.neutralPassiveGray};
      background-clip: content-box;
      border-radius: 1rem;
    }

    ::-webkit-scrollbar {
      background-color: ${color.neutralWhite};
    }

    ::-webkit-scrollbar-track {
      background-color: ${color.neutralWhite};
    }
    @media (hover: hover) {
      &:hover {
        ::-webkit-scrollbar-thumb {
          background-color: ${color.neutralIconGray};
        }
      }
    }

    &:focus {
      outline: none;
      ::-webkit-scrollbar,
      ::-webkit-scrollbar-thumb {
        outline: none;
        box-shadow: none;
      }
    }
  `}
  `
)

export const ModalWrapper = styled.div<
  WrapperProps & { alert?: boolean; fullSizeMainContent: boolean; mobileMode: keyof typeof MobileMode }
>(
  ({
    theme: { spacing, color, breakpoint, borderRadius },
    topPosition,
    $width,
    maxWidth,
    scroll,
    alert,
    fullSizeMainContent,
    mobileMode,
  }) => css`
    max-width: 100%;
    width: 100%;
    height: 100%;
    display: flex;
    background-color: ${color.neutralWhite};
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    overflow: hidden;

    ${alert &&
    `
      ${ModalHeroImage} {
        margin-bottom: ${spacing.space5}rem;
      }
      @media screen and (max-width: ${breakpoint.md}) {
        border-radius: ${borderRadius.sm}rem;
        top: 50%;
        transform: translate(-50%, -50%);
        max-width: calc(100% - 2*(${spacing.space4}rem));
        height: auto;

        ${ModalContent} {
          ${ModalHeroImage} {
            margin-bottom: ${spacing.space4}rem;
          }
        }
        ${ModalTitle} {
          font-size: 1.25rem;
        }
        ${ModalHeader} {
          padding: ${spacing.space3}rem 0;
        }
        ${ModalMainContent} {
          margin-bottom: ${spacing.space2}rem;
        }
      }
    `}

    ${mediaQuery.md`
      max-height: calc(100vh - ${spacing.space9}rem);
      min-width: 20rem;
      max-width: ${maxWidth ?? '48rem'};
      ${$width && `width: ${$width};`}
      min-height: auto;
      min-height: initial;
      height: auto;
      height: initial;
      border-radius: ${borderRadius.md}rem;
      overflow: hidden;
      ${
        topPosition && scroll === ModalScroll.content
          ? `top: ${topPosition};`
          : `top: 50%; transform: translate(-50%, -50%);`
      }
      ${
        scroll === ModalScroll.body &&
        `
        max-height: none;
        margin: ${spacing.space5}rem 0;
        transform: translateX(-50%);
        top: initial;
      `
      }
    `}

    ${ModalContent} {
      padding: ${fullSizeMainContent
        ? '0;'
        : `${spacing.space4}rem 0 ${spacing.space5}rem
        ${spacing.space4}rem;`};

      ${mobileMode === MobileMode.fullscreen &&
      css`
        overflow: visible;
        ${ModalMainContent} {
          overflow: auto;
          padding-right: ${spacing.space4}rem;
        }
        ${ModalHeroImage} + ${ModalContentWrapper} {
          padding: 0;
        }
      `}

      ${mobileMode === MobileMode.alert &&
      css`
        padding-right: ${spacing.space4}rem;

        ${mediaQuery.md`
          padding-right: 0;
        `}

        ${ModalHeroImage} {
          & + ${ModalContentWrapper} {
            padding: 0;

            ${ModalHeader} {
              padding-top: 0;
              ${mediaQuery.md`
                padding-top: ${spacing.space4}rem;
              `}
            }
          }
        }
      `}
    }

    ${ModalMainContent} {
      ${fullSizeMainContent ? 'height: 100%;' : ''}
    }
  `
)

export const ModalHeader = styled.div(
  ({ theme: { spacing } }) => css`
    position: sticky;
    padding: 0 ${spacing.space7}rem ${spacing.space3}rem 0;
    ${mediaQuery.md`
      padding: 0 ${spacing.space9}rem ${spacing.space3}rem 0;      
    `}
  `
)

export const ModalButtonWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    padding: ${spacing.space5}rem ${spacing.space2}rem ${spacing.space2}rem;
    margin-left: -${spacing.space2}rem;
    margin-right: -${spacing.space2}rem;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `
)

export const ModalContentWrapper = styled.div(
  ({ theme: { spacing } }) => css`
    padding: 0 0 ${spacing.space4}rem ${spacing.space4}rem;

    ${mediaQuery.md`
      padding: 0;
    `}
  `
)

export const ModalHeroImage = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    margin: -${spacing.space5}rem 0 ${spacing.space4}rem -${spacing.space4}rem;
    width: calc(100% + ${spacing.space4}rem);
    max-height: 10rem;
    overflow: hidden;
    height: auto;
    justify-content: center;
    align-items: center;

    & > * {
      width: 100%;
    }

    ${mediaQuery.md`
      max-height: 12.5rem;
      margin-top: -${spacing.space4}rem;
    `}
  `
)

export const ModalContent = styled.div.attrs({
  'aria-labelledby': 'modal-title',
  'aria-describedby': 'modal-content',
  role: 'dialog',
  'aria-live': 'assertive',
})`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: auto;

  &:focus {
    outline: none;
  }

  ${mediaQuery.md`
    overflow: visible;
  `}
`
