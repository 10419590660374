import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const SendParcelIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M10.2058 15.7545C10.2061 15.8024 10.2058 15.9754 10.2058 15.9754C10.2058 15.9754 10.3622 15.9051 10.4053 15.8856L15.6676 13.452C15.7663 13.4064 15.8501 13.3327 15.9088 13.2399C15.9675 13.1471 15.9988 13.0391 15.9988 12.9288V6.5206C15.9988 6.51751 16.0013 6.30038 15.9988 6.2987C15.9962 6.29702 15.8021 6.38821 15.7993 6.38949L10.2906 8.93803C10.2658 8.94928 10.2075 8.98065 10.2075 8.98065C10.2076 9.01435 10.2075 9.04096 10.2075 9.06857L10.2058 15.7545Z"
      fill={color}
    />
    <Path
      d="M7.00195 7.98766C6.98052 7.97859 6.95722 7.97504 6.93412 7.97731C6.91101 7.97958 6.88881 7.9876 6.86948 8.00068C6.85015 8.01375 6.83428 8.03147 6.82328 8.05227C6.81228 8.07307 6.80648 8.0963 6.80641 8.11992V9.27401C6.80641 9.38839 6.76175 9.49809 6.68226 9.57897C6.60276 9.65985 6.49495 9.70529 6.38253 9.70529C6.27011 9.70529 6.1623 9.65985 6.0828 9.57897C6.00331 9.49809 5.95865 9.38839 5.95865 9.27401V7.64092C5.95909 7.6122 5.95102 7.584 5.9355 7.56001C5.91998 7.53601 5.89772 7.51732 5.87162 7.50636L3.19555 6.37412C3.15524 6.35676 3.00002 6.29867 3.00002 6.29867C3.00002 6.23443 3.00001 6.50442 3 6.50638V12.9157C2.99999 13.0292 3.03299 13.1401 3.09484 13.2346C3.15669 13.329 3.24463 13.4028 3.34758 13.4464L9.16033 15.9116C9.20329 15.9298 9.35588 16.0003 9.35588 16.0003C9.35588 16.0003 9.35591 15.8261 9.35588 15.7788V9.08195C9.35582 9.05355 9.35588 8.98526 9.35588 8.98526C9.35588 8.98526 9.29458 8.96009 9.26884 8.94912L7.00195 7.98766Z"
      fill={color}
    />
    <Path
      d="M11.7035 4.1837C11.7283 4.17233 11.7494 4.15392 11.7642 4.13066C11.779 4.1074 11.7869 4.08029 11.7869 4.05259C11.7869 4.02489 11.779 3.99778 11.7642 3.97452C11.7494 3.95126 11.7283 3.93284 11.7035 3.92148L9.72543 3.04801C9.65413 3.01634 9.57717 3 9.49937 3C9.42157 3 9.3446 3.01634 9.2733 3.04801L3.84373 5.45049C3.81824 5.46154 3.79651 5.47997 3.78123 5.50348C3.76596 5.527 3.75781 5.55456 3.75781 5.58275C3.75781 5.61094 3.76596 5.6385 3.78123 5.66202C3.79651 5.68553 3.81824 5.70396 3.84373 5.71501L6.22139 6.72074C6.23919 6.72876 6.25844 6.7329 6.27791 6.7329C6.29737 6.7329 6.31663 6.72876 6.33442 6.72074L11.7035 4.1837Z"
      fill={color}
    />
    <Path
      d="M9.71753 8.20379C9.73538 8.2116 9.75461 8.21563 9.77404 8.21563C9.79348 8.21563 9.81271 8.2116 9.83056 8.20379L15.1861 5.7231C15.2109 5.71174 15.232 5.69332 15.2468 5.67006C15.2616 5.6468 15.2694 5.61969 15.2694 5.59199C15.2694 5.56429 15.2616 5.53718 15.2468 5.51392C15.232 5.49066 15.2109 5.47225 15.1861 5.46088L13.0854 4.53393C13.0675 4.52608 13.0483 4.52203 13.0288 4.52203C13.0094 4.52203 12.9902 4.52608 12.9723 4.53393L7.6281 7.05717C7.60334 7.06901 7.58249 7.0879 7.56808 7.11155C7.55366 7.13521 7.54631 7.16261 7.54691 7.19044C7.54751 7.21827 7.55603 7.24532 7.57145 7.26831C7.58686 7.2913 7.60851 7.30924 7.63375 7.31996L9.71753 8.20379Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      d="M19.5859 16.8915L16.4015 13.7071L17.8157 12.2929L22.4143 16.8915L17.8157 21.4901L16.4015 20.0759L19.5859 16.8915Z"
      fill={color}
    />
    <Path fillRule="evenodd" d="M20.7558 17.8924L12 17.8924L12 15.8924L20.7558 15.8924L20.7558 17.8924Z" fill={color} />
  </SVG>
)

SendParcelIcon.propTypes = defaultIconPropTypes

SendParcelIcon.defaultProps = defaultPictographIconProps
