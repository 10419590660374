import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const ArrowUpIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path d="M12 6.70334L5.87299 12.7606L4 10.9089L12 3.00001L20 10.9089L18.127 12.7606L12 6.70334Z" fill={color} />
    <Path d="M13.3244 5.28811L13.3244 21L10.6755 21L10.6755 5.28811L13.3244 5.28811Z" fill={color} />
  </SVG>
)

ArrowUpIcon.propTypes = defaultIconPropTypes

ArrowUpIcon.defaultProps = defaultPictographIconProps

export { ArrowUpIcon }
