import React, { useRef, forwardRef, ForwardedRef, useImperativeHandle } from 'react'
import { UIMessage } from '../../basic-components/UIMessage'
import Select, * as ReactSelect from 'react-select'
import { CustomDropdownProps, DropdownPropTypes, DropdownInstance } from './Dropdown.types'
import { commonComponents, Control, DropdownCustomContext } from './DropdownCustomComponents'
import { KeyboardKeys } from '../../utils/enums'
import { callAll } from '../../utils/helpers'
import { SelectWrapper } from './Dropdown.styles'
import withBrand from '../../utils/withBrand'

export const commonProps = {
  hideSelectedOptions: false,
  tabSelectsValue: false,
  placeholder: null,
  classNamePrefix: 'select',
  theme: (theme) => ({ ...theme, borderRadius: 0 }),
}

export const Dropdown = forwardRef(
  <
    Option,
    IsMulti extends boolean = false,
    Group extends ReactSelect.GroupBase<Option> = ReactSelect.GroupBase<Option>,
  >(
    props: ReactSelect.Props<Option, IsMulti, Group> & CustomDropdownProps,
    forwardedRef: ForwardedRef<DropdownInstance>
  ) => {
    const {
      icon: Icon,
      isValid,
      label,
      zIndex,
      message,
      isMulti,
      messageProps,
      maxMenuHeight,
      iconColor,
      lightBackground,
      hasChevron,
      components,
      handleClose,
      isBrand,
      id,
      isSelected,
      screenReaderDisabledLabel,
    } = props

    const innerRef = useRef(null)
    useImperativeHandle(forwardedRef, () => innerRef.current)
    const messageId = props.id ? `${props.id}-message` : `dropdown-message`
    const labelId = props.id ? `${props.id}-label` : `dropdown-label`

    const dropdownProps = {
      ...commonProps,
      ...props,
      components: {
        ...commonComponents,
        Control: Control,
        ...components,
      },
      maxMenuHeight: maxMenuHeight !== undefined ? maxMenuHeight : 400,
    }

    const { onKeyDown, ...rest } = dropdownProps

    /** By default react-select doesn't open on Enter due to a library bug.
     * handleKeyDown handles open on Enter for better accessibility.
     * Once react-select will fix the bug this should be removed. */
    const handleKeyDown = (e: React.KeyboardEvent<HTMLElement>) => {
      if (e.key == KeyboardKeys.Enter) {
        /**  on Enter functionality follows the same logic react-select is using for Space */
        if (innerRef.current.props.inputValue) {
          return
        }
        /** Dropdown should open on Enter if menu is closed and
         * when menu is open Enter should select a focused value */
        if (!innerRef.current.props.menuIsOpen) {
          innerRef.current.openMenu('first')
        }
      }
    }

    return (
      <DropdownCustomContext.Provider
        value={{
          icon: Icon,
          isValid,
          messageId,
          message,
          iconColor,
          lightBackground,
          zIndex,
          label,
          hasChevron,
          handleClose,
          isBrand,
          labelId,
          isSelected,
          screenReaderDisabledLabel,
        }}
      >
        <SelectWrapper id={`${id}-container`} aria-disabled={rest.isDisabled}>
          <Select
            ref={innerRef}
            isSearchable={false}
            backspaceRemovesValue={props.isSearchable}
            closeMenuOnSelect={!isMulti}
            onKeyDown={callAll(handleKeyDown, onKeyDown)}
            aria-invalid={!isValid}
            /* Temporary solution implemented due to react-select described in PWC-1240 */
            aria-labelledby={`${labelId} ${messageId}`}
            {...rest}
          />
          <div id={messageId} aria-live="assertive">
            {message && <UIMessage isBrand={isBrand} success={isValid} message={message} {...messageProps} />}
          </div>
        </SelectWrapper>
      </DropdownCustomContext.Provider>
    )
  }
)

Dropdown.displayName = 'Dropdown'
Dropdown.propTypes = DropdownPropTypes

export const BrandDropdown = withBrand(Dropdown)
