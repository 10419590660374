import React, { useRef, forwardRef, useCallback } from 'react'
import PropTypes, { Validator } from 'prop-types'
import DatePickerComponent from 'react-datepicker'
import { Input } from '../../basic-components/Input'
import { CalendarStyles, Container, StyledHeaderContainer } from './DatePicker.style'
import { DatePickerProps, DatePickerSubComponents } from './DatePicker.types'
import {
  YearName,
  StyledMonthNavigationButton,
  StyledCalendarIcon,
  StyledBrandCalendarIcon,
  YearNameLabel,
} from '../MonthPicker/MonthPicker.styles'
import { HeadlineSize } from '../../design-tokens/typography'
import { LabelSize as BrandLabelSize } from '../../design-tokens/brand-typography'
import { Size } from '../../utils/enums'
import { useTheme } from '../../utils/useTheme'
import { CustomInputProps } from '../../basic-components/Input/Input.types'
import { inputPropTypes } from '../../basic-components/Input/Input'
import { addNamespace } from '../../utils/helpers'
import withBrand from '../../utils/withBrand'

export const DEFAULT_LOCALE = 'fi'

export const CustomInput = forwardRef<HTMLInputElement, CustomInputProps & { disabled?: boolean }>(
  function CustomInput(props, ref) {
    const { isBrand, disabled, ...rest } = props
    const theme = useTheme()

    return (
      <Input
        {...rest}
        role="combobox"
        ref={ref}
        isBrand={isBrand}
        disabled={disabled}
        label={rest.label}
        rightTemplate={
          isBrand ? (
            <StyledBrandCalendarIcon color={theme.brand.color.gray50} disabled={disabled} />
          ) : (
            <StyledCalendarIcon color={theme.color.neutralIconGray} disabled={disabled} />
          )
        }
      />
    )
  }
)

export type DatePickerRef = DatePickerComponent

const CustomCalendarContainer = ({ children }) => <>{children}</>

const DatePickerWithoutNamespace = forwardRef<DatePickerRef, DatePickerProps>(
  (
    {
      prevMonthButtonAriaLabel,
      nextMonthButtonAriaLabel,
      selectsStart,
      selectsEnd,
      monthsShown,
      pickerSize,
      isBrand,
      calendarContainer: CalendarContainer = CustomCalendarContainer,
      inputProps,
      disabled,
      minDate,
      ...props
    },
    ref
  ) => {
    const monthRef = useRef<HTMLInputElement>()

    return (
      <Container monthsShown={monthsShown}>
        <DatePickerComponent
          ref={ref}
          customInput={<CustomInput ref={monthRef} isBrand={isBrand} disabled={disabled} {...inputProps} />}
          minDate={minDate ?? new Date()}
          monthsShown={monthsShown}
          disabled={disabled}
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 16],
              },
            },
          ]}
          /** useCallback is needed as without it, the given calendarContainer gets unmounted
           * because this arrow function becomes a "new component" during re-render.
           * So if DatePicker is wrapped inside another component, it will cause this component to disappear and appear when selecting a date value.
           **/
          calendarContainer={useCallback(
            ({ children }) => (
              <CalendarContainer>
                <CalendarStyles
                  className="react-datepicker-ignore-onclickoutside"
                  isBrand={isBrand}
                  monthsShown={monthsShown}
                  pickerSize={pickerSize}
                >
                  {children}
                </CalendarStyles>
              </CalendarContainer>
            ),
            [CalendarContainer, isBrand, monthsShown, pickerSize]
          )}
          renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth, customHeaderCount }) => (
            <StyledHeaderContainer pickerSize={pickerSize}>
              <StyledMonthNavigationButton
                orientation={'left'}
                aria-label={prevMonthButtonAriaLabel}
                onClick={decreaseMonth}
                style={customHeaderCount === 0 ? null : { visibility: 'hidden' }}
                isBrand={isBrand}
              />
              {isBrand ? (
                <YearNameLabel size={BrandLabelSize.Three}>
                  {monthDate.toLocaleString(props.locale.toString(), {
                    month: 'long',
                    year: 'numeric',
                  })}
                </YearNameLabel>
              ) : (
                <YearName size={HeadlineSize.Six}>
                  {monthDate.toLocaleString(props.locale.toString(), {
                    month: 'long',
                    year: 'numeric',
                  })}
                </YearName>
              )}
              <StyledMonthNavigationButton
                orientation={'right'}
                aria-label={nextMonthButtonAriaLabel}
                onClick={increaseMonth}
                style={monthsShown ? (customHeaderCount === monthsShown - 1 ? null : { visibility: 'hidden' }) : null}
                isBrand={isBrand}
              />
            </StyledHeaderContainer>
          )}
          {...props}
        />
      </Container>
    )
  }
)

DatePickerWithoutNamespace.displayName = 'DatePicker'

DatePickerWithoutNamespace.propTypes = {
  pickerSize: PropTypes.oneOf(Object.keys(Size)) as Validator<Size>,
  locale: PropTypes.string,
  isBrand: PropTypes.bool,
  inputProps: PropTypes.shape({
    ...inputPropTypes,
  }) as Validator<CustomInputProps>,
}

DatePickerWithoutNamespace.defaultProps = {
  pickerSize: Size.md,
  locale: DEFAULT_LOCALE,
  isBrand: false,
}

const BrandDatePickerWithoutNamespace = withBrand(DatePickerWithoutNamespace)

const datePickerSubComponents: DatePickerSubComponents = {
  Container: Container,
  Main: DatePickerComponent,
  HeaderContainer: StyledHeaderContainer,
  MonthNavigationButton: StyledMonthNavigationButton,
  YearNameLabel: YearNameLabel,
  YearName: YearName,
}

export const DatePicker = addNamespace(DatePickerWithoutNamespace, datePickerSubComponents)
export const BrandDatePicker = addNamespace(BrandDatePickerWithoutNamespace, datePickerSubComponents)
