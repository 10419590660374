import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const PuzzleIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M5.11765 7.88235C5.11765 8.21176 4.85882 8.47059 4.52941 8.47059H3.35294C2.18824 8.47059 1 9.17647 1 10.5294C1 11.8824 2.18824 12.5882 3.35294 12.5882H4.52941C4.85882 12.5882 5.11765 12.8471 5.11765 13.1765V17.8824H9.82353C10.1529 17.8824 10.4118 18.1412 10.4118 18.4706V19.6471C10.4118 20.8118 11.1176 22 12.4706 22C13.8235 22 14.5294 20.8118 14.5294 19.6471V18.4706C14.5294 18.1412 14.7882 17.8824 15.1176 17.8824H21V2.58824C21 2.25882 20.7412 2 20.4118 2H15.3529C15.0235 2 14.7647 2.25882 14.7647 2.58824V4.03529C14.7647 5.30588 13.7294 6.32941 12.4706 6.32941C11.2118 6.32941 10.1765 5.29412 10.1765 4.03529V2.58824C10.1765 2.25882 9.91765 2 9.58823 2H5.11765V7.88235Z" />
  </SVG>
)

PuzzleIcon.propTypes = defaultIconPropTypes
PuzzleIcon.defaultProps = defaultPictographIconProps

export { PuzzleIcon }
