import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import TableContext from './TableContext'
import { mediaQuery } from '../../utils/mediaQuery'
import { TableProps } from './Table.types'
import { TableBody, TableBodyProps } from '../../basic-components/TableBody/TableBody'
import { TableCell, TableCellProps, TableCellFixer } from '../../basic-components/TableCell/TableCell'
import { TableHead, TableHeadProps } from '../../basic-components/TableHead/TableHead'
import { TableRow, TableRowProps } from '../../basic-components/TableRow/TableRow'
import { TableSize } from '../../utils/enums'
import { StyledArrowDownIcon, StyledBrandArrowDownIcon, TableHeaderWrapper } from '../DataTable/DataTable.components'

const StyledTable = styled.table<{ isBrand: boolean; withRowSelection?: boolean }>(
  ({ theme: { brand, breakpoint }, isBrand, withRowSelection }) => css`
    width: 100%;
    overflow-x: auto;

    @media screen and (min-width: ${breakpoint.sm}) {
      white-space: nowrap;
    }

    ${isBrand &&
    css`
      font-family: ${brand.fontFamily.PostiFont};
    `}

    ${withRowSelection &&
    css`
      tr {
        td:first-child,
        th:first-child {
          width: 2.5rem;
          vertical-align: middle;

          ${TableHeaderWrapper} {
            width: 100%;
            justify-content: center;
          }

          @media screen and (min-width: ${breakpoint.sm}) {
            width: 3rem;
          }

          ${StyledArrowDownIcon}, ${StyledBrandArrowDownIcon} {
            display: none;
          }
          ${TableCellFixer} {
            padding: 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    `}

    ${mediaQuery.sm`
      display: table;
      overflow-x: auto;
      white-space: normal;
  `}
  `
)

interface TableSubComponents {
  Body: React.FC<TableBodyProps>
  Cell: React.FC<TableCellProps>
  Head: React.FC<TableHeadProps>
  Row: React.FC<TableRowProps>
  Context: React.Context<Pick<TableProps, 'size' | 'stripes' | 'isBrand'>>
}

export const Table: React.FC<TableProps> & TableSubComponents = ({
  size,
  stripes,
  children,
  isBrand,
  withRowSelection,
  ...rest
}) => {
  const table = React.useMemo(() => ({ size, stripes, isBrand }), [size, stripes, isBrand])

  return (
    <TableContext.Provider value={table}>
      <StyledTable isBrand={isBrand} withRowSelection={withRowSelection} {...rest}>
        {children}
      </StyledTable>
    </TableContext.Provider>
  )
}

Table.Body = TableBody
Table.Cell = TableCell
Table.Head = TableHead
Table.Row = TableRow
Table.Context = TableContext

Table.propTypes = {
  size: PropTypes.oneOf(Object.values(TableSize)),
  stripes: PropTypes.bool,
  isBrand: PropTypes.bool,
}

Table.defaultProps = {
  size: TableSize.lg,
  stripes: true,
  isBrand: false,
}
