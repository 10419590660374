import React from 'react'
import { XyzTheme } from '@postidigital/posti-theme'
import { SVG, Path } from '../icons.styled'
import { DefaultIconProps } from '../icons.types'
import { useTheme } from 'styled-components'

const ParcelXXSIcon: React.FC<DefaultIconProps> = (props) => {
  const theme = useTheme()
  return (
    <SVG {...props}>
      <Path
        d="M0 19.5C0 9.97029 5.27703 0 22 0C38.723 0 44 9.97029 44 19.5C44 29.0304 38.723 39 22 39C5.27703 39.0007 0 29.1036 0 19.5Z"
        fill={theme.color.brandPetrol}
      />
      <Path
        d="M16.2806 26L11.4386 19.952L16.0466 14.03H11.7086L9.35064 17.342L6.70464 14.03H2.27664L7.02864 20.006L2.33064 26H6.72264L9.11664 22.616L11.7986 26H16.2806ZM30.559 26L25.717 19.952L30.325 14.03H25.987L23.629 17.342L20.983 14.03H16.555L21.307 20.006L16.609 26H21.001L23.395 22.616L26.077 26H30.559ZM30.5669 22.148C30.7469 24.254 32.1149 26.306 36.0749 26.306C40.1249 26.306 41.6009 24.65 41.6009 22.292C41.6009 20.078 40.4309 18.746 37.6409 18.512L35.5709 18.332C34.7069 18.26 34.3829 17.864 34.3829 17.342C34.3829 16.694 35.0669 16.334 36.0569 16.334C37.1729 16.334 37.8209 16.838 37.9289 17.63H41.4929C41.3489 15.524 39.8369 13.742 36.0389 13.742C32.5109 13.742 30.7469 15.29 30.7469 17.612C30.7469 19.7 32.0429 20.978 34.2569 21.212L36.7949 21.482C37.5509 21.572 37.9649 21.896 37.9649 22.58C37.9649 23.282 37.3709 23.714 36.1469 23.714C34.7069 23.714 34.1849 22.868 34.0769 22.148H30.5669Z"
        fill={theme.color.brandBrightYellow}
      />
    </SVG>
  )
}

ParcelXXSIcon.defaultProps = {
  viewBox: '0 0 44 39',
  height: `${XyzTheme.iconSize.m}em`,
  width: `${XyzTheme.iconSize.m}em`,
}

export { ParcelXXSIcon }
