import styled, { css } from 'styled-components'
import { CardSelectorProps } from './CardSelector.types'
import { ArrowRightIcon } from '../../design-tokens/icons/pictograph'
import { IconProps } from '../../design-tokens/icons/icons.types'
import { CheckmarkIndicatorIcon } from '../../design-tokens/icons/indicator/CheckmarkIndicatorIcon'

export const CardSelectorWrapper = styled.button<CardSelectorProps>`
  ${({ theme: { color, borderRadius, spacing, brand }, selected, isBrand }) => css`
    position: relative;
    background-color: ${color.neutralWhite};
    border-radius: ${borderRadius.xs}rem;
    border: 3px solid transparent;
    width: 10rem;
    min-height: 6.5rem;
    box-shadow:
      0px 15px 22px rgba(0, 0, 0, 0.06),
      0px 3px 8px rgba(0, 0, 0, 0.08),
      0px 1px 1px rgba(0, 0, 0, 0.08),
      0px 3px 1px rgba(0, 0, 0, 0.04);
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-word;
    padding: ${spacing.space4}rem;
    flex-direction: column;
    user-select: none;

    ${selected &&
    css`
      border: 3px solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
    `}

    &[aria-disabled='true'] {
      pointer-events: none;
      background-color: ${color.neutralGray5};
      border-color: transparent;

      label {
        color: ${color.neutralPassiveGray};
        cursor: default;
      }
    }

    label {
      color: ${isBrand ? brand.color.gray90 : color.signalBlue};
      cursor: pointer;
    }
    @media (hover: hover) {
      &:hover {
        background-color: ${isBrand ? brand.color.gray10 : color.neutralOnHoverGray};
      }
    }

    &:active {
      background-color: ${isBrand ? brand.color.gray20 : color.neutralOnPressGray};
    }

    &:focus {
      outline: 0;
      box-shadow: 0px 0px 7px ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow:
        0px 15px 22px rgba(0, 0, 0, 0.06),
        0px 3px 8px rgba(0, 0, 0, 0.08),
        0px 1px 1px rgba(0, 0, 0, 0.08),
        0px 3px 1px rgba(0, 0, 0, 0.04);
    }
  `}
`

export const StyledIcon = styled(ArrowRightIcon)<IconProps>`
  ${({ theme: { iconSize, spacing } }) => css`
    flex-shrink: 0;
    width: ${iconSize.m}rem;
    height: ${iconSize.m}rem;
    margin-bottom: ${spacing.space2}rem;
  `}
`

export const StyledCheckmarkIndicatorIcon = styled(CheckmarkIndicatorIcon)`
  ${({ theme: { iconSize, spacing } }) => css`
    position: absolute;
    right: ${spacing.space1}rem;
    top: ${spacing.space1}rem;
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
  `}
`
