import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const UserSettingsIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M12.333 14.198a2.76 2.76 0 01.38-.51.438.438 0 00-.109-.686 6.47 6.47 0 00-3.119-.815 6.62 6.62 0 00-6.308 4.706c-.039.131-.177.565-.177.565H11.806a.439.439 0 00.4-.623 2.86 2.86 0 01.127-2.637zM9.581 11.458a3.63 3.63 0 10.002-7.26 3.63 3.63 0 00-.002 7.26z"
      fill={color}
    />
    <Path
      d="M20.056 14.537l.627.078a.362.362 0 01.317.36v.765a.361.361 0 01-.317.36l-.627.078a.09.09 0 00-.076.067 3.403 3.403 0 01-.347.832.09.09 0 00.007.1l.39.5a.362.362 0 01-.03.48l-.542.542a.362.362 0 01-.477.03l-.5-.39a.09.09 0 00-.101-.006c-.261.152-.54.268-.832.346a.09.09 0 00-.067.076l-.078.628a.363.363 0 01-.359.317h-.766a.362.362 0 01-.359-.317l-.078-.628a.09.09 0 00-.067-.076 3.405 3.405 0 01-.831-.346.09.09 0 00-.101.007l-.5.389a.361.361 0 01-.478-.03l-.543-.543a.361.361 0 01-.03-.478l.39-.501a.09.09 0 00.006-.101 3.404 3.404 0 01-.347-.832.09.09 0 00-.075-.067l-.628-.078a.362.362 0 01-.316-.359v-.766a.361.361 0 01.317-.359l.627-.079a.091.091 0 00.075-.067c.079-.291.195-.571.347-.832a.09.09 0 00-.007-.1l-.39-.5a.362.362 0 01.03-.478l.543-.543a.362.362 0 01.477-.03l.5.39a.09.09 0 00.102.007c.26-.152.54-.269.832-.347a.09.09 0 00.066-.076l.079-.628a.362.362 0 01.358-.317h.766a.361.361 0 01.359.317l.08.628a.09.09 0 00.066.076c.291.078.57.195.832.347a.091.091 0 00.1-.007l.5-.39a.36.36 0 01.477.03l.542.543a.362.362 0 01.03.478l-.389.5a.09.09 0 00-.007.101c.152.26.269.54.347.832a.09.09 0 00.076.067zm-3.396 2.268a1.447 1.447 0 100-2.894 1.447 1.447 0 000 2.894z"
      fill={color}
    />
  </SVG>
)

UserSettingsIcon.propTypes = defaultIconPropTypes

UserSettingsIcon.defaultProps = defaultPictographIconProps

export { UserSettingsIcon }
