import { XyzTheme, BrandTheme } from '@postidigital/posti-theme'
import styled, { css } from 'styled-components'
import { CheckmarkIcon, MinusIcon } from '../../design-tokens/icons/pictograph'
import {
  CheckmarkIcon as BrandCheckmarkIcon,
  MinusIcon as BrandMinusIcon,
} from '../../design-tokens/icons/brand-ui-icons'
import { Position } from '../../utils/enums'

export interface CircleProps {
  checked?: boolean
  disabled?: boolean
  isInvalid: boolean
  isBrand?: boolean
}

export const getMainSwitchColor = ({ disabled, isInvalid, checked, isBrand }: CircleProps): string => {
  if (disabled) {
    return isBrand ? BrandTheme.color.gray30 : XyzTheme.color.neutralPassiveGray
  }
  if (isInvalid) {
    return isBrand ? BrandTheme.color.red60 : XyzTheme.color.signalRed
  }
  if (checked) {
    return isBrand ? BrandTheme.color.postiOrange70 : XyzTheme.color.signalHoverBlue
  }
  return isBrand ? BrandTheme.color.gray50 : XyzTheme.color.neutralIconGray
}

export const StyledCircle = styled.div<CircleProps>(
  ({ theme: { borderRadius, color, zIndex, brand }, checked, disabled, isInvalid, isBrand }) => css`
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 2rem;
    height: 2rem;
    border: 0.1875rem solid ${getMainSwitchColor({ checked, disabled, isInvalid, isBrand })};
    border-radius: ${borderRadius.sm}rem;
    background-color: ${isBrand ? (disabled ? brand.color.gray10 : brand.color.white) : color.neutralWhite};
    z-index: ${zIndex.zIndexDefault};
    transition:
      left 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      transform 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      all 100ms ease;
    text-align: center;
    line-height: ${brand.lineHeight.body};
  `
)

export const StyledInput = styled.input<{ disabled?: boolean; checked?: boolean }>(
  ({ theme: { zIndex }, disabled }) => css`
    opacity: 0;
    height: 2rem;
    width: 100%;
    outline: 0;
    -webkit-tap-highlight-color: transparent;
    cursor: ${disabled ? 'default' : 'pointer'};
    z-index: ${zIndex.zIndex2};
    position: absolute;
    left: 0;
    top: 0;
  `
)

export const StyledBackground = styled.div<{
  checked: boolean
  disabled: boolean
  isInvalid: boolean
  isBrand: boolean
}>(
  ({ theme: { borderRadius, color, brand }, checked, disabled, isInvalid, isBrand }) => css`
    position: absolute;
    background-color: ${isInvalid
      ? color.signalHoverRed
      : checked && !disabled
        ? color.signalBlue
        : color.neutralPassiveGray};
    width: 3.625rem;
    height: 2rem;
    border: 0.1875rem solid
      ${isInvalid ? color.signalRed : checked && !disabled ? color.signalBlue : color.neutralPassiveGray};
    border-radius: ${borderRadius.sm}rem;
    transition: all 100ms ease-in;
    ${!isBrand
      ? css`
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        `
      : css`
          background-color: ${isInvalid && !disabled
            ? brand.color.red1
            : checked && !disabled
              ? brand.color.postiOrange60
              : brand.color.gray10};
          border: 0.1875rem solid
            ${isInvalid && !disabled
              ? brand.color.red1
              : checked && !disabled
                ? brand.color.postiOrange60
                : brand.color.gray10};
        `}
  `
)

export const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  margin-top: 2px;
`

export const StyledMinusIcon = styled(MinusIcon)``

export const StyledBrandCheckmarkIcon = styled(BrandCheckmarkIcon)`
  margin-top: 2px;
`

export const StyledBrandMinusIcon = styled(BrandMinusIcon)``

export const StyledSwitch = styled.div<{ isInvalid: boolean; isBrand: boolean; checked: boolean }>(
  ({ theme: { color, zIndex, brand }, isInvalid, isBrand, checked }) => css`
    display: inline-flex;
    width: 4rem;
    min-width: 4rem;
    height: 2rem;
    position: relative;
    vertical-align: middle;
    z-index: ${zIndex.zIndex0};
    align-self: flex-start;

    /* Hover styles */
    @media (hover: hover) {
      ${StyledInput}:not([disabled]):hover ~ ${StyledBackground} {
        ${isBrand &&
        css`
          background-color: ${isInvalid && !checked
            ? brand.color.red5
            : !checked
              ? brand.color.gray20
              : brand.color.postiOrange70};
          border-color: ${isInvalid && !checked
            ? brand.color.red5
            : !checked
              ? brand.color.gray20
              : brand.color.postiOrange70};
        `}
      }

      ${StyledInput}:not([disabled]):hover ~ ${StyledCircle} {
        border-color: ${isBrand
          ? isInvalid && !checked
            ? brand.color.red70
            : checked
              ? brand.color.postiOrange80
              : brand.color.gray60
          : isInvalid && !checked
            ? color.signalRed
            : color.neutralNetworkGray};
        > ${StyledCheckmarkIcon} * {
          fill: ${color.neutralNetworkGray};
          stroke: none;
        }
        ,
        > ${StyledBrandCheckmarkIcon} * {
          stroke: ${brand.color.postiOrange80};
        }
        ,
        > ${StyledMinusIcon} * {
          fill: ${isInvalid && !checked ? color.signalRed : color.neutralNetworkGray};
          stroke: none;
        }
        ,
        > ${StyledBrandMinusIcon} * {
          stroke: ${isInvalid && !checked ? brand.color.red70 : brand.color.gray60};
        }
      }
    }

    /* Active styles */
    ${isBrand &&
    css`
      ${StyledInput}:not([disabled]):active ~ ${StyledBackground} {
        background-color: ${isInvalid && !checked
          ? brand.color.red10
          : !checked
            ? brand.color.gray30
            : brand.color.postiOrange80};
        border-color: ${isInvalid && !checked
          ? brand.color.red10
          : !checked
            ? brand.color.gray30
            : brand.color.postiOrange80};
      }

      ${StyledInput}:not([disabled]):active ~ ${StyledCircle} {
        border-color: ${isInvalid && !checked
          ? brand.color.red80
          : checked
            ? brand.color.postiOrange90
            : brand.color.gray80};
        > ${StyledCheckmarkIcon} * {
          fill: ${color.neutralNetworkGray};
        }
        > ${StyledBrandCheckmarkIcon} * {
          stroke: ${brand.color.postiOrange90};
        }
        ,
        > ${StyledMinusIcon} * {
          fill: ${isInvalid && !checked ? color.signalRed : color.neutralNetworkGray};
        }
        ,
        > ${StyledBrandMinusIcon} * {
          stroke: ${isInvalid && !checked ? brand.color.red80 : brand.color.gray70};
        }
      }
    `}

    /* Focus styles */
    ${StyledInput}:not([disabled]):focus ~ ${StyledBackground} {
      box-shadow: ${isBrand
        ? `0 0 0 2px ${brand.color.white}, 0 0 0 4px ${brand.color.postiOrange60}`
        : `0 0 0.4375rem ${color.signalBlue}`};
    }
    ${StyledInput}:focus:not(:focus-visible) ~ ${StyledBackground} {
      box-shadow: none;
    }
    /* Selected styled */
    ${StyledInput}:checked ~ ${StyledCircle},
    ${StyledInput}:checked:disabled ~ ${StyledCircle} {
      left: ${isBrand ? '1.6rem' : '2rem'};
    }
  `
)

export const StyledLabel = styled.label<{
  labelPosition: Position
  spacing: number
  isBrand: boolean
  disabled: boolean
}>(
  ({ theme, labelPosition, spacing, isBrand, disabled }) => css`
    display: flex;
    align-items: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    ${labelPosition === Position.left &&
    `
    ${StyledSwitch} {
      margin-left: ${spacing || theme.spacing.space7}rem;
    }
  `}

    ${labelPosition === Position.right &&
    `
    ${StyledSwitch} {
      margin-right: ${spacing || theme.spacing.space3}rem;
    }
  `}

  ${isBrand &&
    css`
      font-family: ${theme.brand.fontFamily.PostiFont};
      line-height: ${theme.brand.lineHeight.label};
      font-weight: ${theme.brand.fontWeight.medium};
    `}
  `
)
