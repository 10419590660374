import React, { HTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import styled, { css, StyledComponent, DefaultTheme } from 'styled-components'
import TableContext from '../../composite-components/Table/TableContext'

export interface TableRowProps extends HTMLAttributes<HTMLTableRowElement> {
  selected?: boolean
}

interface TableRowSubComponents {
  Row: StyledComponent<'tr', DefaultTheme, NonNullable<unknown>, never>
}

export const StyledTableRow = styled.tr<{ isBrand: boolean }>(
  ({ theme: { color, brand }, isBrand }) => css`
    border-bottom: ${isBrand ? `1px solid ${brand.color.gray10}` : `2px solid ${color.neutralGray5}`};

    &[data-selected='true'] {
      background-color: ${isBrand ? brand.color.postiOrange1 : color.backgroundCTABlue} !important;
      ${isBrand &&
      css`
        border-bottom: 1px solid ${brand.color.postiOrange1};
      `}
    }

    &:active {
      background-color: ${isBrand ? `${brand.color.gray10}` : `inherit`};
    }

    &:focus {
      position: relative;
      outline: none;
      box-shadow: ${isBrand
        ? `inset 0px 0px 0px 2px ${brand.color.postiOrange60}`
        : `inset 0px 0px 7px ${color.signalBlue}`};
      background-color: ${isBrand ? `${brand.color.gray20}` : `inherit`};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
      background-color: transparent;
    }
  `
)

export const TableRow: React.FC<TableRowProps> & TableRowSubComponents = ({ selected, children, ...rest }) => {
  const table = React.useContext(TableContext)
  return (
    <StyledTableRow tabIndex={0} data-selected={selected} isBrand={table.isBrand} {...rest}>
      {children}
    </StyledTableRow>
  )
}

TableRow.Row = StyledTableRow

TableRow.propTypes = {
  selected: PropTypes.bool,
}
