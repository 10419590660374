import styled, { css } from 'styled-components'
import { Container } from '../../basic-components/Notification/Notification.style'
import { Headline } from '../../design-tokens/typography/Headline'

export const ComplexNotificationContainer = styled(Container)(
  ({ theme: { breakpoint, spacing } }) => css`
    border-radius: ${({ theme }) => theme.borderRadius.sm}rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    padding: ${spacing.space4}rem;

    @media screen and (max-width: ${breakpoint.sm}) {
      padding: ${spacing.space3}rem;
    }
  `
)

export const ComplexNotificationTextWrapper = styled.div(
  ({ theme: { breakpoint, color, spacing } }) => css`
    flex: 1 1 0;
    color: ${color.brandPetrol};
    margin-right: ${spacing.space3}rem;

    @media screen and (max-width: ${breakpoint.sm}) {
      margin-right: ${spacing.space2}rem;
    }
  `
)

export const ComplexNotificationTitle = styled(Headline)(
  ({ theme: { spacing } }) => css`
    &:not(:only-child) {
      margin-bottom: ${spacing.space2}rem;
    }
  `
)

export const ComplexNotificationIllustrationWrapper = styled.div<{
  isCutOnNarrowScreen?: boolean
  hasVerticalIndent?: boolean
  hasHorizontalIndent?: boolean
}>(
  ({ theme: { breakpoint, spacing }, isCutOnNarrowScreen, hasVerticalIndent, hasHorizontalIndent }) => `
    flex: ${isCutOnNarrowScreen ? '1 1 0' : '0 0 auto'};
    overflow: hidden;
    margin-top: ${hasVerticalIndent ? `0` : `-${spacing.space4}rem`};
    margin-bottom: ${hasVerticalIndent ? `0` : `-${spacing.space4}rem`};
    margin-right: ${hasHorizontalIndent ? `0` : `-${spacing.space4}rem`};

    @media screen and (max-width: ${breakpoint.sm}) {
      margin-right: ${
        isCutOnNarrowScreen ? `-${spacing.space4}rem` : hasHorizontalIndent ? `-${spacing.space3}rem` : `0`
      };
    }

    & > * {
      display: block;
      margin-left: auto;
    }
  `
)
