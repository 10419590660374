import React, { useImperativeHandle, useRef, useState, useId } from 'react'
import PropTypes from 'prop-types'
import { UIMessage } from '../UIMessage'
import {
  InputContent,
  LabelAndInputContainer,
  StyledLabel,
  StyledInput,
  StyledBody,
  StyledBrandBody,
  RightTemplate,
  InputWrapper,
  Wrapper,
} from './Input.style'
import { InputProps, InputSubComponents } from './Input.types'
import { BodySize } from '../../design-tokens/typography/Body/Body'
import { addNamespace } from '../../utils/helpers'
import withBrand from '../../utils/withBrand'

const InputWithoutNamespace = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      id,
      isInvalid,
      label,
      message,
      helpText,
      leftTemplate,
      rightTemplate,
      className,
      lightBackground,
      messageProps,
      'aria-describedby': ariaDescribedBy,
      noBorderChangeOnFocus,
      isDark,
      isBrand,
      ...inputProps
    },
    forwardRef
  ) => {
    const localRef = useRef<HTMLInputElement>()
    useImperativeHandle(forwardRef, () => localRef.current)
    const [autocompleteDone, setAutocompleteDone] = useState(false)
    const { disabled, readOnly, ...restInputProps } = inputProps

    const generatedId = useId()

    const inputId = id ? id : `input-id-${generatedId}`
    const messageId = `${inputId}-message`
    const describedByElements = [ariaDescribedBy, messageId].filter(Boolean)

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (autocompleteDone && !event.currentTarget.value) {
        setAutocompleteDone(false)
      }
      inputProps.onChange?.(event)
    }

    const onClick = (event: React.MouseEvent<HTMLInputElement>) => {
      // prevent click event to propagate to the container, causing double focus.
      event.stopPropagation()
      inputProps.onClick?.(event)
    }

    const onInputClick = (event: React.MouseEvent<HTMLInputElement>) => {
      if (!inputProps.disabled) {
        onClick(event)
        localRef.current?.focus()
      }
    }
    /** Prevents any other character in the number Input than 0-9, dot and comma to keep the UI consistent */
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
      if (inputProps.type === 'number' && !/^[0-9,.-]/.test(event.key)) {
        event.preventDefault()
      }

      inputProps.onKeyDown?.(event)
    }

    /**
     * Prevent default when clicking on anything else except input-tag
     * Input element would otherwise lose focus
     * @param event
     */
    const onMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
      const targetElem = event.target as HTMLElement
      if (targetElem.tagName && targetElem.tagName !== 'INPUT') {
        event.preventDefault()
      }
    }

    const onAnimationStart = (event: React.AnimationEvent<HTMLInputElement>) => {
      // The autofill doesn't always go through the normal onChange event, make sure that the input field behaves correctly in these cases
      if (event.animationName === 'onAutoFillStart') {
        // The value might be seemingly "" although it is the actual autofilled value in the UI
        setAutocompleteDone(true)
      }
      inputProps.onAnimationStart?.(event)
    }

    return (
      <Wrapper>
        <InputWrapper onMouseDown={onMouseDown} onClick={onInputClick} className={className}>
          <InputContent
            isInvalid={isInvalid}
            hasMessage={Boolean(message)}
            readOnly={readOnly}
            aria-disabled={disabled}
            lightBackground={lightBackground}
            noBorderChangeOnFocus={noBorderChangeOnFocus}
            isDark={isDark}
            isBrand={isBrand}
          >
            {leftTemplate}
            <LabelAndInputContainer
              hasLeftElement={Boolean(leftTemplate)}
              hasRightElement={Boolean(rightTemplate)}
              /** badInput condition prevents label to collapse in the number Input if something invalid was inserted (in that case value will be empty) */
              hasValue={Boolean(inputProps.value) || localRef.current?.validity.badInput}
              readOnly={readOnly}
              isBrand={isBrand}
            >
              <StyledLabel htmlFor={inputId} aria-disabled={disabled} isBrand={isBrand}>
                {label}
              </StyledLabel>
              <StyledInput
                id={inputId}
                ref={localRef}
                aria-disabled={disabled}
                aria-readonly={readOnly}
                aria-invalid={isInvalid}
                aria-describedby={describedByElements.length ? describedByElements.join(' ') : undefined}
                aria-label={label}
                {...restInputProps}
                value={inputProps.value ?? ''}
                onAnimationStart={onAnimationStart}
                onChange={onChange}
                onClick={onClick}
                onKeyDown={onKeyDown}
                isBrand={isBrand}
                readOnly={readOnly || disabled}
                aria-atomic={true}
                aria-live="assertive"
              />
            </LabelAndInputContainer>
          </InputContent>
          <RightTemplate> {rightTemplate}</RightTemplate>
        </InputWrapper>
        {!!helpText &&
          (isBrand ? (
            <StyledBrandBody size={BodySize.Five}>{helpText}</StyledBrandBody>
          ) : (
            <StyledBody size={BodySize.Five}>{helpText}</StyledBody>
          ))}
        <div id={messageId} aria-live="assertive">
          {message && <UIMessage success={!isInvalid} message={message} {...messageProps} isBrand={isBrand} />}
        </div>
      </Wrapper>
    )
  }
)

export const inputDefaultProps = {
  lightBackground: true,
}

export const inputPropTypes = {
  isInvalid: PropTypes.bool,
  label: PropTypes.string.isRequired,
  id: PropTypes.string,
  readOnly: PropTypes.bool,
  message: PropTypes.string,
  helpText: PropTypes.string,
  messageProps: PropTypes.object,
  leftTemplate: PropTypes.element,
  rightTemplate: PropTypes.element,
  lightBackground: PropTypes.bool,
  noBorderChangeOnFocus: PropTypes.bool,
  isDark: PropTypes.bool,
  isBrand: PropTypes.bool,
}

InputWithoutNamespace.displayName = 'Input'
InputWithoutNamespace.defaultProps = inputDefaultProps
InputWithoutNamespace.propTypes = inputPropTypes

const BrandInputWithoutNamespace = withBrand(InputWithoutNamespace)

const inputSubComponents: InputSubComponents = {
  Content: InputContent,
  LabelInputContainer: LabelAndInputContainer,
  Label: StyledLabel,
  Input: StyledInput,
  Body: StyledBody,
  BrandBody: StyledBrandBody,
  UIMessage: UIMessage,
}

export const Input = addNamespace(InputWithoutNamespace, inputSubComponents)
export const BrandInput = addNamespace(BrandInputWithoutNamespace, inputSubComponents)
