import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, Rect, SVG } from '../icons.styled'

const LanguageIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Rect x="3" y="3" width="18" height="18" rx="3" stroke={color} strokeWidth="2" fill="none" strokeLinejoin="round" />
    <Path
      d="M6 14.9111H7.55049L7.94534 13.7362H10.69L11.0848 14.9111H12.6835L10.1989 8H8.48464L6 14.9111ZM8.39797 12.3738L9.31286 9.63893L10.2277 12.3738H8.39797Z"
      fill={color}
    />
    <Path
      d="M14.8027 15C15.4094 15 16.0547 14.763 16.3628 14.2694C16.488 14.6939 16.8829 15.0889 18 14.921V13.7955C17.7207 13.8449 17.5666 13.7264 17.5666 13.4598V11.5839C17.5666 10.3893 16.9503 9.56982 15.3131 9.56982C14.3308 9.56982 13.5893 10.0042 13.2137 10.3597L13.9456 11.3075C14.2153 11.0113 14.6583 10.7151 15.2553 10.7151C15.9102 10.7151 16.1702 11.0014 16.1702 11.2779C16.1702 11.5346 16.0161 11.6629 15.4383 11.7419C13.5411 12.0085 13.05 12.7391 13.05 13.5289C13.05 14.3879 13.7145 15 14.8027 15ZM14.4656 13.4302C14.4656 13.0846 14.7257 12.8279 15.5346 12.6996C15.8717 12.6502 16.0739 12.5712 16.1895 12.3935V12.7983C16.1895 13.5388 15.6983 13.9633 15.1301 13.9633C14.7546 13.9633 14.4656 13.7659 14.4656 13.4302Z"
      fill={color}
    />
  </SVG>
)

LanguageIcon.propTypes = defaultIconPropTypes
LanguageIcon.defaultProps = defaultPictographIconProps

export { LanguageIcon }
