import PropTypes from 'prop-types'
import React, { useState } from 'react'
import styled, { css, DefaultTheme, StyledComponent } from 'styled-components'
import { UIMessage, UIMessageProps, UIMessageSubComponents } from '../UIMessage/UIMessage'
import { StyledRoot } from '../Radio/Radio.style'

export interface RadioGroupContextValue {
  value: string | number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  isInvalid?: boolean
  disabled?: boolean
}

export interface RadioGroupSubComponents {
  Root: StyledComponent<'div', DefaultTheme, { isBrand?: boolean }, never>
  Group: StyledComponent<'div', DefaultTheme, { alignedHorizontally?: boolean }, never>
  UIMessage: React.FC<UIMessageProps> & UIMessageSubComponents
}

export const RadioGroupContext = React.createContext<RadioGroupContextValue | null>(null)

export const useRadioGroup = (): RadioGroupContextValue => {
  return React.useContext(RadioGroupContext)
}

export interface RadioGroupProps {
  value: string | number
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
  alignedHorizontally?: boolean
  disabled?: boolean
  isBrand?: boolean
  isInvalid?: boolean
  errorMessage?: string
  children: React.ReactNode
}

const StyledRadioGroup = styled.div<{ alignedHorizontally?: boolean }>(
  ({ alignedHorizontally, theme: { spacing } }) => css`
    display: flex;
    flex-direction: ${alignedHorizontally ? `row` : `column`};

    & > div {
      display: block;
      ${alignedHorizontally &&
      css`
        margin-right: ${spacing.space5}rem;
      `}
    }
  `
)

export const RadioGroup: React.FC<RadioGroupProps> & RadioGroupSubComponents = (props) => {
  const { value: valueProp, onChange, children, errorMessage, disabled, isBrand, isInvalid, ...rest } = props
  const [value, setValue] = useState(valueProp)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
    onChange(e)
  }

  return (
    <RadioGroupContext.Provider value={{ value, onChange: handleChange, disabled, isInvalid }}>
      <StyledRoot isBrand={isBrand}>
        <StyledRadioGroup role="radiogroup" {...rest}>
          {children}
        </StyledRadioGroup>
        <div aria-live="assertive">
          {!value && errorMessage && !disabled && isInvalid && <UIMessage isBrand={isBrand} message={errorMessage} />}
        </div>
      </StyledRoot>
    </RadioGroupContext.Provider>
  )
}

RadioGroup.Root = StyledRoot
RadioGroup.Group = StyledRadioGroup
RadioGroup.UIMessage = UIMessage

RadioGroup.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  alignedHorizontally: PropTypes.bool,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,
}

RadioGroup.defaultProps = {
  alignedHorizontally: false,
  isBrand: false,
  isInvalid: false,
  disabled: false,
}
