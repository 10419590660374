import React, { useEffect } from 'react'
import { useDropzone, Accept } from 'react-dropzone'
import PropTypes, { Validator } from 'prop-types'
import { ErrorMessages } from './ErrorMessages'
import {
  DropdownContainer,
  Placeholder,
  Text,
  StyledWrapper,
  FileList,
  FileListMsgContainer,
  StyledIconContainer,
} from './FileUpload.styles'
import { FileUploadProps, FileUploadSubComponents } from './FileUpload.types'

const FileUpload: React.FC<FileUploadProps> & FileUploadSubComponents = ({
  setFiles,
  placeholderText,
  errorMessages,
  setErrorMessages,
  resetErrorMessages,
  inputProps = {},
  maxWidth,
  centerContent,
  children,
  isBrand,
  isInvalid,
  id,
  icon,
  ...rest
}) => {
  const { getRootProps, getInputProps, isFileDialogActive, isDragActive } = useDropzone({
    ...rest,
    onDropRejected: setErrorMessages,
    onDropAccepted: setFiles,
    useFsAccessApi: false,
  })
  const { disabled } = rest

  useEffect(() => {
    if (isFileDialogActive || isDragActive) {
      resetErrorMessages()
    }
  }, [isFileDialogActive, isDragActive, resetErrorMessages])

  const showInvalid = isInvalid || errorMessages.length !== 0
  const messageId = id !== undefined ? id : 'fileupload-message'

  return (
    <StyledWrapper maxWidth={maxWidth} id={id} aria-disabled={disabled}>
      <DropdownContainer
        {...getRootProps({ isDragActive })}
        aria-disabled={disabled}
        isBrand={isBrand}
        isInvalid={showInvalid}
        aria-labelledby={messageId}
        role="button"
        tabIndex={0}
      >
        <input {...getInputProps(inputProps)} />
        <Placeholder centerContent={centerContent}>
          <StyledIconContainer centerContent={centerContent}>{icon}</StyledIconContainer>
          <Text aria-disabled={disabled} centerContent={centerContent} isBrand={isBrand}>
            {placeholderText}
          </Text>
        </Placeholder>
      </DropdownContainer>
      <FileListMsgContainer>
        <FileList>{children}</FileList>
        <ErrorMessages id={messageId} aria-live="assertive" errorMessages={errorMessages} isBrand={isBrand} />
      </FileListMsgContainer>
    </StyledWrapper>
  )
}

export const BrandFileUpload: React.FC<FileUploadProps> = (props) => {
  const { children, isBrand, ...rest } = props
  return (
    <FileUpload {...rest} isBrand={true}>
      {children}
    </FileUpload>
  )
}

FileUpload.Wrapper = StyledWrapper
FileUpload.DropdownContainer = DropdownContainer
FileUpload.Placeholder = Placeholder
FileUpload.IconContainer = StyledIconContainer
FileUpload.FileListMsgContainer = FileListMsgContainer
FileUpload.FileList = FileList
FileUpload.ErrorMessages = ErrorMessages

FileUpload.propTypes = {
  setFiles: PropTypes.func.isRequired,
  placeholderText: PropTypes.node.isRequired,
  errorMessages: PropTypes.arrayOf(PropTypes.string).isRequired,
  setErrorMessages: PropTypes.func.isRequired,
  resetErrorMessages: PropTypes.func.isRequired,
  accept: PropTypes.object as Validator<Accept>,
  minSize: PropTypes.number,
  maxSize: PropTypes.number,
  preventDropOnDocument: PropTypes.bool,
  noClick: PropTypes.bool,
  noKeyboard: PropTypes.bool,
  noDrag: PropTypes.bool,
  noDragEventsBubbling: PropTypes.bool,
  inputProps: PropTypes.object,
  maxWidth: PropTypes.string,
  centerContent: PropTypes.bool,
  children: PropTypes.arrayOf(PropTypes.node),
  isBrand: PropTypes.bool,
  id: PropTypes.string,
  icon: PropTypes.node,
}

FileUpload.defaultProps = {
  preventDropOnDocument: false,
  noClick: false,
  noKeyboard: false,
  noDrag: false,
  noDragEventsBubbling: false,
  maxWidth: 'none',
  centerContent: false,
  isBrand: false,
  id: 'fileupload-message',
}

export { FileUpload }
