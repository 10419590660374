import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const TrackParcelsIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M20.5 7.27734L12 11.9996M12 11.9996L3.49997 7.27734M12 11.9996L12 21.4996M21 11.9996V7.94104C21 7.5984 21 7.42708 20.9495 7.27428C20.9049 7.1391 20.8318 7.01502 20.7354 6.91033C20.6263 6.79199 20.4766 6.70879 20.177 6.54239L12.777 2.43128C12.4934 2.27372 12.3516 2.19494 12.2015 2.16406C12.0685 2.13672 11.9315 2.13672 11.7986 2.16406C11.6484 2.19494 11.5066 2.27372 11.223 2.43128L3.82297 6.54239C3.52345 6.70879 3.37368 6.792 3.26463 6.91033C3.16816 7.01502 3.09515 7.1391 3.05048 7.27428C3 7.42708 3 7.5984 3 7.94104V16.0582C3 16.4008 3 16.5721 3.05048 16.7249C3.09515 16.8601 3.16816 16.9842 3.26463 17.0889C3.37368 17.2072 3.52346 17.2904 3.82297 17.4568L11.223 21.5679C11.5066 21.7255 11.6484 21.8043 11.7986 21.8351C11.9315 21.8625 12.0685 21.8625 12.2015 21.8351C12.3516 21.8043 12.4934 21.7255 12.777 21.5679L13 21.444M7.5 4.4996L16.5 9.49959M22 21.4996L21 20.4996M22 17.9996C22 19.6564 20.6569 20.9996 19 20.9996C17.3431 20.9996 16 19.6564 16 17.9996C16 16.3427 17.3431 14.9996 19 14.9996C20.6569 14.9996 22 16.3427 22 17.9996Z" />
  </SVG>
)

TrackParcelsIcon.propTypes = defaultIconPropTypes
TrackParcelsIcon.defaultProps = defaultPictographIconProps

export { TrackParcelsIcon }
