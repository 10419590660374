import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const BankIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M4.56 8.15l7.08-3.08c.23-.1.49-.1.72 0l7.08 3.1c.8.36.67 1.48-.08 1.71V10.74h-1.63v6.28h.82c.45 0 .81.37.81.82h.82c.45 0 .82.37.82.83 0 .46-.37.83-.82.83H3.82a.82.82 0 01-.82-.83c0-.46.37-.83.82-.83h.82V17l1.63.02v-6.28H4.64v-.16-.67-.05c-.75-.23-.89-1.36-.08-1.71zm4.99 8.87v-6.28H7.9v6.28h1.64zm3.27-6.28v6.28h-1.64v-6.28h1.64zm3.27 0v6.28h-1.64v-6.28h1.64z"
      fill={color}
    />
  </SVG>
)

BankIcon.propTypes = defaultIconPropTypes

BankIcon.defaultProps = defaultPictographIconProps
