import styled, { css } from 'styled-components'
import { NavButtonProps } from './NavButton.types'
import { ChevronDownIcon } from '../../xyz'
import { XyzTheme } from '@postidigital/posti-theme'

const colors = {
  primary: {
    color: XyzTheme.color.neutralWhite,
    colorHover: XyzTheme.color.neutralWhite,
    background: XyzTheme.color.signalBlue,
    backgroundHover: '#007ee6',
  },
  secondary: {
    color: XyzTheme.color.neutralNetworkGray,
    colorHover: XyzTheme.color.signalBlue,
    background: XyzTheme.color.neutralWhite,
    backgroundHover: XyzTheme.color.neutralGray2,
  },
}

export const StyledButton = styled.button<Pick<NavButtonProps, 'isOpen' | 'contentMode' | 'selectedColor' | 'isBrand'>>(
  ({ theme: { color, fontFamily, brand }, isOpen, contentMode, selectedColor, isBrand }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    user-select: none;
    transition:
      color 0.15s ease-in-out,
      background-color 0.15s ease-in-out;
    cursor: pointer;
    height: 100%;
    padding: 0.375rem 0.75rem;
    font-family: ${isBrand ? brand.fontFamily.PostiFont : fontFamily.Montserrat};
    color: ${isBrand ? brand.color.gray90 : colors[contentMode].color};
    background-color: ${isOpen ? colors[contentMode].backgroundHover : colors[contentMode].background};

    @media (hover: hover) {
      &:hover {
        outline: 0;
        background-color: ${colors[contentMode].backgroundHover};
        color: ${colors[contentMode].colorHover};
      }
    }

    &:focus,
    &:active {
      outline: 0;
      background-color: ${colors[contentMode].backgroundHover};
      color: ${colors[contentMode].colorHover};
    }

    &:focus {
      box-shadow: inset 0 0 0 2px ${color.signalBlue};
    }

    &:focus:not(:focus-visible) {
      box-shadow: none;
    }

    ${contentMode === 'secondary' &&
    css`
      &.selected {
        background-color: ${colors[contentMode].backgroundHover};
        border-top: 0.1875em solid transparent;
        border-bottom: 0.1875em solid ${selectedColor};
      }
    `}

    ${isBrand &&
    css`
      font-weight: ${brand.fontWeight.bold};
      line-height: ${brand.lineHeight.label};
    `}
  `
)

export const StyledChevron = styled(ChevronDownIcon)(
  ({ theme: { spacing } }) => css`
    margin: 0 -${spacing.space1}rem 0 ${spacing.space2}rem;
    flex-shrink: 0;
  `
)

export const Title = styled.span(
  ({ theme: { fontSize, fontWeight } }) => css`
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.body.three}rem;
    overflow: hidden;
    white-space: pre-wrap;
    pointer-events: none;
  `
)
