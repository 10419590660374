import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const SendIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M20.34 11.02L4.44 4.58c-.9-.36-1.76.58-1.33 1.45l2.72 5.5.22.5-.22.44-2.72 5.5c-.43.87.43 1.81 1.33 1.45l15.9-6.44c.88-.35.88-1.6 0-1.96z"
      fill={color}
    />
  </SVG>
)

SendIcon.propTypes = defaultIconPropTypes

SendIcon.defaultProps = defaultPictographIconProps

export { SendIcon }
