import styled, { css } from 'styled-components'
import { CalendarIcon } from '../../design-tokens/icons/pictograph'
import { CalendarIcon as BrandCalendarIcon } from '../../design-tokens/icons/brand-ui-icons'
import { iconAttrs } from '../../design-tokens/icons/icons.styled'
import { IconProps } from '../../design-tokens/icons/icons.types'
import { Headline } from '../../design-tokens/typography'
import { MonthNavigationButton } from './MonthNavigationButton'
import { MonthPickerProps } from './MonthPicker.types'
import { Label as BrandLabel } from '../../design-tokens/brand-typography'

export const calendarShadow = ({ theme: { borderRadius } }) => `
  border-radius: ${borderRadius.md}rem;
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.06), 0px 1px 1px 0px rgba(0, 0, 0, 0.08),
    0px 3px 8px 0px rgba(0, 0, 0, 0.08), 0px 15px 22px 0px rgba(0, 0, 0, 0.04);
`

export const StyledCalendarIcon = styled(CalendarIcon).attrs<IconProps>(iconAttrs)<IconProps>``
export const StyledBrandCalendarIcon = styled(BrandCalendarIcon).attrs<IconProps>(iconAttrs)<IconProps>``

export const StyledMonthNavigationButton = styled(MonthNavigationButton)`
  position: static;
  padding: 0.375rem;
`

export const StyledContainer = styled.div<Pick<MonthPickerProps, 'selectsStart' | 'selectsEnd'> & { isBrand: boolean }>(
  ({ theme: { borderRadius, color, brand, fontWeight }, selectsStart, selectsEnd, isBrand }) => css`
    .react-datepicker-popper {
      border-radius: ${borderRadius.sm}rem;
    }

    .react-datepicker {
      ${isBrand &&
      css`
        font-family: ${brand.fontFamily.PostiFont};
      `}
      &__aria-live {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }
      &__month-text {
        ${(selectsStart || selectsEnd) &&
        `
          cursor: default;
          pointer-events: none;
          color: ${isBrand ? brand.color.gray30 : color.neutralPassiveGray};
        `}

        margin: 0.125px;
        width: 3.5rem;
        height: 3.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        border-radius: 50%;
        cursor: pointer;
        transition: all 100ms ease-in;
        font-weight: ${fontWeight.semiBold};
        background-color: ${color.neutralWhite};
        overflow: hidden;
        ${isBrand &&
        css`
          font-size: ${brand.fontSize.label.five}rem;
          font-weight: ${brand.fontWeight.medium};
        `}
        &:focus:not(:focus-visible) {
          border: none;
        }
        &:focus {
          outline: none;
          border: 2px solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
        }
        @media (hover: hover) {
          &:hover {
            background-color: ${isBrand ? brand.color.gray10 : color.neutralOnPressGray};
          }
        }

        &:active {
          background-color: ${isBrand ? brand.color.gray20 : '#cdd1d6'};
        }

        &__month-text--today {
          border: 2px solid ${isBrand ? brand.color.gray10 : color.neutralOnPressGray};
          @media (hover: hover) {
            &:hover {
              border: 2px solid #cdd1d6;
            }
            &:focus:hover {
              background-color: #e2e5e8;
              border: 2px solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
            }
          }

          &:active {
            border: 2px solid #b0b8bf;
          }

          &:focus:active {
            background-color: #b0b8bf;
            border: 2px solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
          }
        }
      }
      &__month--in-range {
        cursor: pointer;
        pointer-events: auto;
        color: ${isBrand ? brand.color.gray70 : color.neutralNetworkGray};
      }

      &__month--selected {
        background-color: ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
        color: ${color.neutralWhite};
        border: 2px solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
        @media (hover: hover) {
          &:hover {
            border: 2px solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
            color: ${color.neutralWhite};
          }
          &:hover,
          &:focus:hover {
            background-color: ${isBrand ? brand.color.postiOrange70 : '#0044a3'};
            border: 2px solid ${isBrand ? brand.color.postiOrange70 : '#0044a3'};
          }
          &:focus:hover {
            border: 2px solid ${isBrand ? brand.color.postiOrange5 : '#cdddf3'};
          }
        }
        &:active {
          border: 2px solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
          color: ${color.neutralWhite};
        }
        &:active,
        &:focus:active {
          background-color: ${isBrand ? brand.color.postiOrange80 : '#003784'};
          border: 2px solid ${isBrand ? brand.color.postiOrange80 : '#003784'};
        }
        &:focus,
        &:focus:active {
          border: 2px solid ${isBrand ? brand.color.postiOrange5 : '#cdddf3'};
        }
        &:focus:not(:focus-visible) {
          border: 2px solid transparent;
        }
      }
    }
    .react-datepicker-popper {
      ${calendarShadow}
    }
  `
)

export const StyledMonthPickerWrapper = styled.div(
  ({ theme: { spacing, color } }) => css`
    .react-datepicker {
      &__month {
        padding: ${spacing.space2}rem 0 ${spacing.space5}rem;
      }

      &__month-wrapper {
        display: flex;
        justify-content: space-evenly;
        width: 23rem;
        margin-bottom: ${spacing.space4}rem;

        &:last-of-type {
          margin-bottom: 0;
        }
      }
      &__header--custom {
        position: relative;

        &::before {
          width: 20.5rem;
          height: 2px;
          background-color: ${color.neutralGray5};
          content: ' ';
          position: absolute;
          bottom: 0;
          left: calc((100% - 20.5rem) / 2);
        }
      }
    }
  `
)

export const YearName = styled(Headline)`
  width: 100%;
  text-align: center;
`

export const YearNameLabel = styled(BrandLabel)`
  width: 100%;
  text-align: center;
  display: block;
`

export const HeaderContainer = styled.div(
  ({ theme: { spacing } }) => css`
    display: flex;
    align-items: center;
    padding: ${spacing.space4}rem;
  `
)
