import React, { ButtonHTMLAttributes } from 'react'
import styled, { DefaultTheme, StyledComponent, css } from 'styled-components'
import PropTypes, { Validator } from 'prop-types'
import { ArrowRightIcon } from '../../xyz'
import { DefaultIconProps, IconProps } from '../../design-tokens/icons/icons.types'
import { BrandSize, LinkButtonSize } from '../../utils/enums'
import { brandFontSize } from '../BrandButton/BrandButton.styles'
import { BrandTheme } from '@postidigital/posti-theme'

export interface LinkButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  disabled?: boolean
  icon?: React.ComponentType<DefaultIconProps>
  isBrand?: boolean
  size?: keyof typeof LinkButtonSize
}

export interface BrandLinkButtonProps extends LinkButtonProps {
  size?: keyof typeof LinkButtonSize
}

interface LinkButtonSubComponents {
  Main: StyledComponent<'button', DefaultTheme, LinkButtonProps, never>
  ButtonCommon: React.FC<Pick<LinkButtonProps, 'children' | 'icon'>>
}

interface BrandLinkButtonSubComponents {
  Main: StyledComponent<'button', DefaultTheme, BrandLinkButtonProps, never>
  ButtonCommon: React.FC<Pick<LinkButtonProps, 'icon' | 'children'>>
}

interface LinkButtonIconProps extends IconProps {
  size?: keyof typeof LinkButtonSize
}

const commonStyles = `
  display: inline-flex;
  align-items: center;
  letter-spacing: 0.0125rem;
  border: 1px solid transparent;
  background-color: transparent;
  border-radius: 0.25rem;

  &:focus:not(:focus-visible) {
    box-shadow: none;
    border: 1px solid transparent;
    outline: none;
  }
`

const brandLinkButtonPadding = {
  [BrandSize.xl]: `${BrandTheme.spacing.space6}rem 0`,
  [BrandSize.lg]: `${BrandTheme.spacing.space5}rem 0`,
  [BrandSize.md]: `1.125rem 0`,
  [BrandSize.sm]: `0.875rem 0`,
  [BrandSize.xs]: `0.875rem 0`,
}

// Min-heights + borders
const heights = {
  [BrandSize.xl]: `calc(4.5rem + 0.375rem)`,
  [BrandSize.lg]: `calc(4rem + 0.375rem)`,
  [BrandSize.md]: `calc(3.5rem + 0.375rem)`,
  [BrandSize.sm]: `calc(3rem + 0.375rem)`,
  [BrandSize.xs]: `calc(2.75rem + 0.375rem)`,
}

const StyledLinkButton = styled.button<LinkButtonProps>`
  ${({ theme: { color, fontWeight, fontSize, spacing }, disabled }) => css`
    ${commonStyles}
    color: ${color.signalBlue};
    padding: ${spacing.space1}rem 0;
    font-weight: ${fontWeight.semiBold};
    font-size: ${fontSize.body.three}rem;

    ${StyledIcon} path {
      fill: ${disabled ? color.neutralPassiveGray : color.signalBlue};
    }

    &[aria-disabled='true'] {
      color: ${color.neutralPassiveGray};
      pointer-events: none;
    }

    @media (hover: hover) {
      &:hover {
        color: ${color.signalHoverBlue};
        ${StyledIcon} path {
          fill: ${color.signalHoverBlue};
        }
      }
    }

    &:focus {
      outline: none;
      border: 1px solid ${color.signalLightBlue};
      box-shadow: 0px 0px 7px ${color.signalBlue};
      background-color: ${color.neutralWhite};
    }
  `}
`

const StyledIcon = styled(ArrowRightIcon)<IconProps>`
  ${({ theme: { spacing, iconSize } }) => css`
    flex-shrink: 0;
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
    margin-left: ${spacing.space2}rem;
  `}
`

const StyledBrandIcon = styled(ArrowRightIcon)<LinkButtonIconProps>`
  ${({ theme: { spacing }, size }) => css`
    flex-shrink: 0;
    width: ${size ? brandFontSize[size] : brandFontSize[LinkButtonSize.md]};
    height: ${size ? brandFontSize[size] : brandFontSize[LinkButtonSize.md]};
    margin-left: ${spacing.space2}rem;
  `}
`

const StyledBrandButton = styled.button<BrandLinkButtonProps>`
  ${({
    theme: {
      brand: { fontWeight, fontFamily, color, lineHeight },
    },
    size,
  }) => css`
    ${commonStyles}
    color: ${color.postiOrange70};
    padding: ${brandLinkButtonPadding[size]};
    min-height: ${heights[size]};
    border: 3px solid transparent;
    border-radius: 0;
    font-family: ${fontFamily.PostiFont};
    font-weight: ${fontWeight.bold};
    font-size: ${size ? brandFontSize[size] : brandFontSize[LinkButtonSize.md]};
    line-height: ${lineHeight.label};

    ${StyledBrandIcon} * {
      stroke: ${color.postiOrange70};
    }
    @media (hover: hover) {
      &:hover {
        color: ${color.postiOrange80};
        text-decoration: underline;

        ${StyledBrandIcon} * {
          stroke: ${color.postiOrange80};
        }
      }
    }

    &:active:not(:focus-visible) {
      color: ${color.postiOrange90};
      ${StyledBrandIcon} * {
        stroke: ${color.postiOrange90};
      }
    }

    &:focus {
      border-color: ${color.postiOrange60};
      outline: none;
      @media (hover: hover) {
        &:hover {
          color: ${color.postiOrange80};
          ${StyledBrandIcon} * {
            stroke: ${color.postiOrange80};
          }
        }
      }
      &:active {
        color: ${color.postiOrange90};
        ${StyledBrandIcon} * {
          stroke: ${color.postiOrange90};
        }
      }
      &[aria-disabled='true'] {
        border: 3px solid transparent;
      }
    }
    &:focus:not(:focus-visible) {
      border: 3px solid transparent;
      outline: none;
    }

    &[aria-disabled='true'] {
      color: ${color.gray30};
      pointer-events: none;
      user-select: none;
      ${StyledBrandIcon} * {
        stroke: ${color.gray30};
      }
    }
  `}
`

const LinkButtonCommon: React.FC<Pick<LinkButtonProps, 'icon' | 'children' | 'isBrand' | 'size'>> = (props) => {
  const { icon: Icon, children, isBrand, size } = props

  return (
    <>
      {children}
      {!!Icon &&
        (isBrand ? (
          <StyledBrandIcon as={Icon} aria-hidden={true} size={size} />
        ) : (
          <StyledIcon as={Icon} aria-hidden={true} />
        ))}
    </>
  )
}

export const LinkButton: React.FC<LinkButtonProps> & LinkButtonSubComponents = (props) => {
  const { icon: Icon, disabled, children, ...rest } = props
  return (
    <StyledLinkButton aria-disabled={disabled} {...rest}>
      <LinkButtonCommon icon={Icon}>{children}</LinkButtonCommon>
    </StyledLinkButton>
  )
}

export const BrandLinkButton: React.FC<BrandLinkButtonProps> & BrandLinkButtonSubComponents = (props) => {
  const { icon: Icon, disabled, children, size, ...rest } = props
  return (
    <StyledBrandButton aria-disabled={disabled} size={size} {...rest}>
      <LinkButtonCommon isBrand={true} size={size} icon={Icon}>
        {children}
      </LinkButtonCommon>
    </StyledBrandButton>
  )
}

const LinkButtonProptypes = {
  icon: PropTypes.elementType as Validator<React.ComponentType<DefaultIconProps>>,
}

LinkButton.Main = StyledLinkButton
LinkButton.ButtonCommon = LinkButtonCommon
LinkButton.propTypes = LinkButtonProptypes

BrandLinkButton.Main = StyledBrandButton
BrandLinkButton.ButtonCommon = LinkButtonCommon

BrandLinkButton.defaultProps = {
  size: LinkButtonSize.sm,
}

BrandLinkButton.propTypes = {
  ...LinkButtonProptypes,
  size: PropTypes.oneOf(Object.values(LinkButtonSize)),
}
