import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const MultipleUsersIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M17.56 18.54c0 .12.05.24.14.32.08.09.2.14.31.14h2.54a.44.44 0 00.37-.2.46.46 0 00.05-.43 4.05 4.05 0 00-3.24-2.52.44.44 0 00-.4.16.46.46 0 00-.08.43c.2.68.31 1.39.31 2.1zM17.22 14.9a2.46 2.46 0 002.36-1.69 2.56 2.56 0 00-.78-2.81 2.47 2.47 0 00-3.86 1 2.56 2.56 0 00.74 2.93c.44.36.98.56 1.54.56zM9.84 12.65a3.8 3.8 0 003.77-3.83A3.8 3.8 0 009.84 5a3.8 3.8 0 00-3.77 3.82 3.8 3.8 0 003.77 3.83zM9.9 13.56c-1.83 0-3.58.54-4.88 1.51A4.63 4.63 0 003 18.73V19h13.79v-.27c0-1.37-.73-2.69-2.02-3.66a8.21 8.21 0 00-4.87-1.51z"
      fill={color}
    />
  </SVG>
)

MultipleUsersIcon.propTypes = defaultIconPropTypes

MultipleUsersIcon.defaultProps = defaultPictographIconProps

export { MultipleUsersIcon }
