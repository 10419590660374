import React, { ComponentType, useState, useRef, useEffect, MutableRefObject } from 'react'
import PropTypes from 'prop-types'
import { useSpring, useChain, config, animated, useSpringRef } from 'react-spring'
import ResizeObserver from 'resize-observer-polyfill'

export interface AnimateProps {
  open: boolean
  style?: Record<string, unknown>
  duration?: number
  children?: React.ReactNode
}

export const useMeasure = (): (
  | { left: number; top: number; width: number; height: number }
  | { ref: MutableRefObject<undefined> }
)[] => {
  const ref = useRef()
  const [bounds, set] = useState({ left: 0, top: 0, width: 0, height: 0 })
  const [ro] = useState(() => new ResizeObserver(([entry]) => set(entry.contentRect)))

  useEffect(() => {
    if (ref.current) ro.observe(ref.current)
    return () => ro.disconnect()
  }, [ro])

  return [{ ref }, bounds]
}

const animationDuration = 200

/**
 * Animate.tsx is meant for expand/collapse content.
 * On expanse it makes room for content which it fades in.
 * On collapse it fades out content and then gradually set content height to 0.
 */
export const Animate: ComponentType<AnimateProps> = React.memo(
  ({ children, open: isOpen = false, duration = animationDuration, style }) => {
    const [bind, bounds] = useMeasure()
    const springHeightRef = useSpringRef()
    const springFadeRef = useSpringRef()

    const viewHeight = 'height' in bounds ? bounds.height : 0
    // Used as back-up value; if collapsing and children = false we want to have last known height to animate from to 0
    const previousViewHeight = useRef<number>(0)

    const springStyle = useSpring({
      ref: springHeightRef,
      config: {
        ...config.slow,
        duration,
      },
      from: { height: 0 },
      to: async (animate) => {
        if (isOpen) {
          await animate({ height: viewHeight })
          await animate({ opacity: 1, visibility: 'visible' })
          await animate({ height: 'auto' })
        } else {
          await animate({ height: viewHeight || previousViewHeight.current })
          await animate({ height: 0, visibility: 'hidden' })
        }
      },
    })

    const { opacity } = useSpring({
      ref: springFadeRef,
      config: {
        ...config.slow,
        duration,
      },
      delay: !isOpen ? 0 : duration,
      from: { opacity: 0 },
      to: { opacity: isOpen ? 1 : 0 },
    })

    previousViewHeight.current = viewHeight

    useChain(isOpen ? [springHeightRef, springFadeRef] : [springFadeRef, springHeightRef], [0, 0.02])

    return (
      <animated.div style={{ ...springStyle, ...style }}>
        {/* React Spiring RC has some known type conflicts with React, line below is a short term solution for the issue */}
        <animated.div {...bind} style={{ opacity: opacity as any, ...style }}>
          {children}
        </animated.div>
      </animated.div>
    )
  }
)

Animate.propTypes = {
  open: PropTypes.bool.isRequired,
  duration: PropTypes.number,
}

Animate.defaultProps = {
  open: false,
  duration: animationDuration,
}

Animate.displayName = 'Animate'

export default Animate
