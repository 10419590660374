import React from 'react'
import PropTypes from 'prop-types'
import { BrandButtonProps, BrandButtonSubComponents } from './BrandButton.types'
import { ButtonProptypes } from '../Button/Button'
import { useTheme } from '../../utils/useTheme'
import { StyledBrandInner, StyledBrandButton, StyledBrandIcon, brandButtonColors } from './BrandButton.styles'
import { StyledLoadingIcon } from '../Button/Button.styles'
import { BrandSize, BrandMode, Position, LoadingAnimationPresetBrandColor } from '../../utils/enums'
import { addNamespace } from '../../utils/helpers'

export const BrandButtonWithoutNamespace = React.forwardRef<HTMLButtonElement, BrandButtonProps>((props, ref) => {
  const {
    icon: Icon,
    contentMode,
    iconPosition,
    size,
    hasBackground,
    isLoading,
    loadingProps,
    children,
    disabled,
    ...rest
  } = props
  const theme = useTheme()
  const iconColor = disabled ? theme.brand.color.gray30 : brandButtonColors(hasBackground)[contentMode].color

  const isOnlyIcon = !React.Children.count(children) && !!Icon
  /** Progress button only  */
  const color = theme.brand.color.white
  const backgroundColor = brandButtonColors(hasBackground)[props.contentMode].background

  const loadingIconProps =
    props.contentMode === BrandMode.primary && hasBackground
      ? { color, backgroundColor }
      : { preset: LoadingAnimationPresetBrandColor.postiOrange60 }

  const RenderIcon = () => {
    return (
      <StyledBrandIcon
        as={Icon}
        iconPosition={iconPosition}
        color={iconColor}
        size={size}
        isOnlyIcon={isOnlyIcon}
        aria-hidden={true}
      />
    )
  }

  return (
    <StyledBrandButton
      ref={ref}
      aria-disabled={disabled}
      contentMode={contentMode}
      size={size}
      hasBackground={hasBackground}
      isOnlyIcon={isOnlyIcon}
      isLoading={isLoading}
      type="button"
      {...rest}
    >
      {!!Icon && iconPosition === Position.left && <RenderIcon />}
      <StyledBrandInner size={size} contentMode={contentMode}>
        {children}
      </StyledBrandInner>
      {!!Icon && iconPosition === Position.right && <RenderIcon />}
      {isLoading && !disabled && <StyledLoadingIcon {...(loadingProps ?? loadingIconProps)} />}
    </StyledBrandButton>
  )
})

const brandButtonSubComponents: BrandButtonSubComponents = {
  Button: StyledBrandButton,
  Icon: StyledBrandIcon,
  Inner: StyledBrandInner,
  LoadingIcon: StyledLoadingIcon,
}

BrandButtonWithoutNamespace.displayName = 'BrandButton'

export const ButtonDefaultProps = {
  contentMode: BrandMode.primary,
  iconPosition: Position.left,
}

BrandButtonWithoutNamespace.propTypes = {
  ...ButtonProptypes,
  size: PropTypes.oneOf(Object.values(BrandSize)),
  contentMode: PropTypes.oneOf(Object.values(BrandMode)),
}

BrandButtonWithoutNamespace.defaultProps = {
  ...ButtonDefaultProps,
  size: BrandSize.md,
}

export const BrandButton = addNamespace(BrandButtonWithoutNamespace, brandButtonSubComponents)
