import React from 'react'

export enum PropCategory {
  Component = 'Component',
  Story = 'Story',
}

export enum FontWeight {
  Regular = 'regular',
  Medium = 'medium',
  Bold = 'bold',
  UltraBold = 'ultraBold',
}

/** Sizes */

export enum BrandSize {
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
  xxs = 'xxs',
  xxxs = 'xxxs',
}

export enum BrandFloatingActionButtonSize {
  md = 'md',
  sm = 'sm',
}

export enum BulletSize {
  xxl = 'xxl',
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
  xxs = 'xxs',
}

export enum BrandBulletSize {
  lg = 'lg',
  md = 'md',
  sm = 'sm',
}

export enum LinkButtonSize {
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
}

export enum LinkSize {
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
}

export enum Size {
  lg = 'lg',
  md = 'md',
  sm = 'sm',
}

export enum LoadingAnimationSize {
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl',
}

export enum TableSize {
  lg = 'lg',
  sm = 'sm',
}

export enum Mode {
  primary = 'primary',
  secondary = 'secondary',
}

export enum BrandMode {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  negativeActionButton = 'negativeActionButton',
  negativeCloseButton = 'negativeCloseButton',
}

export enum Status {
  completed = 'completed',
  active = 'active',
  incomplete = 'incomplete',
}

export enum StepStateValues {
  activated = 'activated',
  completed = 'completed',
  warning = 'warning',
}

export enum LoadingAnimationPresetColor {
  brandPink = 'brandPink',
  brandRed = 'brandRed',
  brandPetrol = 'brandPetrol',
  brandNavy = 'brandNavy',
  brandForest = 'brandForest',
  neutralNetworkGray = 'neutralNetworkGray',
}

export enum LoadingAnimationPresetBrandColor {
  postiOrange60 = 'postiOrange60',
}

export enum buttonTypeOption {
  rightArrow = 'rightArrow',
  bottomArrow = 'bottomArrow',
  noButton = 'noButton',
}

export enum NotificationType {
  alert = 'alert',
  informational = 'informational',
  success = 'success',
  warning = 'warning',
  text = 'text',
}

export enum LabelDirections {
  centred = 'centred',
  left = 'left',
}

export enum Position {
  left = 'left',
  right = 'right',
}

export enum RadioMode {
  default = 'default',
  success = 'success',
}

export enum RadioSize {
  small = 'small',
  default = 'default',
}

export enum Placement {
  /* Positioned below of wrapped element and right sides are aligned */
  'bottom-end' = 'bottom-end',
  /* Positioned below of wrapped element and left sides are aligned */
  'bottom-start' = 'bottom-start',
  /* Positioned below of wrapped element and tooltip is centered */
  'bottom' = 'bottom',
  /* Positioned above of wrapped element and right sides are aligned */
  'top-end' = 'top-end',
  /* Positioned above of wrapped element and left sides are aligned */
  'top-start' = 'top-start',
  /* Positioned above of wrapped element and tooltip is centered */
  'top' = 'top',
  /* Positioned left side of wrapped element */
  'start' = 'start',
  /* Positioned right side of wrapped element */
  'end' = 'end',
}

export enum Locales {
  en = 'en',
  fi = 'fi',
  sv = 'sv',
}

export enum MobileMode {
  /* On mobile Modal will look like a BottomSheet component */
  default = 'default',
  /* On mobile Modal will look like a Modal in the alert mode */
  alert = 'alert',
  /* On mobile Modal will look like a Modal in the default mode (fullscreen) */
  fullscreen = 'fullscreen',
}

export enum ModalScroll {
  body = 'body',
  content = 'content',
}

export enum ModalWidth {
  'max-content' = 'max-content',
  'min-content' = 'min-content',
  'fit-content' = 'fit-content',
  'auto' = 'auto',
}

export enum KeyboardKeys {
  ArrowUp = 'ArrowUp',
  ArrowDown = 'ArrowDown',
  ArrowLeft = 'ArrowLeft',
  ArrowRight = 'ArrowRight',
  Enter = 'Enter',
  Space = ' ',
  Home = 'Home',
  Escape = 'Escape',
  Tab = 'Tab',
  End = 'End',
}
