import styled, { css } from 'styled-components'
import { ChevronRightIcon } from '../../design-tokens/icons/pictograph'
import { IconProps } from '../../design-tokens/icons/icons.types'
import { Position } from '../../utils/enums'
import { IconButtonProps } from './IconButton.types'

export const StyledIconButton = styled.button<Omit<IconButtonProps, 'icon'>>`
  ${({ theme: { zIndex, color, spacing, brand }, buttonLabel, iconPosition, isBrand }) => css`
    display: flex;
    align-items: center;
    border-radius: 3rem;
    z-index: ${zIndex.zIndex0};
    padding: ${spacing.space1}rem;
    border: 1px solid transparent;
    cursor: pointer;
    background-clip: padding-box;
    position: relative;
    background-color: transparent;
    transition: all 100ms ease-in;
    user-select: none;

    ${!!buttonLabel &&
    iconPosition === Position.left &&
    css`
      padding: ${spacing.space2}rem ${spacing.space2}rem ${spacing.space2}rem ${spacing.space1}rem;
      span {
        margin: 0 0 0 ${spacing.space2}rem;
      }
    `}

    ${!!buttonLabel &&
    iconPosition === Position.right &&
    css`
      padding: ${spacing.space2}rem ${spacing.space1}rem ${spacing.space2}rem ${spacing.space2}rem;
      span {
        margin: 0 ${spacing.space2}rem 0 0;
      }
    `}
    @media (hover: hover) {
      &:hover {
        background-color: ${isBrand ? brand.color.gray10 : color.neutralOnPressGray};
        ${isBrand &&
        css`
          color: ${brand.color.gray80};
          border: 1px solid ${brand.color.gray20};
          svg * {
            stroke: ${brand.color.gray60};
          }
        `}
      }
    }

    &:focus {
      border: 1px solid ${color.signalLightBlue};
      box-shadow: 0px 0px 7px ${color.signalBlue};
      background-color: transparent;
      outline: 0;
      &[aria-disabled='true'] {
        box-shadow: none;
        border: 1px solid transparent;
      }
      ${isBrand &&
      css`
        border: 1px solid transparent;
        box-shadow: inset 0 0 0 2px ${brand.color.postiOrange60};
        color: ${brand.color.gray90};
        &[aria-disabled='true'] {
          border: 1px solid ${brand.color.gray5};
        }
      `}
    }

    &:focus:not(:focus-visible) {
      border: 1px solid transparent;
      background-color: ${isBrand ? 'transparent' : color.neutralOnPressGray};
      box-shadow: none;
    }

    &[aria-disabled='true'] {
      color: ${isBrand ? brand.color.gray30 : color.neutralPassiveGray};
      background-color: ${isBrand ? brand.color.gray10 : color.neutralGray2};
      cursor: default;
      pointer-events: none;

      ${isBrand &&
      css`
        border: 1px solid ${brand.color.gray5};

        svg * {
          stroke: ${brand.color.gray30};
        }
      `}

      span {
        color: ${isBrand ? brand.color.gray30 : color.neutralPassiveGray};
      }
    }

    ${isBrand &&
    css`
      color: ${brand.color.gray70};

      svg * {
        stroke: ${brand.color.gray50};
      }

      &:active:not(:focus-visible) {
        background-color: ${brand.color.gray20};
        border: 1px solid ${brand.color.gray30};
        color: ${brand.color.gray90};

        svg * {
          stroke: ${brand.color.gray70};
        }
      }
    `}
  `}
`

export const StyledIcon = styled(ChevronRightIcon)<IconProps>`
  ${({ theme: { iconSize } }) => css`
    flex-shrink: 0;
    width: ${iconSize.s}rem;
    height: ${iconSize.s}rem;
  `}
`
