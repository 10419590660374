import React, { HTMLAttributes } from 'react'
import styled, { css, StyledComponent, DefaultTheme } from 'styled-components'
import TableContext from '../../composite-components/Table/TableContext'
import Tablelvl2Context from '../../composite-components/Table/Tablelvl2Context'
import { TableSize } from '../../utils/enums'
import {
  StyledArrowDownIcon,
  StyledBrandArrowDownIcon,
  TableHeaderWrapper,
} from '../../composite-components/DataTable/DataTable.components'

export interface TableCellProps extends HTMLAttributes<HTMLTableCellElement> {
  className?: string
  isBrand?: boolean
}
interface TableCellSubComponents {
  Th: StyledComponent<'th', DefaultTheme, { size: keyof typeof TableSize }, never>
  Td: StyledComponent<'td', DefaultTheme, { size: keyof typeof TableSize }, never>
}

const tdPaddingVertical: Record<TableSize, number> = {
  [TableSize.lg]: 1.5,
  [TableSize.sm]: 0.625,
}

const thPaddingVertical: Record<TableSize, number> = {
  [TableSize.lg]: 1.375,
  [TableSize.sm]: 1.625,
}

const paddingHorizontal: Record<TableSize, number> = {
  [TableSize.lg]: 1,
  [TableSize.sm]: 0.25,
}

const brandPaddingVertical: Record<TableSize, number> = {
  [TableSize.lg]: 0.75,
  [TableSize.sm]: 0.5,
}

export const TableCellFixer = styled.div``

const StyledTh = styled.th<{ size: keyof typeof TableSize; isBrand: boolean }>(
  ({ theme: { color, fontSize, fontWeight, lineHeight, brand, breakpoint }, size, isBrand }) => css`
    position: relative;
    vertical-align: top;

    & > ${TableCellFixer} {
      padding: ${isBrand
        ? `${brandPaddingVertical[size]}rem ${brand.spacing.space3}rem`
        : `${thPaddingVertical[size]}rem ${paddingHorizontal[size]}rem`};
      color: ${isBrand ? brand.color.gray70 : color.neutralNetworkGray};
      font-weight: ${isBrand ? brand.fontWeight.bold : fontWeight.semiBold};
      font-size: ${isBrand
        ? size === TableSize.lg
          ? brand.fontSize.body.four
          : brand.fontSize.body.five
        : size === TableSize.lg
          ? fontSize.headline.six
          : fontSize.headline.seven}rem;
      line-height: ${isBrand
        ? brand.lineHeight.body
        : size === TableSize.lg
          ? lineHeight.headline.six
          : lineHeight.headline.seven};

      @media screen and (max-width: ${breakpoint.sm}) {
        padding: ${isBrand ? `${brandPaddingVertical[size]}rem 0.25rem` : `${tdPaddingVertical[size]}rem 0.25rem`};
        ${TableHeaderWrapper} {
          width: calc(100% - 1.5rem - 0.5rem);
        }
      }
      @media screen and (max-width: ${breakpoint.md}) {
        display: flex;
      }
    }
  `
)

const StyledTd = styled.td<{ size: keyof typeof TableSize; isBrand: boolean }>(
  ({ theme: { color, fontSize, lineHeight, brand, breakpoint }, size, isBrand }) => css`
    position: relative;

    & > ${TableCellFixer} {
      padding: ${isBrand
        ? `${brandPaddingVertical[size]}rem ${brand.spacing.space3}rem`
        : `${tdPaddingVertical[size]}rem ${paddingHorizontal[size]}rem`};
      color: ${isBrand ? brand.color.gray70 : color.neutralNetworkGray};
      font-size: ${isBrand
        ? size === TableSize.lg
          ? brand.fontSize.body.four
          : brand.fontSize.body.five
        : size === TableSize.lg
          ? fontSize.body.three
          : fontSize.body.four}rem;
      line-height: ${isBrand
        ? brand.lineHeight.body
        : size === TableSize.lg
          ? lineHeight.body.three
          : lineHeight.body.four};

      @media screen and (max-width: ${breakpoint.sm}) {
        padding: ${isBrand ? `${brandPaddingVertical[size]}rem 0.25rem` : `${tdPaddingVertical[size]}rem 0.25rem`};
      }
    }
  `
)

export const TableCell: React.FC<TableCellProps> & TableCellSubComponents = ({ children, ...rest }) => {
  const table = React.useContext(TableContext)
  const tablelvl2 = React.useContext(Tablelvl2Context)
  const isHeadCell = tablelvl2 && tablelvl2.variant === 'head'
  const Component = isHeadCell ? StyledTh : StyledTd
  const size = table && table.size ? table.size : TableSize.lg

  return (
    <Component isBrand={table.isBrand} size={size} {...rest}>
      <TableCellFixer>{children}</TableCellFixer>
    </Component>
  )
}

TableCell.Th = StyledTh
TableCell.Td = StyledTd
