import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const WarningIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M16.07 3.3a1 1 0 00-.72-.3h-6.7a1 1 0 00-.72.3L3.28 8.1a1 1 0 00-.28.7v6.4c0 .25.1.5.28.7l4.65 4.8c.19.19.45.3.72.3h6.7a1 1 0 00.72-.3l4.65-4.8a1 1 0 00.28-.7V8.8a1 1 0 00-.28-.7l-4.65-4.8zm-2.72 12.75a1.35 1.35 0 11-2.7 0 1.35 1.35 0 012.7 0zm.34-9.45H10.3l.56 6.75h2.26l.56-6.75z"
      fill={color}
    />
  </SVG>
)

WarningIcon.propTypes = defaultIconPropTypes

WarningIcon.defaultProps = defaultPictographIconProps

export { WarningIcon }
