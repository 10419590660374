import styled, { css } from 'styled-components'
import { FilterButton } from '../../../basic-components/FilterButton'
import { Notification } from '../../../basic-components/Notification'

export const StyledTimeContainer = styled.div(
  ({ theme: { spacing } }) => css`
    padding: ${spacing.space3}rem 0 0;
    position: relative;
  `
)

export const StyledTimeContainerHeader = styled.div``

export const StyledFilterButton = styled(FilterButton)(
  ({ theme: { spacing } }) => css`
    margin-bottom: ${spacing.space2}rem;
    width: 100%;
    &:last-of-type {
      margin-bottom: 0;
    }
    label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  `
)

export const StyledNotification = styled(Notification).attrs({ 'aria-live': 'assertive' })(
  ({ theme: { spacing } }) => css`
    margin: ${spacing.space2}rem 0 0.625rem;
  `
)

export const StyledLabelPart = styled.span``
