import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const ArrowRightIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Path d="M4 12H20M20 12L14 6M20 12L14 18" />
  </SVG>
)

ArrowRightIcon.propTypes = defaultIconPropTypes
ArrowRightIcon.defaultProps = defaultPictographIconProps

export { ArrowRightIcon }
