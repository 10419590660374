import React from 'react'
import PropTypes from 'prop-types'
import { CheckmarkIcon } from '../../design-tokens/icons/pictograph'
import { CheckmarkIcon as BrandCheckmarkIcon } from '../../design-tokens/icons/brand-ui-icons'
import { useTheme } from '../../utils/useTheme'
import { StyledBox, StyledCheck, StyledInput, StyledLabel, StyledRoot } from './Checkbox.style'
import { UIMessage } from '../UIMessage'
import { Position } from '../../utils/enums'
import { CheckboxProps, CheckboxSubComponents } from './Checkbox.types'
import { addNamespace } from '../../utils/helpers'
import withBrand from '../../utils/withBrand'

const CheckboxWithoutNamespace = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const {
    label,
    labelPosition,
    onChange,
    id,
    disabled,
    tabIndex,
    isInvalid,
    errorMessage,
    isBrand,
    large,
    checked,
    ...rest
  } = props
  const theme = useTheme()
  const messageId = id ? `${id}-message` : `checkbox-message`

  const renderCheckbox = () => (
    <StyledBox aria-disabled={disabled} tabIndex={null} large={large}>
      <StyledInput
        aria-invalid={isInvalid && !checked}
        aria-describedby={messageId}
        id={id}
        ref={ref}
        type="checkbox"
        {...rest}
        aria-disabled={disabled}
        onChange={!disabled ? onChange : null}
        isInvalid={isInvalid && !checked}
        isBrand={isBrand}
        checked={checked}
      />
      <StyledCheck>
        {isBrand
          ? checked && <BrandCheckmarkIcon color={theme.brand.color.white} />
          : checked && <CheckmarkIcon color={theme.color.neutralWhite} />}
      </StyledCheck>
    </StyledBox>
  )

  return (
    <StyledRoot isBrand={isBrand} {...rest}>
      {label ? (
        <StyledLabel labelPosition={labelPosition} isBrand={isBrand} aria-disabled={disabled}>
          {labelPosition === Position.left && label}
          {renderCheckbox()}
          {labelPosition === Position.right && label}
        </StyledLabel>
      ) : (
        renderCheckbox()
      )}
      <div id={messageId} aria-live="assertive">
        {errorMessage && isInvalid && !checked && !disabled && <UIMessage isBrand={isBrand} message={errorMessage} />}
      </div>
    </StyledRoot>
  )
})

CheckboxWithoutNamespace.propTypes = {
  checked: PropTypes.bool,
  isInvalid: PropTypes.bool,
  errorMessage: PropTypes.string,
  large: PropTypes.bool,
  label: PropTypes.node,
  labelPosition: PropTypes.oneOf(Object.values(Position)),
  tabIndex: PropTypes.number,
  onChange: PropTypes.func.isRequired,
}

CheckboxWithoutNamespace.defaultProps = {
  id: 'checkbox',
  checked: false,
  disabled: false,
  isInvalid: false,
  large: false,
  labelPosition: Position.left,
  tabIndex: 0,
}

CheckboxWithoutNamespace.displayName = 'Checkbox'

const BrandCheckboxWithoutNamespace = withBrand(CheckboxWithoutNamespace)

const checkboxSubComponents: CheckboxSubComponents = {
  Box: StyledBox,
  Input: StyledInput,
  Check: StyledCheck,
  Root: StyledRoot,
  Label: StyledLabel,
  UIMessage: UIMessage,
}

export const Checkbox = addNamespace(CheckboxWithoutNamespace, checkboxSubComponents)
export const BrandCheckbox = addNamespace(BrandCheckboxWithoutNamespace, checkboxSubComponents)
