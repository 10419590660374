import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const HeartIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props} fill="none">
    <Path
      d="M20.48 6.67a4.92 4.92 0 00-5.88-2.44c-.73.23-1.4.63-1.96 1.17l-.64.58-.62-.56a4.9 4.9 0 00-4.27-1.35A4.87 4.87 0 003.06 9.6c.17 1 .65 1.92 1.37 2.64l7.03 7.2.54.55.54-.55 7.02-7.19a4.78 4.78 0 00.92-5.59z"
      fill={color}
    />
  </SVG>
)

HeartIcon.propTypes = defaultIconPropTypes

HeartIcon.defaultProps = defaultPictographIconProps

export { HeartIcon }
