import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../icons.styled'

export const DamagedGoodIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.8049 9.94806H13.2628L15.4134 3.93749C15.4134 3.93749 15.4446 3.84565 15.4446 3.70859C15.4433 3.3172 15.1322 3 14.7495 3C14.535 3 14.344 3.10032 14.2162 3.25432L5.63124 12.9272C5.54028 13.0311 5.5 13.1893 5.5 13.3455C5.5 13.7361 5.8111 14.0526 6.19511 14.0526H10.7373L8.58592 20.0632C8.58592 20.0632 8.55468 20.1558 8.55468 20.2928C8.55606 20.6835 8.86716 21 9.25048 21C9.46435 21 9.65601 20.9004 9.78379 20.7464L18.3681 11.0735C18.459 10.9696 18.5 10.8107 18.5 10.6552C18.5 10.2646 18.1889 9.94806 17.8049 9.94806"
      fill={color}
    />
  </SVG>
)

DamagedGoodIcon.propTypes = defaultIconPropTypes

DamagedGoodIcon.defaultProps = defaultPictographIconProps
