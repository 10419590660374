import styled, { css, DefaultTheme } from 'styled-components'
import { TrackFillProps } from './Slider.types'
import { StyledTooltip } from '../Tooltip/Tooltip.style'
import { THUMB_WIDTH } from './utils'
import { Label } from '../../design-tokens/typography'
import { Label as BrandLabel } from '../../design-tokens/brand-typography'

export const SliderContainer = styled.div<{ isBrand?: boolean }>(
  ({ theme: { spacing, color, brand }, isBrand }) => css`
    width: 100%;
    height: ${spacing.space5}rem;
    display: flex;
    align-items: center;
    position: relative;

    ${isBrand &&
    css`
      max-width: 15rem;
      margin: 0 ${brand.spacing.space2}rem;
    `}

    ${Label}, ${BrandLabel} {
      color: ${color.neutralWhite};
    }
  `
)

export const Track = styled.div<{ isBrand?: boolean }>(
  ({ theme: { color, brand }, isBrand }) => css`
    background-color: ${isBrand ? brand.color.gray10 : color.neutralPassiveGray};
    position: relative;
    height: 6px;
    width: 100%;
    border-radius: 3px;
  `
)

export const TrackFill = styled.div.attrs<TrackFillProps>(({ thumbX }) => ({
  style: {
    width: thumbX + THUMB_WIDTH * 0.5,
  },
}))<TrackFillProps & { isBrand: boolean; disabled?: boolean }>(({ theme: { color, brand }, isBrand, disabled }) => {
  return css`
        left: 0;
        height: 6px;
        border-radius: 3px;
        background-color: ${
          isBrand
            ? disabled
              ? brand.color.gray30
              : brand.color.postiOrange60
            : disabled
              ? color.neutralPassiveGray
              : color.signalBlue
        };
        position: absolute;
        top: 0;
      }
    `
})

const thumbStyle = ({
  theme: { spacing, color, borderRadius, brand },
  isBrand,
}: {
  theme: DefaultTheme
  isBrand?: boolean
}) => css`
  appearance: none;
  padding: 0;
  margin: 0;
  width: ${spacing.space5}rem;
  height: ${spacing.space5}rem;
  background: ${color.neutralWhite};
  border: 3px solid ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
  box-sizing: border-box;
  border-radius: ${borderRadius.sm}rem;
  cursor: pointer;
  ${isBrand &&
  css`
    transition: all 200ms ease-in;
  `}
  @media (hover: hover) {
    &:hover {
      border-color: ${isBrand ? brand.color.postiOrange70 : color.signalHoverBlue};
    }
    ${isBrand &&
    css`
      &:active {
        border-color: ${brand.color.postiOrange80};
      }
    `}
  }
`

const thumbFocusedStyle = ({ theme: { color, brand }, isBrand }: { theme: DefaultTheme; isBrand?: boolean }) => css`
  box-shadow: ${isBrand
    ? `0 0 0 2px ${brand.color.white}, 0 0 0 4px ${brand.color.postiOrange60}`
    : `0 0 0.4375rem ${color.signalBlue}`};
  border-color: ${isBrand ? brand.color.postiOrange60 : color.signalBlue};
`

const thumbDisabledStyle = ({ theme: { color, brand }, isBrand }: { theme: DefaultTheme; isBrand?: boolean }) => css`
  background: ${isBrand ? brand.color.white : color.neutralPassiveGray};
  border-color: ${isBrand ? brand.color.gray30 : color.neutralPassiveGray};
  cursor: initial;
`

export const Thumb = styled.input<{ isBrand?: boolean }>(
  ({ theme: { zIndex } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    width: 100%;
    height: 100%;
    background: none;
    margin: 0;
    padding: 0;

    &:focus {
      outline: none;
      border: none;
      z-index: ${zIndex.zIndexDefault};

      ::-webkit-slider-thumb {
        ${thumbFocusedStyle}
      }

      ::-moz-range-thumb {
        ${thumbFocusedStyle}
      }

      ::-ms-thumb {
        ${thumbFocusedStyle}
      }
    }

    &:focus:not(:focus-visible) {
      outline: none;
      ::-webkit-slider-thumb {
        box-shadow: none;
      }

      ::-moz-range-thumb {
        box-shadow: none;
      }

      ::-ms-thumb {
        box-shadow: none;
      }
    }

    ::-webkit-slider-thumb {
      ${thumbStyle}
    }

    ::-moz-range-thumb {
      ${thumbStyle}
    }

    ::-ms-thumb {
      ${thumbStyle}
    }

    ::-webkit-slider-runnable-track {
      background: none;
    }

    ::-moz-range-track {
      background: none;
    }

    ::-ms-track {
      background: none;
      border: none;
      color: transparent;
    }

    ::-ms-fill-lower {
      background: none;
    }

    ::-ms-fill-upper {
      background: none;
    }

    ::-ms-tooltip {
      display: none;
    }

    :disabled {
      ::-webkit-slider-thumb {
        ${thumbDisabledStyle}
      }

      ::-moz-range-thumb {
        ${thumbDisabledStyle}
      }

      ::-ms-thumb {
        ${thumbDisabledStyle}
      }
    }
  `
)

export const LabelContainer = styled.div<{ isBrand?: boolean }>(
  ({ theme: { spacing, brand }, isBrand }) => css`
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: ${isBrand ? `${brand.spacing.space2}rem 0 0 0` : `${spacing.space3}rem 0 0 0`};
    ${isBrand &&
    css`
      max-width: 15rem;
      margin-left: ${brand.spacing.space2}rem;
    `}
  `
)

export const Tooltip = styled(StyledTooltip)(
  ({ theme: { spacing } }) => css`
    :before {
      width: ${spacing.space2}rem;
      height: ${spacing.space2}rem;
      bottom: -${spacing.space1 * 0.5}rem;
    }
  `
)
