import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Path, SVG } from '../brand-icons.styled'

const WeightIcon: React.FC<DefaultIconProps> = ({ color, width, ...props }) => (
  <SVG {...props}>
    <Path
      d="M20.7844 19.8203L18.7745 8.82026C18.6877 8.34517 18.2738 8 17.7908 8L6.20852 8C5.72555 8 5.3116 8.34518 5.2248 8.82028L3.21519 19.8203C3.103 20.4344 3.57466 21 4.19891 21L19.8007 21C20.425 21 20.8966 20.4344 20.7844 19.8203Z"
      stroke={color}
    />
    <Path
      d="M9.03614 16.4232V14.7606L10.4236 16.4232H11.7635L10.0008 14.415L11.698 12.5823H10.4236L9.03614 14.1241V12.5823H8V16.4232H9.03614Z"
      fill={color}
    />
    <Path
      d="M13.9477 16.5C15.2816 16.5 16.0617 15.6221 15.9962 14.2942H13.8584V15.1667H14.8588C14.704 15.4465 14.4003 15.6056 13.9537 15.6056C13.2748 15.6056 12.858 15.1228 12.858 14.4973C12.858 13.8882 13.251 13.4053 13.9418 13.4053C14.3884 13.4053 14.7099 13.5864 14.8945 13.9047L15.7937 13.4877C15.4543 12.8731 14.7933 12.5 13.9477 12.5C12.7091 12.5 11.8218 13.356 11.8218 14.4973C11.8218 15.6385 12.7389 16.5 13.9477 16.5Z"
      fill={color}
    />
    <Path d="M15 8V6C15 4.34315 13.6569 3 12 3V3C10.3431 3 9 4.34315 9 6V8" stroke={color} />
  </SVG>
)

WeightIcon.propTypes = defaultIconPropTypes
WeightIcon.defaultProps = defaultPictographIconProps

export { WeightIcon }
