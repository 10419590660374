import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { Rect, Path, SVG } from '../brand-icons.styled'

const ShelfIcon: React.FC<DefaultIconProps> = ({ ...props }) => (
  <SVG {...props}>
    <Rect x="6" y="4" width="5" height="5" rx="1" />
    <Rect x="13.002" y="12" width="5" height="6" rx="1" />
    <Rect x="14.002" y="6" width="4" height="3" rx="1" />
    <Rect x="6" y="14" width="4" height="4" rx="1" />
    <Path d="M21 9L3 9" />
    <Path d="M21 20L21 4" />
    <Path d="M21 18L3 18" />
    <Path d="M3 20L3 4" />
  </SVG>
)

ShelfIcon.propTypes = defaultIconPropTypes
ShelfIcon.defaultProps = defaultPictographIconProps

export { ShelfIcon }
