import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

export const LocateIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M12 14.5C13.3807 14.5 14.5 13.3807 14.5 12C14.5 10.6193 13.3807 9.5 12 9.5C10.6193 9.5 9.5 10.6193 9.5 12C9.5 13.3807 10.6193 14.5 12 14.5Z"
      fill={color}
    />
    <Path
      fillRule="evenodd"
      d="M13.5 2H10.5V5.1611C7.83804 5.74228 5.74228 7.83807 5.1611 10.5H2V13.5H5.1611C5.74228 16.1619 7.83804 18.2577 10.5 18.8389V22H13.5V18.8389C16.162 18.2577 18.2577 16.1619 18.8389 13.5H22V10.5H18.8389C18.2577 7.83807 16.162 5.74228 13.5 5.1611V2ZM16.5 12C16.5 14.4853 14.4853 16.5 12 16.5C9.51471 16.5 7.5 14.4853 7.5 12C7.5 9.51471 9.51471 7.5 12 7.5C14.4853 7.5 16.5 9.51471 16.5 12Z"
      fill={color}
    />
  </SVG>
)

LocateIcon.propTypes = defaultIconPropTypes

LocateIcon.defaultProps = defaultPictographIconProps
