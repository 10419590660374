import styled, { css } from 'styled-components'
import { Label, Body } from '../../design-tokens/brand-typography'
import { IconProps } from '../../design-tokens/icons/icons.types'
import { ArrowRightIcon } from '../../brand'

export const StyledListContainer = styled.button<{ selected: boolean; isCard?: boolean; isCheckbox?: boolean }>(
  ({ theme: { brand, borderRadius }, selected, isCard }) => css`
    padding: ${brand.spacing.space3}rem ${brand.spacing.space5}rem;
    border: none;
    background: transparent;
    border-bottom: ${isCard ? 'none' : `1px solid ${brand.color.gray10}`};
    display: flex;
    transition: all 100ms ease-in;
    width: 100%;

    ${isCard &&
    css`
      border-radius: ${borderRadius.sm}rem;
      box-shadow:
        0px 15px 22px 0px rgba(0, 0, 0, 0.04),
        0px 3px 8px 0px rgba(0, 0, 0, 0.08),
        0px 1px 1px 0px rgba(0, 0, 0, 0.08),
        0px 3px 1px 0px rgba(0, 0, 0, 0.06);
    `}

    /** Add visual gap between each flex-child */
    & > * + * {
      margin-inline-start: ${brand.spacing.space4}rem;
    }
    @media (hover: hover) {
      &:hover {
        background-color: ${brand.color.gray10};
        ${RadioCheck}, ${CheckboxCheck} {
          background-color: ${selected ? brand.color.postiOrange60 : brand.color.white};
          border: 3px solid ${selected ? brand.color.postiOrange60 : brand.color.gray60};
        }
      }
    }

    &:focus {
      outline: none;
      box-shadow: inset 0px 0px 0px 3px ${brand.color.postiOrange60};
      ${isCard &&
      css`
        box-shadow:
          inset 0px 0px 0px 3px ${brand.color.postiOrange60},
          0px 15px 22px 0px rgba(0, 0, 0, 0.04),
          0px 3px 8px 0px rgba(0, 0, 0, 0.08),
          0px 1px 1px 0px rgba(0, 0, 0, 0.08),
          0px 3px 1px 0px rgba(0, 0, 0, 0.06);
      `}
      &:not(:focus-visible) {
        box-shadow: none;
        ${isCard &&
        css`
          box-shadow:
            0px 15px 22px 0px rgba(0, 0, 0, 0.04),
            0px 3px 8px 0px rgba(0, 0, 0, 0.08),
            0px 1px 1px 0px rgba(0, 0, 0, 0.08),
            0px 3px 1px 0px rgba(0, 0, 0, 0.06);
        `}
      }
    }
    &:active {
      background-color: ${brand.color.gray20};
      ${RadioCheck}, ${CheckboxCheck} {
        background-color: ${selected ? brand.color.postiOrange60 : brand.color.white};
        border: 3px solid ${selected ? brand.color.postiOrange60 : brand.color.gray70};
      }
    }
    &:disabled {
      pointer-events: none;
      ${RadioCheck}, ${CheckboxCheck} {
        background-color: ${selected ? brand.color.gray30 : brand.color.gray10};
        border: 3px solid ${brand.color.gray30};
      }
      ${StyledLabel}, ${Body} {
        color: ${brand.color.gray30};
      }
    }

    svg {
      min-width: 1.5rem;
    }
  `
)

export const StyledLabel = styled(Label)`
  ${({
    theme: {
      brand: { spacing },
    },
  }) => css`
    margin: 0 0 ${spacing.space1}rem;
  `}
`

export const StyledContent = styled.div`
  text-align: left;
  cursor: pointer;
  flex: 1 1 100%;

  & > * {
    cursor: pointer;
  }
`

export const StyledBody = styled(Body)`
  margin: 0;
`

export const RadioCheck = styled.div<{ selected: boolean; disabled: boolean }>`
  ${({ theme: { brand }, selected, disabled }) => css`
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    border: 3px solid ${brand.color.gray50};
    border-radius: 50%;
    background-color: ${brand.color.white};

    ${selected &&
    css`
      background-color: ${brand.color.postiOrange60};
      border: 3px solid ${brand.color.postiOrange60};
      display: flex;
      align-items: center;
      justify-content: center;
      &:after {
        border-radius: 50%;
        content: '';
        width: 0.5rem;
        height: 0.5rem;
        display: block;
        background-color: ${disabled ? brand.color.gray10 : brand.color.white};
      }
    `}
  `}
`

export const CheckboxCheck = styled.div<{ selected: boolean; disabled: boolean }>`
  ${({ theme: { brand, borderRadius }, selected, disabled }) => css`
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    border: 3px solid ${selected ? brand.color.postiOrange60 : brand.color.gray50};
    border-radius: ${borderRadius.xs}rem;

    & > svg {
      min-width: 1rem;
    }

    ${selected &&
    css`
      background-color: ${disabled ? brand.color.gray30 : brand.color.postiOrange60};
      display: flex;
      align-items: center;
      justify-content: center;
    `}
  `}
`

export const StyledIcon = styled(ArrowRightIcon)<IconProps>``
