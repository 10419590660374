import React, { InputHTMLAttributes } from 'react'
import PropTypes from 'prop-types'
import { StyledComponent, DefaultTheme } from 'styled-components'
import {
  StyledSwitch,
  StyledInput,
  StyledLabel,
  StyledBackground,
  StyledCircle,
  getMainSwitchColor,
  StyledCheckmarkIcon,
  StyledMinusIcon,
  StyledBrandCheckmarkIcon,
  StyledBrandMinusIcon,
  CircleProps,
} from './Switch.style'
import { StyledRoot } from '../Radio/Radio.style'
import { UIMessage } from '../UIMessage'
import { UIMessageProps, UIMessageSubComponents } from '../UIMessage/UIMessage'
import { Position } from '../../utils/enums'
import { DefaultIconProps } from '../../design-tokens/icons/icons.types'
import { addNamespace } from '../../utils/helpers'
import withBrand from '../../utils/withBrand'

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  checked?: boolean
  disabled?: boolean
  isInvalid?: boolean
  label?: string | number | JSX.Element
  labelPosition?: Position | keyof typeof Position
  spacing?: number
  isBrand?: boolean
  errorMessage?: string
}

export interface SwitchSubComponents {
  Main: StyledComponent<'div', DefaultTheme, Pick<SwitchProps, 'isInvalid' | 'isBrand' | 'checked'>, never>
  Input: StyledComponent<'input', DefaultTheme, Pick<SwitchProps, 'disabled' | 'checked'>, never>
  Background: StyledComponent<
    'div',
    DefaultTheme,
    Pick<SwitchProps, 'isInvalid' | 'isBrand' | 'checked' | 'disabled'>,
    never
  >
  Circle: StyledComponent<'div', DefaultTheme, CircleProps, never>
  BrandCheckmarkIcon: StyledComponent<React.FC<DefaultIconProps>, DefaultTheme, NonNullable<unknown>, never>
  CheckmarkIcon: StyledComponent<React.FC<DefaultIconProps>, DefaultTheme, NonNullable<unknown>, never>
  Root: StyledComponent<'div', DefaultTheme, { isBrand?: boolean }, never>
  Label: StyledComponent<
    'label',
    DefaultTheme,
    Pick<SwitchProps, 'labelPosition' | 'isBrand' | 'spacing' | 'disabled'>,
    never
  >
  UIMessage: React.FC<UIMessageProps> & UIMessageSubComponents
}

const SwitchWithoutNamespace = React.forwardRef<HTMLInputElement, SwitchProps>((props, ref): JSX.Element => {
  const { checked, isInvalid, isBrand, disabled, label, spacing, labelPosition, onChange, id, errorMessage, ...rest } =
    props
  const showInvalid = isInvalid && !checked
  const showDisabled = (isInvalid && disabled) || disabled
  const iconColor = getMainSwitchColor({ disabled: showDisabled, isInvalid: showInvalid, checked, isBrand })
  const messageId = id ? `${id}-message` : `switch-message`

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e)
  }

  const renderSwitch = () => (
    <StyledSwitch isInvalid={isInvalid} isBrand={isBrand} checked={checked}>
      <StyledInput
        id={id}
        type="checkbox"
        ref={ref}
        checked={checked}
        onChange={handleChange}
        tabIndex={disabled ? -1 : 0}
        aria-disabled={showDisabled}
        disabled={showDisabled}
        aria-invalid={showInvalid}
        aria-describedby={messageId}
        {...rest}
      />

      <StyledBackground checked={checked} disabled={disabled} isInvalid={showInvalid} isBrand={isBrand} />
      <StyledCircle checked={checked} disabled={disabled} isInvalid={showInvalid} isBrand={isBrand}>
        {checked ? (
          isBrand ? (
            <StyledBrandCheckmarkIcon width="1.5em" height="1.5em" color={iconColor} />
          ) : (
            <StyledCheckmarkIcon width="1.5em" height="1.5em" color={iconColor} />
          )
        ) : isBrand ? (
          <StyledBrandMinusIcon width="1em" height="1em" color={iconColor} />
        ) : (
          <StyledMinusIcon width="1em" height="1em" color={iconColor} />
        )}
      </StyledCircle>
    </StyledSwitch>
  )

  return (
    <StyledRoot isBrand={isBrand}>
      {label ? (
        <StyledLabel labelPosition={labelPosition as Position} spacing={spacing} isBrand={isBrand} disabled={disabled}>
          {labelPosition === Position.left && label}
          {renderSwitch()}
          {labelPosition === Position.right && label}
        </StyledLabel>
      ) : (
        renderSwitch()
      )}
      <div id={messageId} aria-live="assertive">
        {errorMessage && showInvalid && !disabled && <UIMessage isBrand={isBrand} message={errorMessage} />}
      </div>
    </StyledRoot>
  )
})

SwitchWithoutNamespace.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  isInvalid: PropTypes.bool,
  spacing: PropTypes.number,
  labelPosition: PropTypes.oneOf(Object.values(Position)),
  isBrand: PropTypes.bool,
  errorMessage: PropTypes.string,
}

SwitchWithoutNamespace.defaultProps = {
  checked: false,
  disabled: false,
  isInvalid: false,
  labelPosition: Position.left,
  isBrand: false,
}

SwitchWithoutNamespace.displayName = 'Switch'

const BrandSwitchWithoutNamespace = withBrand(SwitchWithoutNamespace)

const switchSubComponents: SwitchSubComponents = {
  Main: StyledSwitch,
  Input: StyledInput,
  Background: StyledBackground,
  Circle: StyledCircle,
  BrandCheckmarkIcon: StyledBrandCheckmarkIcon,
  CheckmarkIcon: StyledCheckmarkIcon,
  Root: StyledRoot,
  Label: StyledLabel,
  UIMessage: UIMessage,
}

export const Switch = addNamespace(SwitchWithoutNamespace, switchSubComponents)
export const BrandSwitch = addNamespace(BrandSwitchWithoutNamespace, switchSubComponents)
