import React from 'react'
import { DefaultIconProps, defaultIconPropTypes, defaultPictographIconProps } from '../icons.types'
import { SVG, Path } from '../icons.styled'

const DoorstepIcon: React.FC<DefaultIconProps> = ({ color, ...props }) => (
  <SVG {...props}>
    <Path
      d="M9.54 13.5a1.9 1.9 0 011.9 1.87v2.07a.19.19 0 00.18.18h7a.38.38 0 00.37-.37V4.12A1.12 1.12 0 0017.86 3H10.3a1.14 1.14 0 00-1.13 1.13v9.18c0 .05.02.1.05.13.04.04.09.06.14.06h.18zm8.7-2.25a.75.75 0 01-.47.7.76.76 0 01-.83-.17.75.75 0 01.54-1.28.76.76 0 01.75.75zm-7.18-2.63v-3-.75h6.04v4.5h-6.04v-.75zM19.74 18.75H12.2a.76.76 0 00-.7.47.75.75 0 00-.06.29v.72a.74.74 0 00.46.71c.1.04.2.06.3.06h7.55a.76.76 0 00.76-.75v-.75a.75.75 0 00-.76-.75z"
      fill={color}
    />
    <Path d="M10.3 20.25v-4.88-.74H7.54v2.46h-.87v-2.47H4.26a.76.76 0 00-.76.76V21h6.8v-.75z" fill={color} />
  </SVG>
)

DoorstepIcon.propTypes = defaultIconPropTypes

DoorstepIcon.defaultProps = defaultPictographIconProps

export { DoorstepIcon }
